import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_NGUOI_GIAO_HOAN_TAT,
} from "../../../redux/reducers/workingReducer/_index";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import {
  NGUOI_GIAO_VIEC_LIST_FINISH,
  USER_READ_ALL,
  USER_READ_ALL_DROPDOWN,
  CONFIG_IS_FORCE,
} from "../../../apis/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import LoaderSpinner from "../../loader-spinner/_index";
import { convertDayTime } from "../../../funcs/convertDayTime";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";
import { Checkcolor } from "../../../funcs/checkColor";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const WorkingTaskFinishJSX = () => {
  _onClickOther();

  const [completedEarly, setCompletedEarly] = useState<any>(null);
  const [bienDoTreHan, setbienDoTreHan] = useState<any>(null);

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const shouldLog = useRef(true);

  const link = window.location.pathname;

  useEffect(() => {
    fetchBienDoTreHan();
  }, []);
  // Ví dụ sử dụng JavaScript
  // const responseData = { "Data": [{"BienDoTreHan": 10 }]};

  // Trích xuất giá trị BienDoTreHan từ dữ liệu API
  //const bienDoTreHan = responseData.Data[0].BienDoTreHan;
  async function fetchBienDoTreHan() {
    const accessToken = localStorage.getItem("accessToken"); // => Laco rieng biet
    try {
      await ServiceUtils.getV2(CONFIG_IS_FORCE, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setbienDoTreHan(res?.data?.Data[0]?.BienDoTreHan);
          setCompletedEarly(res?.data?.Data[0]?.CompletedEarly);
        } else {
          toast.error("Tải dữ liệu thất bại");
        }
      });
    } catch (error) {
      toast.error("Tải dữ liệu thất bại");
    }
  }

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_GIAO_VIEC_LIST_FINISH,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
      },
      "",
      "",
      "",
      workingData.searchFilter.selectPhongBan
    );
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  useEffect(() => {
    _fetchData(workingData.pageCurrent, workingData.choosePage);
  }, [workingData.choosePage, workingData.pageCurrent]);

  const _onClickConfirmTaskWithId = (id: any) => {
    context.setFuncs("confirmFinishTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("taskNeedConfirm");
    context.setTaskId(id);
    context.handleOpen();
  };

  const checkTimeConfirm = (priorityLevel: any, deadlineConfirm: any) => {
    var todayTime: any = new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
    )
      .toISOString()
      .slice(0, 23);

    // timeCheck is milliseconds
    const timeEarly = Date.parse(deadlineConfirm) - Date.parse(todayTime);
    const timeLate = Date.parse(todayTime) - Date.parse(deadlineConfirm);

    // ưu tiên duyệt trong 6h
    if (priorityLevel === 1) {
      if (timeEarly > 0) {
        if (convertDayTime(timeEarly / 60000) > convertDayTime(180)) {
          return (
            <p className="text-red-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        } else {
          return (
            <p className="text-green-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        }
      } else {
        return (
          <p className="text-red-600 font-semibold">
            Trễ thời hạn duyệt {convertDayTime(timeLate / 60000)}
          </p>
        );
      }
    }

    // khẩn cấp duyệt trong 4h
    if (priorityLevel === 2) {
      if (timeEarly > 0) {
        if (convertDayTime(timeEarly / 60000) > convertDayTime(120)) {
          return (
            <p className="text-red-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        } else {
          return (
            <p className="text-green-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        }
      } else {
        return (
          <p className="text-red-600 font-semibold">
            Trễ thời hạn duyệt {convertDayTime(timeLate / 60000)}
          </p>
        );
      }
    }

    // bình thường duyệt trong 8h
    if (priorityLevel === 17) {
      if (timeEarly > 0) {
        if (convertDayTime(timeEarly / 60000) > convertDayTime(240)) {
          return (
            <p className="text-red-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        } else {
          return (
            <p className="text-green-600 font-semibold">
              Còn {convertDayTime(timeEarly / 60000)} để duyệt
            </p>
          );
        }
      } else if (timeEarly < 0) {
        return (
          <p className="text-red-600 font-semibold">
            Trễ thời hạn duyệt {convertDayTime(timeLate / 60000)}
          </p>
        );
      } else {
        return null;
      }
    }
  };

  const _renderStatus = (item: any) => {
    const currentTime = new Date().getTime();
    const deadlineTime = new Date(item?.DeadLine).getTime();
    const newDeadline = new Date(item?.NewDeadline).getTime();
    const assignmentDate = new Date(item?.AssignmentDate).getTime();
    const finishedDate = new Date(item?.FinishedDate).getTime();
    const isInProgres =
      assignmentDate <= currentTime && currentTime <= deadlineTime;
    const isWithinExtraTime = currentTime <= newDeadline;
    const isWithinExtraTime_ =
      deadlineTime <= finishedDate && finishedDate <= newDeadline;

    if (item?.TaskStatus === 4) {
      if (!item?.IsCompleted_History) {
        if (isInProgres) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      } else {
        if (assignmentDate <= finishedDate && finishedDate <= deadlineTime) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime_) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      }
    } else if (item?.TaskStatus === 5) {
      const timeEarlyCompleted = new Date(
        deadlineTime - ((deadlineTime - assignmentDate) / 100) * completedEarly
      ).getTime();
      if (timeEarlyCompleted >= finishedDate && finishedDate <= deadlineTime) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Sớm hạn
          </p>
        );
      } else if (
        timeEarlyCompleted < finishedDate &&
        finishedDate <= deadlineTime
      ) {
        return (
          <p className="bg-pink-700 text-white w-max p-1 rounded-md text-xs font-semibold">
            Đúng hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    } else if (item?.TaskStatus === 2 || item?.TaskStatus === 3) {
      if (isInProgres) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong tiến độ
          </p>
        );
      } else if (isWithinExtraTime) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    }
  };

  useEffect(() => {
    if (appData.userList === null && shouldLog.current) {
      shouldLog.current = false;

      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          // toast.error("Tải dữ liệu thất bại")
        }
      };
      takeData();
    }
  });

  return (
    <>
      {workingData.taskFinish === null ? (
        // <div className="flex justify-center items-center flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-2 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.taskFinish.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.taskFinish.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className=" w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>

                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày bắt đầu</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>

                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày kết thúc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button>
                    </div>
                  </th> */}
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button>
                    </div>
                  </th> */}

                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p>
                      <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button>
                    </div>
                  </th> */}

                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người đánh giá</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>

                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người đánh giá</p>
                    </div>
                  </th> */}
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn duyệt</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.taskFinish === null ? (
                  <tr></tr>
                ) : (
                  workingData.taskFinish.data?.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="border border-slate-300 space-y-0.5">
                        <b className="lg:hidden">
                          <span className="lg:hidden">
                            {index +
                              1 +
                              workingData.pageCurrent * workingData.choosePage}
                            .&nbsp;
                          </span>
                          {item.TaskName}
                        </b>
                        <p className="hidden lg:flex">{item.TaskName}</p>
                        {item?.TaskOwner?.trim().toUpperCase() !=
                          item?.TaskReviewer?.trim().toUpperCase() &&
                        item?.TaskReviewer?.trim().toUpperCase() !=
                          appData?.userData?.Id?.trim().toUpperCase() ? (
                          <p className="border border-green-500 text-green-500 w-max p-1 rounded-md text-xs font-semibold">
                            Việc đã chuyển người đánh giá
                          </p>
                        ) : null}
                        {item?.TaskOwner?.trim().toUpperCase() !=
                          item?.TaskReviewer?.trim().toUpperCase() &&
                        item?.TaskReviewer?.trim().toUpperCase() ==
                          appData?.userData?.Id?.trim().toUpperCase() ? (
                          <p className="border border-yellow-500 text-yellow-500 w-max p-1 rounded-md text-xs font-semibold">
                            Việc được chuyển đánh giá
                          </p>
                        ) : null}
                        {/* <p className="lg:hidden">
                          <span className="text-xs"> Người giao: </span>
                          <span className="font-semibold">
                            {item.TaskOwnerName}
                          </span>
                        </p> */}
                        <p className="lg:hidden">
                          <span className="text-xs">Người đánh giá: </span>
                          <span className="font-semibold">
                            {item.TaskReviewerName}
                          </span>
                        </p>

                        <p className="lg:hidden">
                          <span className="text-xs">Người nhận: </span>
                          <span className="font-semibold">
                            {item.RecipientName}
                          </span>
                        </p>
                        {item.SupporterName !== null && (
                          <p className="lg:hidden">
                            <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                            <span className="text-slate-600">
                              {item.SupporterName}
                            </span>
                          </p>
                        )}

                        {/* <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">Thời hạn: </span>
                            <span className="italic">
                              {moment(item.AssignmentDate).format("HH:mm")}
                              &nbsp;
                              {moment(item.AssignmentDate).format("DD/MM")}
                            </span>
                            &nbsp;-&nbsp;
                            <span className="italic">
                              {moment(item.DeadLine).format("HH:mm")}&nbsp;
                              {moment(item.DeadLine).format("DD/MM")}
                            </span>
                          </p>
                        </div> */}

                        <div className="lg:hidden flex justify-between items-center">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold mr-2 ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="work-status flex gap-1 flex-wrap">
                            {item.TaskStatus === 2 ? (
                              <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                Chưa nhận
                              </p>
                            ) : null}
                            {item.TaskStatus === 3 ? (
                              <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                Chưa xử lý
                              </p>
                            ) : null}
                            {item?.TaskStatus === 4 ? (
                              <>
                                {!item?.IsCompleted_History ? (
                                  <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                    Đang xử lý
                                  </p>
                                ) : (
                                  <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
                                    Đã báo cáo chờ duyệt
                                  </p>
                                )}
                              </>
                            ) : null}
                            {item.TaskStatus === 5 ? (
                              <>
                                <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Hoàn tất
                                </p>
                              </>
                            ) : null}
                            {<>{_renderStatus(item)}</>}
                            {item.NumberReturnWork > 0 && (
                              <p className="text-red-500 text-xs lg:text-sm font-semibold">
                                Bị trả {item.NumberReturnWork} lần
                              </p>
                            )}
                          </div>
                          <div className="lg:hidden ml-3 ">
                            <div className="dropdown2">
                              {/* button options */}
                              <button
                                className="dropbtn2"
                                onClick={() => _onClickFuncs(index.toString())}
                              >
                                ...
                              </button>

                              {/* options fields */}
                              <div
                                id={index.toString()}
                                className="dropdown-content2 text-xs font-semibold"
                              >
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickEditTaskWithId(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <p
                          style={{
                            color: Checkcolor(item.PriorityLevelID, appData),
                          }}
                          className={`font-bold ${Checkcolor(
                            item.PriorityLevelID,
                            appData
                          )}`}
                        >
                          {item.PriorityLevelName}
                        </p>
                      </td>

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>
                            {moment(item.AssignmentDate).format("DD/MM/YYYY")}
                          </p>
                          <p>
                            {moment(item.AssignmentDate).format("HH:mm:ss")}
                          </p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                        </div>
                      </td>

                      {/* <td className="hidden lg:table-cell border border-slate-300">
                        {item.TaskOwnerName}
                      </td> */}

                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.TaskReviewerName}
                        {item?.TaskOwner?.trim().toUpperCase() !=
                          item?.TaskReviewer?.trim().toUpperCase() && (
                          <p className="text-xs">
                            <span className="text-slate-600">Người giao:</span>{" "}
                            {item.TaskOwnerName}
                          </p>
                        )}
                      </td>

                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.RecipientName}
                        {item.SupporterName && (
                          <p className="text-xs">
                            <span className="text-slate-600">Hỗ trợ:</span>{" "}
                            {item.SupporterName}
                          </p>
                        )}
                      </td>

                      {/* <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.TaskReviewerName}
                      </td> */}

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.DeadlineConfirm && (
                          <>
                            <p>
                              {moment(item.DeadlineConfirm).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                            <p>
                              {moment(item.DeadlineConfirm).format("HH:mm")}
                            </p>
                            <p>
                              {checkTimeConfirm(
                                item.PriorityLevelID,
                                item.DeadlineConfirm
                              )}
                            </p>
                          </>
                        )}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickEditTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskFinishJSX;
