const reportView = `
<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <link rel="stylesheet" href="reset.css">
        <link rel="stylesheet" href="style.css"> -->
        <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"> -->
        <script src="https://cdn.tailwindcss.com"></script>
        <title>Document</title>
    </head>
    <body style="font-size: 13px;">
        <div class="border-solid mx-[10%] my-[5%]">
            <form action="#" method="GET">
                <div class="mx-[5%] my-[3%]">
                    <header class="h-[9rem] flex border-b-[1rem] border-b-orange-400 space-x-3"
                    style="margin-left: -5.5%; margin-right: -5.5%;">
                        <article>
                            <img id="lacoImage" style="width: 8rem; height: 6rem; margin-left: 20%; padding-right: 10%; border-right: 1px solid gray;" src="../Asset1.png" alt="">
                        </article>
                        <article class="my-0">
                            <div class="ml-5">
                            <h2 class="text-blue-400 font-bold" style="font-size: 26px;">
                                LACO TRADE SERVICE CORPORATION
                            </h2>
                            <p class="text-xs">Địa chỉ: Biệt thự Chủ động Anna, Khu nhà ở Minh Ân, X. Xuân Thới Đông, H. Hóc Môn, TP.HCM <br>
                                MST: 0307287200 <br>
                                Website: <a href="www.lacocorp.vn">www.lacocorp.vn</a>
                            </p>
                            </div>
                        </article>   
                    
                    </header>
                    <div>                  
                        <div class="flex justify-center " >
                            <h1 id="nameOfReport" class="text-orange-400 font-bold text-3xl my-[3%] ">BÁO CÁO CÔNG VIỆC</h1>
                            <!-- <h1 style="font-weight: bold;">CÔNG VIỆC VÀ KẾ HOẠCH HÀNH ĐỘNG</h1> -->
                        </div>
                        <div style="font-size: 13px" class="flex flex-col space-y-1">

                            <div class="w-full h-max flex justify-center items-center space-x-3">
                                <p class="font-semibold text-md">LOẠI BÁO CÁO</p>
                            </div>
                            <div class="w-full h-max flex justify-center items-center space-x-3">
                                <div class="w-max h-max flex space-x-1 items-center">
                                    <input 
                                        id="reportDay"
                                        class="w-3 h-3"
                                        type="checkbox"
                                        disabled
                                        readOnly
                                    />
                                    <p>NGÀY</p>
                                </div>

                                <div class="w-max h-max flex space-x-1 items-center">
                                    <input
                                        id="reportMonth" 
                                        class="w-3 h-3"
                                        type="checkbox"
                                        disabled
                                        readOnly
                                    />
                                    <p>THÁNG</p>
                                </div>

                                <div class="w-max h-max flex space-x-1 items-center">
                                    <input 
                                        id="reportQuarter"
                                        class="w-3 h-3"
                                        type="checkbox"
                                        disabled
                                        readOnly
                                    />
                                    <p>QUÝ</p>
                                </div>

                                <div class="w-max h-max flex space-x-1 items-center">
                                    <input
                                        id="reportYear" 
                                        class="w-3 h-3"
                                        type="checkbox"
                                        disabled
                                        readOnly
                                    />
                                    <p>NĂM</p>
                                </div>

                                <div class="w-max h-max flex space-x-1 items-center">
                                    <input
                                        id="reportCreateOwnwer" 
                                        class="w-3 h-3"
                                        type="checkbox"
                                        disabled
                                        readOnly
                                    />
                                    <p>TỰ TẠO</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end items-stretch">

                        </div>
                        <br>

                        <br>
                        <div classname="space-y-3" style="font-size: 13px;font-weight: bold;">
                            <div classname="flex flex-col" style="width: 100%;">
                                <p classname="w-full" >Kính gửi: <span id="taskOwner">fafafa</span></p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Ông/Bà">
                                    <option selected>Ông</option>
                                    <option value="1">Bà</option>
                                    </select>
                                    <input style="height: 1.3rem; width: 10rem;border: 0cap;" type="text" value="" id="input"> -->
                                <p classname="w-full">Chức vụ: <span id="levelBoss"></span></p>
                                <p classname="w-full">Phòng ban: <span id="phongBanBoss"></span></p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                    <option selected>Giám đốc</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </div>
                            <div classname="flex flex-col" style="width: 100%;">
                                <p classname="w-full">Tôi tên: <span id="nameStaff"></span></p>
                                <!-- <input style="height: 1.3rem; width: 13.9rem; border: 0cap;"  type="text" value="" id="input">   
                                    <i class="recipient_subttl">Chức vụ : </i>
                                    <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                        <option selected>Nhân viên</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select> -->
                                <p classname="w-full">Chức vụ: <span id="levelStaff"></span></p>
                                <p classname="w-full">Phòng Ban: <span id="phongBanStaff"></span></p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Phòng Ban">
                                    <option selected>Phần mềm</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </div>
                        </div>
                        <br>
                    </div>
                    <div class=" section_1">
                        <h6 style="font-weight: bold;">I. CÁC CÔNG VIỆC ĐANG CHỊU TRÁCH NHIỆM</h6>

                        <div style="margin: 10px auto;">
                            <div id="fromTextDate" class="" style="font-size: 15px;margin-left: 15px;">
                            </div>
                            <p style="font-size: 15px;margin-left: 15px;">Tôi hiện đang chịu trách nhiệm thực hiện những công việc như sau: </p>
                        </div>
                        
                        <table style="font-size: 12px; width: 100%;  ">
                            <div style="background-color: #0051ff;">
                                <tr style="font-weight: bold; height: 2rem; font-size: 15px;">
                                    <th style="color:white; border: 2px solid black;" scope="col">STT</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Nội dung công việc</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Mô tả</th>
                                </tr>
                            </div>
                            <tbody id="bodyOfTaskResponsible">
                                <tr>
                                    <th style="border: 2px solid black; height: 2rem;" scope="row">1</th>
                                    <td style="border: 2px solid black;">
                                    </td>
                                    <td class="" style="border: 2px solid black;">
                                        ok
                                    </td>                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class=" section_2">
                        <h6 style="font-weight: bold;margin: 15px auto;">II.	KẾT QUẢ</h6>
                        <!-- <table class="table table-bordered table-hover border-dark"> -->
                        <div class="flex flex-col px-4 w-full h-max">
                            <p>Tổng việc đã nhận: <span id="receiverTask"></span></p>
                            <p>Hoàn thành: <span id="completeTask"></span></p>
                            <p>Hỗ trợ: <span id="supportTask"></span></p>
                            <p class="pt-3">Kết quả: </p> 

                            <div class="flex flex-col w-full h-max px-4 pb-3">
                                <p>+ Xuất sắc: <span id="perfectResult"></span></p>
                                <p>+ Tốt: <span id="goodResult"></span></p>
                                <p>+ Trung bình: <span id="aveResult"></span></p>
                                <p>+ Kém: <span id="badResult"></span></p>
                            </div>

                            <p>Năng suất lao động: <span id="workingCapacity"></span></p>
                            <p>Năng suất hỗ trợ: <span id="supportCapacity"></span></p>
                            <p>Năng lực làm việc: <span id="workingLevel"></span></p>
                            <p>Năng lực hỗ trợ: <span id="supportLevel"></span></p>
                        </div>
                        <div id="resultText">
                        </div>
                       
                    </div>

                    <div class=" section_2">
                        <h6 style="font-weight: bold;margin: 15px auto;">III.	THƯỞNG PHẠT</h6>
                        <div id="bonusOrPayoff"></div>
                    </div>

                    <div class="section_3">
                        <h6 style="font-weight: bold;margin:15px auto">IV. KẾ HOẠCH HÀNH ĐỘNG</h6>
                        <div id="planOfAction"></div>
                        <div class="section_3--content" style="font-size: 12px;">

                        </div>
                    </div>
                    <div class="review" style="font-size: 12px;">
                    </div>
                    <!-- <div class="btn_submit" style="width: 100%; text-align: center;">
                        <button type="submit" class="btn btn-primary">
                            Gui
                        </button>
                        </div> -->
                    
                </div>
            </form>
            <footer>
                <img style=" width:100%;" src="Asset1.svg" alt="">
            </footer>
        </div>
    </body>
</html>
`

export default reportView;