import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONCHANGE_USERLIST, ONLOAD_USERLIST } from "../../../../redux/reducers/settingReducer/_index";

import AddIcon from "@mui/icons-material/Add";

import { takePermission } from "../../../../funcs";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";
import { USER_READ_ALL } from "../../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import { unsignedToAccented } from "../../../../helpers/handleHelper";
import ServiceUtils from "../../../../utils/services";

const ListOfEmployeeUI = () => {
  const menu = takePermission(10);

  const settingData = useSelector((state: any) => state.settingReducer);
  const [users, setUser] = useState<any>([]);

  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const _onClickCreateListOfEmployee = (id: any) => {
    context.setFuncs("createListOfEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  const onKeyUpSearch = (e: any) => {
    const value = e.target.value;

    const users = settingData.userListOriginals || [];

    if (!value) {
      dispatch(ONCHANGE_USERLIST(users));
    } else {
      const valueFilter = unsignedToAccented(value.trim().toLowerCase());

      const userFilters = users.filter((p: any) => {
        const fullName = unsignedToAccented(
          (p.FullName || "").trim().toLowerCase()
        );

        return (
          fullName.includes(valueFilter) ||
          fullName.startsWith(valueFilter) ||
          fullName.endsWith(valueFilter) ||
          fullName.indexOf(valueFilter) > -1 ||
          fullName.lastIndexOf(valueFilter) > -1
        );
      });

      dispatch(ONCHANGE_USERLIST(userFilters));
    }
  };

  useEffect(() => {
    if (users.length <= 0) {
      setUser(settingData.userList || []);
    }
  }, []);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(USER_READ_ALL, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_USERLIST(res.data.Data));
              }
            });
        };

        takeData();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại.");
      }
    }
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm nhân sự.."
            onKeyUp={onKeyUpSearch}
          />

          <button
            className={`
              p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateListOfEmployee}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default ListOfEmployeeUI;
