import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const systemReducer = createSlice({
  name: "systemReducer",
  initialState: {
    // navbar
    mainMenu: [],
    subMenu: [],
    navBarButton: "",
    showingMenu: sessionStorage.getItem("whatMainOptionsShowing"),
    navBarMain: [
      {
        id: "1",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "2",
        name: "workingOptions",
        active: "false",
      },
      {
        id: "3",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "4",
        name: "workingTableOptions",
        active: "false",
      },
      {
        id: "5",
        name: "workingTableOptions",
        active: "false",
      },
    ],
    isDrive: false,
    drives: [],
    spreadrums: []
  },
  reducers: {
    ONMOVE_SPREADRUM: (state: any, actions: PayloadAction<any>) => {
      const spreadrums = [...state.spreadrums];

      const newSpreadRums = [];

      for (let i = 0; i < spreadrums.length; i++) {
        if (spreadrums[i].id == actions.payload) {
          newSpreadRums.push(spreadrums[i]);

          break;
        } else {
          newSpreadRums.push(spreadrums[i]);
        }
      }

      state.spreadrums = newSpreadRums;
    },
    ONINIT_SPREADRUM: (state: any, actions: PayloadAction<any>) => {
      const spreadrums = [actions.payload || {}];

      state.spreadrums = spreadrums;
    },
    ONADD_SPREADRUM: (state: any, actions: PayloadAction<any>) => {
      const data = actions.payload || {};

      const spreadrums = [...state.spreadrums];

      const checkExistData = spreadrums.find((p: any) => p.id == data.id);

      if (!checkExistData) {
        spreadrums.push(actions.payload || {});

        state.spreadrums = spreadrums;
      }
    },
    ONREMOVE_SPREADRUM: (state: any, actions: PayloadAction<any>) => {
      let spreadrums = [...state.spreadrums];

      spreadrums = spreadrums.filter((p: any) => p.id != (actions.payload || {}).id);

      state.spreadrums = spreadrums;
    },
    ONOPEN_DRIVE: (state: any) => {
      state.isDrive = true;
    },
    ONCLOSE_DRIVE: (state: any) => {
      state.isDrive = false;
    },
    ONCHANGE_DRIVE: (state: any, actions: PayloadAction<any>) => {
      state.drives = actions.payload || [];
    },
    ONCHANGE_NAVBAR_BUTTON: (state: any) => {
      if (state.navBarButton === "true") {
        state.navBarButton = "false";
      } else {
        state.navBarButton = "true";
      }
      sessionStorage.setItem("isShowButtonNavbar", state.navBarButton);
    },
    ONSET_NAVBAR_OPTIONS_AT_FIRST: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.navBarMain.forEach((item: any) => {
        if (item.id === actions.payload) {
          item.active = "true";
        } else {
          item.active = "false";
        }
      });
    },
    ONCHANGE_NAVBAR_CLOSE_RESPONSIVE: (state: any) => {
      state.navBarButtonResponsive = false;
    },
    ONCHANGE_NAVBAR_RESPONSIVE: (state: any) => {
      state.navBarButtonResponsive = true;
    },
    ONSET_NAVBAR_BUTTON_AT_FIRST: (state: any, actions: PayloadAction<any>) => {
      state.navBarButton = actions.payload;

      sessionStorage.setItem("isShowButtonNavbar", state.navBarButton);
    },
    // new thing
    ONCHANGE_NAVBAR_OPTIONS: (state: any, actions: PayloadAction<any>) => {
      sessionStorage.setItem("isShowButtonNavbar", "true");
      state.navBarButton = "true";

      const formId = parseInt(actions.payload);

      state.mainMenu.forEach((item: any) => {
        if (
          item.FormId.toString() == formId ||
          formId == 0
        ) {
          sessionStorage.setItem(
            "whatMainOptionsShowing",
            formId == 0 ? 0 : item.FormId
          );

          state.showingMenu = formId;
        } else {
          return;
        }
      });
    },
    ONCHANGE_MENULIST: (state: any) => {
      const tempMenuList = localStorage.getItem("menuList");
      state.subMenu = [];
      state.mainMenu = [];
      if (tempMenuList === null) {
        return;
      } else {
        const menuList = JSON.parse(tempMenuList);

        menuList.forEach((item: any) => {
          if (item.PerActive === true && item.ParentFormId === 0) {
            state.mainMenu.push(item);
          } else if (item.PerActive === true && item.ParentFormId !== 0) {
            state.subMenu.push(item);
          } else {
            return;
          }
        });
      }
      // state.mainMenu.forEach((item: any, index: any) => {
      //   console.log(item);
      // })
    },
  },
});

export const {
  ONSET_NAVBAR_BUTTON_AT_FIRST,
  ONCHANGE_NAVBAR_OPTIONS,
  ONCHANGE_NAVBAR_RESPONSIVE,
  ONCHANGE_MENULIST,
  ONCHANGE_NAVBAR_CLOSE_RESPONSIVE,
  ONSET_NAVBAR_OPTIONS_AT_FIRST,
  ONCHANGE_NAVBAR_BUTTON,
  ONOPEN_DRIVE,
  ONCLOSE_DRIVE,
  ONCHANGE_DRIVE,
  ONADD_SPREADRUM,
  ONREMOVE_SPREADRUM,
  ONINIT_SPREADRUM,
  ONMOVE_SPREADRUM
} = systemReducer.actions;

export default systemReducer.reducer;