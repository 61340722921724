import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";


import LoaderSpinner from "../../loader-spinner/_index";

import {
  BAO_CAO_CONG_VIEC_CHECK_REPORT
} from "../../../apis/_index";

import Select from "react-select";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";
import { LOAD_SEP } from "../../../apis/_index";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";

// var index = require("../../../temp/baocao/index.html");

const CreateElectricReportOwner = (props: any) => {
  // reducer
  const dispatch = useDispatch();

  const handleClose = props.handleClose;

  const context = useContext(ModalController)
  const onLoad = context.funcsLoad?.onLoad

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);

  const handleCloseSub = () => setOpenSub(false);
  const [bossList, setBossList] = useState<any>(null);
  const [reportType, setReportType] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);

  const [nameOfReport, setNameOfReport] = useState<any>("");

  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);

  const [quarter, setQuarter] = useState<any>(null);
  const [taskManager, setTaskManager] = useState<any>(null);
  const [taskOwner, setTaskOwner] = useState<any>(null);

  const [paramObject, setParamObject] = useState<any>(null)

  // props body
  const [bodyOfReport, setBodyOfReport] = useState<any | null>({})

  const reportTypeList = [
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch ngày" : "Báo cáo ngày", value: 1 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch tháng" : "Báo cáo tháng", value: 2 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch quý" : "Báo cáo quý", value: 3 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch năm" : "Báo cáo năm", value: 4 },
  ];

  const _onChangeReportType = (data: any) => {
    setReportType(data);
  };

  const _onChangeNameOfReport = (e: any) => {
    e.preventDefault();
    setNameOfReport(e.target.value)
  }

  const monthList = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];


  const yearList = [
    { label: 2023, value: 2023 }
  ]

  const _onChangeMonth = (data: any) => {
    setMonth(data);
  };

  const _onChangeYear = (data: any) => {
    if (data === null) {
      setYear("")
    } else {
      setYear(data)
    }
  }

  const quarterList = [
    { label: "Quý 1", value: 31 },
    { label: "Quý 2", value: 32 },
    { label: "Quý 3", value: 33 },
    { label: "Quý 4", value: 34 },
  ];

  const _onChangeQuarter = (data: any) => {
    setQuarter(data);
  };

  const taskManagerList = bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const _onChangeTaskManager = (data: any) => {
    setTaskManager(data);
  };

  const taskOwnerList = bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const _onChangeTaskOwner = (data: any) => {
    setTaskOwner(data);
  };

  const _onChangeFromDate = (e: any) => {
    setFromDate(e.target.value);
  };

  const _onChangeToDate = (e: any) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        // const accessToken = localStorage.getItem("accessToken");

        try {
          await ServiceUtils.getV2(LOAD_SEP, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                setBossList(res.data.Data);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeData();
    }
  });

  // // onChange Report change
  // useEffect(() => {
  //   setFromDate(null)
  //   setToDate(null)
  //   setQuarter(null)
  //   setMonth(null)
  //   setYear(null)
  // }, [reportTypeList])

  const _onClickViewReport = async (funcs: string) => {
    if (reportType === null) {
      toast.error("Vui lòng chọn loại báo cáo");
      return;
    }
    if (reportType.value === 1) {
      if (fromDate === null) {
        toast.error("Vui lòng chọn ngày bắt đầu");
        return;
      }
      if (toDate === null) {
        toast.error("Vui lòng chọn ngày kết thúc");
        return;
      }
    }
    if (reportType.value === 2) {
      if (month === null) {
        toast.error("Vui lòng chọn tháng");
        return;
      }
    }
    if (reportType.value === 3) {
      if (quarter === null) {
        toast.error("Vui lòng chọn quý");
        return;
      }
    }
    if (reportType.value === 4) {
      if (year === null) {
        toast.error("Vui lòng chọn năm");
        return;
      }
    }
    if (taskManager === null) {
      toast.error("Vui lòng chọn người yêu cầu");
      return;
    }
    if (taskOwner === null) {
      toast.error("Vui lòng chọn người nhận");
      return;
    } else {
      setBodyOfReport({
        taskName: nameOfReport.toUpperCase(),
        reportType: reportType,
        fromDate: fromDate,
        toDate: toDate,
        taskManager: taskManager,
        taskOwner: taskOwner,
        month: month,
        year: year,
        quarter: quarter,
      })
      setFuncsSub(funcs)
      handleOpenSub()
    }
  };

  const _onClickCreateReport = async () => {
    if (reportType === null) {
      toast.error("Vui lòng chọn loại báo cáo");
      return;
    }
    if (nameOfReport === "" || nameOfReport === null) {

    }
    if (reportType.value === 1) {
      if (fromDate === null) {
        toast.error("Vui lòng chọn ngày bắt đầu");
        return;
      }
      if (toDate === null) {
        toast.error("Vui lòng chọn ngày kết thúc");
        return;
      }
    }
    if (reportType.value === 2) {
      if (month === null) {
        toast.error("Vui lòng chọn tháng");
        return;
      }
    }
    if (reportType.value === 3) {
      if (quarter === null) {
        toast.error("Vui lòng chọn quý");
        return;
      }
    }
    if (reportType.value === 4) {
      if (year === null) {
        toast.error("Vui lòng chọn năm");
        return;
      }
    }
    if (taskManager === null) {
      toast.error("Vui lòng chọn người yêu cầu");
      return;
    }
    if (taskOwner === null) {
      toast.error("Vui lòng chọn người nhận");
      return;
    } else {

      const paramObject: any
        = parseInt(reportType.value) === 1 ? {
          PERIODIC: parseInt(reportType.value),
          SEARCH_DATEADD_FROM: fromDate,
          SEARCH_DATEADD_TO: toDate,
          TaskOwner: taskOwner?.Id,
          TaskManager: taskManager?.Id
        }
          : parseInt(reportType.value) === 2 ? {
            PERIODIC: parseInt(reportType.value),
            SEARCH_MONTH: month,
            SEARCH_YEAR: year,
            TaskOwner: taskOwner?.Id,
            TaskManager: taskManager?.Id
          }
            : parseInt(reportType.value) === 3 ? {
              PERIODIC: parseInt(reportType.value),
              SEARCH_QUARTER: quarter,
              SEARCH_YEAR: year,
              TaskOwner: taskOwner?.Id,
              TaskManager: taskManager?.Id
            }
              :
              {
                PERIODIC: parseInt(reportType.value),
                SEARCH_YEAR: year,
                TaskOwner: taskOwner?.Id,
                TaskManager: taskManager?.Id
              }

      const accessToken = localStorage.getItem("accessToken")

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(
            BAO_CAO_CONG_VIEC_CHECK_REPORT,
            paramObject,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                toast.success("Tạo báo cáo thành công.")
              }
            })
        } catch (error: any) {
          toast.error("Tạo báo cáo thất bại.")
        }
      }

      return takeData()
    }
  }

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[40rem] md:w-[40rem] w-screen lg:h-max md:h-max h-[calc(100vh-6rem)]
      rounded-md
    "
    >
      {/* SubModal */}
      <SubModal
        bodyOfReport={bodyOfReport}
        funcs={funcsSub}
        open={openSub}
        handleClose={handleCloseSub}
        onLoad={onLoad}
      />

      {/* Header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        {
          urlLink === "/report/PlanSent" ?
            <p>Tạo kế hoạch mới</p>
            :
            <p>Tạo báo cáo mới</p>
        }

        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="w-full h-max">
          {
            urlLink === "/report/PlanSent"
              ?
              <label>
                Loại kế hoạch&nbsp;<span className="text-red-500">(*)</span>
              </label>
              :
              <label>
                Loại báo cáo&nbsp;<span className="text-red-500">(*)</span>
              </label>
          }

          <Select
            className="z-[10]"
            options={reportTypeList}
            onChange={(data: any) => _onChangeReportType(data)}
            isClearable
            placeholder={urlLink === "/report/PlanSent" ? "Chọn loại kế hoạch" : "Chọn loại báo cáo"}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />

        </div>

        <div className="flex flex-col w-full pr-0 h-max">
          {
            urlLink === "/report/PlanSent"
              ?
              <label>
                Tên kế hoạch&nbsp;<span className="text-red-500">(*)</span>
              </label>
              :
              <label>
                Tên báo cáo&nbsp;<span className="text-red-500">(*)</span>
              </label>
          }
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder={urlLink === "/report/PlanSent" ? "Nhập tên kế hoạch" : "Nhập tên báo cáo"}
            value={nameOfReport}
            onChange={(e: any) => _onChangeNameOfReport(e)}
          />
        </div>

        {reportType?.value === 1 ? (
          <div className="flex w-full h-max space-x-2">
            <div className="w-full">
              <label>
                Từ ngày&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                type="date"
                className="w-full h-8 border border-gray-300 rounded-md pl-2"
                onChange={(e) => {
                  e.preventDefault();
                  _onChangeFromDate(e);
                }}
              />
            </div>
            <div className="w-full">
              <label>
                Đến ngày&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                type="date"
                className="w-full h-8 border border-gray-300 rounded-md pl-2"
                onChange={(e) => {
                  e.preventDefault();
                  _onChangeToDate(e);
                }}
              />
            </div>
          </div>
        ) : reportType?.value === 2 ? (
          <div className="w-full h-max flex space-x-3">
            <div className="w-1/2 h-max">
              <label>
                Chọn tháng&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[9]"
                options={monthList}
                onChange={(data: any) => _onChangeMonth(data)}
                isClearable
                placeholder="Chọn tháng"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="w-1/2 h-max">
              <label>
                Chọn năm&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[9]"
                options={yearList}
                onChange={(data: any) => _onChangeYear(data)}
                isClearable
                placeholder="Chọn năm"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        ) : reportType?.value === 3 ? (
          <div className="w-full h-max flex space-x-3">
            <div className="w-1/2 h-max">
              <label>
                Chọn quý&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[9]"
                options={quarterList}
                onChange={(data: any) => _onChangeQuarter(data)}
                isClearable
                placeholder="Chọn quý"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="w-1/2 h-max">
              <label>
                Chọn năm&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[9]"
                options={yearList}
                onChange={(data: any) => _onChangeYear(data)}
                isClearable
                placeholder="Chọn năm"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

          </div>
        ) : (
          reportType?.value === 4 && (
            <div className="w-full h-max">
              <label>
                Chọn năm&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[9]"
                options={yearList}
                onChange={(data: any) => _onChangeYear(data)}
                isClearable
                placeholder="Chọn năm"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )
        )}

        <div className="w-full h-max">
          <label>
            Chọn người đưa yêu cầu&nbsp;
            <span className="text-red-500">(*)</span>
          </label>
          <Select
            className="z-[6]"
            options={taskManagerList}
            onChange={(data: any) => _onChangeTaskManager(data)}
            isClearable
            placeholder="Chọn người đưa yêu cầu"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="w-full h-max">
          <label>
            Chọn người nhận&nbsp;<span className="text-red-500">(*)</span>
          </label>
          <Select
            className="z-[5]"
            options={taskOwnerList}
            onChange={(data: any) => _onChangeTaskOwner(data)}
            isClearable
            placeholder="Chọn người nhận"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
            flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-amber-500 hover:bg-amber-400
        `}
            onClick={() => _onClickViewReport(urlLink === "/report/PlanSent" ? "checkPlanning" : "checkViewPort")}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <>
                {
                  urlLink === "/report/PlanSent" ?
                    <p>Xem kế hoạch</p>
                    :
                    <p>Xem báo cáo</p>
                }
              </>
            )}
          </button>

          {/* <button
            className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md bg-green-500 hover:bg-green-400
        `}
            onClick={_onClickCreateReport}
          >
            <p>Tạo mới</p>
          </button> */}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateElectricReportOwner;
