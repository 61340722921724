import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import SubModal from "../_subModal/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import { ModalController } from "../../../ui/homeUI/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { GET_DETAIL_PROPOSE, HANDLE_PROCEDURE_STEP, PROPOSE_DETAIL } from "../../../apis/_index";
import moment from "moment";
import { PROCEDUCE_STEP_TYPES, PROCEDUCE_STEP_TYPE_OBJECTS } from "../../../constants/dataConstants";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import ServiceUtils from "../../../utils/services";

const ProposeDetail = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;
  const id = props.id;

  // state

  const urlLink = window.location.pathname;

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const [isForce, setIsForce] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const data = useSelector((state: any) => state.appReducer);
  const context = useContext(ModalController);
  const [settingProcedureSteps, setSettingProcedureStep] = useState<any>([]);
  const [contentProcedureStep, setContentProcedureStep] = useState<any>('');

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  // load detail
  useEffect(() => {
    // const accessToken = localStorage.getItem("accessToken");
    // const API = PROPOSE_DETAIL(taskId);

    // const takeData = async () => {
    //   try {
    //     await axios
    //       .get(API, {
    //         headers: {
    //           Authorization: "Bearer " + accessToken,
    //           Accept: "text/plain",
    //         },
    //       })
    //       .then((res: any) => {
    //         if (res.data.StatusCode === 200) {
    //           setDataDetail(res.data.Data[0]);
    //           // console.log("res.data", res.data.Data[0]);
    //         } else {
    //           toast.error(res.Message);
    //         }
    //       });
    //   } catch (error: any) {
    //     toast.error(error);
    //   }
    // };

    // takeData();

    // const accessToken = localStorage.getItem("accessToken");
    const API = GET_DETAIL_PROPOSE.replace('{id}', taskId);

    const takeData = async () => {
      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        })
          .then((res: any) => {
            console.log(res);

            if ((res.data || {}).StatusCode === 200) {
              setDataDetail(((res.data || {}).Data || {}).propose);
              setSettingProcedureStep(((res.data || {}).Data || {}).settingProcedureSteps || []);
              // console.log("res.data", res.data.Data[0]);
            } else {
              toast.error(res.Message);
            }
          });
      } catch (error: any) {
        toast.error(error);
      }
    };

    takeData();
  }, [taskId]);

  // onClick confirm
  const _onClickConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeConfirm");
  };
  const onOpenSub = (text: string, id: String) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // onClick unConfirm
  const _onClickUnConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeUnConfirm");
  };

  const onHandleProcedureStep = (result: any, labelFunction: any) => () => {
    if (!contentProcedureStep) {
      toast.error('Vui lòng nhập nội dung gửi đi!');

      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    const apiUrl = HANDLE_PROCEDURE_STEP;

    const body: any = {
      ProposeId: taskId,
      Content: contentProcedureStep,
      FuctionType: result
    };

    const messageSuccessfully = labelFunction ? `${labelFunction} thành công!` : 'Gửi thành công!';
    const messageFailure = labelFunction ? `${labelFunction} thất bại!` : 'Gửi thất bại!';

    try {
      ServiceUtils.patchV2(apiUrl, body, {
        headers: {
          Accept: "text/plain",
        },
      })
        .then((res: any) => {
          console.log(res);

          const data = res.data || {};

          if (data.RCode === 200) {
            toast.success(data.RMessage || messageSuccessfully);
          } else {
            toast.error(data.RMessage || messageFailure);
          }
        });
    } catch (err: any) {
      toast.error(messageFailure);
    }
  }

  // const renderSelectProcedureStep = () => {
  //   const detail = dataDetail || {};

  //   const result1CurrentStep = detail.ProcedureStepResult1;
  //   const result2CurrentStep = detail.ProcedureStepResult2;
  //   const result3CurrentStep = detail.ProcedureStepResult3;

  //   const typeCurrentStep = detail.ProcedureStepType;

  //   let isHaveSelect = false;

  //   const nextStepResult1 = settingProcedureSteps.find(p => p.StepNumber == result1CurrentStep);
  //   const nextStepResult2 = settingProcedureSteps.find(p => p.StepNumber == result2CurrentStep);
  //   const nextStepResult3 = settingProcedureSteps.find(p => p.StepNumber == result3CurrentStep);

  //   if (nextStepResult1 && nextStepResult1.OrganizationID && nextStepResult1.PositionID) {
  //     isHaveSelect = true;
  //   } else if (nextStepResult2 && nextStepResult2.OrganizationID && nextStepResult1.PositionID) {
  //     isHaveSelect = true;
  //   } else if (nextStepResult3 && nextStepResult3.OrganizationID && nextStepResult3.PositionID) {
  //     isHaveSelect = true;
  //   }

  //   if (isHaveSelect) {
  //     return <>
  //       <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
  //         <div className="w-full h-max flex flex-col p-1">
  //           <p>Chọn phòng ban</p>
  //           <textarea
  //             style={{
  //               height: '100px'
  //             }}
  //             rows={5}
  //             className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
  //           />
  //         </div>
  //       </div>
  //     </>;
  //   }

  //   return null;
  // }

  const onChangeContentProcedureStep = (e: any) => {
    const value = e.target.value;

    setContentProcedureStep(value);
  }

  const renderContentProcedureStep = () => {
    const detail = dataDetail || {};

    const contentCurrentByUser = detail.ContentCurrentByUser;
    const contentPrevious = detail.ContentPrevious;

    if (contentCurrentByUser) {
      return <>
        <div style={{
          marginTop: '10px',
          width: '100%',
          height: '2px',
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}></div>
        <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
          <div className="w-full h-max flex flex-col p-1">
            <p>Nội dung bạn đã gửi đi</p>
            <textarea
              readOnly={true}
              style={{
                height: '100px'
              }}
              rows={5}
              className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
              value={contentCurrentByUser}
            />
          </div>
        </div>
      </>;
    }

    if (contentPrevious) {
      return <>
        <div style={{
          marginTop: '10px',
          width: '100%',
          height: '2px',
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}></div>
        <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
          <div className="w-full h-max flex flex-col p-1">
            <p>Nội dung người thực hiện bước trước đó</p>
            <textarea
              readOnly={true}
              style={{
                height: '100px'
              }}
              rows={5}
              className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
              value={contentPrevious}
            />
          </div>
        </div>
      </>;
    }
  }

  const renderFormProcedureStep = () => {
    const detail = dataDetail || {};

    const isFinishProcedureStep = (detail.ProcedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.noContent || settingProcedureSteps.length == detail.CurrentStepNumber) ? true : false;

    if (isFinishProcedureStep) {
      return null;
    }

    if (!detail.IsPermissionHandleProcedureStep) {
      return null;
    }

    return <>
      <div style={{
        marginTop: '10px',
        width: '100%',
        height: '2px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }}></div>
      <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
        <div className="w-full h-max flex flex-col p-1">
          <p>Nội dung gửi đi (*)</p>
          <textarea
            style={{
              height: '100px'
            }}
            rows={5}
            className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
            onChange={(e: any) => onChangeContentProcedureStep(e)}
            value={contentProcedureStep}
          />
        </div>
      </div>
    </>;
  }

  const renderFunctionProcedureStep = () => {
    const detail = dataDetail || {};

    if (!detail.IsPermissionHandleProcedureStep) {
      return null;
    }

    const procedureStepType = detail.ProcedureStepType;

    if (procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.transferStep) {
      return <button
        className="
flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
hover:bg-green-700
"
        onClick={onHandleProcedureStep(1, detail.ProcedureStepLabelFunction1)}
      >
        <SkipNextIcon fontSize="small" />
        <p>{detail.ProcedureStepLabelFunction1}</p>
      </button>;
    } else if (procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmNotConfirm) {
      return <>
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
          hover:bg-red-700
        "
          onClick={onHandleProcedureStep(2, detail.ProcedureStepLabelFunction2)}
        >
          <CloseIcon fontSize="small" />
          <p>{detail.ProcedureStepLabelFunction2}</p>
        </button>
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-700
        "
          onClick={onHandleProcedureStep(1, detail.ProcedureStepLabelFunction1)}
        >
          <CheckIcon fontSize="small" />
          <p>{detail.ProcedureStepLabelFunction1}</p>
        </button>
      </>;
    } else if (procedureStepType == PROCEDUCE_STEP_TYPE_OBJECTS.confirmSuggestNotConfirm) {
      return <>
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
          hover:bg-red-700
        "
          onClick={onHandleProcedureStep(2, detail.ProcedureStepLabelFunction2)}
        >
          <SkipNextIcon fontSize="small" />
          <p>{detail.ProcedureStepLabelFunction2}</p>
        </button>
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
          hover:bg-red-700
        "
          onClick={onHandleProcedureStep(3, detail.ProcedureStepLabelFunction3)}
        >
          <CloseIcon fontSize="small" />
          <p>{detail.ProcedureStepLabelFunction3}</p>
        </button>
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-700
        "
          onClick={onHandleProcedureStep(1, detail.ProcedureStepLabelFunction1)}
        >
          <CheckIcon fontSize="small" />
          <p>{detail.ProcedureStepLabelFunction1}</p>
        </button>
      </>;
    }

    return null
  }

  console.log(dataDetail);

  const isHaveProcedureStep = (dataDetail || {}).ProcedureStepID ? true : false;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[40rem] md:w-[40rem] w-screen h-max
      rounded-md
    "
    >
      <SubModal taskId={taskId} open={openSub} handleClose={handleCloseSub} />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết đề xuất</p>
        <button
          className="hover:bg-slate-300 px-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <>
        {dataDetail === null ? (
          <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars"></div>
        ) : (
          <div className="flex flex-col w-full items-center text-sm p-2 overflow-auto styled-scrollbars">
            <div className="w-full h-max flex font-semibold items-center rounded-sm">
              <p className="font-semibold text-black bg-slate-200 px-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã đề xuất - {dataDetail.ProposeCode}
              </p>
            </div>

            <div className="w-full h-max font-semibold items-center rounded-sm">
              <p className="p-1">
                Tên đề xuất -
                <span className="w-full h-[1.9rem] rounded-md px-2">
                  {dataDetail.Name}
                </span>
              </p>
            </div>

            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Loại đề xuất</p>
                <input
                  className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
                  value={dataDetail.ProposeTypeName}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Mức độ</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2 border-slate-200"
                  value={dataDetail.PriorityLevelName}
                  disabled
                />
              </div>
            </div>
            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Người đề xuất</p>
                <input
                  className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                  value={dataDetail.UserAddName}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p>Người nhận</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2"
                  value={dataDetail.UserCheckName}
                  disabled
                />
              </div>
            </div>
            {dataDetail.ListNamePropose && (
              <div className="w-full h-max flex flex-col p-1">
                <p>Người hỗ trợ</p>
                <textarea
                  className="w-full h-[1.9rem] rounded-md border-2 items-center p-1"
                  value={dataDetail.ListNamePropose}
                  disabled
                />
              </div>
            )}

            {dataDetail.IsUseTime === true ? (
              <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày bắt đầu</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                    value={moment(dataDetail.StartTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày kết thúc</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md px-2 border-2"
                    value={moment(dataDetail.EndTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-max flex font-bold flex-col p-1">
                <p className="">Đề xuất không có thời hạn</p>
              </div>
            )}

            <div className="w-full h-max flex flex-col p-1">
              <p className="">Nội dung</p>
              <textarea
                className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                value={dataDetail.Content}
                disabled
              />
            </div>
            {dataDetail.Status !== 0 ? (
              <div className="danh-gia w-full h-max flex flex-row p-1">
                <p className="mb-2">Đánh giá</p>
                <div>
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Rating
                      name="simple-controlled"
                      value={dataDetail.Star}
                      readOnly={dataDetail.Status}
                    />
                  </Box>
                </div>
              </div>
            ) : null}
            {dataDetail.Reason !== null ? (
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Lý do</p>
                <textarea
                  className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                  value={dataDetail.Reason}
                  disabled
                />
              </div>
            ) : null}
            {isHaveProcedureStep && renderContentProcedureStep()}
            {isHaveProcedureStep && renderFormProcedureStep()}
          </div>
        )}
      </>
      {/* footer */}
      <div
        className={`flex w-full p-12-16 footer rounded-b-md ${urlLink === "/working/receiver/task-new" && isForce === false
          ? "justify-end"
          : "justify-end"
          }  items-center text-sm space-x-4`}
      >
        {isHaveProcedureStep ? renderFunctionProcedureStep() : <>
          {urlLink !== "/propose/sender/list" && dataDetail?.Status === 0 ? (
            <>
              <button
                className="
          flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
          hover:bg-red-700
        "
                onClick={() => _onClickUnConfirm(dataDetail.ID)}
              >
                <CloseIcon fontSize="small" />
                <p>Không duyệt</p>
              </button>
              <button
                className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-700
        "
                onClick={() => _onClickConfirm(dataDetail.ID)}
              >
                <CheckIcon fontSize="small" />
                <p>Duyệt</p>
              </button>
            </>
          ) : null}
        </>}
        <button
          className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-500
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default ProposeDetail;
