import { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ASK_EXTEND } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_LIST_PROCESSING, BAO_CAO_DELAY } from "../../../apis/_index";
import { ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN } from "../../../redux/reducers/workingReducer/_index";
import CloseIcon from '@mui/icons-material/Close';

import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const ReportDelay = (props: any) => {
  const taskId = props.taskId;
  const taskCode = props.taskCode;
  const handleClose = props.handleClose;
  const urlLink = window.location.pathname;

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const [notes, setNotes] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);

  const shouldLog = useRef(true);

  // funcs
  // const _onClickSubmit = async () => {
  //   const accessToken = localStorage.getItem("accessToken");

  //   const body = {
  //     TaskID: taskId,
  //     Reason: notes,
  //     NewDate: date,
  //   };

  //   try {
  //     setIsLoading(true);
  //     await axios
  //       .post(ASK_EXTEND, body, {
  //         headers: {
  //           Authorization: "Bearer " + accessToken,
  //           Accept: "text/plain",
  //         },
  //       })
  //       .then((res: any) => {
  //         if (res.status === 200) {
  //           readList(
  //             NGUOI_NHAN_VIEC_LIST_PROCESSING,
  //             workingData.pageCurrent,
  //             workingData.choosePage,
  //             workingData.searchFilter.taskName,
  //             workingData.searchFilter.selectedPrior,
  //             workingData.searchFilter.selectedReceiver,
  //             workingData.searchFilter.fromDate,
  //             workingData.searchFilter.toDate,
  //             workingData.searchFilter.deadLine,
  //             shouldLog,
  //             urlLink,
  //             (res: any) => {
  //               dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
  //             }
  //           );

  //           handleClose();
  //           context.handleClose();
  //           toast.success("Xác nhận xin gia hạn");
  //           setIsLoading(false);
  //         }
  //       });
  //   } catch (error: any) {
  //     handleClose();
  //     toast.error("Xác nhận xin gia hạn");
  //     setIsLoading(false);
  //   }
  // };

  const _onClickConfirmDelay = async () => {
    const accessToken = localStorage.getItem("accessToken")
    const body: any = {
      ReportID: taskId,
      Reason: notes,
      NewDate: date
    }
    try {
      await ServiceUtils.postV2(
        BAO_CAO_DELAY,
        body,
        {
          headers: {
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Gia hạn báo cáo thành công.")
            handleClose()
            context.handleClose()
          }
        })
    } catch (error) {

    }
  }

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        px-[16px] py-[12px] items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Xác nhận gia hạn</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        {/* <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Mã công việc&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={taskCode}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div> */}
        <div className="flex flex-col w-full h-max">
          <label>
            Thời hạn mới&nbsp;<span className="text-red-500">(*)</span>
          </label>
          <input
            className="w-full h-[2rem] rounded-md p-2 border-2 border-slate-200"
            type="datetime-local"
            value={date}
            onChange={(e: any) => {
              e.preventDefault();
              setDate(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Nội dung gia hạn&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập nội dung gia hạn"
              onChange={(e: any) => {
                e.preventDefault();
                setNotes(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${notes === "" || date === ""
                ? "bg-slate-300"
                : "bg-green-500 hover:bg-green-400"
              }
        `}
            disabled={
              notes === "" || date === "" || isLoading === true ? true : false
            }
            onClick={_onClickConfirmDelay}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <p>Xác nhận</p>
            )}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportDelay;
