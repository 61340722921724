import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import DoneIcon from "@mui/icons-material/Done";

import LoaderSpinner from "../../loader-spinner/_index";
import { READ_PARAMETER } from "../../../apis/_index";
import { ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { CREATE_PAREMETER } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import { convertNameParameter } from "../../../helperText/index";
import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";

import "../../../styles/createListOfGeneral/index.css";

const CreateListOfGeneral = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();

  const [parameterName, setParameterName] = useState<string>("");
  const [parameterGroup, setParameterGroup] = useState<any>(0);
  const [isActive, setIsActive] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (parameterName === "" || parameterGroup === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [parameterName, parameterGroup]);

  const _onChangeParameterName = (e: any) => {
    e.preventDefault();
    setParameterName(e.target.value);
  };

  const _onChangeParameterGroup = (e: any) => {
    e.preventDefault();
    setParameterGroup(e.target.value);
  };

  const _onChangeIsActive = () => {
    setIsActive((data: any) => !data);
  };

  // funcs
  const _onSubmit = () => {
    if (parameterName === "" || parameterGroup === "") {
      setIsValid(true);
    } else {
      setIsValid(false);
      setIsSuccess(false);
      setIsLoading(true);

      const createAPI = async () => {
        const body: any = {
          ParameterKey: parameterGroup,
          ParameterValue: parameterName,
          IsActive: isActive,
        };

        try {
          await ServiceUtils.postV2(CREATE_PAREMETER, body, {
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            // console.log("post cate", res.data.Data[0]);
            if (res.status === 200 && res.data.Data[0].RCode === 200) {
              setParameterName("");
              setParameterGroup(0);
              setIsLoading(false);
              setIsSuccess(true);
              let API = "";
              if (data.parameterChoose.toString() == "1") {
                API = READ_PARAMETER;
              } else {
                API = READ_PARAMETER + `?key=${data.parameterChoose}`;
              }
              const takeParameterList = async () => {
                try {
                  await ServiceUtils.getV2(API, {
                    headers: {},
                  }).then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
                      toast.success("Tạo danh mục thành công.");
                    } else {
                      return;
                    }
                  });
                } catch (error: any) {
                  toast.error("Tải dữ liệu thất bại.");
                }
              };
              takeParameterList();
            } else {
              setIsLoading(false);
              toast.error(res.data.Data[0].RMessage);
            }
          });
        } catch (error: any) {
          setIsLoading(false);
          return;
        }
      };
      createAPI();
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[30rem] w-screen h-[21rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo danh mục mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên danh mục&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={parameterName}
              onChange={(e: any) => {
                _onChangeParameterName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Nhóm danh mục&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={parameterGroup}
              onChange={(e: any) => {
                _onChangeParameterGroup(e);
              }}
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn nhóm danh mục ---
              </option>
              {data.parameterListKey.length === 0
                ? ""
                : data.parameterListKey
                    .filter(
                      (item: any) =>
                        item.ParameterKey !== "LI_BOPHAN" &&
                        item.ParameterKey !== "LI_PHONGBAN"
                    )
                    .map((item: any, index: any) => (
                      <option key={index} value={item.ParameterKey}>
                        {convertNameParameter(item.ParameterKey)}
                      </option>
                    ))}
            </select>
          </div>

          {/* <div className="flex flex-col w-1/2 pl-2 h-max">
            <label>Nhóm tham chiếu (nếu có)&nbsp;<span className="text-red-500">(*)</span></label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              defaultValue={0}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn nhóm tham chiếu ---</option>
              <option>Nhóm tham chiếu 1</option>
              <option>Nhóm tham chiếu 2</option>
              <option>Nhóm tham chiếu 3</option>
            </select>
          </div> */}
        </div>

        <div className="absolute left-4 bottom-4 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max core-create-list-of-general">
          <p className="font-bold text-primary">Hiển thị</p>
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isActive}
            onChange={_onChangeIsActive}
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>

        <div
          className={`absolute right-4 bottom-4 ${
            isValid === false ? "hidden" : "flex"
          } items-center space-x-4 w-max lg:pr-2 mt-5 pr-0 h-max`}
        >
          <p className="text-red-500">Vui lòng điền đầy đủ thông tin</p>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">
        <div className="flex w-max h-max items-center">
          <div
            className={`w-max h-max items-center ${
              isLoading === true ? "flex" : "hidden"
            }`}
          >
            <LoaderSpinner h={32} w={32} />
          </div>

          <div
            className={`${
              isSuccess === true ? "flex" : "hidden"
            } w-max h-max items-center text-green-500`}
          >
            <DoneIcon fontSize="small" />
            <p className="font-bold">Đã thêm</p>
          </div>
        </div>

        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2
          text-white rounded-md
          ${
            isValid === true || isLoading === true
              ? "bg-slate-400"
              : "bg-primary hover:bg-sky-400"
          }
        `}
            onClick={_onSubmit}
            disabled={isValid}
          >
            <SaveIcon fontSize="small" />
            <p>Tạo danh mục</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateListOfGeneral;
