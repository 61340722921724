import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { takePermission } from "../../../../funcs";

import { ModalController } from "../../../homeUI/_index";
import { GET_LIST_CATEGORY_SUGGEST_API, PRIOR_LIST, SETTING_PROCEDUCE_LIST } from "../../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { ONLOAD_CATEGORY_SUGGEST, ONLOAD_PRIORLIST } from "../../../../redux/reducers/settingReducer/_index";
import { unsignedToAccented } from "../../../../helpers/handleHelper";
import { MODAL_KEYS } from "../../../../constants/constants";
import { ONLOAD_SETTINGPROCEDUCE_ALL } from "../../../../redux/reducers/workingReducer/_index";
import { readList } from "../../../../funcs/taskNguoiGiaoViec";

const SettingProceduce = () => {
  const settingData = useSelector((state: any) => state.settingReducer);
  const { workingReducer: data } = useSelector((state: any) => ({ ...state }));
  const link = window.location.pathname;

  const [categorySuggests, setCategorySuggest] = useState((settingData || {}).categorySuggests || []);

  const context = useContext(ModalController);
  const menu = takePermission(13);

  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const _onClickCreateSettingProceduce = () => {
    context.setFuncs(MODAL_KEYS.createOrUpdateSettingProceduce);
    context.handleOpen();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      readList(
        SETTING_PROCEDUCE_LIST,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONLOAD_SETTINGPROCEDUCE_ALL(res.data.Data));
        },
        null,
        null,
        null,
        data.searchFilter.selectPhongBan
      );
    }
  }, []);

  useEffect(() => {
    if (categorySuggests.length <= 0) {
      setCategorySuggest(settingData.categorySuggests || []);
    }
  }, []);

  const onKeyUpSearch = (e: any) => {
    const value = e.target.value;

    if (!value) {
      dispatch(ONLOAD_CATEGORY_SUGGEST(categorySuggests));
    } else {
      const valueFilter = unsignedToAccented(value.trim().toLowerCase());

      const categorySuggestFilters = categorySuggests.filter((p: any) => {
        const name = unsignedToAccented((p.Name || '').trim().toLowerCase());

        return name.includes(valueFilter) || name.startsWith(valueFilter) || name.endsWith(valueFilter) || name.indexOf(valueFilter) > -1 || name.lastIndexOf(valueFilter) > -1;
      });
      
      dispatch(ONLOAD_CATEGORY_SUGGEST(categorySuggestFilters));
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />
      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
        <div className="w-full h-[2rem] flex justify-end items-center">
          <button
            className={`
              p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateSettingProceduce}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>
        <Table />
      </div>
    </div>
  );
};

export default SettingProceduce;