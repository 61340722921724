import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../utils/services";

export const propose = (
  api: any,
  page: number,
  limit: number,
  filterProposeName: any,
  filterPriorityLevelID: number,
  filterUserAdd: any,
  filterUserCheck: any,
  filterFromDate: any,
  filterToDate: any,
  shouldLog: any,
  link: string,
  callback: (res: any) => void
) => {
  const accessToken = localStorage.getItem("accessToken");

  const proposeName = filterProposeName ? filterProposeName : "";
  const selectedPrior = filterPriorityLevelID ? filterPriorityLevelID : "";
  const userAdd = filterUserAdd ? filterUserAdd : "";
  const userCheck = filterUserCheck ? filterUserCheck : "";
  const fromDate = filterFromDate ? filterFromDate : "";
  const toDate = filterToDate ? filterToDate : "";

  const body:any = {
    Page: page,
    Limit: limit,
    TaskName: proposeName,
    PriorityLevelID: selectedPrior,
    UserAdd: userAdd,
    UserCheck: userCheck,
    DateAdd_From: fromDate,
    DateAdd_To: toDate,
  };

  if (link === "/propose/sender/list") {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
              headers: {
                Accept: "text/plain",
                "Content-Type": "application/json",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                callback(res);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };
      takeData();
    }
  }
};
