import React, { useRef, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CheckIcon from '@mui/icons-material/Check';

import { ModalController } from "../../../ui/homeUI/_index";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CloseIcon from '@mui/icons-material/Close';

import DeleteIcon from '@mui/icons-material/Delete';

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";

import { NGUOI_GIAO_VIEC_XOA, NGUOI_GIAO_VIEC_LIST, BAO_CAO_CONFIRM } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";

const ConfirmReport = (props: any) => {

  const data = useSelector((state: any) => state.workingReducer)
  const dispatch = useDispatch()
  const shouldLog = useRef(true);
  const urlLink = window.location.pathname;
  const context = useContext(ModalController)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // States
  const [isResult, setIsResult] = useState<boolean>(true)
  const [notes, setNotes] = useState<string>("")

  const _onChangeIsResult = (e: any) => {
    console.log(e.target.value)
    if (e.target.value === "1") {
      setIsResult(true)
    } else {
      setIsResult(false)
    }

    console.log(isResult)
  }

  // funcs submit
  const _onClickSubmit = () => {
    const accessToken = localStorage.getItem("accessToken")

    const body: any = {
      ID: taskId,
      IsResult: isResult,
      Result: notes
    }

    const fetchData = async () => {
      setIsLoading(true)

      try {
        await ServiceUtils.postV2(
          BAO_CAO_CONFIRM,
          body,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setIsLoading(false)
              toast.success("Duyệt báo cáo thành công.")
              handleClose()
              context.handleClose()
            }
          })
      } catch (error) {
        setIsLoading(false)
        toast.error("Duyệt báo cáo thất bại.")
      }
    }

    fetchData()
  }


  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  return (
    <div className="
      flex bg-slate-100
      lg:w-[35rem] w-screen h-[30rem]
      rounded-md text-white flex-col
    ">
      {/* Header */}
      <div className="        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
">
        <p className="text-lg font-semibold">Xác nhận duyệt báo cáo</p>

        <button className="w-[1.5rem] h-[1.5rem] p-2 rounded-full hover:bg-slate-200 justify-center items-center"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </button>
      </div>

      {/* Content */}
      <div className="w-full h-[calc(100%-6rem)] p-2 text-black space-y-6">
        {/* Confirm fields */}
        <fieldset
          onChange={(e: any) => {
            _onChangeIsResult(e)
          }}
          className={`
                flex w-full h-max space-x-3
              `}
        >
          <div className="flex items-center space-x-3">
            <input
              type="radio"
              id="1"
              name="fav_language"
              value={1}
              checked={isResult === true ? true : false}
              placeholder=""
            />
            <p>Đồng ý</p>
          </div>
          <div className="flex items-center space-x-3">
            <input
              type="radio"
              id="1"
              name="fav_language"
              value={2}
              checked={isResult === false ? true : false}
              placeholder=""
            />
            <p>Không đồng ý</p>
          </div>
        </fieldset>

        {/* Content fields */}
        <div className="flex flex-col w-full h-max">
          <label>Nội dung duyệt</label>
          <textarea
            className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
            placeholder="Nhập nội dung duyệt"
            value={notes}
            onChange={(e: any) => _onChangeNotes(e)}

          />
        </div>
      </div>

      {/* Footer */}
      <div className="w-full footer p-12-16 justify-end flex rounded-b-md
        items-center space-x-2
      ">
        <button
          className="
          flex items-center space-x-1 w-max h-[1.8rem] p-2 bg-green-500 text-white rounded-md
          hover:bg-green-400
        "
          onClick={_onClickSubmit}
          disabled={isLoading}
        >
          <CheckIcon fontSize="small" />
          <p>Xác nhận</p>
        </button>

        <button
          className="
          flex items-center space-x-1 w-max h-[1.8rem] p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
          disabled={isLoading}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  )
}

export default ConfirmReport;
