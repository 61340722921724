import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { CONFIG_LOADTASKREJECT } from "../../apis/_index";
import LoaderSpinner from '../loader-spinner/_index';
import ServiceUtils from "../../utils/services";
const ViewReason = (props: any) => {
  // state submodal
    const handleClose = props.handleClose
    const taskId = props.taskId
    const [taskStatus, setloadtaskreject] = useState<any>(null)

    const [data, setData] = useState<any>(null)
    
    useEffect(() => {
        fetchloadtaskreject()
      },[])
      
      async function fetchloadtaskreject() {
        const accessToken = localStorage.getItem("accessToken")
        try {
          
          await ServiceUtils.getV2(
            CONFIG_LOADTASKREJECT + taskId,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
          .then((res: any) => {
            if (res?.data?.StatusCode === 200) {          
                setData(res?.data?.Data)
            } else {
              toast.error(res?.data?.Message  || "Tải dữ liệu thất bại")
            }
          })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }
  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[40rem] w-screen lg:h-[20rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Xem lý do trả việc</p>
        </div>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
        {
            data === null ?
            <div className="tableFixHead styled-scrollbars styled-scrollbars h-full overflow-hidden p-4 text-sm">
              
            </div>
            :
            <div className="tableFixHead styled-scrollbars styled-scrollbars h-full overflow-hidden p-4 text-sm">
            {/* {data && data.length > 0 ? (
              data.map((item: any, index: number) => (
                <>
                  <p>Lí do bị trả</p>
                  <input
                    className="w-full h-[3rem] max-h-[7rem] rounded-md p-2 border-2 border-slate-200"
                    value={item?.Reason}
                    disabled
                  />
                </>
              ))
            ) : (
              <>
              <p>Không có dữ liệu</p>
              </>
              
            )} */}
                {
                  data?.map((item: any, index: number) => (
                      <>
                          <p>Lý do bị trả</p>
                           <textarea
                            className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                            value={item?.Reason}
                            disabled
                            />                         
                      </>
                  ))
                }
                              
                                        
            </div>
        }

   

      {/* footer */}
      <div className="flex p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-300
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewReason;
