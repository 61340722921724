import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { Pagination } from "@mui/material";

import { NGUOI_NHAN_VIEC_LIST_ALL, TU_TAO_BAO_CAO } from "../../../apis/_index";

import {
  BAO_CAO_CONG_VIEC_CHECK_REPORT
} from "../../../apis/_index";

import Select from "react-select";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";
import { LOAD_SEP } from "../../../apis/_index";

import moment from "moment";
import ServiceUtils from "../../../utils/services";

// var index = require("../../../temp/baocao/index.html");

import '../../../styles/createNewElectronicReports/index.css';

const CreateNewElectronicReport = (props: any) => {

  // take userId
  let userData: any = localStorage.getItem("userData")
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  const userId = userData.Data[0].Id

  // reducer
  const context = useContext(ModalController)
  const dispatch = useDispatch();

  const onLoad = context.funcsLoad?.onLoad

  // buttonList
  const [data, setData] = useState<any[] | null>(null);
  const [pageCurrent, setPageCurrent] = useState<number>(0);
  const pageLimit = 20;
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [buttonSelected, setButtonSelected] = useState<number>(0);

  const [selectedTask, setSelectedTask] = useState<any[]>([]);

  // funcs
  const _onClickAddingTaskOrRemove = (id: string) => {
    const foundItem = selectedTask?.find(item => item.ID === id)

    if (foundItem) {
      let tempArray = [...selectedTask]

      for (let i = 0; i < selectedTask?.length; i++) {
        if (selectedTask[i].ID === id) {
          tempArray.splice(i, 1)
          break
        }
      }
      setSelectedTask(tempArray)
    } else {
      setSelectedTask((oldValue: any) => [...oldValue, { ID: id }])
    }
    // console.log(selectedTask.find(_item => _item.ID === id))
  }

  const [buttonList, setButtonList] = useState<any[]>([
    {
      id: 0,
      title: "report",
      isShow: true,
    },
    {
      id: 1,
      title: "hard",
      isShow: false,
    },
  ]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const takeData = async () => {
      const body:any = {
        Page: pageCurrent,
        Limit: pageLimit,
        Recipient: userId,
      };

      try {
        await ServiceUtils.postV2(NGUOI_NHAN_VIEC_LIST_ALL, body, {
            headers: {
              Accept: "text/plain",
            },
          })
          .then((res:any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data.data);

              if (res.data.Data.count % 20 === 0) {
                setPageTotal(Math.floor(res.data.Data.count / 20));
              } else {
                setPageTotal(Math.floor(res.data.Data.count / 20) + 1);
              }
            }
          });
      } catch (error) {
        toast.error("Tải dữ liệu thất bại");
      }
    };

    takeData();
  }, [buttonSelected, pageCurrent]);

  // funcs onClick
  const _onClickChoosingButton = (type: string) => {
    setPageCurrent(0);

    const tempArray = buttonList.map((item) => {
      if (item.title === type) {
        setButtonSelected(item.id);
        return {
          ...item,
          isShow: true,
        };
      } else {
        return {
          ...item,
          isShow: false,
        };
      }
    });

    setButtonList(tempArray);
  };

  const handleClose = props.handleClose;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);

  const handleCloseSub = () => setOpenSub(false);
  const [bossList, setBossList] = useState<any>(null);
  const [reportType, setReportType] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);

  const [nameOfReport, setNameOfReport] = useState<any>("");

  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);

  const [quarter, setQuarter] = useState<any>(null);
  const [taskManager, setTaskManager] = useState<any>(null);
  const [taskOwner, setTaskOwner] = useState<any>(null);

  const [paramObject, setParamObject] = useState<any>(null)

  // props body
  const [bodyOfReport, setBodyOfReport] = useState<any | null>({})

  const reportTypeList = [
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch ngày" : "Báo cáo ngày", value: 1 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch tháng" : "Báo cáo tháng", value: 2 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch quý" : "Báo cáo quý", value: 3 },
    { label: urlLink === "/report/PlanSent" ? "Kế hoạch năm" : "Báo cáo năm", value: 4 },
  ];

  const _onChangeReportType = (data: any) => {
    setReportType(data);
  };

  const _onChangeNameOfReport = (e: any) => {
    e.preventDefault();
    setNameOfReport(e.target.value)
  }

  const monthList = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
  ];


  const yearList = [
    { label: 2023, value: 2023 }
  ]

  const _onChangeMonth = (data: any) => {
    setMonth(data);
  };

  const _onChangeYear = (data: any) => {
    if (data === null) {
      setYear("")
    } else {
      setYear(data)
    }
  }

  const quarterList = [
    { label: "Quý 1", value: 31 },
    { label: "Quý 2", value: 32 },
    { label: "Quý 3", value: 33 },
    { label: "Quý 4", value: 34 },
  ];

  const _onChangeQuarter = (data: any) => {
    setQuarter(data);
  };

  const taskManagerList = bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const _onChangeTaskManager = (data: any) => {
    setTaskManager(data);
  };

  const taskOwnerList = bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const _onChangeTaskOwner = (data: any) => {
    setTaskOwner(data);
  };

  const _onChangeFromDate = (e: any) => {
    setFromDate(e.target.value);
  };

  const _onChangeToDate = (e: any) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        // const accessToken = localStorage.getItem("accessToken");

        try {
          await ServiceUtils.getV2(LOAD_SEP, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                setBossList(res.data.Data);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeData();
    }
  });

  // // onChange Report change
  // useEffect(() => {
  //   setFromDate(null)
  //   setToDate(null)
  //   setQuarter(null)
  //   setMonth(null)
  //   setYear(null)
  // }, [reportTypeList])

  const _onClickViewReport = async (funcs: string) => {
    // if (reportType === null) {
    //   toast.error("Vui lòng chọn loại báo cáo");
    //   return;
    // }
    // if (reportType.value === 1) {
    //   if (fromDate === null) {
    //     toast.error("Vui lòng chọn ngày bắt đầu");
    //     return;
    //   }
    //   if (toDate === null) {
    //     toast.error("Vui lòng chọn ngày kết thúc");
    //     return;
    //   }
    // }
    // if (reportType.value === 2) {
    //   if (month === null) {
    //     toast.error("Vui lòng chọn tháng");
    //     return;
    //   }
    // }
    // if (reportType.value === 3) {
    //   if (quarter === null) {
    //     toast.error("Vui lòng chọn quý");
    //     return;
    //   }
    // }
    // if (reportType.value === 4) {
    //   if (year === null) {
    //     toast.error("Vui lòng chọn năm");
    //     return;
    //   }
    // }
    if (taskManager === null) {
      toast.error("Vui lòng chọn người yêu cầu");
      return;
    }
    if (taskOwner === null) {
      toast.error("Vui lòng chọn người nhận");
      return;
    } else {
      // setBodyOfReport({
      //   taskName: nameOfReport.toUpperCase(),
      //   reportType: reportType,
      //   fromDate: fromDate,
      //   toDate: toDate,
      //   taskManager: taskManager,
      //   taskOwner: taskOwner,
      //   month: month,
      //   year: year,
      //   quarter: quarter,
      // })
      // setFuncsSub(funcs)
      // handleOpenSub()

      _onClickCreateReport()
    }
  };

  const _onClickCreateReport = async () => {
    // if (reportType === null) {
    //   toast.error("Vui lòng chọn loại báo cáo");
    //   return;
    // }
    // if (nameOfReport === "" || nameOfReport === null) {

    // }
    // if (reportType.value === 1) {
    //   if (fromDate === null) {
    //     toast.error("Vui lòng chọn ngày bắt đầu");
    //     return;
    //   }
    //   if (toDate === null) {
    //     toast.error("Vui lòng chọn ngày kết thúc");
    //     return;
    //   }
    // }
    // if (reportType.value === 2) {
    //   if (month === null) {
    //     toast.error("Vui lòng chọn tháng");
    //     return;
    //   }
    // }
    // if (reportType.value === 3) {
    //   if (quarter === null) {
    //     toast.error("Vui lòng chọn quý");
    //     return;
    //   }
    // }
    // if (reportType.value === 4) {
    //   if (year === null) {
    //     toast.error("Vui lòng chọn năm");
    //     return;
    //   }
    // }
    if (taskManager === null) {
      toast.error("Vui lòng chọn người yêu cầu");
      return;
    }
    if (taskOwner === null) {
      toast.error("Vui lòng chọn người nhận");
      return;
    } else {

      const paramObject1: any = {
        taskName: nameOfReport.toUpperCase(),
        TaskOwner: taskOwner?.Id,
        TaskManager: taskManager?.Id,
        Task_ListReportID: selectedTask
      }

      const accessToken = localStorage.getItem("accessToken")

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(
            TU_TAO_BAO_CAO,
            paramObject1,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                onLoad()
                toast.success("Tạo báo cáo thành công.")
                handleClose()
              }
            })
        } catch (error: any) {
          toast.error("Tạo báo cáo thất bại.")
          handleClose()
        }
      }

      return takeData()
    }
  }

  // funcs
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCurrent(value - 1);
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[50rem] h-[calc(100vh-6rem)]
      rounded-md
    "
    >
      {/* SubModal */}
      <SubModal
        bodyOfReport={bodyOfReport}
        funcs={funcsSub}
        open={openSub}
        handleClose={handleCloseSub}
      />

      {/* Header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        {
          urlLink === "/report/PlanSent" ?
            <p>Tạo kế hoạch mới</p>
            :
            <p>Tự tạo báo cáo</p>
        }

        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="w-full flex h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2 core-create-new-electronic-report">
        {/* Infomation of report */}
        <div className="w-1/3 h-full pr-3 border-r-2 border-slate-300 flex flex-col space-y-3 core-create-new-electronic-report-filter">
          <>
            <div className="w-full h-max">
              {
                urlLink === "/report/PlanSent"
                  ?
                  <label>
                    Loại kế hoạch&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  :
                  <></>
              }

              {/* <Select
            className="z-[10]"
            options={reportTypeList}
            onChange={(data: any) => _onChangeReportType(data)}
            isClearable
            placeholder={urlLink === "/report/PlanSent" ? "Chọn loại kế hoạch" : "Chọn loại báo cáo"}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          /> */}

            </div>

            <div className="flex flex-col w-full pr-0 h-max">
              {
                urlLink === "/report/PlanSent"
                  ?
                  <label>
                    Tên kế hoạch&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  :
                  <label>
                    Tên báo cáo&nbsp;<span className="text-red-500">(*)</span>
                  </label>
              }
              <input
                className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                placeholder={urlLink === "/report/PlanSent" ? "Nhập tên kế hoạch" : "Nhập tên báo cáo"}
                value={nameOfReport}
                onChange={(e: any) => _onChangeNameOfReport(e)}
              />
            </div>

            {reportType?.value === 1 ? (
              <div className="flex w-full h-max space-x-2">
                <div className="w-full">
                  <label>
                    Từ ngày&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <input
                    type="date"
                    className="w-full h-8 border border-gray-300 rounded-md pl-2"
                    onChange={(e) => {
                      e.preventDefault();
                      _onChangeFromDate(e);
                    }}
                  />
                </div>
                <div className="w-full">
                  <label>
                    Đến ngày&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <input
                    type="date"
                    className="w-full h-8 border border-gray-300 rounded-md pl-2"
                    onChange={(e) => {
                      e.preventDefault();
                      _onChangeToDate(e);
                    }}
                  />
                </div>
              </div>
            ) : reportType?.value === 2 ? (
              <div className="w-full h-max flex space-x-3">
                <div className="w-1/2 h-max">
                  <label>
                    Chọn tháng&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <Select
                    className="z-[9]"
                    options={monthList}
                    onChange={(data: any) => _onChangeMonth(data)}
                    isClearable
                    placeholder="Chọn tháng"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="w-1/2 h-max">
                  <label>
                    Chọn năm&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <Select
                    className="z-[9]"
                    options={yearList}
                    onChange={(data: any) => _onChangeYear(data)}
                    isClearable
                    placeholder="Chọn năm"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            ) : reportType?.value === 3 ? (
              <div className="w-full h-max flex space-x-3">
                <div className="w-1/2 h-max">
                  <label>
                    Chọn quý&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <Select
                    className="z-[9]"
                    options={quarterList}
                    onChange={(data: any) => _onChangeQuarter(data)}
                    isClearable
                    placeholder="Chọn quý"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

                <div className="w-1/2 h-max">
                  <label>
                    Chọn năm&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <Select
                    className="z-[9]"
                    options={yearList}
                    onChange={(data: any) => _onChangeYear(data)}
                    isClearable
                    placeholder="Chọn năm"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>

              </div>
            ) : (
              reportType?.value === 4 && (
                <div className="w-full h-max">
                  <label>
                    Chọn năm&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <Select
                    className="z-[9]"
                    options={yearList}
                    onChange={(data: any) => _onChangeYear(data)}
                    isClearable
                    placeholder="Chọn năm"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )
            )}

            <div className="w-full h-max">
              <label>
                Chọn người đưa yêu cầu&nbsp;
                <span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[6]"
                options={taskManagerList}
                onChange={(data: any) => _onChangeTaskManager(data)}
                isClearable
                placeholder="Chọn người đưa yêu cầu"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="w-full h-max">
              <label>
                Chọn người nhận&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <Select
                className="z-[5]"
                options={taskOwnerList}
                onChange={(data: any) => _onChangeTaskOwner(data)}
                isClearable
                placeholder="Chọn người nhận"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </>

          <div className="w-full h-max flex flex-col space-y-2 mt-10">
            {/* <button
              className={` mt-6
              w-full h-max flex font-semibold transition-all bg-slate-100 p-4 hover:text-orange-500 hover:shadow-lg hover:p-5
              ${buttonList[0].isShow === true ? "bg-sky-200" : "text-slate-400"}
            `}
              onClick={() => _onClickChoosingButton("report")}
            >
              <p className="w-2/3">Công việc cần báo cáo</p>
            </button>

            <button
              className={`
              w-full h-max flex font-semibold transition-all bg-slate-100 p-4 hover:text-orange-500 hover:shadow-lg hover:p-5
              ${buttonList[1].isShow === true ? "bg-sky-200" : "text-slate-400"}
            `}
              onClick={() => _onClickChoosingButton("hard")}
            >
              <p className="w-2/3">Công việc gặp khó khăn</p>
            </button> */}
          </div>
        </div>

        {/* Table */}
        <div className="w-2/3 h-full pl-3 core-create-new-electronic-report-list">
          <div className="w-full h-full">
            <div className="tableFixHead w-full h-[calc(100%-2rem)] shadow-md">

              <table className="shadow-lg transition-all hover:shadow-slate-500">
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="hidden lg:table-cell border border-slate-300 ">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>STT</p>
                        <button className="hidden lg:flex w-max h-max hover:text-slate-200"></button>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Loại</p>
                      </div>
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Tên công việc</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Mức độ</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Ngày giao</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Người nhận</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Thời hạn</p>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto text-black">
                  {data === null ? (
                    <tr></tr>
                  ) : (
                    data?.map((item: any, index: any) => (
                      <tr className={`
                        w-max h-[3rem] cursor-pointer
                        ${selectedTask.find(_item => _item.ID === item.ID) !== undefined ? "bg-green-200" : "bg-white"}
                      `} onClick={() => _onClickAddingTaskOrRemove(item.ID)}>

                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {index + 1 + pageCurrent * pageLimit}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.IsRecipient ? (
                            <p>Thực hiện chính</p>
                          ) : (
                            <p>Hỗ trợ</p>
                          )}
                        </td>
                        <td className="border border-slate-300 space-y-0.5">
                          <p className="lg:hidden font-bold">
                            <span className="lg:hidden">{index}</span>
                            {item.TaskName}
                          </p>
                          <p className="hidden lg:flex">{item.TaskName}</p>
                          <p className="lg:hidden">
                            <span className="text-xs">Người nhận: </span>
                            <span className="font-semibold">
                              {item.RecipientName}
                            </span>
                          </p>
                          {item.SupporterName !== null && (
                            <p className="lg:hidden">
                              <span className="text-xs">
                                Người hỗ trợ:&nbsp;
                              </span>
                              <span className="text-slate-600">
                                {item.SupporterName}
                              </span>
                            </p>
                          )}
                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">Thời hạn: </span>
                              <span className="italic">
                                {moment(item.AssignmentDate).format("HH:mm")}
                                &nbsp;
                                {moment(item.AssignmentDate).format("DD/MM")}
                              </span>
                              &nbsp;-&nbsp;
                              <span className="italic">
                                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                {moment(item.DeadLine).format("DD/MM")}
                              </span>
                            </p>
                            <p>{item.PriorityLevelName}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p>{item.PriorityLevelName}</p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.RecipientName}
                          {item.SupporterName && (
                            <p className="text-xs">
                              <span className="text-slate-600">Hỗ trợ:</span>{" "}
                              {item.SupporterName}
                            </p>
                          )}
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </div>
                        </td>

                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Paginate */}
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={pageTotal}
              page={pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
            flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-amber-500 hover:bg-amber-400
        `}
            onClick={() => _onClickViewReport(urlLink === "/report/PlanSent" ? "checkPlanning" : "checkViewPort")}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <>
                {
                  urlLink === "/report/PlanSent" ?
                    <p>Xem kế hoạch</p>
                    :
                    <p>Xem báo cáo</p>
                }
              </>
            )}
          </button>

          {/* <button
            className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md bg-green-500 hover:bg-green-400
        `}
            onClick={_onClickCreateReport}
          >
            <p>Tạo mới</p>
          </button> */}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateNewElectronicReport;
