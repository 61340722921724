import React, { useEffect, useRef, useState, useContext } from "react";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser"
import ReactToPrint from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';

import { ModalController } from "../../../ui/homeUI/_index";

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import planView from "../../../temp/kehoach";

import SubModal from "../_subModal/_index";

import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import { KE_HOACH_GUI, KE_HOACH_XOA, BAO_CAO_CHI_TIET, BAO_CAO_UPDATE_REPORT, KE_HOACH_CHI_TIET } from "../../../apis/_index";

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { toast } from "react-toastify";
import axios from "axios";

import moment from "moment";

import reportView from "../../../temp/baocao";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import LoaderSpinner from "../../loader-spinner/_index";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const PlanElectric = (props: any) => {

  // Take props
  const taskId = props.taskId;
  const dataModal = props.dataModal;
  const handleClose = props.handleClose;

  const context = useContext(ModalController)

  const onLoad = context.funcsLoad?.onLoad

  const componentRef = useRef(null)
  const urlLink = window.location.pathname;

  const [resultOfReport, setResultOfReport] = useState<any>(null)
  const [optionParser, setOptionParser] = useState<any>(null)
  const [reportNotFinish, setReportNotFinish] = useState<any[] | null>(null)

  // funcs
  const _onChangeProcessingTask = (id: string, type: string, text: string) => {

    // setReportNotFinish((prevState: any) => {
    //   return {
    //     ...prevState,
    //     taskProccessing: prevState?.taskProccessing.map((item: any) =>
    //         item.TaskID === id && type === "different" ? { ...item, Report_Hard: text }
    //           : item.TaskID === id && type === "solution" ? { ...item, Report_Solution: text }
    //             : item.TaskID === id && type === "plan" ? { ...item, Report_Plan: text }
    //               : item
    //   )}
    // })
  }

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button
      id="print"
      className="
    flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md
    hover:bg-blue-400
  "

    >
      <PrintIcon fontSize="small" />
      <p>In báo cáo</p>
    </button>;
  }, []);



  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onClickSendReport = () => {
    setFuncsSub("planSend")
    handleOpenSub()
  }

  const _onClickDeleteReport = () => {
    setFuncsSub("planDelete")
    handleOpenSub()
  }

  // onMount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")
    const body: any = {
      ID: taskId
    }
    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(
          KE_HOACH_CHI_TIET,
          body,
          {
            headers: {
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              const fetchData = () => {
                setResultOfReport(res.data.Data)
                setReportNotFinish(res.data.Data.taskProccessing)

                console.log(res.data.Data)
              }
              fetchData()
            }
          })
      } catch (error) {
        toast.error("Tải dữ liệu báo cáo thất bại.")
      }
    }

    fetchData()
  }, [])

  // console.log(resultOfReport)

  // parser-html
  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: domNode => {
        const typedDomNode = domNode as Element

        if (typedDomNode.attribs) {
          if (parseInt(dataModal.periodic) === 1) {
            switch (typedDomNode.attribs.id) {
              case "chucVuUser":
                return <span>{resultOfReport?.tasks_users[0].ChucVuUser}</span>
              case "phongBanUser":
                return <span>{resultOfReport?.tasks_users[0].PhongBanUser}</span>

              case "planName":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{resultOfReport?.tasks_report[0].TaskName}</h1>

              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{dataModal?.taskName}</h1>
              case "toDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black' }} scope="row">
                          <div className="w-full h-full">
                            {index + 1}
                          </div>

                        </th>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                            {item.Job_Content}
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                            {item.Job_Purpose}
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                            {item.Job_Step}
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              // case "bodyOfTaskComplete":
              //   return <tbody>
              //     {
              //       resultOfReport?.taskCompleted.map((item: any, index: number) => (
              //         <tr key={index}>
              //           <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskOwnerName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="w-full h-full">
              //             </div>
              //           </td>
              //         </tr>
              //       ))
              //     }

              //   </tbody>;
              // case "bodyOfTaskProcessing":
              //   return <div className="flex flex-col space-y-10">
              //     {
              //       resultOfReport?.taskProccessing.map((item: any, index: number) => (
              //         <div key={index}>
              //           <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Khó Khăn</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Hard}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "different", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Giải pháp</label>
              //             <textarea
              //               readOnly
              //               wrap="soft"
              //               value={item.Report_Solution}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "solution", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Plan}
              //               wrap="soft"
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "plan", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>
              //         </div>
              //       ))
              //     }
              //   </div>;
              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 2) {
            switch (typedDomNode.attribs.id) {
              case "chucVuUser":
                return <span>{resultOfReport?.tasks_users[0].ChucVuUser}</span>
              case "phongBanUser":
                return <span>{resultOfReport?.tasks_users[0].PhongBanUser}</span>

              case "planName":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{resultOfReport?.tasks_report[0].TaskName}</h1>

              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{dataModal?.taskName}</h1>
              case "toDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Content}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Purpose}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Step}
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              // case "bodyOfTaskComplete":
              //   return <tbody>
              //     {
              //       resultOfReport?.taskCompleted.map((item: any, index: number) => (
              //         <tr key={index}>
              //           <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskOwnerName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="w-full h-full">
              //             </div>
              //           </td>
              //         </tr>
              //       ))
              //     }

              //   </tbody>;
              // case "bodyOfTaskProcessing":
              //   return <div className="flex flex-col space-y-10">
              //     {
              //       resultOfReport?.taskProccessing.map((item: any, index: number) => (
              //         <div key={index}>
              //           <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Khó Khăn</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Hard}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "different", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Giải pháp</label>
              //             <textarea
              //               readOnly
              //               wrap="soft"
              //               value={item.Report_Solution}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "solution", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Plan}
              //               wrap="soft"
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "plan", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>
              //         </div>
              //       ))
              //     }
              //   </div>;
              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 3) {
            switch (typedDomNode.attribs.id) {
              case "chucVuUser":
                return <span>{resultOfReport?.tasks_users[0].ChucVuUser}</span>
              case "phongBanUser":
                return <span>{resultOfReport?.tasks_users[0].PhongBanUser}</span>

              case "planName":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{resultOfReport?.tasks_report[0].TaskName}</h1>

              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{dataModal?.taskName}</h1>
              case "toDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Content}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Purpose}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Step}
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              // case "bodyOfTaskComplete":
              //   return <tbody>
              //     {
              //       resultOfReport?.taskCompleted.map((item: any, index: number) => (
              //         <tr key={index}>
              //           <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskOwnerName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="w-full h-full">
              //             </div>
              //           </td>
              //         </tr>
              //       ))
              //     }

              //   </tbody>;
              // case "bodyOfTaskProcessing":
              //   return <div className="flex flex-col space-y-10">
              //     {
              //       resultOfReport?.taskProccessing.map((item: any, index: number) => (
              //         <div key={index}>
              //           <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Khó Khăn</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Hard}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "different", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Giải pháp</label>
              //             <textarea
              //               readOnly
              //               wrap="soft"
              //               value={item.Report_Solution}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "solution", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Plan}
              //               wrap="soft"
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "plan", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>
              //         </div>
              //       ))
              //     }
              //   </div>;
              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 4) {
            switch (typedDomNode.attribs.id) {
              case "chucVuUser":
                return <span>{resultOfReport?.tasks_users[0].ChucVuUser}</span>
              case "phongBanUser":
                return <span>{resultOfReport?.tasks_users[0].PhongBanUser}</span>

              case "planName":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{resultOfReport?.tasks_report[0].TaskName}</h1>

              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{dataModal?.taskName}</h1>
              case "toDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Content}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Purpose}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.Job_Step}
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              // case "bodyOfTaskComplete":
              //   return <tbody>
              //     {
              //       resultOfReport?.taskCompleted.map((item: any, index: number) => (
              //         <tr key={index}>
              //           <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             {item.TaskOwnerName}
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="flex flex-col text-center">
              //               <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
              //               <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
              //             </div>
              //           </td>
              //           <td style={{ border: '2px solid black' }}>
              //             <div className="w-full h-full">
              //             </div>
              //           </td>
              //         </tr>
              //       ))
              //     }

              //   </tbody>;
              // case "bodyOfTaskProcessing":
              //   return <div className="flex flex-col space-y-10">
              //     {
              //       resultOfReport?.taskProccessing.map((item: any, index: number) => (
              //         <div key={index}>
              //           <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Khó Khăn</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Hard}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "different", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Giải pháp</label>
              //             <textarea
              //               readOnly
              //               wrap="soft"
              //               value={item.Report_Solution}
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "solution", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>

              //           <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
              //             <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
              //             <textarea
              //               readOnly
              //               value={item.Report_Plan}
              //               wrap="soft"
              //               onChange={(e: any) => {
              //                 e.preventDefault()
              //                 _onChangeProcessingTask(item.ID, "plan", e.target.value)
              //               }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
              //           </div>
              //         </div>
              //       ))
              //     }
              //   </div>;
              default:
                break
            }
          }
        }
      }
    };

    setOptionParser(options)

  }, [resultOfReport])

  // funcs
  const _onClickUpdateReport = () => {
    const accessToken = localStorage.getItem("accessToken")
    const body: any = {
      ID: taskId,
      TaskName: dataModal.taskName,
      Report_Update: reportNotFinish
    }

    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(
          BAO_CAO_UPDATE_REPORT,
          body,
          {
            headers: {
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              toast.success("Cập nhật thành công")
              handleClose()
              window.location.reload()
            }
          })
      } catch (error) {
        toast.error("Cập nhật thất bại.")
      }
    }

    fetchData()
  }

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        ">
        <p>Biểu mẫu kế hoạch</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {
        planView === null || resultOfReport === null || reportNotFinish === null ?
          // <div className="flex-col w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll justify-center items-center">
          //   <LoaderSpinner w={32} h={32} />
          //   <p className="text-primary">Đang tải dữ liệu</p>
          // </div>
          <LoadingControl />
          :
          <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
            <div className="w-full h-max flex">
              {parse(planView, optionParser)}
            </div>
          </div>
      }



      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md  items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center">
          {/* Print button */}
          {/* <button
            className={`
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md bg-green-500 hover:bg-green-400
            `}
          >

          </button> */}
          <div className="w-max h-max flex space-x-3">
            <ReactToPrint
              content={() => { return componentRef.current }}
              trigger={reactToPrintTrigger}
            />

            <div className="hidden">
              <div className="w-full h-max" ref={componentRef}>
                {parse(planView, optionParser)}
              </div>
            </div>
          </div>
          {/* Print button */}


          {/* Close button */}

          <div className="w-max h-max flex space-x-2">
            {
              urlLink === "/report/PlanSent"
                ?
                <button
                  className="
                flex items-center space-x-1 w-max h-max p-2 bg-red-500 text-white rounded-md
                hover:bg-red-400
              "
                  onClick={_onClickDeleteReport}
                >
                  <DeleteIcon fontSize="small" />
                  <p>Xoá</p>
                </button>
                :
                <></>
            }

            {
              urlLink === "/report/PlanSent"
                ?
                //         <button
                //           className="
                //   flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-amber-500 text-white rounded-md
                //   hover:bg-amber-400
                // "
                //           onClick={_onClickSendReport}
                //         >
                //           <ModeEditOutlineIcon fontSize="small" />
                //           <p>Chỉnh sửa</p>
                //         </button>
                <></>
                :
                <></>
            }

            {
              urlLink === "/report/PlanSent"
                ?
                <button
                  className="
              flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
              hover:bg-green-400
            "
                  onClick={_onClickSendReport}
                >
                  <SendIcon fontSize="small" />
                  <p>Gửi kế hoạch</p>
                </button>
                :
                <></>
            }

            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanElectric
