import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../../components/title";

import AddIcon from "@mui/icons-material/Add";
import SourceTwoToneIcon from "@mui/icons-material/SourceTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";

import SubModal from "../../../../components/modal/_subModal/_index";

import { takePermission } from "../../../../funcs";

import { ModalController } from "../../../homeUI/_index";

import { READ_ALL_TASK_LIST } from "../../../../apis/_index";
import {
  ONLOAD_TASKLIST,
  ONPUSH_SUBLIST,
} from "../../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../../utils/services";

const ListOfTaskUI = () => {
  const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();

  const menu = takePermission(11);

  const shouldLog = useRef(true);

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // funcs
  const _onClickSubList = (id: number) => {
    dispatch(ONPUSH_SUBLIST({ id: id }));
    // const accessToken = localStorage.getItem("accessToken");

    // const takeData = async () => {
    //   try {
    //     await ServiceUtils.getV2(
    //       READ_ALL_TASK_LIST,
    //       {
    //         headers: {
    //         }
    //       }
    //     )
    //       .then((res: any) => {
    //         if (res.status === 200) {
    //           let tempArray: any[] = [];

    //           res.data.Data.forEach((item: any) => {
    //             if (item.ParentID === id) {
    //               tempArray.push(item)
    //             }
    //           });

    //           dispatch(ONPUSH_SUBLIST({ id: id, array: tempArray }));

    //           setIsLoading(false);
    //         } else {
    //           setIsLoading(false);
    //         }
    //       })
    //   } catch (error: any) {
    //     toast.error("Tải dữ liệu thất bại");
    //     setIsLoading(false);
    //     return;
    //   }
    // }

    // takeData();
  };

  useEffect(() => {

    if (data.taskList === null && shouldLog.current) {
      setIsLoading(true);
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(READ_ALL_TASK_LIST, {
              headers: {
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_TASKLIST(res.data.Data));
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
          setIsLoading(false);
          return;
        }
      };

      takeData();
    }
  }, [data.taskList, dispatch]);

  const context = useContext(ModalController);

  const _onClickCreateListOfTask = (id: any) => {
    context.setFuncs("listOfTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailListOfTask = (id: any) => {
    context.setFuncs("detailListOfTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] pl-5 pr-5 pb-5 text-sm">
        <div className="pt-3">
          <button
            className={`
          w-max h-max bg-green-600 text-white p-2
          items-center rounded-md hover:bg-green-500
          ${menu.PerInsert === true ? "flex" : "hidden"}
        `}
            onClick={() => _onClickCreateListOfTask(1)}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Tạo đầu việc</p>
          </button>
        </div>

        <div className="flex flex-col w-full h-max pt-3">
          {isLoading === true ? (
            <p className="text-primary font-bold">Đang tải dữ liệu...</p>
          ) : data.taskList === null ? (
            ""
          ) : (
            data.taskList.map((item: any, index: any) => (
              <div key={index} className="flex flex-col w-full h-max">
                <div className="flex w-full h-max space-x-1">
                  <button
                    onClick={() => _onClickSubList(item.Id)}
                    className="w-max h-max"
                  >
                    {item.isShow === false ? (
                      <ArrowRightIcon
                        className="hover:text-slate-400"
                        fontSize="small"
                      />
                    ) : (
                      <ArrowDropDownIcon
                        className="hover:text-slate-400"
                        fontSize="small"
                      />
                    )}
                  </button>

                  <SourceTwoToneIcon
                    className="text-primary font-medium"
                    fontSize="small"
                  />

                  <button
                    className="pl-1 w-max h-max hover:text-primary hover:underline font-medium"
                    onClick={() => _onClickDetailListOfTask(item.Id)}
                  >
                    {item.Tendauviec}
                  </button>
                </div>

                {item.isShow === false
                  ? ""
                  : item.subTask.length === 0
                  ? ""
                  : item.subTask.map((item: any, index: any) => (
                      <button
                        key={index}
                        className="flex ml-12 space-x-2 hover:text-primary hover:underline w-max"
                        onClick={() => _onClickDetailListOfTask(item.Id)}
                      >
                        <TaskTwoToneIcon
                          className="text-primary"
                          fontSize="small"
                        />
                        <p>{item.Tendauviec}</p>
                      </button>
                    ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ListOfTaskUI;
