/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Pagination } from "@mui/material";

import moment from "moment";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";
import { Checkcolor } from "../../../funcs/checkColor";
import CloseIcon from '@mui/icons-material/Close';

// api importing
import {
  NGUOI_GIAO_LIST_NEW,
  NGUOI_GIAO_LIST_NEED_DELIVERY,
  NGUOI_GIAO_LIST_RETURNWORK,
} from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const TotalTaskAll = (props: any) => {
  // props
  const handleClose = props.handleClose;
  const dataModal = props.dataModal;
  const userId = props.userId;

  // state
  const [data, setData] = useState<any[] | null>(null);
  const [pageCurrent, setPageCurrent] = useState<number>(0);
  const pageLimit = 20;
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [buttonSelected, setButtonSelected] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [buttonList, setButtonList] = useState<any[]>([
    {
      id: 0,
      title: "countListNew",
      isShow: true,
    },
    {
      id: 1,
      title: "countListReveced",
      isShow: false,
    },
    {
      id: 2,
      title: "countListReturnWork",
      isShow: false,
    },
  ]);

  // funcs onClick
  const _onClickChoosingButton = (type: string) => {
    setPageCurrent(0);

    const tempArray = buttonList.map((item) => {
      if (item.title === type) {
        setButtonSelected(item.id);
        return {
          ...item,
          isShow: true,
        };
      } else {
        return {
          ...item,
          isShow: false,
        };
      }
    });

    setButtonList(tempArray);
  };

  // onMount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    let API: string = "";

    switch (buttonSelected) {
      case 0:
        API = NGUOI_GIAO_LIST_NEW;
        break;
      case 1:
        API = NGUOI_GIAO_LIST_NEED_DELIVERY;
        break;
      case 2:
        API = NGUOI_GIAO_LIST_RETURNWORK;
        break;

      default:
        break;
    }

    const takeData = async () => {
      const body:any = {
        Page: pageCurrent,
        Limit: pageLimit,
        Recipient: userId,
      };

      try {
        setIsLoading(true);
        await ServiceUtils.postV2(API, body, {
            headers: {
              Accept: "text/plain",
            },
          })
          .then((res:any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data.data);

              if (res.data.Data.count % 20 === 0) {
                setPageTotal(Math.floor(res.data.Data.count / 20));
                setIsLoading(false);
              } else {
                setPageTotal(Math.floor(res.data.Data.count / 20) + 1);
                setIsLoading(false);
              }
            }
          });
      } catch (error) {
        toast.error("Tải dữ liệu thất bại");
        setIsLoading(false);
      }
    };

    takeData();
  }, [buttonSelected, pageCurrent]);

  // funcs
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCurrent(value - 1);
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen h-[40rem]
      rounded-md
    "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tổng hợp công việc</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      <div className="flex text-primary w-full h-[calc(100%-6rem)] overflow-auto p-4 lg:text-xs text-xs styled-scrollbars">
        {/* Left info */}
        <div className="flex flex-col w-1/5 h-full lg:text-md text-xs border-r-2 border-slate-300 space-y-1 pt-[0.5rem] pr-1">
          {/* Elements */}
          <div className="flex flex-col w-full h-max">
            <button
              className={`
              w-full h-max flex font-semibold transition-all bg-slate-100 p-4 hover:text-orange-500 hover:shadow-lg hover:p-5
              ${buttonList[0].isShow === true ? "shadow-lg" : "text-slate-400"}
            `}
              onClick={() => _onClickChoosingButton("countListNew")}
            >
              <p className="w-2/3">Chưa nhận </p>
              <p className="w-1/3 text-slate-400">({dataModal.countListNew})</p>
            </button>
          </div>

          {/* <div className="flex flex-col w-max h-max">
            <button className={`
              w-max h-max font-semibold hover:font-bold
              ${buttonList[1].isShow === true ? "bg-sky-200" : ""}
            `}
              onClick={() => _onClickChoosingButton("countListReveced")}
            >
              <p>Công việc đã nhận</p>
            </button>
            <p>{dataModal.countListReveced}</p>
          </div> */}

          <div className="flex flex-col w-full h-max">
            <button
              className={`
              w-full h-max flex font-semibold transition-all bg-slate-100 p-4 hover:text-orange-500 hover:shadow-lg hover:p-5
              ${buttonList[2].isShow === true ? "shadow-lg" : "text-slate-400"}
            `}
              onClick={() => _onClickChoosingButton("countListReturnWork")}
            >
              <p className="w-2/3">Trả lại</p>
              <p className="w-1/3 text-slate-400">
                ({dataModal.countListReturnWork})
              </p>
            </button>
          </div>
        </div>

        {/* Right table */}
        <div className="w-4/5 h-full pl-3">
          {isLoading === true ? (
            // <div className="w-full h-full flex flex-col justify-center items-center">
            //   <LoaderSpinner w={32} h={32} />
            //   <p className="text-primary">Đang tải dữ liệu</p>
            // </div>
            <LoadingControl />
          ) : (
            <div className="w-full h-full">
              <div className="tableFixHead w-full h-[calc(100%-2rem)] shadow-md">
                <table className="shadow-lg transition-all hover:shadow-slate-500">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="hidden lg:table-cell border border-slate-300 ">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>STT</p>
                          <button className="hidden lg:flex w-max h-max hover:text-slate-200"></button>
                        </div>
                      </th>
                      <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Loại</p>
                        </div>
                      </th>
                      <th className="border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Tên công việc</p>
                        </div>
                      </th>
                      <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Mức độ</p>
                        </div>
                      </th>
                      <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Ngày giao</p>
                        </div>
                      </th>
                      <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Người nhận</p>
                        </div>
                      </th>
                      <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                        <div className="w-full h-full flex space-x-1 justify-center items-center">
                          <p>Thời hạn</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto text-black">
                    {data === null ? (
                      <tr></tr>
                    ) : (
                      data?.map((item: any, index: any) => (
                        <tr className="w-max h-[3rem] bg-white">
                          <td className="hidden lg:table-cell text-center border border-slate-300">
                            {index + 1 + pageCurrent * pageLimit}
                          </td>
                          <td className="hidden lg:table-cell border border-slate-300">
                            {item.IsRecipient ? (
                              <p>Thực hiện chính</p>
                            ) : (
                              <p>Hỗ trợ</p>
                            )}
                          </td>
                          <td className="border border-slate-300 space-y-0.5">
                            <p className="lg:hidden font-bold">
                              <span className="lg:hidden">
                                {index + 1 + pageCurrent * pageLimit}.{" "}
                                {item.TaskName}
                              </span>
                            </p>
                            <p className="hidden lg:flex">{item.TaskName}</p>
                            <p className="lg:hidden">
                              <span className="text-xs">Người nhận: </span>
                              <span className="font-semibold">
                                {item.RecipientName}
                              </span>
                            </p>
                            {item.SupporterName !== null && (
                              <p className="lg:hidden">
                                <span className="text-xs">
                                  Người hỗ trợ:&nbsp;
                                </span>
                                <span className="text-slate-600">
                                  {item.SupporterName}
                                </span>
                              </p>
                            )}
                            <div className="lg:hidden w-full h-max flex flex-col justify-center">
                              <p>
                                <span className="text-xs">Thời hạn: </span>
                                <span className="italic">
                                  {moment(item.AssignmentDate).format("HH:mm")}
                                  &nbsp;
                                  {moment(item.AssignmentDate).format("DD/MM")}
                                </span>
                                &nbsp;-&nbsp;
                                <span className="italic">
                                  {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                  {moment(item.DeadLine).format("DD/MM")}
                                </span>
                              </p>
                              <p>{item.PriorityLevelName}</p>
                            </div>
                          </td>
                          <td className="hidden lg:table-cell text-center border border-slate-300">
                            <p>{item.PriorityLevelName}</p>
                          </td>
                          <td className="hidden lg:table-cell text-center border border-slate-300">
                            <div className="w-full h-max flex flex-col justify-center items-center">
                              <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                              <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                          <td className="hidden lg:table-cell border border-slate-300">
                            {item.RecipientName}
                            {item.SupporterName && (
                              <p className="text-xs">
                                <span className="text-slate-600">Hỗ trợ:</span>{" "}
                                {item.SupporterName}
                              </p>
                            )}
                          </td>
                          <td className="hidden lg:table-cell text-center border border-slate-300">
                            <div className="w-full h-max flex flex-col justify-center items-center">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
                <Pagination
                  count={pageTotal}
                  page={pageCurrent + 1}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* footer */}
      <div
        className={`flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4`}
      >
        <button
          className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
          onClick={handleClose}
        >
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default TotalTaskAll;
