import React from "react"

import { EditorInterface } from "../editor.interface"

// types of button

// end of importing

const EditorRouter: React.FC<EditorInterface> = (props) => {
	const type = props.type?.value

	switch (type) {
		/*----------------- SWITCH CASE OF TYPE COMPONENTS  -----------------------*/
		/**
		 * Switch case for type of components in here. Example
		 * 
		 * import ExmapleButton from ".."
		 * 
		 * switch (type) {
		 *    case "example":
		 *        return <ExmapleButton type={props.type} />    => Must have attrs type={props.tyupe}
		 * }
		 * 
		 * **/


		/* ------------------------- END OF CASING ----------------------------*/

		default:
			return <></>
	}
}

export default EditorRouter