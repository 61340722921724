import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import LoaderSpinner from "../../loader-spinner/_index";

import {
  NGUOI_GIAO_VIEC_LIST,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
} from "../../../apis/_index";
import {
  ONLOAD_TASKLIST_ALL,
  ONCHANGE_VIEC_CAN_GIAO,
} from "../../../redux/reducers/workingReducer/_index";

import SendIcon from "@mui/icons-material/Send";
import { APPROVE_TASK } from "../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const GiveTask = (props: any) => {
  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true);

  const urlLink = window.location.pathname;

  const reloadData = () => {
    if (urlLink === "/working/new-task") {
      readList(
        NGUOI_GIAO_VIEC_LIST,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        urlLink,
        (res: any) => {
          dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
        }
      );
    }
  };

  const _onClickSubmit = () => {
    const approve = async () => {
      // const accessToken = localStorage.getItem("accessToken");
      const API = APPROVE_TASK + taskId;

      try {
        setIsLoading(true);
        await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (res.status === 200) {
              if (urlLink === "/working/new-task") {
                readList(
                  NGUOI_GIAO_VIEC_LIST,
                  data.pageCurrent,
                  data.choosePage,
                  data.searchFilter.taskName,
                  data.searchFilter.selectedPrior,
                  data.searchFilter.selectedReceiver,
                  data.searchFilter.fromDate,
                  data.searchFilter.toDate,
                  data.searchFilter.deadLine,
                  shouldLog1,
                  urlLink,
                  (res: any) => {
                    dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                  }
                );
              } else {
                readList(
                  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
                  data.pageCurrent,
                  data.choosePage,
                  data.searchFilter.taskName,
                  data.searchFilter.selectedPrior,
                  data.searchFilter.selectedReceiver,
                  data.searchFilter.fromDate,
                  data.searchFilter.toDate,
                  data.searchFilter.deadLine,
                  shouldLog,
                  urlLink,
                  (res: any) => {
                    dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
                  }
                );
              }

              handleClose();
              toast.success("Giao việc thành công");
            }

            // if ((((res || {}).data || {}).Data || {}).RCode === 200) {

            //   if (handleClose) {
            //     reloadData()
            //     setIsLoading(false)
            //     handleClose()
            //   }
            //   toast.success("Giao thành công.");
            // } else {
            //   if (handleClose) {
            //     setIsLoading(false)
            //     handleClose()
            //   }
            //   toast.error("Giao thất bại.");
            // }
          });
      } catch (error: any) {
        if (handleClose) {
          setIsLoading(false);
          handleClose();
        }
        toast.error("Giao thất bại.");
      }
    };
    approve();
  };

  return (
    <div
      className="
      flex bg-emerald-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <SendIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý giao công việc này không?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className={`
            w-[5rem] h-max p-2 rounded-md ${
              isLoading === true
                ? "bg-slate-300"
                : "bg-slate-500  hover:bg-slate-400"
            }
            flex justify-center items-center
          `}
            onClick={() => _onClickSubmit()}
            disabled={isLoading}
          >
            {isLoading === false ? <p>Có</p> : <LoaderSpinner w={20} h={20} />}
          </button>
          <button
            className={`w-[5rem] h-max p-2 rounded-md ${
              isLoading === true ? "bg-red-300" : "bg-red-500 hover:bg-red-400"
            }`}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiveTask;
