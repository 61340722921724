import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ONLOAD_USERLIST } from "../../../redux/reducers/settingReducer/_index";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import SubModal from "../_subModal/_index";

import { takePermission } from "../../../funcs";

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { USER_DETAIL, TAKE_PARAMETER_LISTKEY_DROPDOWN, GROUP_READ_ALL_DROPDOWN, USER_CREATE, USER_READ_ALL, USER_UPDATE } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import { onTouchForm } from "../../../funcs/onTochForm";

import LoaderSpinner from "../../loader-spinner/_index";
import CloseIcon from '@mui/icons-material/Close';
import { Guid } from "guid-typescript";

import AddIcon from '@mui/icons-material/Add';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import LevelComponent from "../../organizations/levels/levelComponent";
import OrganizationRepository from "../../../repositories/organizations/organizationRepository";
import ServiceUtils from "../../../utils/services";

import '../../../styles/createListOfEmployees/index.css';

const DetailListOfEmployee = (props: any) => {

  const menu = takePermission(10)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onDeleteEmployee = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  const _onClickChoosePermission = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true);

  const dispatch = useDispatch();

  // state
  const [username, setUsername] = useState<any>();
  const [fullname, setFullname] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [isActive, setIsActive] = useState<any>();
  const [isActiveOffice, setIsActiveOffice] = useState<any>();

  const [employeeBophan, setEmployeeBophan] = useState<any>();
  const [employeeChucvu, setEmployeeChucvu] = useState<any>();
  const [employeePhongban, setEmployeePhongban] = useState<any>();
  const [employeeNhomquyen, setEmployeeNhomquyen] = useState<any>([]);

  const [bophanList, setBophanList] = useState<any>(undefined);
  const [chucvuList, setChucvuList] = useState<any>(undefined);
  const [phongbanList, setPhongbanList] = useState<any>(undefined);
  const [nhomquyenList, setNhomquyenList] = useState<any>(undefined);

  const [overloadType, setOverloadType] = useState<number>(0)
  const [overloadFree, setOverloadFree] = useState<number>(0)
  const [overloadBusy, setOverloadBusy] = useState<number>(0)
  const [overloadFull, setOverloadFull] = useState<number>(0)

  const [userPositions, setUserPositions] = useState<any>([]);

  // funcs
  const _onChangeOverload = (e: any, type: string) => {
    e.preventDefault()
    switch (type) {
      case "overloadFree":
        setOverloadFree(parseInt(e.target.value))
        break;
      case "overloadBusy":
        setOverloadBusy(parseInt(e.target.value))
        break;
      case "overloadFull":
        setOverloadFull(parseInt(e.target.value))
        break;
      default:
        break;
    }
  }

  const _onChangeOverloadType = () => {
    switch (overloadType) {
      case 0:
        setOverloadType(1);
        break;
      case 1:
        setOverloadType(0);
        break;
    }
  }

  const _onClickSubmit = async () => {
    if (userPositions.length <= 0) {
      return;
    }

    if ((userPositions.length > 0 && userPositions.find((p: any) => !p.positionId || !p.departmentId))) {
      return;
    }

    setIsLoading(true)
    const accessToken = localStorage.getItem("accessToken");
    let tempArray: any[] = [];

    employeeNhomquyen.forEach((item: any, index: any) => {
      tempArray.push(parseInt(item));
    })

    const body: any = {
      Id: taskId.toString(),
      UserName: username,
      FullName: fullname,
      Address: address,
      Email: email,
      PhoneNumber: phoneNumber,
      // BoPhan: parseInt(employeeBophan),
      BoPhan: 0,
      // ChucVu: parseInt(employeeChucvu),
      // PhongBan: parseInt(employeePhongban),
      IsActive: isActive === 1 ? true : false,
      IsActiveOffice: isActiveOffice === 1 ? true : false,
      GroupsIDs: tempArray,
      OverloadType: overloadType,
      UserPositions: userPositions.map((p: any) => {
        return {
          ChucVu: p.positionId,
          PhongBan: p.departmentId
        };
      })
      // OverloadFree: overloadFree,
      // OverloadBusy: overloadBusy,
      // OverloadFull: overloadFull
    }

    try {
      await ServiceUtils.postV2(
        USER_UPDATE,
        body,
        {
          headers: {
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            try {
              const takeData = async () => {
                await ServiceUtils.getV2(
                  USER_READ_ALL,
                  {
                    headers: {
                      Accept: "text/plain"
                    }
                  }
                )
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_USERLIST(res.data.Data))
                      toast.success("Cập nhật thành công.");
                      handleClose()
                      setIsLoading(false)
                    }
                  })
              }

              takeData()
            } catch (error: any) {
              toast.error("Tải dữ liệu thất bại.")
              setIsLoading(false)
            }
          } else {
            toast.error("Cập nhật thất bại.");
            setIsLoading(false)
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật thất bại.");
      setIsLoading(false)
    }
  }

  const _onChangeSelectedNhomquyen = (e: any) => {

    if (employeeNhomquyen.includes(e.target.value) === false) {
      setEmployeeNhomquyen((oldValue: any) => [...oldValue, e.target.value]);
    } else {
      setEmployeeNhomquyen((oldValue: any) => oldValue.filter((index: any) => index !== e.target.value));
    }
  }

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const API_BOPHAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_BOPHAN";
    const API_CHUCVU = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_CHUCVU"
    const API_PHONGBAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

    try {
      if (shouldLog1.current) {
        shouldLog1.current = false;

        const takeBophan = async () => {
          await ServiceUtils.getV2(
            API_BOPHAN,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setBophanList(res.data.Data);
              }
            })
        }

        const takeChucvu = async () => {
          await ServiceUtils.getV2(
            API_CHUCVU,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setChucvuList(res.data.Data);
              }
            })
        }

        const takePhongban = async () => {
          // await ServiceUtils.getV2(
          //   API_PHONGBAN,
          //   {
          //     headers: {
          //       Accept: "text/plain"
          //     }
          //   }
          // )
          //   .then((res: any) => {
          //     if (res.status === 200) {
          //       const organizations = OrganizationUtils.getListTree((res.data || {}).Data || [], {
          //         id: 'ParameterId',
          //         parentId: 'RefId'
          //       });

          //       setPhongbanList(organizations);
          //     }
          //   })

          OrganizationRepository.getListDropdown().then((res: any) => {
            if (res.status) {
              const data = (res.data || {}).organizations || [];
              
              const organizationTrees = OrganizationUtils.getListTree(data, {
                id: 'ParameterId',
                parentId: 'RefId'
              });

              setPhongbanList(organizationTrees);
            }
          });
        }

        const takeNhomquyen = async () => {
          await ServiceUtils.getV2(
            GROUP_READ_ALL_DROPDOWN,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setNhomquyenList(res.data.Data);
              }
            })
        }

        takeBophan();
        takeChucvu();
        takePhongban();
        takeNhomquyen();
      }
    } catch (error: any) {
      toast.error("Tải dữ liệu thất bại")
    }
  })

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = USER_DETAIL + taskId.toString();

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(
            API,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              console.log(res);

              if ((res.data || {}).StatusCode == 200) {
                const data = ((res.data || {}).Data || {}).data[0] || {};
                const userPositions = ((res.data || {}).Data || {}).userPositions || [];

                setUsername(data.UserName);
                setFullname(data.FullName);
                setEmail(data.Email);
                setPhoneNumber(data.PhoneNumber);
                setAddress(data.Address);
                setIsActive(data.IsActive);
                setIsActiveOffice(data.IsActiveOffice);

                setEmployeeBophan(data.BoPhan);
                setEmployeeChucvu(data.ChucVu);
                setEmployeePhongban(data.PhongBan);
                // setEmployeeNhomquyen(data.GroupIds);
                setOverloadType(data.OverloadType)
                setOverloadFree(data.OverloadFree)
                setOverloadBusy(data.OverloadBusy)
                setOverloadFull(data.OverloadFull)

                if (!data.GroupIds) {
                  setEmployeeNhomquyen([])
                } else {
                  const tempArray = data.GroupIds.split(",").filter((p: any) => p).map((p: any) => (p || '').trim());
                  const convertArray: any[] = [];

                  tempArray.forEach((item: any, index: any) => {
                    convertArray.push(item.trim());
                  })

                  setEmployeeNhomquyen(convertArray);
                }

                const userPositionHandles = [];

                for (let i = 0; i < userPositions.length; i++) {
                  userPositionHandles.push({
                    id: Guid.create().toString(),
                    positionId: userPositions[i].ChucVu,
                    departmentId: userPositions[i].PhongBan
                  });
                }

                setUserPositions(userPositionHandles);
              } else {
                toast.error("Tải dữ liệu thất bại");
              }
            })
        }

        takeData();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại");
      }
    }
  })

  const _onChangeSelectedChucvuItem = (e: any, item: any) => {
    const value = e.target.value;

    const _userPositions = [...userPositions];

    const permission = _userPositions.find((p: any) => p.id == item.id);

    if (permission) {
      permission.positionId = value;

      setUserPositions(_userPositions);
    }
  }

  const _onChangeSelectedPhongBanItem = (e: any, item: any) => {
    const value = e.target.value;

    const _userPositions = [...userPositions];

    const permission = _userPositions.find((p: any) => p.id == item.id);

    if (permission) {
      permission.departmentId = value;

      setUserPositions(_userPositions);
    }
  }

  const onAddPermission = () => {
    const _userPositions = [...userPositions];

    _userPositions.push({
      id: Guid.create().toString(),
      positionId: '',
      departmentId: ''
    });

    setUserPositions(_userPositions);
  }

  const onDeletePermission = (item: any) => () => {
    let _userPositions = [...userPositions];

    _userPositions = _userPositions.filter((p: any) => p.id != item.id);

    setUserPositions(_userPositions);
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[50rem] w-screen h-[35rem]
    rounded-md
  ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}

        nhomquyenList={nhomquyenList}
        selectedNhomquyen={employeeNhomquyen}
        _onChangeSelectedNhomquyen={_onChangeSelectedNhomquyen}
      />

      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Thông tin cán bộ</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {
        isLoading === true ?
          <div className="relative justify-center items-center flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            <LoaderSpinner
              w={32}
              h={32}
            />
            <p className="text-primary">Đang xử lý dữ liệu</p>
          </div>
          :
          <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            {/* lines */}
            <div className="flex w-full h-max core-control-group">
              <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
                <label>Mã số cán bộ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={username}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setUsername(e.target.value);
                  }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
                <label>Tên cán bộ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={fullname}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setFullname(e.target.value);
                  }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max core-control-group">
              <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
                <label>Email</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={email}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
                <label>Điện thoại</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={phoneNumber}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setPhoneNumber(e.target.value);
                  }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full h-max">
                <label>Địa chỉ liên hệ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={address}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setAddress(e.target.value);
                  }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

            </div>

            {/* <div className="flex lg:flex-row flex-col w-full h-max">
              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label>Bộ phận</label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={employeeBophan}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setEmployeeBophan(e.target.value);
                  }}
                >
                  <option className="text-gray-300" value={0} disabled>--- Chọn bộ phận ---</option>
                  {bophanList === undefined ? ""
                    : bophanList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                    ))
                  }
                </select>
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label>Chức vụ</label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={employeeChucvu}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setEmployeeChucvu(e.target.value);
                  }}
                >
                  <option className="text-gray-300" value={0} disabled>--- Chọn danh mục ---</option>
                  {chucvuList === undefined ? ""
                    : chucvuList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                    ))
                  }
                </select>
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label>Phòng ban</label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={employeePhongban}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setEmployeePhongban(e.target.value);
                  }}
                >
                  <option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
                  {phongbanList === undefined ? ""
                    : phongbanList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                    ))
                  }
                </select>
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pl-2 pr-0 h-max space-y-1">
                <label>Nhóm quyền hạn</label>

                <button onClick={() => _onClickChoosePermission("choosePermission")} className="w-max h-max bg-green-400 pl-1.5 pr-1.5 rounded-md">
                  chọn
                </button>
              </div>
            </div> */}
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} className="core-control-group">
              {/* <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label>Bộ phận</label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={employeeBophan}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setEmployeeBophan(e.target.value);
                  }}
                >
                  <option className="text-gray-300" value={0} disabled>--- Chọn bộ phận ---</option>
                  {bophanList === undefined ? ""
                    : bophanList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                    ))
                  }
                </select>
              </div> */}
              <div className="flex flex-col lg:w-1/4 w-full lg:pl-2 pr-0 h-max">
                <label>Nhóm quyền hạn</label>
                <button onClick={() => _onClickChoosePermission("choosePermission")} className="pl-2 pt-2 pb-2 pr-2 bg-green-400 hover:bg-green-300 mt-1 rounded-md w-max h-max">chọn</button>
              </div>
            </div>
            {userPositions.map((item: any, index: any) => {
              return <div key={`permissison-${index}`} className="flex lg:flex-row flex-col w-full h-max">
                <div className="flex flex-col lg:w-1/2 lg:pr-2 pr-0 h-max core-create-list-of-employee-role-list-item-position">
                  <label>Chức vụ</label>
                  <select
                    className="h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={item.positionId}
                    onChange={(e: any) => { _onChangeSelectedChucvuItem(e, item) }}
                  >
                    <option className="text-gray-300" value={0}>--- Chọn danh mục ---</option>
                    {chucvuList === undefined ? ""
                      : chucvuList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="flex flex-col lg:w-1/2 lg:pr-2 pr-0 h-max core-create-list-of-employee-role-list-item-organization">
                  <label>Phòng ban</label>
                  <select
                    className="h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={item.departmentId}
                    onChange={(e: any) => { _onChangeSelectedPhongBanItem(e, item) }}
                  >
                    <option className="text-gray-300" value={0}>--- Chọn phòng ban ---</option>
                    {phongbanList === undefined ? ""
                      : phongbanList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          <LevelComponent item={item} />{item.ParameterValue}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="core-create-list-of-employee-role-list-item-button-remove">
                  <button style={{

                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={onDeletePermission(item)}>
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              </div>
            })}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0px 10px'
            }}>
              <button className="flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200" onClick={onAddPermission} style={{
                  backgroundColor: '#4ADE80',
                  // width: '24px',
                  // height: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // borderRadius: '50%',
                  color: '#ffffff'
                }}>
                <AddIcon fontSize="small" />
                Thêm chức vụ và phòng ban
              </button>
            </div>
            <div className="flex flex-row w-full h-max space-x-4">
              <p className="font-bold text-primary">Tạo việc toàn cơ quan</p>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={isActiveOffice === 1 ? true : false}
                onChange={() => {
                  if (isActiveOffice === 1) {
                    setIsActiveOffice(0);
                  } else {
                    setIsActiveOffice(1);
                  }
                }}
                disabled={isLoading}
              />
            </div>

            {/* <div className="flex lg:flex-row flex-col w-full h-max">
              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-green-500">Có thể nhận việc (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-green-500"
                  type="number"
                  value={overloadFree}
                  onChange={(e: any) => _onChangeOverload(e, "overloadFree")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-yellow-500">Nhiều việc (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-yellow-500"
                  type="number"
                  value={overloadBusy}
                  onChange={(e: any) => _onChangeOverload(e, "overloadBusy")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-red-500">Quá tải (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-red-500"
                  type="number"
                  value={overloadFull}
                  onChange={(e: any) => _onChangeOverload(e, "overloadFull")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div> */}

            <div className="flex w-full h-max">
              <fieldset className="flex flex-col w-full h-max"
                onChange={_onChangeOverloadType}
              >
                {/* <div className="flex items-center space-x-3">
                  <input type="radio" id="1" name="fav_language" value={0} checked={overloadType === 0 ? true : false} placeholder="" />
                  <p>Quá tải = Số đầu việc đang xử lý</p>
                </div> */}
                {/* <div className="flex items-center space-x-3">
                  <input type="radio" id="1" name="fav_language" value={1} checked={overloadType === 1 ? true : false} placeholder="" />
                  <p>Quá tải = Số ngày việc đã được giao trong tháng</p>
                </div> */}
              </fieldset>
            </div>
          </div>
      }


      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="absolute left-4 bottom-3 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max core-create-list-of-employee-use-system">
          <p className="font-bold text-primary">Sử dụng phần mềm</p>
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isActive === 1 ? true : false}
            onChange={() => {
              if (isActive === 1) {
                setIsActive(0);
              } else {
                setIsActive(1);
              }
            }}
            disabled={isLoading}
          />
        </div>

        <div className="flex w-max h-max space-x-3">
          <div className={`${menu.PerDelete === true ? "flex" : "hidden"}`}>
            <button className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md

          ${isLoading === true ? "bg-slate-300" : "hover:bg-slate-400 bg-slate-500"}
        `}
              onClick={() => _onDeleteEmployee("deleteEmployee")}
              disabled={isLoading}
            >
              <DeleteIcon fontSize="small" />
              <p className="lg:flex hidden">Xoá</p>
            </button>
          </div>
          <button className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${isLoading === true ? "bg-slate-300" : "hover:bg-sky-400 bg-primary"}
        `}
            onClick={_onClickSubmit}
            disabled={isLoading}
          >
            <SaveIcon fontSize="small" />
            <p className="lg:flex hidden">Cập nhật</p>
          </button>
          <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200 core-create-list-of-employee-button-close
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DetailListOfEmployee;
