import React, { useState, useContext, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select"

import { LOAD_SEP, NOTIFICATON_TASK } from "../../../apis/_index";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import moment from "moment";
import ServiceUtils from "../../../utils/services";

const ChooseBossNoti = (props: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const taskId = props.taskId;
  const [isChangeTimeExtend, setIsChangeTimeExtend] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;
  const onClickSubmitAndNoti = props.onClickSubmitAndNoti;
  const [bossChoose, setBossChoose] = useState<any>(null)

  const [isloading, setIsLoading] = useState(false);

  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch()
  const urlLink = window.location.pathname
  const shouldLog = useRef<any>(true)
  const [listBoss, setListBoss] = useState();

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  useEffect(() => {

    


    const takeData = async () => {
      try {
        await ServiceUtils.getV2(LOAD_SEP, {
            headers: {
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              let _dataBoss: any = res?.data?.Data?.filter((x: any) => x.DataLevel > userData?.Data[0]?.DataLevel).map((item: any) => { return { ...item, label: item.FullName, value: item.Id } });
              if (_dataBoss.length == 1) {
                setBossChoose(_dataBoss[0])
                console.log('taskId: ' + taskId);
              }
              setListBoss(_dataBoss);
            } else {
              toast.error(res.data.Message || "Tải danh sách sếp thất bại");
            }
          });
      } catch (error: any) {
        toast.error(error || "Tải danh sách sếp thất bại");
      }
    };

    takeData();



  }, []);



  const _onClickSubmit = () => {
    debugger
    if (onClickSubmitAndNoti) {

      onClickSubmitAndNoti();
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(NOTIFICATON_TASK + taskId + '&bossId=' + bossChoose.Id, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res?.data?.StatusCode === 200) {
                toast.success(res?.data?.Message || "Chấp nhận và thông báo cấp trên thành công!");
              } else {
                toast.error(res?.data?.Message || "Tải danh sách sếp thất bại");
              }
            });
        } catch (error: any) {
          toast.error(error || "Tải danh sách sếp thất bại");
        }
      };

      takeData();

    }
  };

  const _onChangeBoss = (e: any) => {
    if (e) {
      setBossChoose(e)
    }
  }


  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Thông báo cho cấp trên</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="flex flex-col w-full space-y-1">
          <label>
            Chọn cấp trên&nbsp;
            <span className="text-red-500">(*)</span>
          </label>
          <Select
            className="z-[5]"
            options={listBoss}
            onChange={(e: any) => _onChangeBoss(e)}
            value={bossChoose}
            isClearable
            placeholder="--- Chọn cấp trên ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
      {/* footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${bossChoose?.Id == null
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
                }`}
              disabled={
                bossChoose?.Id == null
              }
              onClick={_onClickSubmit}
            >
              <p>Xác nhận</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseBossNoti;
