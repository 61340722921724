/* eslint-disable no-restricted-globals */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import HandleDate from "../../../utils/date/handleDate";

export const workingReducer = createSlice({
  name: "workingReducer",

  initialState: {
    newTask: {
      prior: 0,
    },

    listPermission: null,
    listPermissionChoose: 0,

    quytrinhList: null,

    listPhongBan: null,

    choosePage: 10,
    pageCurrent: 0,
    paginateTaskList: null,
    newTaskList: null,
    needGiveList: null,
    taskDelay: null,
    taskEndToday: null,
    taskEndSoon: null,

    taskFinish: null,

    taskTotal: null,
    taskProccessing: null,

    taskNew: null,
    taskReceived: null,
    taskProcessing: null,
    taskReceiverDelay: null,
    taskReceiverEndToday: null,
    receiverTaskEndSoon: null,
    receiverTaskAllTask: null,
    taskReceiverShared: null,

    taskWithNormal: null,

    taskFinishEarly: null,
    taskFinishLate: null,
    taskFinishPunctual: null,

    taskChangeDeadline: null,

    taskReturn: null,
    taskReturnNguoiNhan: null,
    taskWaiting: null,

    //REPORT
    deliverWorkerCapacity: null,
    //-----

    receiverList: null,
    priorList: null,
    bossList: null,
    votingList: null,

    createTask: {
      phongbanList: null,
      nguoinhanList: null,

      nguoiphoihopList: null,

      loaicongviecList: null,
      selectedLoaiCongViec: -1,

      nguonvanbanList: null,
      selectedNguonVanBan: -1,

      douutienList: null,
      selectedDoUuTien: null,

      dokhanList: null,
      selectedDoKhan: null,

      quytrinhList: null,
      selectedQuytrinhList: -1,

      buocList: null,
      selectedBuocList: null,

      ketquaList: null,
      selectedKetquaList: null,

      selectedPhongBan: null,
      selectedNguoiNhan: null,

      daumucList: null,
      selectedDauMucList: -1,

      selectedViecCanGiao: null,

      taskName: null,

      isForce: false,

      deadLine: "",

      descriptions: "",

      notes: "",

      selectedIsForce: {
        AllowTaskReject: null,
        Solanduocxemchitiet: 0,
      },

      selectedNguoiPhoiHop: [],
      selectedNguoiPhoiHopObject: [],
      selectedNguoiPhoiHopString: "",
      selectedNguoiPhoiHopVoiTask: [],
      selectedFiles: [],

      selectedFilesDetail: [],
    },

    searchFilter: {
      taskName: "",
      selectedPrior: -1,
      selectedReceiver: -1,
      fromDate: "",
      toDate: "",
      deadLine: "",
      selectedBoss: -1,
      selectPhongBan: "",
      status: 0,
      result: null,
    },

    taskDetail: null,

    // highManagerStorage: "",
    highManagerStorage2: "",

    // mediumManagerStorage: "",
    mediumManagerStorage2: "",

    taskReceiverLate: null,
    taskReceiverEarly: null,
    taskReceiverPunctual: null,
  },

  reducers: {
    ONCHAGE_FILTER_SELECTED_NGUOI_NHAN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.searchFilter.selectedReceiver = actions.payload;
    },

    ONCHAGE_FILTER_RESULT_WITH_NUMBER: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.highManagerStorage = "";

      state.searchFilter.result = actions.payload.id;

      const title =
        actions.payload.id === 1
          ? "Danh sách công việc đạt kết quả xuất sắc"
          : actions.payload.id === 2
          ? "Danh sách công việc đạt kết quả tốt"
          : actions.payload.id === 3
          ? "Danh sách công việc đạt kết quả trung bình"
          : actions.payload.id === 4
          ? "Danh sách công việc đạt kết quả  kém"
          : "";

      const month = actions.payload.month;
      const year = actions.payload.year;
      const urlLink = window.location.pathname;

      const takeData = () => {
        const compareFromDate = HandleDate.firstDayOfYear(urlLink);
        const compareToDate = HandleDate.currentDate(urlLink);

        state.searchFilter.fromDate = compareFromDate;
        state.searchFilter.toDate = compareToDate;
        // const tempFromDate = new Date(year, month - 1, 1, 7, 0, 0).toISOString().slice(0, 16)
        // const tempToDate = new Date(year, month, 0, 7, 0, 0).toISOString().slice(0, 16)

        // const compareFromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
        //   .toISOString()
        //   .slice(0, 16)

        // const compareToDate = new Date(new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000)
        //   .toISOString()
        //   .slice(0, 16)

        // if (tempFromDate === compareFromDate) {
        //   state.searchFilter.fromDate = ""
        // } else {
        //   state.searchFilter.fromDate = tempFromDate
        // }

        // if (tempToDate === compareToDate) {
        //   state.searchFilter.toDate = ""
        // } else {
        //   state.searchFilter.toDate = tempToDate
        // }
      };

      takeData();

      window.history.pushState({ title: title }, "", "/working/task-totalall");
    },

    ONCHAGE_FILTER_RESULT_WITH_NUMBER_RECEIVER: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      // state.mediumManagerStorage = ""

      state.searchFilter.result = actions.payload.id;

      const title =
        actions.payload.id === 1
          ? "Danh sách công việc đạt kết quả xuất sắc"
          : actions.payload.id === 2
          ? "Danh sách công việc đạt kết quả tốt"
          : actions.payload.id === 3
          ? "Danh sách công việc đạt kết quả trung bình"
          : actions.payload.id === 4
          ? "Danh sách công việc đạt kết quả kém"
          : "";

      const month = actions.payload.month;
      const year = actions.payload.year;
      const urlLink = window.location.pathname;
      const takeData = () => {
        const compareFromDate = HandleDate.firstDayOfYear(urlLink);
        const compareToDate = HandleDate.currentDate(urlLink);

        state.searchFilter.fromDate = compareFromDate;
        state.searchFilter.toDate = compareToDate;
        // const tempFromDate = new Date(year, month - 1, 1, 7, 0, 0)
        //   .toISOString()
        //   .slice(0, 16);
        // const tempToDate = new Date(year, month, 0, 7, 0, 0)
        //   .toISOString()
        //   .slice(0, 16);

        // const compareFromDate = new Date(
        //   new Date().getFullYear(),
        //   new Date().getMonth(),
        //   1,
        //   7,
        //   0,
        //   0
        // )
        //   .toISOString()
        //   .slice(0, 16);

        // const compareToDate = new Date(
        //   new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
        // )
        //   .toISOString()
        //   .slice(0, 16);

        // if (tempFromDate === compareFromDate) {
        //   state.searchFilter.fromDate = "";
        // } else {
        //   state.searchFilter.fromDate = tempFromDate;
        // }

        // if (tempToDate === compareToDate) {
        //   state.searchFilter.toDate = "";
        // } else {
        //   state.searchFilter.toDate = tempToDate;
        // }
      };

      takeData();

      window.history.pushState(
        { title: title },
        "",
        "/working/receiver/all-task"
      );
    },

    ONCHAGE_FILTER_RESULT: (state: any, actions: PayloadAction<any>) => {
      if (actions.payload === null) {
        state.searchFilter.result = null;
      } else {
        state.searchFilter.result = actions.payload.Id;
      }
    },

    // ONCHANG_HIGHMANGER_STORAGE: (state: any, actions: PayloadAction<any>) => {
    //   state.highManagerStorage = actions.payload
    // },

    RESET_HIGHMANGER_STORAGE: (state: any) => {
      state.highManagerStorage = "";
    },

    // ONCHANG_HIGHMANGER_STORAGE2: (state: any, actions: PayloadAction<any>) => {
    //   state.highManagerStorage2 = actions.payload
    // },

    // RESET_HIGHMANGER_STORAGE2: (state: any) => {
    //   state.highManagerStorage2 = ""
    // },

    // ONCHANG_MEDIUMMANAGER_STORAGE: (state: any, actions: PayloadAction<any>) => {
    //   state.mediumManagerStorage = actions.payload
    // },

    ONCHANGE_FILTER_SELECTED_PHONGBAN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      if (actions.payload === null) {
        state.searchFilter.selectPhongBan = "";
      } else {
        state.searchFilter.selectPhongBan = actions.payload.ParameterId;
      }
    },

    ONCHANGE_TASKLIST_WITH_NORMAL: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      // let userData: any = localStorage.getItem("userData")
      // userData = userData.replace(/'/g, '"'); //replacing all ' with "
      // userData = JSON.parse(userData);
      // let tempArray: any[] = []
      // actions.payload.data.forEach((item: any) => {
      //   if (item.TaskOwner === userData.Data[0].Id) {
      //     tempArray.push(item)
      //   }
      // })
      // state.taskWithNormal = tempArray;
      // if (actions.payload.count % state.choosePage === 0) {
      //   state.paginateTaskList = Math.floor(
      //     actions.payload.count / state.choosePage
      //   );
      // } else {
      //   state.paginateTaskList =
      //     Math.floor(actions.payload.count / state.choosePage) + 1;
      // }

      state.taskWithNormal = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_PHONGBAN_LIST: (state: any, actions: PayloadAction<any>) => {
      state.listPhongBan = actions.payload;
    },

    ONCHANGE_TASK_COMPLETED_PUNCTUAL: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskFinishPunctual = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHAGE_FILTER_STATUS: (state: any, actions: PayloadAction<any>) => {
      if (actions.payload === null) {
        state.searchFilter.status = 0;
      } else {
        state.searchFilter.status = actions.payload.Id;
      }
    },

    ONCHANGE_TASK_COMPLETED_EARLY: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskFinishEarly = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_VOTING_LIST: (state: any, actions: PayloadAction<any>) => {
      state.votingList = actions.payload;
      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_TASK_WAITING: (state: any, actions: PayloadAction<any>) => {
      state.taskWaiting = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_TASK_COMPLETED_LATE: (state: any, actions: PayloadAction<any>) => {
      state.taskFinishLate = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_RESET_FILTER: (state: any, actions: PayloadAction<any>) => {
      const urlLink = actions.payload.url || window.location.pathname;

      state.searchFilter.taskName = "";
      state.searchFilter.selectedPrior = -1;
      state.searchFilter.selectedReceiver = -1;
      state.searchFilter.fromDate = HandleDate.firstDayOfYear(urlLink);
      state.searchFilter.toDate = HandleDate.currentDate(urlLink);
      state.searchFilter.deadLine = "";
      state.searchFilter.selectedBoss = -1;

      state.searchFilter.selectPhongBan = null;
      state.searchFilter.status = 0;
      state.searchFilter.result = null;
    },

    ONCHANGE_SELECTED_BOSS: (state: any, actions: PayloadAction<any>) => {
      if (actions.payload === null) {
        state.searchFilter.selectedBoss = -1;
      } else {
        state.searchFilter.selectedBoss = actions.payload.Id;
      }
    },

    ONLOAD_SELECTED_RECEIVER: (state: any, actions: PayloadAction<any>) => {
      if (actions.payload === null) {
        state.searchFilter.selectedReceiver = -1;
      } else {
        state.searchFilter.selectedReceiver = actions.payload.Id;
      }
    },

    ONLOAD_BOSS_LIST: (state: any, actions: PayloadAction<any>) => {
      state.bossList = actions.payload;
    },

    ONSET_RETURN_PAGE_CURRENT: (state: any) => {
      state.pageCurrent = 0;
    },

    ONFILTER_DATA: (state: any, actions: PayloadAction<any>) => {
      const urlLink = window.location.pathname;

      switch (urlLink) {
        // NGUOI GIAO
        case "/working/new-task":
          switch (actions.payload.type) {
            case "taskCode":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.newTaskList.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-need-give":
          switch (actions.payload.type) {
            case "taskCode":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.needGiveList.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-finish":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskFinish.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-proccessing":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskProccessing.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-delay":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskDelay.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-change-deadline":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskChangeDeadline.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-end-today":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskEndToday.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-end-soon":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskEndSoon.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/task-total":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskRecipient":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.RecipientName < b.RecipientName) return -1;
                if (a.RecipientName > b.RecipientName) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskTotal.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        // NGUOI NHAN
        case "/working/receiver/task-new":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "taskRecipient":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.RecipientName < b.RecipientName) return -1;
                if (a.RecipientName > b.RecipientName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskNew.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/task-received":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskReceived.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/task-processing":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskProcessing.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/task-delay":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskReceiverDelay.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/task-end-today":
          switch (actions.payload.type) {
            case "taskCode":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.taskReceiverEndToday.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/task-end-soon":
          switch (actions.payload.type) {
            case "taskCode":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.receiverTaskEndSoon.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        case "/working/receiver/all-task":
          switch (actions.payload.type) {
            case "taskCode":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.TaskCode < b.TaskCode) return -1;
                if (a.TaskCode > b.TaskCode) return 1;
                return 0;
              });
              break;
            case "isRecipient":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.IsRecipient < b.IsRecipient) return -1;
                if (a.IsRecipient > b.IsRecipient) return 1;
                return 0;
              });
              break;
            case "taskName":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.TaskName < b.TaskName) return -1;
                if (a.TaskName > b.TaskName) return 1;
                return 0;
              });
              break;
            case "priorLevel":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.PriorityLevelName < b.PriorityLevelName) return -1;
                if (a.PriorityLevelName > b.PriorityLevelName) return 1;
                return 0;
              });
              break;
            case "assignmentDate":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.AssignmentDate < b.AssignmentDate) return -1;
                if (a.AssignmentDate > b.AssignmentDate) return 1;
                return 0;
              });
              break;
            case "taskOwner":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.TaskOwnerName < b.TaskOwnerName) return -1;
                if (a.TaskOwnerName > b.TaskOwnerName) return 1;
                return 0;
              });
              break;
            case "taskRecipient":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.RecipientName < b.RecipientName) return -1;
                if (a.RecipientName > b.RecipientName) return 1;
                return 0;
              });
              break;
            case "deadLine":
              state.receiverTaskAllTask.data.sort((a: any, b: any) => {
                if (a.DeadLine < b.DeadLine) return -1;
                if (a.DeadLine > b.DeadLine) return 1;
                return 0;
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      // const something = state.taskNew.data
      //   .sort((a: any, b: any) => {
      //     if (a.TaskName < b.TaskName) return -1
      //     if (a.TaskName > b.TaskName) return 1
      //     return 0
      //   })

      // something.forEach((item: any) => {
      //   console.log(item.PriorityLevelName)
      // })
    },

    ONCHANGE_TASKNAME_DETAIL: (state: any, actions: PayloadAction<any>) => {
      switch (actions.payload.type) {
        case "taskName":
          state.createTask.taskName = actions.payload.value;
          break;

        case "phongBanSelected":
          state.createTask.selectedPhongBan =
            state.createTask.phongbanList.find((item: any) =>
              (item?.IdPhongBan || "")
                .toString()
                .split(",")
                .find((p: any) => p == parseInt(actions.payload.value))
                ? true
                : false
            );
          break;

        case "deadLine":
          state.createTask.deadLine = actions.payload.value;
          break;

        case "descriptions":
          state.createTask.descriptions = actions.payload.value;
          break;

        case "notes":
          state.createTask.notes = actions.payload.value;
          break;

        default:
          break;
      }
    },

    ONREAD_VALUE_DETAIL: (state: any, actions: PayloadAction<any>) => {
      state.createTask.taskName = actions.payload.task.TaskName;

      // -- phong ban, nguoi nhan
      state.createTask.selectedPhongBan = state.createTask.phongbanList.find(
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(actions.payload.task.IDPhongban))
            ? true
            : false
      );

      state.createTask.nguoinhanList = state.createTask.nguoiphoihopList.filter(
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(actions.payload.task.IDPhongban))
            ? true
            : false
      );

      state.createTask.selectedNguoiNhan = state.createTask.nguoinhanList.find(
        (item: any) => item.Id === actions.payload.task.Recipient.toString()
      );
      // -- end nguoi nhan

      // state.createTask.phongbanList = actions.payload.phongbanList;
      // state.createTask.nguoiphoihopList = actions.payload.nguoiphoihopList;

      // -- nguoi phoi hop
      const idNguoiPhoiHop = actions.payload.task.Supporter.replace("{", "")
        .replace("}{", ",")
        .replace("}", "");

      const tempArrayNguoiPhoiHop = idNguoiPhoiHop.split(",");

      state.createTask.selectedNguoiPhoiHop = tempArrayNguoiPhoiHop;

      let tempArrayNguoiPhoiHop2: any[] = [];

      tempArrayNguoiPhoiHop.forEach((item: any) => {
        tempArrayNguoiPhoiHop2.push(
          state.createTask.nguoiphoihopList.find(
            (item2: any) => item === item2.Id
          )
        );
      });

      state.createTask.selectedNguoiPhoiHopObject = tempArrayNguoiPhoiHop2;
      // -- end nguoi phoi hop

      state.createTask.selectedLoaiCongViec = actions.payload.task.TaskListID;
      state.createTask.selectedDoUuTien = state.createTask.douutienList.find(
        (item: any) =>
          item.Id === parseInt(actions.payload.task.PriorityLevelID)
      );
      state.createTask.selectedNguonVanBan = actions.payload.task.NguonViecID;
      state.createTask.selectedDoKhan = state.createTask.dokhanList.find(
        (item: any) =>
          item.Id === actions.payload.task.SecurityLevelID.toString()
      );
      state.createTask.isForce = actions.payload.task.IsForce;
      state.createTask.deadLine = actions.payload.task.DeadLine;
      state.createTask.selectedQuytrinhList =
        state.createTask.quytrinhList.find(
          (item: any) => item.id === parseInt(actions.payload.task.TaskListID)
        );
      state.createTask.selectedDauMucList = actions.payload.task.TaskListID;

      state.createTask.descriptions = actions.payload.task.Description;
      state.createTask.notes = actions.payload.task.Notes;

      // -- buoc quy trinh
      let tempArray: any[] = [];
      let tempResult: any[] = [];

      actions.payload.taskContents.forEach((item: any) => {
        tempArray.push({
          ID: item.ID,
          tenbuoc: item.StepName,
          orderBy: item.SortOrder,
          nguoithuchien: state.createTask.selectedNguoiPhoiHopObject.find(
            (item2: any) => item.Id === item2.Id
          ),
          deadline: item.DeadLine,
        });
      });

      state.createTask.selectedBuocList = tempArray;
      // --> ok
      // -- end buoc quy trinh

      // -- ket qua quy trinh
      actions.payload.taskResults.forEach((item: any) => {
        tempResult.push({
          tenketqua: item.ResultText,
          ID: item.ID,
          TaskID: item.taskID,
        });
      });

      state.createTask.selectedKetquaList = tempResult;
      // -- end ket qua quy trinh

      // -- files upload
      const filesLink = actions.payload.task.ListFiles.split(";");
      const filesName = actions.payload.task.ListFilesName.split(";");

      let tempFileArray: any[] = [];

      for (let i = 0; i < filesLink.length; i++) {
        if (filesLink[i] === "" || filesName[i] === "") {
          // do nothing
        } else {
          tempFileArray.push({
            filesName: filesName[i],
            filesLink: filesLink[i],
          });
        }
      }

      state.createTask.selectedFilesDetail = tempFileArray;
      // -- end files upload
    },

    ONCHANGE_NGUOI_NHAN_RETURN: (state: any, actions: PayloadAction<any>) => {
      state.taskReturnNguoiNhan = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_IS_FORCE_2: (state: any) => {
      state.createTask.isForce = !state.createTask.isForce;
    },

    ONCHANGE_FROM_HOME_TO_PROCESSING: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      const month = actions.payload.month;
      const year = actions.payload.year;
      const selectedPhongBan = actions.payload.selectedPhongBan;
      const urlLink = window.location.pathname;

      const takeData = () => {
        const tempFromDate = new Date(year, month - 1, 1, 7, 0, 0)
          .toISOString()
          .slice(0, 16);
        const tempToDate = new Date(year, month, 0, 7, 0, 0)
          .toISOString()
          .slice(0, 16);

        const compareFromDate = HandleDate.firstDayOfYear(urlLink);

        const compareToDate = HandleDate.currentDate(urlLink);

        // console.log("Temp From Date: ", tempFromDate);
        // console.log("Temp To Date: ", tempToDate);

        // console.log("From Date: ", compareFromDate);
        // console.log("To Date: ", compareToDate);

        // if (tempFromDate === compareFromDate) {
        //   state.searchFilter.fromDate = ""
        // } else {
        //   state.searchFilter.fromDate = tempFromDate
        // }

        // if (tempToDate === compareToDate) {
        //   state.searchFilter.toDate = ""
        // } else {
        //   state.searchFilter.toDate = tempToDate
        // }
        state.searchFilter.fromDate = compareFromDate;
        state.searchFilter.toDate = compareToDate;

        // setPhongBan
        if (
          selectedPhongBan === undefined ||
          selectedPhongBan === null ||
          selectedPhongBan === ""
        ) {
          // do nothing
        } else {
          state.searchFilter.selectPhongBan = selectedPhongBan;
        }

        console.log(state.searchFilter.selectPhongBan);
      };

      takeData();
    },

    ONCHANGE_DETAIL_VALUE: (state: any, actions: PayloadAction<any>) => {
      switch (actions.payload.types) {
        case "taskName":
          state.taskDetail.task.TaskName = actions.payload.value;
          break;
        case "loaiCongViec":
          break;
        case "uuTien":
          break;
        case "nguonVanBan":
          break;
        case "doMat":
          break;
        case "deadLine":
          break;
        case "dauMuc":
          break;

        default:
          break;
      }
    },

    ONCHANGE_SELECTED_NGUOINHAN_2: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.selectedNguoiPhoiHop = "";
      state.createTask.selectedNguoiNhan = state.createTask.nguoinhanList.find(
        (item: any) => item.Id === actions.payload.toString()
      );
    },

    ONCHANGE_SELECTED_PHONGBAN_2: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedPhongBan = state.createTask.phongbanList.find(
        (item: any) => item.IdPhongBan === parseInt(actions.payload)
      );
      state.selectedNguoiPhoiHop = "";

      state.createTask.selectedNguoiNhan = null;
      state.createTask.nguoinhanList = state.createTask.nguoiphoihopList.filter(
        (item: any) => item.IdPhongBan === parseInt(actions.payload)
      );
    },

    ONCHANGE_SELECTED_NGUOI_PHOI_HOP_FROM_DETAIL: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      let obj: any[] = [];

      actions.payload.b.forEach((item0: any) => {
        if (item0 !== "" || item0 !== null || item0.trim().length > 0) {
          if (actions.payload.a) {
            actions.payload.a.map((item: any) => {
              if (item.Id.toString() === item0.toString()) {
                obj.push(item);
                //state.createTask.selectedNguoiPhoiHopObject.push(item)
              }
            });
          }
        }
      });

      state.createTask.selectedNguoiPhoiHopObject = obj;
    },

    ONCHANGE_TASK_DETAIL: (state: any, actions: PayloadAction<any>) => {
      state.taskDetail = actions.payload;
    },

    ONCHANGE_VIEC_CAN_GIAO: (state: any, actions: PayloadAction<any>) => {
      state.needGiveList = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_DANG_TRE_HAN: (state: any, actions: PayloadAction<any>) => {
      state.taskDelay = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_GIAO_RETURN: (state: any, actions: PayloadAction<any>) => {
      state.taskReturn = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_GIAO_HOAN_TAT: (state: any, actions: PayloadAction<any>) => {
      state.taskFinish = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskChangeDeadline = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_TOI_HAN_HOM_NAY: (state: any, actions: PayloadAction<any>) => {
      state.taskEndToday = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskProcessing = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_XONG_SOM: (state: any, actions: PayloadAction<any>) => {
      state.taskReceiverEarly = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_XONG: (state: any, actions: PayloadAction<any>) => {
      state.taskReceiverPunctual = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_CHIA_SE: (state: any, actions: PayloadAction<any>) => {
      state.taskReceiverShared = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_XONG_TRE: (state: any, actions: PayloadAction<any>) => {
      state.taskReceiverLate = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.receiverTaskEndSoon = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_TAT_CA: (state: any, actions: PayloadAction<any>) => {
      state.receiverTaskAllTask = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_SAP_TOI_HAN: (state: any, actions: PayloadAction<any>) => {
      state.taskEndSoon = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_MOI: (state: any, actions: PayloadAction<any>) => {
      state.taskNew = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_DA_NHAN: (state: any, actions: PayloadAction<any>) => {
      state.taskReceived = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_TRE_HAN: (state: any, actions: PayloadAction<any>) => {
      state.taskReceiverDelay = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_GIAO_TONG_HOP: (state: any, actions: PayloadAction<any>) => {
      state.taskTotal = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_GIAO_DANG_XU_LY: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskProccessing = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.taskReceiverEndToday = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_IS_FORCE: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedIsForce = actions.payload;
    },

    ONCHANGE_ID_QUY_TRINH: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedQuytrinhList = actions.payload;
    },

    ONPUSH_FILES: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedFiles = actions.payload;
    },

    ONCHANGE_DEADLINE_STEP: (state: any, actions: PayloadAction<any>) => {
      let check = state.createTask.selectedNguoiPhoiHopVoiTask.find(
        (item: any) => item.StepName === actions.payload.StepName
      );

      if (check === undefined) {
        state.createTask.selectedNguoiPhoiHopVoiTask.push(actions.payload);
      } else {
        state.createTask.selectedNguoiPhoiHopVoiTask.map((item: any) => {
          if (item.StepName === actions.payload.StepName) {
            item.SortOrder = actions.payload.SortOrder;
            item.DeadLine_Step = actions.payload.DeadLine_Step;
          }
        });
      }
    },

    ONCHANGE_ADDING_NGUOI_PHOI_HOP: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      let check = state.createTask.selectedNguoiPhoiHopVoiTask.find(
        (item: any) => item.StepName === actions.payload.StepName
      );

      if (check === undefined) {
        state.createTask.selectedNguoiPhoiHopVoiTask.push(actions.payload);
      } else {
        state.createTask.selectedNguoiPhoiHopVoiTask.map((item: any) => {
          if (item.StepName === actions.payload.StepName) {
            item.UserDo = actions.payload.UserDo;
            item.SortOrder = actions.payload.SortOrder;
          }
        });
      }
    },

    ONCHANGE_SELECTED_DAU_MUC: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedDauMucList = actions.payload;
    },

    ONLOAD_DAUMUC: (state: any, actions: PayloadAction<any>) => {
      state.createTask.daumucList = actions.payload;
    },

    ONPUSH_KETQUA: (state: any, actions: PayloadAction<any>) => {
      let check = state.createTask.selectedKetquaList.find(
        (item: any) => item.tenketqua === actions.payload
      );

      if (check === undefined) {
        state.createTask.selectedKetquaList.push(actions.payload);
      } else {
        return;
      }
    },

    ONCHANGE_DELETE_QUYTRINH_KETQUA: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      const index = state.createTask.selectedKetquaList.findIndex(
        (item: any) => item.idkq === actions.payload
      );
      state.createTask.selectedKetquaList.splice(index, 1);
    },

    ONCHANGE_QUYTRINH_KETQUA: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedKetquaList.map((item: any) => {
        if (item.idkq === actions.payload.id) {
          item.tenketqua = actions.payload.value;
        }
      });
    },

    ONCHANGE_QUYTRINH_BUOC: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedBuocList.map((item: any, index: any) => {
        if (item.idbuoc === actions.payload.id) {
          switch (actions.payload.type) {
            case "buoc":
              item.orderBy = parseInt(actions.payload.value);
              break;

            case "gio":
              item.giohoanthanh = parseInt(actions.payload.value);
              break;

            case "ten":
              item.tenbuoc = actions.payload.value.toString();
              break;

            default:
              break;
          }
        }
      });
    },

    ONDELETE_QUYTRINH: (state: any, actions: PayloadAction<any>) => {
      // params orderBy
      const index = state.createTask.selectedBuocList.findIndex(
        (item: any) => item.orderBy === actions.payload
      );
      state.createTask.selectedBuocList.splice(index, 1);
    },

    ONPUSH_QUYTRINH: (state: any, actions: PayloadAction<any>) => {
      let check = state.createTask.selectedBuocList.find(
        (item: any) => item.orderBy === parseInt(actions.payload.orderBy)
      );

      if (check === undefined) {
        state.createTask.selectedBuocList.push(actions.payload);
        state.createTask.selectedBuocList.sort((a: any, b: any) => {
          return a.orderBy - b.orderBy;
        });
      } else {
        return;
      }
    },

    ONREFRESH_SELECTED_BUOC_VA_KETQUA: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.createTask.selectedBuocList = null;
      state.createTask.selectedQuytrinhList = null;
      actions.payload.onClose();
    },

    ONCHANGE_SELECTED_BUOC_LIST: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedBuocList = actions.payload;
    },

    ONCHANGE_SELECTED_KET_QUA: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedKetquaList = actions.payload;
    },

    ONCHANGE_SELECTED_QUY_TRINH: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedQuytrinhList =
        state.createTask.quytrinhList.find(
          (item: any) => item.id === parseInt(actions.payload)
        );
    },

    ONCHANGE_SELECTED_DO_UU_TIEN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedDoUuTien = state.createTask.douutienList.find(
        (item: any) => item.Id === parseInt(actions.payload)
      );
    },

    ONCHANGE_SELECTED_DO_KHAN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedDoKhan = state.createTask.dokhanList.find(
        (item: any) => item.Id === actions.payload
      );
    },

    ONCHANGE_SELECTED_NGUON_VAN_BAN: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.createTask.selectedNguonVanBan = actions.payload;
    },

    ONCHANGE_SELECTED_LOAI_CONG_VIEC: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.createTask.selectedLoaiCongViec = actions.payload;
    },

    ONLOAD_DO_KHAN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.dokhanList = actions.payload;
    },

    ONLOAD_QUY_TRINH: (state: any, actions: PayloadAction<any>) => {
      state.createTask.quytrinhList = actions.payload;
    },

    ONLOAD_DO_UU_TIEN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.douutienList = actions.payload;
    },

    ONLOAD_NGUON_VAN_BAN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.nguonvanbanList = actions.payload;
    },

    ONLOAD_LOAI_CONG_VIEC: (state: any, actions: PayloadAction<any>) => {
      state.createTask.loaicongviecList = actions.payload;
    },

    ONCHANGE_NGUOI_PHOI_HOP: (state: any, actions: PayloadAction<any>) => {
      const isTrue = state.createTask.selectedNguoiPhoiHop.includes(
        actions.payload
      );

      if (isTrue === false) {
        state.createTask.selectedNguoiPhoiHop.push(actions.payload);
        state.createTask.selectedNguoiPhoiHopObject.push(
          state.createTask.nguoiphoihopList.find(
            (item: any) => item.Id === actions.payload.toString()
          )
        );
      } else {
        const index = state.createTask.selectedNguoiPhoiHop.findIndex(
          (item: any) => item === actions.payload
        );
        const indexObject =
          state.createTask.selectedNguoiPhoiHopObject.findIndex(
            (item: any) => item.Id === actions.payload.toString()
          );

        state.createTask.selectedNguoiPhoiHop.splice(index, 1);
        state.createTask.selectedNguoiPhoiHopObject.splice(indexObject, 1);
      }
    },

    ONCHANGE_SELECTED_NGUOINHAN: (state: any, actions: PayloadAction<any>) => {
      state.selectedNguoiPhoiHop = "";
      state.createTask.selectedNguoiNhan = state.createTask.nguoinhanList.find(
        (item: any) => item.Id === actions.payload.toString()
      );

      state.createTask.selectedNguoiPhoiHop = [];
      state.createTask.selectedNguoiPhoiHopObject = [];
    },

    ONCHANGE_SELECTED_PHONGBAN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.selectedPhongBan = state.createTask.phongbanList.find(
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(actions.payload))
            ? true
            : false
      );
      state.selectedNguoiPhoiHop = "";

      state.createTask.selectedNguoiNhan = null;
      state.createTask.nguoinhanList = state.createTask.nguoiphoihopList.filter(
        (item: any) =>
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == parseInt(actions.payload))
            ? true
            : false
      );

      state.createTask.selectedNguoiPhoiHop = [];
      state.createTask.selectedNguoiPhoiHopObject = [];
    },

    ONCHANGE_PHONGBAN: (state: any, actions: PayloadAction<any>) => {
      state.createTask.phongbanList = actions.payload.phongbanList;
      state.createTask.nguoiphoihopList = actions.payload.nguoiphoihopList;

      state.selectedNguoiPhoiHop = "";
    },

    ONCHANGE_TASK_NAME: (state: any, actions: PayloadAction<any>) => {
      state.searchFilter.taskName = actions.payload;
    },

    ONCHANGE_DATE: (state: any, actions: PayloadAction<any>) => {
      switch (actions.payload.type) {
        case "fromDate":
          state.searchFilter.fromDate = actions.payload.value;
          break;
        case "toDate":
          state.searchFilter.toDate = actions.payload.value;
          break;
        case "deadLine":
          state.searchFilter.deadLine = actions.payload.value;
          break;
      }
    },

    ONLOAD_SELECTED_PRIOR: (state: any, actions: PayloadAction<any>) => {
      if (actions.payload === null) {
        state.searchFilter.selectedPrior = -1;
      } else {
        state.searchFilter.selectedPrior = actions.payload.Id;
      }
    },

    ONLOAD_RECEIVERLIST: (state: any, actions: PayloadAction<any>) => {
      state.receiverList = actions.payload;
    },

    ONLOAD_PRIORLIST: (state: any, actions: PayloadAction<any>) => {
      state.priorList = actions.payload;
    },

    ONCHANGE_CHOOSEPAGE: (state: any, actions: PayloadAction<any>) => {
      state.choosePage = parseInt(actions.payload);
      state.pageCurrent = 0;
    },

    ONCHANGE_PAGINATION_TASKLIST: (state: any, actions: PayloadAction<any>) => {
      state.pageCurrent = actions.payload - 1;
    },

    ONLOAD_TASKLIST_ALL: (state: any, actions: PayloadAction<any>) => {
      state.newTaskList = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONCHANGE_SHOW_STEPLIST_OF_QUYTRINH: (
      state: any,
      actions: PayloadAction<any>
    ) => {
      state.quytrinhList.map((item: any, index: any) => {
        if (item.id === actions.payload.id) {
          if (item.stepList.length === 0) {
            actions.payload.value.forEach((subItem: any, subIndex: any) => {
              item.stepList.push(subItem);
            });
          }

          if (item.showStepList === false) {
            item.showStepList = true;
          } else {
            item.showStepList = false;
          }
        }
      });
    },

    ONLOAD_QUYTRINH_LIST: (state: any, actions: PayloadAction<any>) => {
      let tempArray: any[] = [];

      actions.payload.forEach((item: any, index: any) => {
        tempArray.push({
          id: item.id,
          tenquytrinh: item.tenquytrinh,
          UserAdd: item.UserAdd,
          DateAdd: item.DateAdd,
          UserModify: item.UserModify,
          DateModify: item.DateModify,
          UserDeleted: item.UserDeleted,
          DateDeleted: item.DateDeleted,
          Deleted: item.Deleted,
          stepList: [],
          showStepList: false,
        });
      });

      state.quytrinhList = tempArray;
    },

    ONLOAD_LIST_PERMISSION: (state: any, actions: PayloadAction<any>) => {
      let tempArray: any[] = [];

      actions.payload.sort((a: any, b: any) => {
        return a.ParentID - b.ParentID;
      });

      actions.payload.forEach((item: any, index: any) => {
        if (item.ParentID === 0) {
          tempArray.push({
            Id: item.Id,
            Tendauviec: item.Tendauviec,
            ParentID: item.ParentID,
            PerActive: item.PerActive,
            subItem: [],
          });
        }
      });

      tempArray.forEach((item: any, index: any) => {
        actions.payload.forEach((item2: any, index2: any) => {
          if (item2.ParentID === item.Id) {
            item.subItem.push(item2);
          }
        });
      });

      state.listPermission = tempArray;
      // console.log(state.listPermission);
    },

    ONCHANGE_PRIOR: (state: any, actions: PayloadAction<any>) => {
      state.newTask.prior = parseInt(actions.payload);
    },

    ONLOAD_TASKRECOVERYLIST_ALL: (state: any, actions: PayloadAction<any>) => {
      state.taskRecovery = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONLOAD_TASKREVOKELIST_ALL: (state: any, actions: PayloadAction<any>) => {
      state.taskRevoke = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },

    ONLOAD_SETTINGPROCEDUCE_ALL: (state: any, actions: PayloadAction<any>) => {
      state.settingProceduce = actions.payload;

      if (actions.payload.count % state.choosePage === 0) {
        state.paginateTaskList = Math.floor(
          actions.payload.count / state.choosePage
        );
      } else {
        state.paginateTaskList =
          Math.floor(actions.payload.count / state.choosePage) + 1;
      }
    },
  },
});

export const {
  ONCHANGE_TASK_COMPLETED_PUNCTUAL,
  ONCHANGE_TASK_COMPLETED_EARLY,
  ONCHANGE_TASK_COMPLETED_LATE,
  ONCHANGE_TASK_WAITING,
  ONCHANGE_RESET_FILTER,
  ONCHANGE_SELECTED_BOSS,
  ONLOAD_BOSS_LIST,
  ONSET_RETURN_PAGE_CURRENT,
  ONCHANGE_NGUOI_GIAO_RETURN,
  ONFILTER_DATA,
  ONCHANGE_IS_FORCE_2,
  ONCHANGE_NGUOI_NHAN_TAT_CA,
  ONCHANGE_TASKNAME_DETAIL,
  ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN,
  ONREAD_VALUE_DETAIL,
  ONCHANGE_DETAIL_VALUE,
  ONCHANGE_SELECTED_NGUOINHAN_2,
  ONCHANGE_SELECTED_PHONGBAN_2,
  ONCHANGE_NGUOI_NHAN_MOI,
  ONCHANGE_NGUOI_GIAO_TONG_HOP,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_NGUOI_NHAN_DA_NHAN,
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_NGUOI_NHAN_XONG_SOM,
  ONCHANGE_NGUOI_NHAN_XONG,
  ONCHANGE_NGUOI_NHAN_XONG_TRE,
  ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY,
  ONCHANGE_NGUOI_NHAN_TRE_HAN,
  ONCHANGE_SELECTED_NGUOI_PHOI_HOP_FROM_DETAIL,
  ONCHANGE_NGUOI_GIAO_HOAN_TAT,
  ONCHANGE_TASK_DETAIL,
  ONCHANGE_VIEC_CAN_GIAO,
  ONCHANGE_DANG_TRE_HAN,
  ONCHANGE_TOI_HAN_HOM_NAY,
  ONCHANGE_SAP_TOI_HAN,
  ONCHANGE_DEADLINE_STEP,
  ONCHANGE_IS_FORCE,
  ONCHANGE_ID_QUY_TRINH,
  ONPUSH_FILES,
  ONCHANGE_ADDING_NGUOI_PHOI_HOP,
  ONLOAD_DAUMUC,
  ONCHANGE_SELECTED_DAU_MUC,
  ONPUSH_KETQUA,
  ONCHANGE_DELETE_QUYTRINH_KETQUA,
  ONCHANGE_QUYTRINH_KETQUA,
  ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN,
  ONCHANGE_QUYTRINH_BUOC,
  ONDELETE_QUYTRINH,
  ONPUSH_QUYTRINH,
  ONREFRESH_SELECTED_BUOC_VA_KETQUA,
  ONCHANGE_SELECTED_QUY_TRINH,
  ONCHANGE_SELECTED_BUOC_LIST,
  ONCHANGE_SELECTED_KET_QUA,
  ONLOAD_QUY_TRINH,
  ONCHANGE_SELECTED_LOAI_CONG_VIEC,
  ONCHANGE_SELECTED_DO_KHAN,
  ONCHANGE_SELECTED_DO_UU_TIEN,
  ONCHANGE_SELECTED_NGUON_VAN_BAN,
  ONLOAD_LOAI_CONG_VIEC,
  ONLOAD_DO_UU_TIEN,
  ONLOAD_DO_KHAN,
  ONLOAD_NGUON_VAN_BAN,
  ONCHANGE_NGUOI_PHOI_HOP,
  ONCHANGE_SELECTED_NGUOINHAN,
  ONCHANGE_SELECTED_PHONGBAN,
  ONCHANGE_PHONGBAN_LIST,
  ONCHANGE_PHONGBAN,
  ONCHANGE_TASK_NAME,
  ONCHANGE_NGUOI_NHAN_RETURN,
  ONCHANGE_DATE,
  ONLOAD_SELECTED_PRIOR,

  ONCHANGE_FROM_HOME_TO_PROCESSING,

  ONLOAD_SELECTED_RECEIVER,
  ONCHANGE_FILTER_SELECTED_PHONGBAN,
  ONLOAD_RECEIVERLIST,
  ONLOAD_PRIORLIST,
  ONCHANGE_CHOOSEPAGE,
  ONCHANGE_PAGINATION_TASKLIST,
  ONLOAD_TASKLIST_ALL,
  ONCHANGE_SHOW_STEPLIST_OF_QUYTRINH,
  //ONCHANG_HIGHMANGER_STORAGE,

  ONCHAGE_FILTER_RESULT_WITH_NUMBER_RECEIVER,

  // ONCHANG_HIGHMANGER_STORAGE2,
  // RESET_HIGHMANGER_STORAGE2,

  // ONCHANG_MEDIUMMANAGER_STORAGE,
  ONLOAD_QUYTRINH_LIST,
  RESET_HIGHMANGER_STORAGE,
  ONLOAD_LIST_PERMISSION,
  ONCHANGE_PRIOR,
  ONCHANGE_TASKLIST_WITH_NORMAL,
  ONCHAGE_FILTER_STATUS,
  ONCHANGE_VOTING_LIST,
  ONCHAGE_FILTER_RESULT,
  ONCHAGE_FILTER_RESULT_WITH_NUMBER,
  ONCHAGE_FILTER_SELECTED_NGUOI_NHAN,
  ONCHANGE_NGUOI_NHAN_CHIA_SE,
  ONLOAD_TASKRECOVERYLIST_ALL,
  ONLOAD_TASKREVOKELIST_ALL,
  ONLOAD_SETTINGPROCEDUCE_ALL,
} = workingReducer.actions;

export default workingReducer.reducer;
