import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
// import moment from "moment";

import {
  CONFIRM_EXTEND,
  NGUOI_GIAO_UPDATE_DEADLINE,
  ON_LEAVE_CREATE,
  ON_LEACE_DETAIL,
  ON_LEAVE_UPDATE
} from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AddIcon from '@mui/icons-material/Add';

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import { set } from "date-fns";

import Select from "react-select"

import SubModal from "../_subModal/_index";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const DetailOnLeaving = (props: any) => {

  // Props
  const workingData = useSelector((state: any) => state.workingReducer);
  const shouldLog = useRef(true)

  const accessToken = localStorage.getItem("accessToken");
  const taskId = props.taskId;
  const timeExtend = props.timeExtend;
  const [isChangeTimeExtend, setIsChangeTimeExtend] = useState<boolean>(false);
  const extendId = props.extendId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const [newTimeExtend, setNewTimeExtend] = useState<string>(timeExtend);
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  console.log()

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  // States
  const [id, setId] = useState<any>(undefined)
  const [dayOff, setDayOff] = useState<any>("")
  const [timeOff, setTimeOff] = useState<any>("")
  const [reason, setReason] = useState<any>("")
  const [personOff, setPersonOff] = useState<any | null>(null)
  const [personId, setPersonId] = useState<any>()
  const [typeOff, setTypeOff] = useState<boolean | null>(null)

  // Const
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  // Funcs
  const loadDetail = () => {
    const accessToken = localStorage.getItem("accessToken")
    const API = ON_LEACE_DETAIL + `?id=${taskId}`

    const fetchData = async () => {
      try {
        await ServiceUtils.getV2(
          API,
          {
            headers: {

              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setId(res.data.Data.ID)
              setTimeOff(res.data.Data.HoursOff)
              setDayOff(res.data.Data.DateOff)
              setReason(res.data.Data.Reason)
              setTypeOff(res.data.Data.IsAwol)
              setPersonOff(res.data.Data.UseId)
            } else {
              toast.error(res.data.Message)
            }
          })
      } catch (error) {
        toast.error("Tải dữ liệu thất bại")
      }
    }

    fetchData()
  }

  const _onClickUpdateLeaving = () => {
    const accessToken = localStorage.getItem("accessToken")

    const body: any = {
      ID: taskId,
      DateOff: dayOff,
      HoursOff: timeOff,
      Reason: reason,
      IsAwol: typeOff,
      UseId: personOff
    }

    const fetchData = async () => {
      setIsLoading(true)
      try {
        await ServiceUtils.postV2(
          ON_LEAVE_UPDATE,
          body,
          {
            headers: {
              Authorization: "Bearer " + accessToken
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setIsLoading(false)
              context.dataModal.tempFuncs()
              toast.success("Cập nhật nghỉ phép thành công")
              handleClose()
              context.handleClose()
            }
          })
      } catch (error) {
        toast.error("Cập nhật thất bại")
        setIsLoading(false)
      }
    }

    fetchData()
  }

  // CRUD
  const _onClickConfirmDelete = () => {
    setFuncsSub("deleteLeaving")
    // context.setDataModal({
    //   tempFuncs: loadAll
    // })
    handleOpenSub()
  }

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false

      loadDetail()
    }
  })

  // useMemo
  const tempTypeOff = useMemo(() => {
    if (typeOff === false) {
      return {
        id: 1,
        value: "Không phép",
        label: "Không phép",
        type: false
      }
    } else {
      return {
        id: 2,
        value: "Có phép",
        label: "Có phép",
        type: true
      }
    }
  }, [typeOff])

  const tempPerson = useMemo(() => {
    return tempReceiverList?.find((item: any) => item.Id === personOff)
  }, [personOff])

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />

      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết nghỉ phép</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>
      {/* content */}
      {
        id === undefined || isloading === true ?
          // <div className="relative flex justify-center items-center flex-col w-full h-[25rem] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          //   <LoaderSpinner w={32} h={32} />
          //   <p className="text-primary">Đang tải dữ liệu</p>
          // </div>
          <LoadingControl />
          :
          <div className="relative flex flex-col w-full h-[25rem] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
            <div className="space-y-4">
              <div className="flex items-center">
                <label className="w-1/4">Ngày nghỉ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  type="datetime-local"
                  value={dayOff}
                  onChange={(e: any) => {
                    e.preventDefault()
                    setDayOff(e.target.value)
                  }}
                />
              </div>

              <div className="flex items-center">
                <label className="w-1/4">Giờ nghỉ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  type="number"
                  placeholder="Nhập số giờ nghỉ"
                  value={timeOff}
                  onChange={(e: any) => {
                    e.preventDefault()
                    setTimeOff(e.target.value)
                  }}
                />
              </div>

              <div className="flex items-center">
                <label className="w-1/4">Lý do xin nghỉ</label>
                <textarea
                  className="w-full h-[5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={reason}
                  onChange={(e: any) => {
                    e.preventDefault()
                    setReason(e.target.value)
                  }}
                />
              </div>

              <div className="flex items-center">
                <label className="w-1/4">Người nghỉ</label>
                <Select
                  className="z-30 w-full"
                  options={tempReceiverList}
                  isClearable
                  placeholder="--- Chọn người nghỉ ---"
                  value={tempPerson}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(data: any) => {
                    if (data === null) {
                      setPersonOff(null)
                    } else {
                      setPersonOff(data?.Id)
                    }
                  }}
                />
              </div>

              <div className="flex items-center">
                <label className="w-1/4">Loại</label>
                <Select
                  className="z-20 w-full"
                  options={[
                    {
                      id: 1,
                      value: "Không phép",
                      label: "Không phép",
                      type: false
                    },
                    {
                      id: 2,
                      value: "Có phép",
                      label: "Có phép",
                      type: true
                    },
                  ]}
                  value={tempTypeOff}
                  isClearable
                  placeholder="--- Chọn Loại ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(data: any) => {
                    if (data === null) {
                      setTypeOff(null)
                    } else {
                      setTypeOff(data?.type)
                    }
                  }}
                />
              </div>
            </div>
          </div>
      }

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="w-full h-max flex justify-between items-center">
          <div className="w-max h-max flex">
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${(isResult === false && result === "")
                  ? "bg-slate-300"
                  : "bg-red-500 hover:bg-red-400"
                }`}
              disabled={
                (isResult === false && result === "")
              }

              onClick={_onClickConfirmDelete}
            >
              <DeleteIcon fontSize="small" />
              <p>Xoá</p>
            </button>
          </div>

          <div className="flex w-max h-max space-x-3">
            {isloading ? (
              <LoaderSpinner h={25} w={25} />
            ) : (
              <button
                className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${(isResult === false && result === "")
                    ? "bg-slate-300"
                    : "bg-sky-500 hover:bg-sky-400"
                  }`}
                disabled={
                  (isResult === false && result === "")
                }

                onClick={_onClickUpdateLeaving}
              >
                <EditIcon fontSize="small" />
                <p>Cập nhật</p>
              </button>
            )}

            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>


      </div>
    </div>
  );
};

export default DetailOnLeaving;
