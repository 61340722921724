import React, { memo, useEffect, useRef } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// pages importing
import LoginPage from "../pages/loginPage/_index";
import ForgetPasswordPage from "../pages/forgetPasswordPage/_index";
import NewPasswordPage from "../pages/newPasswordPage/_index";

import HomePage from "../pages/homePage/_index";
import WorkingTableTotalUI from "../ui/workingTableTotalUI/_index";
import PlanBossPage from "../pages/kehoachPage/planBoss";

// working
import WorkingNewTaskUI from "../pages/workingPage/_deliver/createNewTaskUI/_index";
import TaskNeedGiveUI from "../pages/workingPage/_deliver/taskNeedGiveUI/_index";
import TaskFinishUI from "../pages/workingPage/_deliver/taskFinishUI/_index";
import TaskDelayUI from "../pages/workingPage/_deliver/taskDelayUI/_index";
import TaskProccessingUI from "../pages/workingPage/_deliver/taskProccessingUI/_index";
import TaskEndSoonUI from "../pages/workingPage/_deliver/taskEndSoon/_index";
import TaskEndTodayUI from "../pages/workingPage/_deliver/taskEndToday/_index";
import TaskTotalUI from "../pages/workingPage/_deliver/taskTotal/_index";
import ManagementWorkFlow from "../pages/workingPage/_deliver/managementWorkFlowUI/_index";
import TaskReturnPage from "../pages/workingPage/_deliver/taskReturnUI/_index";
import TaskChangeDeadlineUI from "../pages/workingPage/_deliver/taskChangeDeadlineUI/_index";

import PlanSenderPage from "../pages/kehoachPage/planSender";

import VotingPage from "../pages/workingPage/_deliver/votingPage/votingPage";

import ElectronicReciverReport from "../pages/reportPage/electronicReport/_receiver/electronicReceiver";

import TaskNewRPage from "../pages/workingPage/_receiver/taskNew_R/_index";
import TaskEndSoonRPage from "../pages/workingPage/_receiver/taskEndSoon_R/_index";
import TaskEndTodayRPage from "../pages/workingPage/_receiver/taskEndToday_R/_index";
import TaskDelayRPage from "../pages/workingPage/_receiver/taskDelay_R/_index";
import TaskProcessingRPage from "../pages/workingPage/_receiver/taskProcessing_R/_index";
import TaskReceivedRPage from "../pages/workingPage/_receiver/taskReceived_R/_index";
import TaskAllTaskRPage from "../pages/workingPage/_receiver/taskAllTask_R/_index";
import TaskReturnR from "../pages/workingPage/_receiver/taskReturn_R/_index";
import TaskWaitingPage from "../pages/workingPage/_receiver/taskWaiting/taskWaitingPage";

import OnLeavePage from "../pages/onLeave/_index";

import FinishEarly from "../pages/workingPage/_deliver/finishEarly/_index";
import FinishLate from "../pages/workingPage/_deliver/finishLate/_index";
import FinishPunctual from "../pages/workingPage/_deliver/finishPunctual/_index";

import TaskCompleteLatePage from "../pages/workingPage/_receiver/taskCompleteLate/_index";
import TaskCompleteEarlyPage from "../pages/workingPage/_receiver/taskCompleteEarly/_index";
import TaskCompletePunctualPage from "../pages/workingPage/_receiver/taskCompletePunctual/_index";

// report
import MissionProcessingPage from "../pages/reportPage/_deliver/missionProcessing";
import DetailWorkerCapacity from "../pages/reportPage/_deliver/detailWorkerCapacity";
import TaskGeneralDeliver from "../pages/reportPage/_deliver/taskGeneralDeliver";
import WorkerCapacity from "../pages/reportPage/_deliver/workerCapacity";
import IndividualCapacity from "../pages/reportPage/_receiver/individualCapacity";
import TableReportCapacity from "../pages/reportPage/_receiver/tableReportCapacity";
import UserDifTask from "../pages/reportPage/dificult/sumDiftask";
import SumDifTask from "../pages/reportPage/dificult/userDiftask";
import AllDifTask from "../pages/reportPage/dificult/allDiftask";
import ReportRanking from "../pages/reportPage/ranking/_index";

// setting
import ListOfEmployeePage from "../pages/settingPage/_listCategory/listOfEmployeePage";
import ListOfGeneralPage from "../pages/settingPage/_listCategory/listOfGeneralPage";
import ListOfTaskPage from "../pages/settingPage/_listCategory/listOfTaskPage";
import PriorRatePage from "../pages/settingPage/_listCategory/priorRatePage";
import ScoreRatingCapacityPage from "../pages/settingPage/_listCategory/scoreRatingCapacityPage";
import TargetRatingCapacityPage from "../pages/settingPage/_listCategory/targetRatingCapacityPage";
import AlertTaskingPage from "../pages/settingPage/_setting/alertTaskingPage";
import EmailAutomaticPage from "../pages/settingPage/_setting/emailAutomaticPage";
import OverloadSettingPage from "../pages/settingPage/_setting/overloadSettingPage";
import ListOfSecurity from "../pages/settingPage/_listCategory/listOfSecurityLevel";
import DifficultRate from "../pages/settingPage/_listCategory/difficultRate";
import SumWorkReport from "../pages/reportPage/sumwork/overviewReport";

// user
import UserListPage from "../pages/userPage/userListPage";
import FunctionalListPage from "../pages/userPage/functionalListPage";
import ScreenListPage from "../pages/userPage/screenListPage";
import WorkCategoryListPage from "../pages/userPage/workCategoryList";
import DataOptionPage from "../pages/userPage/dataOptionPage";

import OverviewReport from "../pages/reportPage/sumwork/overviewReport";
import SumRedWorkReport from "../pages/reportPage/sumwork/sumRedWorkReport";
import SumGreenWorkReport from "../pages/reportPage/sumwork/sumGreenWorkReport";
import UserTaskPercentReport from "../pages/reportPage/userwork/userTaskPercentReport";
import UserGreenWorkReport from "../pages/reportPage/userwork/userGreenWorkReport";
import UserRedWorkReport from "../pages/reportPage/userwork/userRedWorkReport";
import SumTaskListAllReport from "../pages/reportPage/sumwork/sumTaskListAllReport";
import TilehoanthanhReport from "../pages/reportPage/sumwork/tilehoanthanhReport";
import UserSumWorkReport from "../pages/reportPage/userwork/userSumWorkReport";
import PersonalGreenWorkReport from "../pages/reportPage/userwork/personalGreenWorkReport";
import PersonalRedWorkReport from "../pages/reportPage/userwork/personalRedWorkReport";
import PersonalSumWorkReport from "../pages/reportPage/userwork/personalSumWorkReport";
import ElectronicReport from "../pages/reportPage/electronicReport/_sender/electronicReport";
//propose
import ProposeSend from "../pages/proposePage/_deliver/_index";
import ProposeGet from "../pages/proposePage/_receiver/_index";
import AllPage from "../pages/allPage/_index";
import TaslkListAllPage from "../pages/workingPage/taskListAll/_index";
import MissionProcessingUI from "../ui/shared/reportUI/_deliver/missionProcessing";

import ElectronicManagerReport from "../pages/reportPage/electronicReport/_manager/electronicManager";

import NormalStaff from "../temp/Normal/_index";

// temp
import HighManager from "../temp/HighManager/_index";
import MediumManager from "../temp/MediumManager/_index";
import Error404 from "../pages/error404Page/_index";
import TaskSharedPage from "../pages/workingPage/_receiver/taskShared/_index";
import { NAVIGATION_KEYS } from "../constants/constants";
import CategorySuggestPage from "../pages/settingPage/_listCategory/categorySuggestPage";
import SettingProceducePage from "../pages/settingPage/_listCategory/settingProceducePage";

import ReportWaitingVerify from "../pages/reportPage/electronicReport/_receiver/reportWaitingVerify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ONCHANGE_MENULIST } from "../redux/reducers/_appReducer/_index";
import FileManagementPage from "../pages/fileManagement/_index";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/allPage" element={<AllPage />} />

        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/new-password" element={<NewPasswordPage />} />

        <Route
          path="/working/receiver/task-returnwork"
          element={<TaskReturnR />}
        />
        <Route
          path="/working/receiver/task-waiting"
          element={<TaskWaitingPage />}
        />

        <Route path="/home" element={<HomePage />} />
        <Route path="/workingtable/total" element={<SumWorkReport />} />
        <Route path="/workingtable/total1" element={<OverviewReport />} />
        <Route path="/workingtable/total2" element={<SumRedWorkReport />} />
        <Route path="/workingtable/total3" element={<SumGreenWorkReport />} />
        <Route path="/working/task-return" element={<TaskReturnPage />} />
        <Route path="/report/sumwork" element={<SumWorkReport />} />
        <Route path="/report/overview" element={<OverviewReport />} />
        <Route path="/report/sum-redwork" element={<SumRedWorkReport />} />
        <Route path="/report/sum-greenwork" element={<SumGreenWorkReport />} />
        <Route path="/report/ranking" element={<ReportRanking />} />
        <Route
          path="/report/user-greenwork"
          element={<UserGreenWorkReport />}
        />
        <Route path="/report/user-redwork" element={<UserRedWorkReport />} />
        <Route
          path="/report/sum-taskpercent"
          element={<TilehoanthanhReport />}
        />
        <Route
          path="/report/user-taskpercent"
          element={<UserTaskPercentReport />}
        />
        <Route
          path="/report/sum-tasklistall"
          element={<SumTaskListAllReport />}
        />
        <Route path="/report/user-sumwork" element={<UserSumWorkReport />} />
        <Route path="/report/user-diftask" element={<UserDifTask />} />
        <Route path="/report/sum-diftask" element={<SumDifTask />} />
        <Route path="/report/all-diftask" element={<AllDifTask />} />

        <Route
          path="/report/personal-greenwork"
          element={<PersonalGreenWorkReport />}
        />
        <Route
          path="/report/personal-redwork"
          element={<PersonalRedWorkReport />}
        />
        <Route
          path="/report/personal-sumwork"
          element={<PersonalSumWorkReport />}
        />

        <Route path="/leave/onleave" element={<OnLeavePage />} />

        <Route path="/report/PlanRecieved" element={<PlanBossPage />} />

        <Route path="/report/electronic" element={<ElectronicReport />} />
        <Route path="/report/received" element={<ElectronicReciverReport />} />
        <Route path="/report/request" element={<ElectronicManagerReport />} />

        <Route path="/working/new-task" element={<WorkingNewTaskUI />} />
        <Route path="/working/task-need-give" element={<TaskNeedGiveUI />} />
        <Route path="/working/task-finish" element={<TaskFinishUI />} />

        <Route path="/working/task-delay" element={<TaskDelayUI />} />

        <Route
          path="/working/task-change-deadline"
          element={<TaskChangeDeadlineUI />}
        />

        <Route
          path="/working/receiver/task-finish_puntual"
          element={<TaskCompletePunctualPage />}
        />

        <Route
          path="/working/receiver/task-share"
          element={<TaskSharedPage />}
        />
        <Route
          path="/working/receiver/task-finish_late"
          element={<TaskCompleteLatePage />}
        />
        <Route
          path="/working/receiver/task-finish_early"
          element={<TaskCompleteEarlyPage />}
        />

        <Route path="/report/PlanSent" element={<PlanSenderPage />} />

        <Route
          path="/working/task-proccessing"
          element={<TaskProccessingUI />}
        />

        <Route
          path="/working/task-proccessing"
          element={<TaskProccessingUI />}
        />
        <Route path="/working/task-end-soon" element={<TaskEndSoonUI />} />
        <Route path="/working/task-end-today" element={<TaskEndTodayUI />} />
        <Route path="/working/task-total" element={<TaskTotalUI />} />

        <Route path="/voting/voting_work" element={<VotingPage />} />
        <Route path="/voting/voting_wait" element={<VotingPage />} />

        <Route
          path="/working/management-workflow"
          element={<ManagementWorkFlow />}
        />

        <Route path="/working/receiver/task-new" element={<TaskNewRPage />} />
        <Route
          path="/working/receiver/task-delay"
          element={<TaskDelayRPage />}
        />
        <Route
          path="/working/receiver/task-end-soon"
          element={<TaskEndSoonRPage />}
        />
        <Route
          path="/working/receiver/task-end-today"
          element={<TaskEndTodayRPage />}
        />
        <Route
          path="/working/receiver/task-processing"
          element={<TaskProcessingRPage />}
        />
        <Route
          path="/working/receiver/task-received"
          element={<TaskReceivedRPage />}
        />
        <Route
          path="/working/receiver/all-task"
          element={<TaskAllTaskRPage />}
        />

        <Route
          path="/report/deliver/task-general-deliver"
          element={<TaskGeneralDeliver />}
        />
        <Route
          path="/report/deliver/mission-processing"
          element={<MissionProcessingPage />}
        />
        <Route
          path="/report/deliver/detail-worker-capacity"
          element={<DetailWorkerCapacity />}
        />
        <Route
          path="/report/deliver/worker-capacity"
          element={<WorkerCapacity />}
        />
        <Route
          path="/report/receiver/individual-capacity"
          element={<IndividualCapacity />}
        />
        <Route
          path="/report/receiver/table-report-capacity"
          element={<TableReportCapacity />}
        />

        <Route
          path="/setting/listof-category/list-of-employee"
          element={<ListOfEmployeePage />}
        />
        <Route
          path="/setting/listof-category/list-of-task"
          element={<ListOfTaskPage />}
        />
        <Route
          path="/setting/listof-category/list-of-general"
          element={<ListOfGeneralPage />}
        />
        <Route
          path="/setting/listof-category/prior-rate"
          element={<PriorRatePage />}
        />
        <Route
          path="/setting/listof-category/score-rating-capacity"
          element={<ScoreRatingCapacityPage />}
        />
        <Route
          path="/setting/listof-category/target-rating-capacity"
          element={<TargetRatingCapacityPage />}
        />
        <Route
          path="/setting/setting/alert-tasking"
          element={<AlertTaskingPage />}
        />
        <Route
          path="/setting/setting/email-automatic"
          element={<EmailAutomaticPage />}
        />
        <Route
          path="/setting/setting/overload-setting"
          element={<OverloadSettingPage />}
        />
        <Route
          path="/setting/listof-category/security-rate"
          element={<ListOfSecurity />}
        />
        <Route
          path="/setting/listof-category/difficult-rate"
          element={<DifficultRate />}
        />

        <Route path="/user/user-list" element={<UserListPage />} />
        <Route path="/user/functional-list" element={<FunctionalListPage />} />
        <Route path="/user/screen-list" element={<ScreenListPage />} />
        <Route
          path="/user/work-category-list"
          element={<WorkCategoryListPage />}
        />
        <Route path="/user/data-option" element={<DataOptionPage />} />
        <Route path="/propose/sender/list" element={<ProposeSend />} />
        <Route path="/propose/receiver/list" element={<ProposeGet />} />

        <Route path="/working/task-finish-late" element={<FinishLate />} />
        <Route
          path="/working/task-finish-punctual"
          element={<FinishPunctual />}
        />
        <Route path="/working/task-finish-early" element={<FinishEarly />} />

        <Route
          path="/working/task-totalall/:result"
          element={<TaskTotalUI />}
        />

        <Route path="/working/task-listall" element={<TaslkListAllPage />} />
        <Route path="/testing" element={<MissionProcessingUI />} />

        {/* Temp */}
        <Route path="/normal-staff" element={<NormalStaff />} />
        <Route path="/medium-manager" element={<MediumManager />} />
        <Route path="/high-manager" element={<HighManager />} />

        <Route
          path={NAVIGATION_KEYS.catgegorySuggest}
          element={<CategorySuggestPage />}
        />
        <Route
          path={NAVIGATION_KEYS.settingProceduce}
          element={<SettingProceducePage />}
        />
        <Route
          path={NAVIGATION_KEYS.reportWaitingVerify}
          element={<ReportWaitingVerify />}
        />
        <Route path="/drives/:id" element={<FileManagementPage />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
