import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPassword } from "../../action/userAction"
// ui importing
import LoginLeftSideUI from "../shared/loginLeftSideUI/_index";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

const ForgetPasswordUI = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState<any>(null)
  const [email, setEmail] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false);

  const _onChangeText = (e: any, option: any) => {

    const value = e.target.value;
    if (option === "userName") {
      setUserName(value)
    } else if (option === "email") {
      setEmail(value)
    }
  }

  const _onClickSubmit = () => {
    setIsLoading(true);
    const body: any = {
      Username: userName ? userName : '',
      Email: email ? email : ''
    }
    ForgotPassword(JSON.stringify(body)).then((res: any) => {

      try {
        const data = ((res || {}).data || {}).data || {}
        if (data.StatusCode === 200) {
          toast.success(data.Message || "Mật khẩu của bạn đã được reset!")
          setIsLoading(false);
        } else {
          toast.error(data.Message || "Có lỗi xảy ra, vui lòng thử lại!")
          setIsLoading(false);
        }
      } catch (error: any) {
        toast.error(error || "Có lỗi xảy ra, vui lòng thử lại!")
        setIsLoading(false);
      }

    })
  }

  return (
    <>

      <div className="flex h-full w-full">
        {/* left side */}
        <LoginLeftSideUI />

        {/* <div className="flex justify-center items-center flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải dữ liệu</p>
        </div> */}
        {/* right side */}
        <div className="
        flex lg:w-1/2 w-full h-full
        justify-center items-center
        relative
      ">
          {/* forms */}
          <div className="
          lg:w-2/3 w-full h-max
        ">
            {/* title */}
            <div className="relative flex justify-center items-center w-full zzzh-[3rem]">
              {/* back button */}
              <div className="
              absolute left-4 w-max h-full
              justify-center items-center flex text-primary
            ">
                <button onClick={() => { navigate(-1) }} className="w-max h-max hover:text-red-400">
                  &lt; Quay lại
                </button>
              </div>

              {/* title-content */}
              <div className="
              flex justify-center items-center
              w-1/2 h-full
              text-2xl text-primary font-semibold border-b-2 border-primary
            ">
                QUÊN MẬT KHẨU
              </div>
            </div>

            {/* content */}
            <div className="
            flex flex-col w-full h-max
            pt-12 pr-4 pl-4 space-y-3
          ">
              {/* username */}
              <div className="flex flex-col w-full h-max">
                <label>Tên đăng nhập</label>
                <input
                  className="w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên đăng nhập"
                  onChange={(e: any) => { _onChangeText(e, "userName") }}
                />
              </div>

              {/* email */}
              <div className="flex flex-col w-full h-max">
                <label>Email</label>
                <input
                  className="w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập email"
                  onChange={(e: any) => { _onChangeText(e, "email") }}
                />
              </div>

              {/* captcha */}
              <div className="flex w-full h-max">
                {/* captcha-input */}
                <div className="w-1/2 h-[3.7rem]">
                  <label>Captcha</label>
                  <input
                    className="w-5/6 h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập captcha"
                  />
                </div>

                {/* captcha-show */}
                <div className="
                flex w-1/2 h-[3.7rem] border-2 border-primary rounded-sm
                text-primary text-2xl justify-center items-center font-semibold
              ">
                  12345678
                </div>
              </div>

              {/* submit */}
              <div className="relative flex flex-col w-full h-[4rem]">
                <div className={`absolute left-0 bottom-2 w-max h-max ${isLoading === true ? "flex" : "hidden"}`}>
                  <Oval
                    height={32}
                    width={32}
                    color="#00B1FF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#38bdf8"
                    strokeWidth={3}
                    strokeWidthSecondary={3}
                  />
                </div>
                <button className="
                absolute right-0 bottom-2 w-max h-max
                text-white bg-button p-2
                flex justify-center items-center rounded-md
                hover:bg-sky-400
              "
                  disabled={isLoading}
                  onClick={_onClickSubmit}>
                  Gửi thông tin
                </button>
              </div>
            </div>
          </div>

          {/* version */}
          <div className="
          flex divide-x-0 lg:justify-end justify-between
          items-center
          w-full h-[2.5rem] bottom-0 absolute
          text-primary
        ">
            <div className="lg:hidden flex ml-2">
              Bản quyền thuộc về LACOTECH -  © 2023 LACOGROUP
            </div>

            <div className="mr-2">
              Phiên bản 5.0.0.1
            </div>
          </div>

          {/* logo */}
          <div className="
          lg:hidden flex
          w-full h-[7rem]
          absolute top-0
          flex-col justify-center items-center
          divide-y text-white bg-primary font-semibold
          divide-white
        ">
            <p className="text-xl">ISOPRO5</p>
            {/* <p>UBND PHUONG XA</p> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPasswordUI;
