import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ONLOAD_USERLIST } from "../../../redux/reducers/settingReducer/_index";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import LoaderSpinner from "../../loader-spinner/_index";
import { takePermission } from "../../../funcs";

import SubModal from "../_subModal/_index";

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

import { onTouchForm } from "../../../funcs/onTochForm";

import {
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  GROUP_READ_ALL_DROPDOWN,
  USER_CREATE,
  USER_READ_ALL
} from "../../../apis/_index";

import { toast } from "react-toastify";

import AddIcon from '@mui/icons-material/Add';
import { Guid } from "guid-typescript";

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import LevelComponent from "../../organizations/levels/levelComponent";
import OrganizationRepository from "../../../repositories/organizations/organizationRepository";
import ServiceUtils from "../../../utils/services";

import '../../../styles/createListOfEmployees/index.css';

const CreateListOfEmployee = (props: any) => {

  const handleClose = props.handleClose;
  const dispatch = useDispatch();

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onClickChoosePermission = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  // state
  const [id, setId] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isActiveOffice, setIsActiveOffice] = useState<boolean>(false);

  const [selectedBoPhan, setSelectedBoPhan] = useState<any>(0);
  const [selectedChucvu, setSelectedChucvu] = useState<any>(0);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(0);

  const [selectedNhomquyen, setSelectedNhomquyen] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [overloadType, setOverloadType] = useState<number>(1)
  const [overloadFree, setOverloadFree] = useState<number>(90)
  const [overloadBusy, setOverloadBusy] = useState<number>(100)
  const [overloadFull, setOverloadFull] = useState<number>(100);

  const [userPositions, setUserPositions] = useState<any>([]);

  // funcs
  const _onChangeOverload = (e: any, type: string) => {
    e.preventDefault()
    switch (type) {
      case "overloadFree":
        setOverloadFree(parseInt(e.target.value))
        break;
      case "overloadBusy":
        setOverloadBusy(parseInt(e.target.value))
        break;
      case "overloadFull":
        setOverloadFull(parseInt(e.target.value))
        break;
      default:
        break;
    }
  }

  const _onChangeOverloadType = () => {
    switch (overloadType) {
      case 0:
        setOverloadType(1);
        break;
      case 1:
        setOverloadType(0);
        break;
    }
  }

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");

    if (userPositions.length <= 0) {
      return;
    }

    if ((userPositions.length > 0 && userPositions.find((p: any) => !p.positionId || !p.departmentId))) {
      return;
    }

    const body: any = {
      UserName: id,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      FullName: name,
      Address: address,
      PhoneNumber: phoneNumber,
      // BoPhan: selectedBoPhan,
      BoPhan: 0,
      // ChucVu: selectedChucvu,
      // PhongBan: selectedPhongban,
      IsActive: isActive,
      IsActiveOffice: isActiveOffice,
      GroupsIDs: selectedNhomquyen,
      OverloadType: overloadType,
      UserPositions: userPositions.map((p: any) => {
        return {
          ChucVu: p.positionId,
          PhongBan: p.departmentId
        };
      })
      // OverloadFree: overloadFree,
      // OverloadBusy: overloadBusy,
      // OverloadFull: overloadFull,
    }

    try {
      setIsLoading(true);
      await ServiceUtils.postV2(
        USER_CREATE,
        body,
        {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json"
          }
        }
      )
        .then((res: any) => {
          if (res.data.StatusCode === 200) {
            const takeData = async () => {
              ServiceUtils.getV2(
                USER_READ_ALL,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  if (res.status === 200) {
                    dispatch(ONLOAD_USERLIST(res.data.Data))
                    setIsLoading(false);
                    toast.success("Tạo mới cán bộ thành công.")
                    if (handleClose) {
                      handleClose()
                    }
                  } else {
                    setIsLoading(false)
                  }
                })
            }

            takeData();
          } else {
            toast.error(res.data.Data.jsonData.Message);
            setIsLoading(false)
          }
        })
    } catch (error: any) {
      toast.error("Tạo mới thất bại.");
      setIsLoading(false)
    }
  }

  const _onChangeIsActive = () => {
    setIsActive((value: any) => !value);
  }
  const _onChangeIsActiveOffice = () => {
    setIsActiveOffice((value: any) => !value);
  }

  const _onChangeSelectedBophan = (e: any) => {
    e.preventDefault();
    setSelectedBoPhan(e.target.value);
  }

  const _onChangeSelectedChucvu = (e: any) => {
    e.preventDefault();
    setSelectedChucvu(e.target.value);
  }

  const _onChangeSelectedPhongban = (e: any) => {
    e.preventDefault();
    setSelectedPhongban(e.target.value);
  }

  const _onChangeSelectedNhomquyen = (e: any) => {
    if (selectedNhomquyen?.includes(e.target.value) === false) {
      setSelectedNhomquyen((oldValue: any) => [...oldValue, e.target.value]);
    } else {
      setSelectedNhomquyen((oldValue: any) => oldValue?.filter((index: any) => index !== e.target.value));
    }
  }

  const _onChangeId = (e: any) => {
    e.preventDefault();
    setId(e.target.value);
  }

  const _onChangeName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  }

  const _onChangeEmail = (e: any) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  const _onChangePhoneNumber = (e: any) => {
    e.preventDefault();
    setPhoneNumber(e.target.value);
  }

  const _onChangeAddress = (e: any) => {
    e.preventDefault();
    setAddress(e.target.value);
  }

  const _onChangePassword = (e: any) => {
    e.preventDefault();
    setPassword(e.target.value);
  }

  const _onChangeConfirmPassword = (e: any) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  }

  const shouldLog1 = useRef(true);

  const [bophanList, setBophanList] = useState<any>(undefined);
  const [chucvuList, setChucvuList] = useState<any>(undefined);
  const [phongbanList, setPhongbanList] = useState<any>(undefined);
  const [nhomquyenList, setNhomquyenList] = useState<any>(undefined);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const API_BOPHAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_BOPHAN";
    const API_CHUCVU = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_CHUCVU"
    const API_PHONGBAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

    try {
      if (shouldLog1.current) {
        shouldLog1.current = false;

        const takeBophan = async () => {
          await ServiceUtils.getV2(
            API_BOPHAN,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setBophanList(res.data.Data);
              }
            })
        }

        const takeChucvu = async () => {
          await ServiceUtils.getV2(
            API_CHUCVU,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setChucvuList(res.data.Data);
              }
            })
        }

        const takePhongban = async () => {


          // await ServiceUtils.getV2(
          //   API_PHONGBAN,
          //   {
          //     headers: {
          //       
          //       Accept: "text/plain"
          //     }
          //   }
          // )
          //   .then((res: any) => {
          //     if (res.status === 200) {
          //       const organizations = OrganizationUtils.getListTree((res.data || {}).Data || [], {
          //         id: 'ParameterId',
          //         parentId: 'RefId'
          //       });

          //       setPhongbanList(organizations);
          //     }
          //   })

          OrganizationRepository.getListDropdown().then((res: any) => {
            if (res.status) {
              const data = (res.data || {}).organizations || [];
              
              const organizationTrees = OrganizationUtils.getListTree(data, {
                id: 'ParameterId',
                parentId: 'RefId'
              }, true);
              
              console.log(organizationTrees);

              setPhongbanList(organizationTrees);
            }
          });
        }

        const takeNhomquyen = async () => {
          await ServiceUtils.getV2(
            GROUP_READ_ALL_DROPDOWN,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setNhomquyenList(res.data.Data);
              }
            })
        }

        takeBophan();
        takeChucvu();
        takePhongban();
        takeNhomquyen();
      }
    } catch (error: any) {
      toast.error("Tải dữ liệu thất bại")
    }
  })

  // funcs
  const _onClickTempClosing = () => {
    /**
     * 
     * id
     * name
     * password
     * confirmPassword
     * email
     * phoneNumber
     * address
     * selectedBoPhan
     * selectedChucVu
     * selectedPhongBan
     * nhomquyenList
     * selectedNhomQuyen
     * overloadFree
     * overloadBusy
     * overloadFull
     * isActive
     * 
     * **/

    const body = {
      id,
      name,
      password,
      confirmPassword,
      email,
      phoneNumber,
      address,
      selectedBoPhan,
      selectedChucvu,
      selectedPhongban,
      nhomquyenList,
      selectedNhomquyen,
      overloadFree,
      overloadBusy,
      overloadFull,
    }

    localStorage.setItem("tempEmployeeCreate", JSON.stringify(body))
    handleClose()
  }

  // Take Caching
  useEffect(() => {
    const tempEmployeeCreate = localStorage.getItem("tempEmployeeCreate")

    if (tempEmployeeCreate === null || tempEmployeeCreate === undefined) {

    } else {
      const tempObject = JSON.parse(tempEmployeeCreate)

      setId(tempObject?.id)
      setName(tempObject?.name)
      setPassword(tempObject?.password)
      setConfirmPassword(tempObject?.confirmPassword)
      setEmail(tempObject?.email)
      setPhoneNumber(tempObject?.phoneNumber)
      setAddress(tempObject?.address)
      setSelectedBoPhan(tempObject?.selectedBoPhan)
      setSelectedChucvu(tempObject?.selectedChucVu)
      setSelectedPhongban(tempObject?.selectedPhongBan)
      setNhomquyenList(tempObject?.nhomquyenList)
      setSelectedNhomquyen(tempObject?.selectedNhomQuyen)
      setOverloadFree(tempObject?.overloadFree)
      setOverloadBusy(tempObject?.overloadBusy)
      setOverloadFull(tempObject?.overloadFull)
    }
  }, []);

  const updatePermission = () => {

  }

  const onAddPermission = () => {
    const _userPositions = [...userPositions];

    _userPositions.push({
      id: Guid.create().toString(),
      positionId: '',
      departmentId: ''
    });

    setUserPositions(_userPositions);
  }

  const onDeletePermission = (item: any) => () => {
    let _userPositions = [...userPositions];

    _userPositions = _userPositions.filter((p: any) => p.id != item.id);

    setUserPositions(_userPositions);
  }

  const _onChangeSelectedChucvuItem = (e: any, item: any) => {
    const value = e.target.value;

    const _userPositions = [...userPositions];

    const permission = _userPositions.find((p: any) => p.id == item.id);

    if (permission) {
      permission.positionId = value;

      setUserPositions(_userPositions);
    }
  }

  const _onChangeSelectedPhongBanItem = (e: any, item: any) => {
    const value = e.target.value;

    const _userPositions = [...userPositions];

    const permission = _userPositions.find((p: any) => p.id == item.id);

    if (permission) {
      permission.departmentId = value;

      setUserPositions(_userPositions);
    }
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[50rem] w-screen h-[35rem]
    rounded-md
  ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}

        nhomquyenList={nhomquyenList}
        selectedNhomquyen={selectedNhomquyen}
        _onChangeSelectedNhomquyen={_onChangeSelectedNhomquyen}
      />

      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Tạo mới cán bộ</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={_onClickTempClosing}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {
        isLoading === false ?
          <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            {/* lines */}
            <div className="flex w-full h-max core-control-group">
              <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
                <label>Mã số cán bộ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={id}
                  onChange={(e: any) => { _onChangeId(e) }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
                <label>Tên cán bộ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={name}
                  onChange={(e: any) => { _onChangeName(e) }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max core-control-group">
              <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
                <label>Mật khẩu</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={password}
                  onChange={(e: any) => { _onChangePassword(e) }}
                  type="password"
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
                <label>Xác nhận mật khẩu</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={confirmPassword}
                  onChange={(e: any) => { _onChangeConfirmPassword(e) }}
                  type="password"
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max core-control-group">
              <div className="flex flex-col lg:w-1/3 w-full lg:pr-2 pr-0 h-max">
                <label>Email</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={email}
                  onChange={(e: any) => { _onChangeEmail(e) }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-2/3 w-full lg:pl-2 pr-0 h-max">
                <label>Điện thoại</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={phoneNumber}
                  onChange={(e: any) => { _onChangePhoneNumber(e) }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full h-max">
                <label>Địa chỉ liên hệ</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={address}
                  onChange={(e: any) => { _onChangeAddress(e) }}
                />
              </div>

            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }} className="core-control-group">
              {/* <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label>Bộ phận</label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={selectedBoPhan}
                  onChange={(e: any) => { _onChangeSelectedBophan(e) }}
                >
                  <option className="text-gray-300" value={0} disabled>--- Chọn bộ phận ---</option>
                  {bophanList === undefined ? ""
                    : bophanList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                    ))
                  }
                </select>
              </div> */}
              <div className="flex flex-col lg:w-1/4 w-full lg:pl-2 pr-0 h-max">
                <label>Nhóm quyền hạn</label>
                <button onClick={() => _onClickChoosePermission("choosePermission")} className="pl-1.5 pr-1.5 bg-green-400 hover:bg-green-300 mt-1 rounded-md w-max h-max">chọn</button>
              </div>
            </div>
            {userPositions.map((item: any, index: any) => {
              return <div key={`permissison-${index}`} className="flex lg:flex-row flex-col w-full h-max">
                <div className="flex flex-col lg:w-1/2 w-full lg:pr-2 pr-0 h-max core-create-list-of-employee-role-list-item-position">
                  <label>Chức vụ</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={item.positionId}
                    onChange={(e: any) => { _onChangeSelectedChucvuItem(e, item) }}
                  >
                    <option className="text-gray-300" value={0}>--- Chọn danh mục ---</option>
                    {chucvuList === undefined ? ""
                      : chucvuList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="flex flex-col lg:w-1/2 w-full lg:pr-2 pr-0 h-max core-create-list-of-employee-role-list-item-organization">
                  <label>Phòng ban</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={item.departmentId}
                    onChange={(e: any) => { _onChangeSelectedPhongBanItem(e, item) }}
                  >
                    <option className="text-gray-300" value={0}>--- Chọn phòng ban ---</option>
                    {phongbanList === undefined ? ""
                      : phongbanList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}><LevelComponent item={item} />{item.ParameterValue}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="core-create-list-of-employee-role-list-item-button-remove">
                  <button style={{
           
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={onDeletePermission(item)}>
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              </div>
            })}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0px 10px'
            }}>
              <button className="flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200" onClick={onAddPermission} style={{
                  backgroundColor: '#4ADE80',
                  // width: '24px',
                  // height: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // borderRadius: '50%',
                  color: '#ffffff'
                }}>
                <AddIcon fontSize="small" />
                Thêm chức vụ và phòng ban
              </button>
            </div>
            <div className="flex flex-row w-full h-max space-x-4">
              <p className="font-bold text-primary">Tạo việc toàn cơ quan</p>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={isActiveOffice}
                onChange={_onChangeIsActiveOffice}
                disabled={isLoading}
              />
            </div>

            {/* <div className="flex lg:flex-row flex-col w-full h-max">
              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-green-500">Có thể nhiều việc (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-green-500"
                  type="number"
                  value={overloadFree}
                  onChange={(e: any) => _onChangeOverload(e, "overloadFree")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-yellow-500">Nhiều việc (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-yellow-500"
                  type="number"
                  value={overloadBusy}
                  onChange={(e: any) => _onChangeOverload(e, "overloadBusy")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col lg:w-1/4 w-full lg:pr-2 pr-0 h-max">
                <label className="text-red-500">Quá tải (% số ngày)</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-red-500"
                  type="number"
                  value={overloadFull}
                  onChange={(e: any) => _onChangeOverload(e, "overloadFull")}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div> */}

            {/* <div className="flex w-full h-max">
              <fieldset className="flex flex-col w-full h-max"
                onChange={_onChangeOverloadType}
              > */}
            {/* <div className="flex items-center space-x-3">
                  <input type="radio" id="1" name="fav_language" value={0} checked={overloadType === 0 ? true : false} placeholder="" />
                  <p>Quá tải = Số đầu việc đang xử lý</p>
                </div> */}
            {/* <div className="flex items-center space-x-3">
                  <input type="radio" id="1" name="fav_language" value={1} checked={overloadType === 1 ? true : false} placeholder="" />
                  <p>Quá tải = Số ngày việc đã được giao trong tháng</p>
                </div>
              </fieldset>
            </div> */}
          </div>
          :
          <div className="relative flex flex-col justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            <LoaderSpinner
              w={36}
              h={36}
            />
            <p className="text-primary">Đang tạo mới cán bộ</p>
          </div>
      }

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="absolute left-4 bottom-3 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max core-create-list-of-employee-use-system">
          <p className="font-bold text-primary">Sử dụng phần mềm</p>
          <input
            type="checkbox"
            className="w-5 h-5"
            checked={isActive}
            onChange={_onChangeIsActive}
            disabled={isLoading}
          />
        </div>

        <div className="flex w-max h-max space-x-3">
          <button className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${id === ""
              || name === ""
              || email === ""
              || password === ""
              || confirmPassword === ""
              || phoneNumber === ""
              || address === ""
              // || selectedBoPhan === 0
              // || selectedChucvu === 0
              // || selectedPhongban === 0
              || userPositions.length <= 0
              || (userPositions.length > 0 && userPositions.find((p: any) => !p.positionId || !p.departmentId))
              || isLoading === true
              ? "bg-slate-300"
              : "bg-primary hover:bg-sky-400"
            }
        `}
            disabled={
              id === ""
              || name === ""
              || email === ""
              || password === ""
              || confirmPassword === ""
              || phoneNumber === ""
              || address === ""
              // || selectedBoPhan === 0
              // || selectedChucvu === 0
              // || selectedPhongban === 0
              || userPositions.length <= 0
              || (userPositions.length > 0 && userPositions.find((p: any) => !p.positionId || !p.departmentId))
              || isLoading === true
            }
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Tạo mới</p>
          </button>

          <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200 core-create-list-of-employee-button-close
        "
            disabled={isLoading}
            onClick={_onClickTempClosing}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateListOfEmployee;
