import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalController } from "../../ui/homeUI/_index";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CloseIcon from '@mui/icons-material/Close';

import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import {
  CONFIG_LOADTASKREJECT,
  REPORT_TASK_TOTAL_LIST,
} from "../../apis/_index";
import LoaderSpinner from "../loader-spinner/_index";
import ServiceUtils from "../../utils/services";

const ViewReport = (props: any) => {
  // state submodal
  const context = useContext(ModalController);
  const shouldLog = useRef(true);

  const monthSearch = context.dataModal.monthSearch;
  const yearSearch = context.dataModal.yearSearch;

  const [staData, setStaData] = useState<any[] | null>(null);

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  const handleClose = props.handleClose;
  const taskId = props.taskId;

  const [taskStatus, setloadtaskreject] = useState<any>(null);
  
  //
  const _onLoadData = async (
    searchMonth: number | undefined,
    searchYear: number,
    searchPhongBan: string | number,
    userId: string | number
  ) => {
    // const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      SEARCH_MONTH: searchMonth,
      SEARCH_YEAR: searchYear,
      SERACH_PHONGBAN: searchPhongBan,
      USER_ID: userId,
    };

    try {
      await ServiceUtils.postV2(REPORT_TASK_TOTAL_LIST, body, {
          headers: {
            Accept: "text/plain",
          },
        })
        .then((res:any) => {
          if (res.data.StatusCode === 200) {
            if (res.data.Data.data === null) {
              setStaData([]);
            } else {
              setStaData(res.data.Data.data);
            }
          }
        });
    } catch (error) {}
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      _onLoadData(monthSearch, yearSearch, userData.PhongBan, userData.Id);
    }
  }, []);

  // async function fetchloadtaskreject() {
  //   const accessToken = localStorage.getItem("accessToken");
  //   try {
  //     await axios
  //       .get(CONFIG_LOADTASKREJECT + taskId, {
  //         headers: {
  //           Authorization: "Bearer " + accessToken,
  //           Accept: "text/plain",
  //         },
  //       })
  //       .then((res) => {
  //         if (res?.data?.StatusCode === 200) {
  //           setData(res?.data?.Data);
  //         } else {
  //           toast.error(res?.data?.Message || "Tải dữ liệu thất bại");
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Tải dữ liệu thất bại");
  //   }
  // }

  const tinhwidth = (maxWidth: any, currentWidth: any) => {
    if (maxWidth <= 0) {
      return 10;
    } else {
      return (currentWidth / maxWidth) * 100;
    }
  };

  const _onClickReportTasking = (
    type: string,
    userId: string,
    dataModal: any
  ) => {
    switch (type) {
      case "TotalListAll":
        context.setFuncs("TotalListAll");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListNew":
        context.setFuncs("TotalListNew");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListNew_Support":
        context.setFuncs("TotalListNew_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListReveced":
        context.setFuncs("TotalListReveced");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListReveced_Support":
        context.setFuncs("TotalListReveced_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListProccessing":
        context.setFuncs("TotalListProccessing");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;
      case "TotalListProccessing_Support":
        context.setFuncs("TotalListProccessing_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompletedWaiting":
        context.setFuncs("TotalListCompletedWaiting");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompleted":
        context.setFuncs("TotalListCompleted");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      case "TotalListCompleted_Support":
        context.setFuncs("TotalListCompleted_Support");
        context.setDataModal(dataModal);
        context.setUserId(userId);
        context.handleOpen();
        break;

      default:
        break;
    }
  };

  console.log(staData);

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[50rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <AssessmentIcon />
          <p>Thống kê</p>
        </div>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {staData === null ? (
        <div className="tableFixHead styled-scrollbars styled-scrollbars h-full overflow-hidden p-4 text-sm"></div>
      ) : (
        <div className="tableFixHead styled-scrollbars styled-scrollbars h-full overflow-hidden p-4 text-[16px]">
          {staData?.map((item, index) => (
            <>
              <div
                key={index}
                className="w-full lg:h-max flex flex-col border-b-2 border-slate-300 space-y-2 pb-10"
              >
                <div className="w-full h-max flex lg:justify-between lg:flex-row flex-col">
                  <p className="w-max lg:text-xl text-lg font-semibold space-y-3 pb-3">
                    Họ Tên:{" "}
                    <span className="text-primary"> {item.TenUser} </span>
                  </p>

                  {/* <button
                        className="
                                w-max h-max lg:p-2 p-1 flex rounded-md bg-primary hover:bg-cyan-400 text-white font-semibold
                                shadow-md
                              "
                        onClick={() =>
                          _onClickCompareStaff(
                            item?.Userid,
                            item?.IDPHONGBAN.toString()
                          )
                        }
                      >
                        <p>So sánh</p>
                      </button> */}
                </div>
                <p className="w-full text-md font-semibold ">
                  Chức vụ:{" "}
                  <span className="text-primary"> {item.TenChucVu} </span>{" "}
                  <br />
                  Bộ phận:{" "}
                  <span className="text-primary"> {item.TenBoPhan} </span>{" "}
                  <br />
                  Phòng ban:{" "}
                  <span className="text-primary"> {item.TenPhongBan} </span>
                </p>

                <div className="flex w-full h-max space-y-2">
                  {/* Fields */}
                  <div className="w-full h-full justify-center items-center">
                    {/* Total task */}
                    <div className="w-full h-max">
                      <div className="w-full h-max flex justify-center items-center">
                        <p className="text-2xl text-center font-semibold text-sky-600 space-y-3 pb-3">
                          Thống kê số lượng công việc
                        </p>
                      </div>

                      {/* Top fields */}

                      <div className="flex flex-col w-full h-max p-5 space-y-5">
                        {/* Elements */}
                        <div className="w-full h-max flex flex-col">
                          <p className="font-bold">
                            Tổng hợp công việc:
                            <span className="text-yellow-600 font-bold">
                              {item.TotalListAll}
                            </span>
                          </p>

                          {/* <div style={{
                                              width: `${tinhwidth(item.TotalListAll, item.TotalListAll)}%`,
                                              color: "black"
                                            }}
                                              className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                                            >
                                              <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-yellow-300 to-yellow-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListAll}</div>
                                              <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListAll}</p>
                                            </div> */}
                          <div className="w-full h-max flex flex-col space-y-1 mt-2 text-[15px]">
                            <div className="w-full h-max flex flex-col text-[15px]">
                              <p>Thực hiện chính</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListResponsible
                                  )}%`,
                                  color: "black",
                                }}
                                className={`p-2 h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                                // onClick={() => _onClickReportTasking(
                                //   "TotalListReveced",
                                //   item.Userid,
                                //   {
                                //     countListReveced: item.countListReveced,
                                //     countListWorking: item.countListWorking
                                //   })}
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListResponsible}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListResponsible}
                                </p>
                              </button>
                            </div>

                            <div className="w-full h-max flex flex-col">
                              <p>Hỗ trợ</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListResponsible_SUP
                                  )}%`,
                                  color: "black",
                                }}
                                className={`p-2 h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                                // onClick={() => _onClickReportTasking(
                                //   "TotalListReveced",
                                //   item.Userid,
                                //   {
                                //     countListReveced: item.countListReveced,
                                //     countListWorking: item.countListWorking
                                //   })}
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListResponsible_SUP}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListResponsible_SUP}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-max flex flex-col text-[15px]">
                          <p className="font-bold">
                            Tổng việc mới được giao:{" "}
                            <span className="text-green-600 font-bold">
                              {item.TotalListNew + item.TotalListNew_SUP}
                            </span>
                          </p>

                          {/* Main of Elemnets */}
                          <div className="w-full h-max flex flex-col space-y-1 mt-2">
                            <div className="w-full h-max flex flex-col">
                              <p>Thực hiện chính</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListNew
                                  )}%`,
                                  color: "black",
                                }}
                                className={`p-2 h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListNew",
                                    item.Userid,
                                    {
                                      countListReveced: item.countListReveced,
                                      countListWorking: item.countListWorking,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListNew}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListNew}
                                </p>
                              </button>
                            </div>

                            <div className="w-full h-max flex flex-col">
                              <p>Hỗ trợ</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListNew_SUP
                                  )}%`,
                                  color: "black",
                                }}
                                className={`p-2 h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListNew_Support",
                                    item.Userid,
                                    {
                                      countListReveced: item.countListReveced,
                                      countListWorking: item.countListWorking,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListNew_SUP}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListNew_SUP}
                                </p>
                              </button>
                            </div>
                          </div>

                          {/* <button style={{
                                              width: `${tinhwidth(item.TotalListAll, item.TotalListNew)}%`,
                                              color: "black"
                                            }}
                                              className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                                              onClick={() => _onClickReportTasking(
                                                "TotalListNew",
                                                item.Userid,
                                                {
                                                  countListPunctualCompleted: item.countListPunctualCompleted,
                                                  countListEarlyComplete: item.countListEarlyComplete,
                                                  countListLateComplete: item.countListLateComplete,
                                                  countListCompleted: item.countListCompleted,
                                                  countListCompletedWaiting: item.countListCompletedWaiting
                                                })}
                                            >
                                              <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListNew}</div>
                                              <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListNew}</p>
                                            </button> */}
                        </div>

                        <div className="w-full h-max flex flex-col">
                          <p className="font-bold">
                            Tổng việc chưa xử lý:{" "}
                            <span className="text-orange-600 font-bold">
                              {item.TotalListReveced +
                                item.TotalListReveced_SUP}
                            </span>
                          </p>

                          {/* Main of Elemnets */}
                          <div className="w-full h-max flex flex-col space-y-1 mt-2 text-[15px]">
                            <div className="w-full h-max flex flex-col">
                              <p>Thực hiện chính</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListReveced
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListReveced",
                                    item.Userid,
                                    {
                                      countListReveced: item.countListReveced,
                                      countListWorking: item.countListWorking,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListReveced}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListReveced}
                                </p>
                              </button>
                            </div>

                            <div className="w-full h-max flex flex-col">
                              <p>Hỗ trợ</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListReveced_SUP
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListReveced_Support",
                                    item.Userid,
                                    {
                                      countListReveced: item.countListReveced,
                                      countListWorking: item.countListWorking,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListReveced_SUP}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListReveced_SUP}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-max flex flex-col">
                          <p className="font-bold">
                            Tổng việc đang xử lý:{" "}
                            <span className="text-purple-600 font-bold">
                              {item.TotalListProccessing +
                                item.TotalListProccessing_SUP}
                            </span>
                          </p>

                          {/* Main of Elemnets */}
                          <div className="w-full h-max flex flex-col space-y-1 mt-2 text-[15px]">
                            <div className="w-full h-max flex flex-col">
                              <p>Thực hiện chính</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListProccessing
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListProccessing",
                                    item.Userid,
                                    {
                                      countListProccessing:
                                        item.countListProccessing,
                                      countListLate: item.countListLate,
                                      countListReturnWork:
                                        item.countListReturnWork,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListProccessing}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListProccessing}
                                </p>
                              </button>
                            </div>

                            <div className="w-full h-max flex flex-col">
                              <p>Hỗ trợ</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListProccessing_SUP
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListProccessing_Support",
                                    item.Userid,
                                    {
                                      countListProccessing:
                                        item.ListProccessing_SUP,
                                      countListLate: item.ListLate_SUP,
                                      countListReturnWork:
                                        item.ListReturnWork_SUP,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListProccessing_SUP}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListProccessing_SUP}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-max flex flex-col">
                          <p className="font-bold">
                            Tổng việc hoàn tất:{" "}
                            <span className="text-rose-600 font-bold">
                              {item.TotalListCompleted +
                                item.TotalListCompleted_SUP}
                            </span>
                          </p>

                          {/* <button style={{
                                          width: `${tinhwidth(item.TotalListAll, item.TotalListCompleted)}%`,
                                          color: "black"
                                        }}
                                          className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 shadow-lg text-center text-white rounded-r-lg  justify-center items-center  `}

                                          onClick={() => _onClickReportTasking(
                                            "TotalListCompleted",
                                            item.Userid,
                                            {
                                              countListEarlyComplete: item.countListEarlyComplete,
                                              countListPunctualCompleted: item.countListPunctualCompleted,
                                              countListLateComplete: item.countListLateComplete
                                            })}
                                        >

                                          <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 shadow-lg opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListCompleted}</div>
                                          <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListCompleted}</p>
                                        </button> */}

                          <div className="w-full h-max flex flex-col space-y-1 mt-2 text-[15px]">
                            <div className="w-full h-max flex flex-col">
                              <p>Thực hiện chính</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListCompleted
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 text-center text-white rounded-r-lg  justify-center items-center  `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListCompleted",
                                    item.Userid,
                                    {
                                      countListEarlyComplete:
                                        item.countListEarlyComplete,
                                      countListPunctualCompleted:
                                        item.countListPunctualCompleted,
                                      countListLateComplete:
                                        item.countListLateComplete,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListCompleted}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListCompleted}
                                </p>
                              </button>
                            </div>

                            <div className="w-full h-max flex flex-col text-[15px]">
                              <p>Hỗ trợ</p>
                              <button
                                style={{
                                  width: `${tinhwidth(
                                    item.TotalListAll,
                                    item.TotalListCompleted_SUP
                                  )}%`,
                                  color: "black",
                                }}
                                className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 text-center text-white rounded-r-lg  justify-center items-center  `}
                                onClick={() =>
                                  _onClickReportTasking(
                                    "TotalListCompleted_Support",
                                    item.Userid,
                                    {
                                      countListEarlyComplete:
                                        item.ListEarlyComplete_SUP,
                                      countListPunctualCompleted:
                                        item.ListPunctualCompleted_SUP,
                                      countListLateComplete:
                                        item.ListLateComplete_SUP,
                                    }
                                  )
                                }
                              >
                                <div
                                  className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                >
                                  {item.TotalListCompleted_SUP}
                                </div>
                                <p className="w-[0%] text-center font-bold text-semibold">
                                  {item.TotalListCompleted_SUP}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* TotalTask delivery */}

                    {item?.useradd ? (
                      <div className="w-full h-max">
                        <p className="text-2xl text-center font-semibold text-rose-600 space-y-3 pb-3">
                          Thống kê công việc đã giao
                        </p>
                        {/* Top fields */}

                        <div className="flex flex-col w-full h-max p-5 space-y-5">
                          {/* Elements */}
                          <div className="w-full h-max flex flex-col">
                            <p className="font-bold">
                              Tổng hợp công việc đã giao
                            </p>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  item.useradd.TotalListAll_UserAdd,
                                  item.useradd.TotalListAll_UserAdd
                                )}%`,
                                color: "black",
                              }}
                              className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-yellow-300 to-yellow-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                              >
                                {item.useradd.TotalListAll_UserAdd}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.useradd.TotalListAll_UserAdd}
                              </p>
                            </div>
                          </div>

                          <div className="w-full h-max flex flex-col">
                            <p className="font-bold">Công việc mới giao</p>

                            <button
                              style={{
                                width: `${tinhwidth(
                                  item.useradd.TotalListAll_UserAdd,
                                  item.useradd.TotalListNew_UserAdd
                                )}%`,
                                color: "black",
                              }}
                              className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                              onClick={() =>
                                _onClickReportTasking(
                                  "TotalListNew",
                                  item.Userid,
                                  {
                                    countListPunctualCompleted:
                                      item.countListPunctualCompleted,
                                    countListEarlyComplete:
                                      item.countListEarlyComplete,
                                    countListLateComplete:
                                      item.countListLateComplete,
                                    countListCompleted: item.countListCompleted,
                                    countListCompletedWaiting:
                                      item.countListCompletedWaiting,
                                  }
                                )
                              }
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                              hover:opacity-100 hover:w-full"
                              >
                                {item.useradd.TotalListNew_UserAdd}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.useradd.TotalListNew_UserAdd}
                              </p>
                            </button>
                          </div>

                          <div className="w-full h-max flex flex-col">
                            <p className="font-bold">
                              Công việc đã giao đang xử lý
                            </p>

                            {/* Main of Elemnets */}
                            <div className="w-full h-max flex flex-col space-y-1 mt-2">
                              <div className="w-full h-max flex flex-col text-xs">
                                <button
                                  style={{
                                    width: `${tinhwidth(
                                      item.useradd.TotalListAll_UserAdd,
                                      item.useradd.TotalListProccessing_UserAdd
                                    )}%`,
                                    color: "black",
                                  }}
                                  className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                  onClick={() =>
                                    _onClickReportTasking(
                                      "TotalListReveced",
                                      item.Userid,
                                      {
                                        countListReveced: item.countListReveced,
                                        countListWorking: item.countListWorking,
                                      }
                                    )
                                  }
                                >
                                  <div
                                    className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                  >
                                    {item.useradd.TotalListProccessing_UserAdd}
                                  </div>
                                  <p className="w-[0%] text-center font-bold text-semibold">
                                    {item.useradd.TotalListProccessing_UserAdd}
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="w-full h-max flex flex-col">
                            <p className="font-bold">
                              Công việc đã giao hoàn tất
                            </p>

                            {/* Main of Elemnets */}
                            <div className="w-full h-max flex flex-col space-y-1 mt-2">
                              <div className="w-full h-max flex flex-col text-xs">
                                <button
                                  style={{
                                    width: `${tinhwidth(
                                      item.useradd.TotalListAll_UserAdd,
                                      item.useradd.TotalListCompleted_UserAdd
                                    )}%`,
                                    color: "black",
                                  }}
                                  className={`h-[1.5rem] p-2 hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}
                                  onClick={() =>
                                    _onClickReportTasking(
                                      "TotalListProccessing",
                                      item.Userid,
                                      {
                                        countListProccessing:
                                          item.countListProccessing,
                                        countListLate: item.countListLate,
                                        countListReturnWork:
                                          item.countListReturnWork,
                                      }
                                    )
                                  }
                                >
                                  <div
                                    className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full"
                                  >
                                    {item.useradd.TotalListCompleted_UserAdd}
                                  </div>
                                  <p className="w-[0%] text-center font-bold text-semibold">
                                    {item.useradd.TotalListCompleted_UserAdd}
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* Botom left feld */}
                    <div className="lg:flex hidden flex-col-2 w-full h-max pt-5">
                      <p className="w-1/2 text-2xl text-center font-semibold text-yellow-600 space-y-3 pb-3">
                        Kết quả
                      </p>
                      <p className="w-1/2 text-2xl text-center font-semibold text-green-600 space-y-3 pb-3">
                        Đánh giá năng lực
                      </p>
                    </div>

                    <div className="flex lg:flex-row flex-col w-full h-max pb-5 space-y-5">
                      {/* Thuc hien chinh */}
                      <div className="lg:w-1/2 w-full flex flex-col h-max pt-5 pb-5 items-center justify-center space-y-7">
                        {/* Title */}
                        <p
                          className="
                                          w-full text-2xl text-center font-semibold text-yellow-600 space-y-3 pb-3
                                          lg:hidden flex justify-center items-center
                                        "
                        >
                          Kết quả
                        </p>

                        {/* Content */}
                        {/* Main result */}
                        <div className="w-full h-full flex justify-center items-center">
                          {/* Sub title */}
                          <div className="lg:w-1/5 w-[40%] text-right pr-3 space-y-2">
                            <div className="w-full h-[1.5rem] font-bold mb-3">
                              Thực hiện chính
                            </div>
                            <div className="w-full h-[1.5rem]">Xuất sắc</div>
                            <div className="w-full h-[1.5rem]">Tốt</div>
                            <div className="w-full h-[1.5rem]">Trung bình</div>
                            <div className="w-full h-[1.5rem]">Kém</div>
                          </div>

                          {/* Value */}
                          <div className="lg:w-4/5 w-[60%] space-y-2 pr-[3rem] font-semibold">
                            <div className="mb-8"></div>
                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Recipient_NumberExcellent
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Recipient_NumberExcellent}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Recipient_NumberExcellent}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Recipient_NumberGood
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Recipient_NumberGood}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Recipient_NumberGood}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Recipient_NumberMedium
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Recipient_NumberMedium}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Recipient_NumberMedium}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Recipient_NumberExcellent
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75k justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Recipient_NumberBad}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Recipient_NumberBad}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* Support result */}
                        <div className="w-full h-full flex justify-center items-center">
                          {/* Sub title */}
                          <div className="lg:w-1/5 w-[40%] text-right pr-3 space-y-2">
                            <div className="w-full h-[1.5rem] font-bold mb-3">
                              Hỗ trợ
                            </div>
                            <div className="w-full h-[1.5rem]">Xuất sắc</div>
                            <div className="w-full h-[1.5rem]">Tốt</div>
                            <div className="w-full h-[1.5rem]">Trung bình</div>
                            <div className="w-full h-[1.5rem]">Kém</div>
                          </div>

                          {/* Value */}
                          <div className="lg:w-4/5 w-[60%] space-y-2 pr-[3rem] font-semibold">
                            <div className="mb-8"></div>
                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Supporter_NumberExcellent
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Supporter_NumberExcellent}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Supporter_NumberExcellent}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Supporter_NumberGood
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Supporter_NumberGood}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Supporter_NumberGood}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Supporter_NumberMedium
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Supporter_NumberMedium}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Supporter_NumberMedium}
                              </p>
                            </div>

                            <div
                              style={{
                                width: `${tinhwidth(
                                  100,
                                  item.Supporter_NumberFail
                                )}%`,
                              }}
                              className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75k justify-center items-center "
                            >
                              <div
                                className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full"
                              >
                                {item.Supporter_NumberFail}
                              </div>
                              <p className="w-[0%] text-center font-bold text-semibold">
                                {item.Supporter_NumberFail}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* Attitude */}
                        <div className="w-full h-full flex justify-center items-center">
                          {/* Sub title */}
                        </div>
                      </div>

                      {/* Botom left feld */}
                      <div className="lg:w-1/2 w-full flex flex-col h-max pt-5 pb-5 font-semibold items-center justify-center">
                        <div className="w-full h-max">
                          {/* Title */}
                          <p
                            className="
                                          w-full text-2xl text-right font-semibold text-green-600 space-y-3 pb-3 pr-[5rem]
                                          lg:hidden flex justify-end items-center
                                        "
                          >
                            Đánh giá năng lực
                          </p>

                          {/* Content */}
                          <div className="w-full h-full flex">
                            <div className="w-full h-max flex-col space-y-8 flex p-3">
                              {/* Element */}
                              <div className="w-full h-[1.5rem]">
                                <p>Năng suất lao động</p>
                                <div
                                  style={{
                                    width: `${tinhwidth(100, item.NANGSUAT)}%`,
                                  }}
                                  className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                      transition-all delay-75"
                                >
                                  <div
                                    className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                      hover:opacity-100 hover:w-full"
                                  >
                                    {item?.NANGSUAT ? item?.NANGSUAT : 0}%
                                  </div>
                                  <p className="w-0 h-[1.5rem] font-bold text-semibold">
                                    {item?.NANGSUAT ? item?.NANGSUAT : 0}%
                                  </p>
                                </div>
                              </div>

                              <div className="w-full h-[1.5rem]">
                                <p>Năng lực hoàn thành</p>
                                <div
                                  style={{
                                    width: `${tinhwidth(100, item.NANGLUC)}%`,
                                  }}
                                  className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                      transition-all delay-75"
                                >
                                  <div
                                    className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                      hover:opacity-100 hover:w-full"
                                  >
                                    {item?.NANGLUC ? item?.NANGLUC : 0}%
                                  </div>
                                  <p className="w-0 h-[1.5rem] font-bold text-semibold">
                                    {item?.NANGLUC ? item?.NANGLUC : 0}%
                                  </p>
                                </div>
                              </div>

                              <div className="w-full h-[1.5rem]">
                                <p>Năng suất phối hợp</p>
                                <div
                                  style={{
                                    width: `${tinhwidth(
                                      100,
                                      item.NANGSUATPHOIHOP
                                    )}%`,
                                  }}
                                  className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                                  transition-all delay-75"
                                >
                                  <div
                                    className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                                  hover:opacity-100 hover:w-full"
                                  >
                                    {item?.NANGSUATPHOIHOP
                                      ? item?.NANGSUATPHOIHOP
                                      : 0}
                                    %
                                  </div>
                                  <p className="w-0 h-[1.5rem] font-bold text-semibold">
                                    {item?.NANGSUATPHOIHOP
                                      ? item?.NANGSUATPHOIHOP
                                      : 0}
                                    %
                                  </p>
                                </div>
                              </div>

                              <div className="w-full h-[1.5rem]">
                                <p>Năng lực phối hợp</p>
                                <div
                                  style={{
                                    width: `${tinhwidth(
                                      100,
                                      item.NANGLUCPHOIHOP
                                    )}%`,
                                  }}
                                  className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                                  transition-all delay-75"
                                >
                                  <div
                                    className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                                  hover:opacity-100 hover:w-full"
                                  >
                                    {item?.NANGLUCPHOIHOP
                                      ? item?.NANGLUCPHOIHOP
                                      : 0}
                                    %
                                  </div>
                                  <p className="w-0 h-[1.5rem] font-bold text-semibold">
                                    {item?.NANGLUCPHOIHOP
                                      ? item?.NANGLUCPHOIHOP
                                      : 0}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-full space-y-2 p-6">
                          <p
                            className="
                                              w-full text-2xl text-center font-semibold text-purple-600 space-y-3 pb-3
                                              flex justify-center items-center
                                            "
                          >
                            Thái độ hỗ trợ
                          </p>
                          <div className="w-full h-max flex flex-col space-y-2">
                            <div className="w-full h-[1.5rem]">
                              Nhiệt tình: {item.Supporter_Ardent}
                            </div>
                            <div className="w-full h-[1.5rem]">
                              Không nhiệt tình: {item.Supporter_NoArdent}
                            </div>
                            <div className="w-full h-[1.5rem]">
                              Nhiệt tình không chuyên môn:{" "}
                              {item.Supporter_Ardent_NoExpertise}
                            </div>
                            <div className="w-full h-[1.5rem]">
                              Không nhiệt tình không chuyên môn:{" "}
                              {item.Supporter_Not_Engaged}
                            </div>
                            <div className="w-full h-[1.5rem]">
                              Không tham gia: {item.Supporter_No_Joining}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}

      {/* footer */}
      <div className="flex p-4  p-12-16 footer rounded-b-md justify-end items-center text-sm">
        <div className="flex h-max w-max space-x-4">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-400 text-white rounded-md
          hover:bg-slate-300
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewReport;
