import React, { useEffect, useRef, useState, useContext } from "react";
import parse from 'html-react-parser';
import { ModalController } from "../../../ui/homeUI/_index";

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { HTMLReactParserOptions, Element } from 'html-react-parser';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';
import reportView from "../../../temp/baocao";
import DeleteIcon from '@mui/icons-material/Delete';

import { toast } from "react-toastify";
import axios from "axios";

import { KE_HOACH_SEND_BOSS, KE_HOACH_GUI, BAO_CAO_CONG_VIEC_BAO_CAO, BAO_CAO_CONG_VIEC_CHECK_REPORT, BAO_CAO_CONG_VIEC_CREATE_REPORT } from "../../../apis/_index";
import LoaderSpinner from "../../loader-spinner/_index";
import SendIcon from '@mui/icons-material/Send';

import lacoLogo from "../../../temp/baocao/Asset7.png";
import ReactToPrint from "react-to-print";

import { ReportView } from "../../../temp/baocao/ReportView";

import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";

// report Importing
const CheckPlanning = (props: any) => {

  // Take props
  const handleClose = props.handleClose
  const bodyOfReport = props.bodyOfReport

  const componentRef = useRef(null);

  const context = useContext(ModalController)
  const onLoad = context.funcsLoad?.onLoad

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultOfReport, setResultOfReport] = useState<any>(null)
  const [optionParser, setOptionParser] = useState<any>(null)
  const [reportNotFinish, setReportNotFinish] = useState<any[] | null>(null)

  const [listOfAction, setListOfAction] = useState<any[]>([])

  // funcs onChangeProccessingTask
  const _onChangeProcessingTask = (_index: number, type: string, text: string) => {
    setListOfAction((prevState: any) => {
      return prevState?.map((item: any, index: number) =>
        index === _index && type === "content" ? { ...item, Job_Content: text }
          : index === _index && type === "stepForward" ? { ...item, Job_Step: text }
            : index === _index && type === "compose" ? { ...item, Job_Purpose: text }
              : item
      )
    })
  }

  const _onClickAddingAction = () => {
    let tempArray = [...listOfAction]

    tempArray.push({
      Job_Content: "",
      Job_Step: "",
      Job_Purpose: ""
    })

    setListOfAction(tempArray)
  }

  const _onDeleteAction = (index: number) => {
    let tempArray = [...listOfAction]
    tempArray.splice(index, 1);
    setListOfAction(tempArray);
  }

  // funcs onGiveBoss
  const _onClickGiveReportForBoss = () => {
    const paramObject: any
      = parseInt(bodyOfReport?.reportType.value) === 1 ? {
        PERIODIC: parseInt(bodyOfReport?.reportType.value),
        SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
        SEARCH_DATEADD_TO: bodyOfReport?.toDate,
        TaskOwner: bodyOfReport?.taskOwner?.Id,
        TaskManager: bodyOfReport?.taskManager?.Id,
        TaskName: bodyOfReport?.taskName,
        Task_ReportPlan_Detail_Requests: listOfAction
      }
        : parseInt(bodyOfReport?.reportType.value) === 2 ? {
          PERIODIC: parseInt(bodyOfReport?.reportType.value),
          SEARCH_MONTH: bodyOfReport?.month.label,
          SEARCH_YEAR: bodyOfReport?.year.label.toString(),
          TaskOwner: bodyOfReport?.taskOwner?.Id,
          TaskManager: bodyOfReport?.taskManager?.Id,
          TaskName: bodyOfReport?.taskName,
          Task_ReportPlan_Detail_Requests: listOfAction
        }
          : parseInt(bodyOfReport?.reportType.value) === 3 ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_QUARTER: bodyOfReport?.quarter.value.toString(),
            SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportPlan_Detail_Requests: listOfAction
          }
            :
            {
              PERIODIC: parseInt(bodyOfReport?.reportType.value),
              SEARCH_YEAR: bodyOfReport?.year.label.toString(),
              TaskOwner: bodyOfReport?.taskOwner?.Id,
              TaskManager: bodyOfReport?.taskManager?.Id,
              TaskName: bodyOfReport?.taskName,
              Task_ReportPlan_Detail_Requests: listOfAction
            }

    const fetchData = async () => {
      const accessToken = localStorage.getItem("accessToken")
      try {
        setIsLoading(true)
        await ServiceUtils.postV2(
          KE_HOACH_GUI,
          paramObject,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setIsLoading(false)
              onLoad()
              toast.success("Gửi báo cáo thành công")
              handleClose()
              context.handleClose()
            }
          })
      } catch (error: any) {
        setIsLoading(false)
        toast.error("Gửi báo cáo thất bại")
      }
    }
    fetchData()
  }

  const _onClickCreateReport = () => {
    const paramObject: any
      = parseInt(bodyOfReport?.reportType.value) === 1 ? {
        PERIODIC: parseInt(bodyOfReport?.reportType.value),
        SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
        SEARCH_DATEADD_TO: bodyOfReport?.toDate,
        TaskOwner: bodyOfReport?.taskOwner?.Id,
        TaskManager: bodyOfReport?.taskManager?.Id,
        TaskName: bodyOfReport?.taskName,
        Task_ReportText: reportNotFinish
      }
        : parseInt(bodyOfReport?.reportType.value) === 2 ? {
          PERIODIC: parseInt(bodyOfReport?.reportType.value),
          SEARCH_MONTH: bodyOfReport?.month.label,
          SEARCH_YEAR: bodyOfReport?.year.label.toString(),
          TaskOwner: bodyOfReport?.taskOwner?.Id,
          TaskManager: bodyOfReport?.taskManager?.Id,
          TaskName: bodyOfReport?.taskName,
          Task_ReportText: reportNotFinish
        }
          : parseInt(bodyOfReport?.reportType.value) === 3 ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_QUARTER: bodyOfReport?.quarter.value.toString(),
            SEARCH_YEAR: bodyOfReport?.year.label.toString(),
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id,
            TaskName: bodyOfReport?.taskName,
            Task_ReportText: reportNotFinish
          }
            :
            {
              PERIODIC: parseInt(bodyOfReport?.reportType.value),
              SEARCH_YEAR: bodyOfReport?.year.label.toString(),
              TaskOwner: bodyOfReport?.taskOwner?.Id,
              TaskManager: bodyOfReport?.taskManager?.Id,
              TaskName: bodyOfReport?.taskName,
              Task_ReportText: reportNotFinish
            }

    const fetchData = async () => {
      const accessToken = localStorage.getItem("accessToken")
      try {
        setIsLoading(true)
        await ServiceUtils.postV2(
          BAO_CAO_CONG_VIEC_CREATE_REPORT,
          paramObject,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setIsLoading(false)
              toast.success("Gửi báo cáo thành công")
              handleClose()
              context.handleClose()
              window.location.reload()
            }
          })
      } catch (error: any) {
        setIsLoading(false)
        toast.error("Gửi báo cáo thất bại")
      }
    }
    fetchData()
  }

  const _readReport = async () => {
    const paramObject: any
      = parseInt(bodyOfReport?.reportType.value) === 1 ? {
        PERIODIC: parseInt(bodyOfReport?.reportType.value),
        SEARCH_DATEADD_FROM: bodyOfReport?.fromDate,
        SEARCH_DATEADD_TO: bodyOfReport?.toDate,
        TaskOwner: bodyOfReport?.taskOwner?.Id,
        TaskManager: bodyOfReport?.taskManager?.Id
      }
        : parseInt(bodyOfReport?.reportType.value) === 2 ? {
          PERIODIC: parseInt(bodyOfReport?.reportType.value),
          SEARCH_MONTH: bodyOfReport?.month ? bodyOfReport?.month.label : "",
          SEARCH_YEAR: bodyOfReport?.year ? bodyOfReport?.year.value.toString() : "",
          TaskOwner: bodyOfReport?.taskOwner?.Id,
          TaskManager: bodyOfReport?.taskManager?.Id
        }
          : parseInt(bodyOfReport?.reportType.value) === 3 ? {
            PERIODIC: parseInt(bodyOfReport?.reportType.value),
            SEARCH_QUARTER: bodyOfReport?.quarter ? bodyOfReport?.quarter.value.toString() : "",
            SEARCH_YEAR: bodyOfReport?.year ? bodyOfReport?.year.value.toString() : "",
            TaskOwner: bodyOfReport?.taskOwner?.Id,
            TaskManager: bodyOfReport?.taskManager?.Id
          }
            :
            {
              PERIODIC: parseInt(bodyOfReport?.reportType.value),
              SEARCH_YEAR: bodyOfReport?.year ? bodyOfReport?.year.value.toString() : "",
              TaskOwner: bodyOfReport?.taskOwner?.Id,
              TaskManager: bodyOfReport?.taskManager?.Id
            }

    const accessToken = localStorage.getItem("accessToken")

    const takeData = async () => {
      try {
        await ServiceUtils.postV2(
          BAO_CAO_CONG_VIEC_BAO_CAO,
          paramObject,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {

              setResultOfReport(res.data.Data)

              const setTaskProcessing = () => {
                let tempArray: any[] = res.data.Data.taskProccessing.map((item: any) => { return { TaskID: item?.ID, Report_Hard: "", Report_Solution: "", Report_Plan: "" } })
                setReportNotFinish(tempArray)
              }

              setTaskProcessing()
            }
          })
      } catch (error: any) {
        toast.error("Đọc báo cáo thất bại.")
      }
    }
    return takeData()
  }

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button
      id="print"
      className="
    flex items-center space-x-1 w-max h-max p-2 bg-blue-500 text-white rounded-md
    hover:bg-blue-400
  "

    >
      <PrintIcon fontSize="small" />
      <p>In báo cáo</p>
    </button>;
  }, []);
  // --- end using React to print

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  useEffect(() => {
    _readReport()
  }, [])

  // console.log(bodyOfReport)

  // console.log(reportNotFinish)

  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: domNode => {
        const typedDomNode = domNode as Element

        if (typedDomNode.attribs) {
          if (parseInt(bodyOfReport?.reportType.value) === 1) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{userData.Data[0].FullName}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(bodyOfReport?.fromDate).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]">{bodyOfReport?.taskName.toUpperCase()}</h1>
              case "toDate":
                return <div>&nbsp;{moment(bodyOfReport?.toDate).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <span>{moment(bodyOfReport?.fromDate).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(bodyOfReport?.toDate).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            value={resultOfReport?.taskProccessing[index].Report_Hard}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1" />
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }

                </div>;
              default:
                break
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 2) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{userData.Data[0].FullName}</span></p>
              case "optionsMonth":
                return <div>Tháng: {bodyOfReport?.month.label}</div>;
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]">{bodyOfReport?.taskName.toUpperCase()}</h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>
                        <p>{resultOfReport?.taskProccessing[index].Report_Hard}</p>
                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea

                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea onChange={(e: any) => {
                            e.preventDefault()
                            _onChangeProcessingTask(item.ID, "solution", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }

                </div>;
              default:
                break
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 3) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{userData.Data[0].FullName}</span></p>
              case "optionsQuarter":
                return <div>{bodyOfReport?.quarter.label}</div>;
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]">{bodyOfReport?.taskName.toUpperCase()}</h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea

                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea onChange={(e: any) => {
                            e.preventDefault()
                            _onChangeProcessingTask(item.ID, "solution", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }

                </div>;
              default:
                break
            }
          } else if (parseInt(bodyOfReport?.reportType.value) === 4) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{userData.Data[0].FullName}</span></p>
              case "optionsYear":
                return <div>Năm: {bodyOfReport?.year.label}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]">{bodyOfReport?.taskName.toUpperCase()}</h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{bodyOfReport?.taskOwner.FullName}</span>;
              case "taskOwnerLevel":
                return <span>{bodyOfReport?.taskOwner.TenChucVu}</span>;
              case "taskManager":
                return <span>{bodyOfReport?.taskManager.FullName}</span>;
              case "taskManagerLevel":
                return <span>{bodyOfReport?.taskManager.TenChucVu}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea onChange={(e: any) => {
                            e.preventDefault()
                            _onChangeProcessingTask(item.ID, "solution", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }

                </div>;
              default:
                break
            }
          }
        }
      }
    };

    setOptionParser(options)
  }, [resultOfReport])

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        ">
        <p>Biểu mẫu kế hoạch</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
        <div className="w-full h-max flex flex-col">
          {/* Body */}
          <div className="w-full h-max flex flex-col text-lg font-medium space-y-3 border-b-2 border-slate-300 mb-4 ">
            <p>Tên kế hoạch: {bodyOfReport?.taskName}</p>

            <div className="w-full h-max flex justify-between items-center">
              <p>Theo yêu câu của: {bodyOfReport?.taskManager.FullName}</p>
              <p>Chức vụ: {bodyOfReport?.taskManager.TenChucVu}</p>
            </div>

            <div className="w-full h-max flex justify-between items-center">
              <p>Kính gửi: {bodyOfReport?.taskOwner.FullName}</p>
              <p>Chức vụ: {bodyOfReport?.taskOwner.TenChucVu}</p>
            </div>

            <div className="w-full h-max flex justify-between items-center">
              <p>Tôi tên: {userData.Data[0].FullName}</p>
              <p>Chức vụ: {userData.Data[0].TenChucVu}</p>
              <p>Phòng ban: {userData.Data[0].TenPhongBan}</p>
            </div>
          </div>

          <div className="w-full h-max flex flex-col space-y-8">
            {
              listOfAction.map((item: any, index: number) => (
                <>
                  <div className="w-full h-max flex flex-col">
                    {/* Spaces */}
                    <div className="w-full h-max flex">
                      <div className="w-5/6 h-max flex flex-col">
                        {/* Elements */}
                        <div className="w-full h-max">
                          <label className="text-sm">
                            Nội dung công việc
                          </label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập nội dung công việc"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(index, "content", e.target.value)
                            }}
                          />
                        </div>

                        <div className="w-full h-max">
                          <label className="text-sm">
                            Các bước triển khai
                          </label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập các bước triển khai"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(index, "stepForward", e.target.value)
                            }}
                          />
                        </div>

                        <div className="w-full h-max">
                          <label className="text-sm">
                            Mục đích
                          </label>
                          <textarea
                            className="w-full h-40 border-2 border-slate-200 p-1"
                            placeholder="Nhập mục đích"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(index, "compose", e.target.value)
                            }}
                          />
                        </div>

                      </div>
                      <div className="w-1/6 h-full flex justify-center items-center">
                        <button className="w-full h-full text-red-600 hover:text-red-500"
                          onClick={() => _onDeleteAction(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))
            }
          </div>

          <button className="
                bg-green-500 w-max h-max p-2 text-white mt-5
                rounded-md hover:bg-green-400 
              "
            onClick={_onClickAddingAction}
          >
            Thêm kế hoạch
          </button>
        </div>
      </div>

      {/* Footer */}
      <div className="flex w-full footer p-12-16 rounded-b-md items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center">
          <div className="w-max h-max flex space-x-3">
            <div className="hidden">
              <div className="w-full h-max" ref={componentRef}>
                {parse(reportView, optionParser)}
              </div>
            </div>
          </div>

          {/* Create button */}
          <div className="w-max h-max flex space-x-3">
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
              onClick={_onClickGiveReportForBoss}
            >
              <AddIcon fontSize="small" />
              <p>Tạo kế hoạch</p>
            </button>

            {/* Close button */}
            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckPlanning