import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_DANG_TRE_HAN,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import LoaderSpinner from "../../loader-spinner/_index";
import { convertDayTime } from "../../../funcs/convertDayTime";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";

import { Checkcolor } from "../../../funcs/checkColor";
import TaskUtils from "../../../utils/tasks/taskUtils";
import { MODAL_KEYS } from "../../../constants/constants";

import RedoIcon from "@mui/icons-material/Redo";
import SubModal from "../_subModal/_index";
import {
  NGUOI_GIAO_VIEC_LIST_DELAY,
  NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
} from "../../../apis/_index";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import LoadingControl from "../../../controls/loading/loadingControl";

const WorkingTaskDelayJSX = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const appData = useSelector((state: any) => state.appReducer);

  const link = window.location.pathname;

  const shouldLog = useRef(true);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [taskId, setTaskId] = useState("");

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_GIAO_VIEC_LIST_DELAY,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
      },
      "",
      "",
      "",
      workingData.searchFilter.selectPhongBan
    );
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));

    _fetchData(0, value);
  };

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("readTotalTask_nguoigiao");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickRemindTaskWithId = (id: any) => {
    context.setFuncs("notiTask_Model");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickHistoryExtendWithId = (id: any) => {
    context.setFuncs("readHistoryExtend");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickSwitchToBoss = (id: any) => {
    context.setFuncs("switchTaskToBoss");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickGiveTask = (id: any) => {
    context.setFuncs("giveTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const onRecoveryAndTransfer = (id: any, taskCode: any) => () => {
    context.setFuncs(MODAL_KEYS.editTaskRecoveryAndTransfer);
    context.setTaskId(id);
    context.setTaskCode(taskCode);
    context.handleOpen();
  };

  const onRecovery = (id: any) => () => {
    setTaskId(id);
    setFuncsSub(MODAL_KEYS.recoveryTask);
    handleOpenSub();
  };

  const onHandleRecovery = () => {
    setTaskId("");
    setFuncsSub("");
    handleCloseSub();

    readList(
      NGUOI_GIAO_VIEC_LIST_DELAY,
      workingData.pageCurrent,
      workingData.choosePage,
      "",
      -1,
      -1,
      "",
      "",
      "",
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY((res.data || {}).Data));
      }
    );
  };

  return (
    <>
      <SubModal
        open={openSub}
        timeExtend={null}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        isTransfer={false}
        taskHistory={null}
        takeHistoryDetail={null}
        isViewHistoty={false}
        taskContentsHistory={""}
        taskContentHistoryId={""}
        onHandleRecovery={onHandleRecovery}
        taskId={taskId}
        idHistory={null}
        _taskContents={null}
        _handleCheckCompled={null}
        taskInfoText={null}
        task={null}
      />
      {workingData.taskDelay === null ? (
        // <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.taskDelay.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.taskDelay.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người đánh giá</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.taskDelay === null ? (
                  <tr></tr>
                ) : (
                  workingData.taskDelay.data?.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="border border-slate-300 space-y-0.5">
                        <p className="lg:hidden font-bold">
                          <span className="lg:hidden">
                            {index +
                              1 +
                              workingData.pageCurrent * workingData.choosePage}
                            .&nbsp;
                          </span>
                          {item.TaskName}
                        </p>
                        <p className="hidden lg:flex">{item.TaskName}</p>
                        <p className="lg:hidden">
                          <span className="text-xs">Người đánh giá: </span>
                          <span className="font-semibold">
                            {item.TaskReviewerName}
                          </span>
                        </p>
                        <p className="lg:hidden">
                          <span className="text-xs">Người nhận: </span>
                          <span className="font-semibold">
                            {item.RecipientName}
                          </span>
                        </p>
                        {item.SupporterName !== null && (
                          <p className="lg:hidden">
                            <span className="text-xs">Người hỗ trợ:&nbsp;</span>
                            <span className="text-slate-600">
                              {item.SupporterName}
                            </span>
                          </p>
                        )}
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            <span className="text-xs">Thời hạn: </span>
                            <span className="italic">
                              {moment(item.AssignmentDate).format("HH:mm")}
                              &nbsp;
                              {moment(item.AssignmentDate).format("DD/MM")}
                            </span>
                            &nbsp;-&nbsp;
                            <span className="italic">
                              {moment(item.DeadLine).format("HH:mm")}&nbsp;
                              {moment(item.DeadLine).format("DD/MM")}
                            </span>
                          </p>
                        </div>
                        {item.IsExtend && (
                          <p className="border border-primary text-primary w-max p-1 rounded-md text-xs font-semibold">
                            Chờ duyệt gia hạn
                          </p>
                        )}
                        <div className="flex lg:flex-col text-xs lg:text-sm">
                          <p className="text-amber-500 font-semibold">
                            Đã giao {convertDayTime(item.NumberOfWorking)}
                          </p>
                          <p className="lg:hidden">&nbsp;-&nbsp;</p>
                          <p className="text-red-500 font-semibold">
                            Trễ {convertDayTime(item.DayLate)}
                          </p>
                        </div>

                        <div className="lg:hidden flex justify-between items-center">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold mr-2 ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              {/* detail */}
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() => _onClickEditTaskWithId(item.ID)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickHistoryExtendWithId(item.ID)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <MoreTimeIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Lịch sử gia hạn</div>
                                </div>
                              </button>

                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() =>
                                  _onClickRemindTaskWithId(item.ID)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <NotificationsActiveIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Nhắc việc</div>
                                </div>
                              </button>
                              {TaskUtils.isHaveRecovery(item.TaskStatus) && (
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={onRecovery(item.ID)}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <RedoIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div
                                      style={{
                                        textAlign: "left",
                                      }}
                                    >
                                      Thu hồi
                                    </div>
                                  </div>
                                </button>
                              )}
                              {TaskUtils.isHaveTransfer(item.TaskStatus) && (
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={onRecoveryAndTransfer(
                                    item.ID,
                                    item.TaskCode
                                  )}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <RedoIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div
                                      style={{
                                        textAlign: "left",
                                      }}
                                    >
                                      Thu hồi và chuyển giao
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <p
                          style={{
                            color: Checkcolor(item.PriorityLevelID, appData),
                          }}
                          className={`font-bold ${Checkcolor(
                            item.PriorityLevelID,
                            appData
                          )}`}
                        >
                          {item.PriorityLevelName}
                        </p>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.RecipientName}
                        {item.SupporterName && (
                          <p className="text-xs">
                            <span className="text-slate-600">Hỗ trợ:</span>{" "}
                            {item.SupporterName}
                          </p>
                        )}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.TaskReviewerName}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickEditTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>

                            <button
                              className="flex w-full h-full"
                              onClick={() =>
                                _onClickHistoryExtendWithId(item.ID)
                              }
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <MoreTimeIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Lịch sử gia hạn</div>
                              </div>
                            </button>

                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickRemindTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <NotificationsActiveIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Nhắc việc</div>
                              </div>
                            </button>
                            {TaskUtils.isHaveRecovery(item.TaskStatus) && (
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={onRecovery(item.ID)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <RedoIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    Thu hồi
                                  </div>
                                </div>
                              </button>
                            )}
                            {TaskUtils.isHaveTransfer(item.TaskStatus) && (
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={onRecoveryAndTransfer(
                                  item.ID,
                                  item.TaskCode
                                )}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <RedoIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    Thu hồi và chuyển giao
                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskDelayJSX;
