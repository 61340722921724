import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LOAD_SEP } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_GIAO_VIEC_CHUYEN_SEP, NGUOI_GIAO_VIEC_LIST } from "../../../apis/_index";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";

// icon
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import axios from "axios";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";

const SwitchTaskToBoss = (props: any) => {

  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const shouldLog = useRef(true)

  const dispatch = useDispatch()
  const dataReducer = useSelector((state: any) => state.workingReducer)
  const shouldLog4 = useRef(true)

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [data, setData] = useState<any>(null)
  const [selectedData, setSelectedData] = useState<any>(null)
  const link = window.location.pathname

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // funcs
  const _onClickSelectedBoss = (item: any) => {
    setSelectedData(item)
  }

  // transfer
  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken")

    const API = NGUOI_GIAO_VIEC_CHUYEN_SEP(taskId, selectedData.Id)

    try {
      setIsLoading(true)
      await ServiceUtils.getV2(
        API,
        {
          headers: {
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            readList(
              NGUOI_GIAO_VIEC_LIST,
              dataReducer.pageCurrent,
              dataReducer.choosePage,
              dataReducer.searchFilter.taskName,
              dataReducer.searchFilter.selectedPrior,
              dataReducer.searchFilter.selectedReceiver,
              dataReducer.searchFilter.fromDate,
              dataReducer.searchFilter.toDate,
              dataReducer.searchFilter.deadLine,
              shouldLog4,
              link,
              (res: any) => { dispatch(ONLOAD_TASKLIST_ALL(res.data.Data)) }
            )
            setIsLoading(false)
            handleClose()
            toast.success("Chuyển việc thành công")
          }
        })
    } catch (error: any) {
      setIsLoading(false)
      toast("Chuyển việc thất bại")
    }
  }

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        const accessToken = localStorage.getItem("accessToken")

        try {
          await ServiceUtils.getV2(
            LOAD_SEP,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setData(res.data.Data)
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      takeData();
    }
  })

  return (
    <div className="
      flex flex-col bg-white
      lg:w-[55rem] w-screen h-[40rem]
      rounded-md
    ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}

        taskId={taskId}
        taskOwner={selectedData === null ? "" : selectedData.Id}
      />

      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <div className="flex space-x-2 items-center">
          <CameraFrontIcon />
          <p>Chuyển việc</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon/>
        </button>
      </div>

      {/* content */}
      {
        data === null ?
          <div className="flex flex-col justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải biểu mẫu</p>
          </div>
          :
          <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
            <div className="w-full h-[2.3rem]">
              <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 w-max rounded-sm shadow-sm">Mã công việc</p>
            </div>

            <div className="flex w-full h-[5rem]">
              <div className="flex flex-col w-4/5 pr-3 h-max">
                <label>Tên sếp</label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên sếp"
                />
              </div>
              <div className="flex flex-col w-1/5 pl-3">
                <label className="text-white">Tìm kiếm</label>
                <button
                  className="
                bg-slate-300 text-black w-full h-max rounded-md
                hover:bg-slate-200 p-2
              "
                >
                  Tìm kiếm
                </button>
              </div>
            </div>

            <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-10.7rem)] shadow-md border-t-2">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">Tên sếp</th>
                    <th className="border-l-[1px] border-slate-300">Phòng ban</th>
                    <th className="border-l-[1px] border-slate-300">Chức vụ</th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {
                    data === null ? <></>
                      : data.map((item: any, index: any) => (
                        <tr className={`w-max h-[3rem] ${selectedData === null ? "bg-white" : selectedData.FullName === item.FullName ? "bg-green-200" : "bg-white"}`}>
                          <td className="border border-slate-300 text-center">{index + 1}</td>
                          <td className="border border-slate-300">{item.FullName}</td>
                          <td className="border border-slate-300">{item.TenPhongBan}</td>
                          <td className="border border-slate-300">{item.TenChucVu}</td>
                          <td className="border border-slate-300 text-center">
                            <button
                              className="
                              w-max h-max text-white bg-green-500 hover:bg-green-400
                              p-2 rounded-md text-xs
                            "
                              onClick={() => _onClickSelectedBoss(item)}
                            >
                              Chọn
                            </button>
                          </td>
                        </tr>
                      ))
                  }
                </tbody>
              </table>
            </div>

            <div className="w-full h-[3rem] justify-end flex flex-col">
              Đã chọn: {selectedData === null ? "" : selectedData.FullName}
            </div>
          </div>
      }

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <button className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${selectedData === null ? "bg-slate-300" : "hover:bg-amber-300 bg-amber-500"}
          `}
          disabled={selectedData === null || isLoading === true ? true : false}
          onClick={_onClickSubmit}
        >
          {
            isLoading === false ?
              <>
                <CameraFrontIcon fontSize="small" />
                <p>Chuyển việc</p>
              </>
              :
              <div className="w-max h-max flex justify-center">
                <LoaderSpinner w={20} h={20} />
              </div>
          }

        </button>

        <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  )
}

export default SwitchTaskToBoss;
