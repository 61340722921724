import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";

import { onTouchForm } from "../../../funcs/onTochForm";

import LoaderSpinner from "../../loader-spinner/_index";
import { toast } from "react-toastify";

import { DETAIL_PARAMETER, UPDATE_PARAMETER, READ_PARAMETER } from "../../../apis/_index";
import { UPDATE_PARAM_CACHE } from "../../../redux/reducers/settingReducer/_index";
import { ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";

import SubModal from "../_subModal/_index";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";

const DetailSingleOfGeneral = (props: any) => {

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onDeleteGeneral = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.settingReducer);
  const shouldLog = useRef(true);
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [parameterKey, setParameterKey] = useState<any>("");
  const [parameterValue, setParameterValue] = useState<any>("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      parameterKey === ""
      && parameterValue === ""
      && shouldLog.current
    ) {
      shouldLog.current = false;
      const fetchData = async () => {
        try {
          const API = DETAIL_PARAMETER + taskId.toString();
          await ServiceUtils.getV2(
            API,
            {
              headers: {
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setParameterKey(res.data.Data[0].ParameterKey);
                setParameterValue(res.data.Data[0].ParameterValue);
                setIsActive(res.data.Data[0].IsActive);
              } else {
                toast.error("Tải dữ liệu thất bại.")
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      }
      fetchData();
    } else {
      shouldLog.current = false
      return;
    }
  })

  // funcs
  const _onClickUpdateParameter = async () => {
    const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      ParameterId: taskId,
      ParameterKey: parameterKey,
      ParameterValue: parameterValue,
      IsActive: isActive
    }

    try {
      ServiceUtils.postV2(
        UPDATE_PARAMETER,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const accessToken = localStorage.getItem("accessToken");
            let API = "";

            if (data.parameterChoose.toString() === "1") {
              API = READ_PARAMETER;
            } else {
              API = READ_PARAMETER + `?key=${data.parameterChoose}`
            }

            const takeParameterList = async () => {
              try {
                await ServiceUtils.getV2(
                  API,
                  {
                    headers: {
                    }
                  }
                )
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_PARAMETER_LIST(res.data.Data))
                    } else {
                      return;
                    }
                  })
              } catch (error: any) {
                toast.error("Tải dữ liệu thất bại.");
              }
            }

            toast.success("Cập nhật thành công.");
            takeParameterList();
          } else {
            toast.error("Cập nhật danh mục thất bại.")
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật danh mục thất bại.")
    }
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[30rem] w-screen h-[21rem]
    rounded-md
  ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Chi tiết</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {parameterKey === "" && parameterValue === "" ?
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center">
          <LoaderSpinner
            w={32}
            h={32}
          />
        </div>
        :
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          {/* lines */}
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full pr-0 h-max">
              <label>Tên danh mục&nbsp;<span className="text-red-500">(*)</span></label>

              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 bg-slate-200"
                value={parameterValue}
                readOnly
                onTouchStart={(e: any) => onTouchForm(e)}
              />

            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>Nhóm danh mục&nbsp;<span className="text-red-500">(*)</span></label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 bg-slate-200"
                value={parameterKey}
                readOnly
                onTouchStart={(e: any) => onTouchForm(e)}
              >
              </input>
            </div>
          </div>


          <div className="absolute left-4 bottom-4 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max">
            <p className="font-bold text-primary">Hiển thị</p>
            <input
              type="checkbox"
              className="w-5 h-5"
              checked={isActive}
              readOnly
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      }


      {/* footer */}
      {parameterKey === "" && parameterValue === "" ?
        <div className="flex w-full p-12-16 footer  rounded-b-md justify-between items-center text-sm space-x-4"></div>
        :
        <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">

        </div>
      }

    </div>
  )
}

export default DetailSingleOfGeneral;
