class OrganizationUtils {
    static getListTreeItem = (organizations: any, parent: any, trees: any, level: any, keypairs: any, isPhongBanInCreateUser: boolean = false) => {

        const childrens = organizations.filter((p: any) => p[keypairs.parentId] == parent[keypairs.id]);

        ++level;

        for (let i = 0; i < childrens.length; i++) {
            childrens[i].level = level;

            if (isPhongBanInCreateUser == true) {
                childrens[i].ParameterId = childrens[i].ParameterId;
            } else {
                childrens[i].ParameterId = childrens[i].PhongBanId;
            }

            trees.push(childrens[i]);

            OrganizationUtils.getListTreeItem(organizations, childrens[i], trees, level, keypairs);
        }
    }

    static getListTree = (organizations: any, keypairs: any, isPhongBanInCreateUser: boolean = false): any => {
        const organizationHandles = organizations ? [...organizations] : [];
        const keypairHandles = keypairs || {};
        let trees: any = [];

        const parents = organizationHandles.filter((p: any) => !p[keypairHandles.parentId] || !organizationHandles.find((m: any) => m[keypairHandles.id] == p[keypairHandles.parentId]));

        let level = 0;


        for (let i = 0; i < parents.length; i++) {
            parents[i].level = level;
            if (isPhongBanInCreateUser == true) {
                parents[i].ParameterId = parents[i].ParameterId;
            } else {
                parents[i].ParameterId = parents[i].PhongBanId;
            }

            trees.push(parents[i]);

            OrganizationUtils.getListTreeItem(organizationHandles, parents[i], trees, level, keypairHandles, isPhongBanInCreateUser);
        }

        // const notParents = organizationHandles.filter((p: any) => !organizationHandles.find((m: any) => m.IdPhongBan == p.RefID));

        // trees = trees.concat(notParents);



        return trees;
    }

    static convertToListValueLabel = (organizations: any, keypairs: any) => {
        const keypairHandles = keypairs || {};

        return (organizations || []).map((p: any) => {
            return {
                ...p,
                value: p[keypairHandles.value],
                label: p[keypairHandles.label]
            }
        });
    }
}

export default OrganizationUtils;