import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import CheckIcon from "@mui/icons-material/Check";
import { ModalController } from "../../../ui/homeUI/_index";
import { PROPOSE_UNCONFIRM } from "../../../apis/_index";

import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const UnConfirmPropose = (props: any) => {
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [rateValue, setRateValue] = React.useState<number | null>(0);

  const taskId = props.taskId;
  const _loadData = props._loadData;

  // funcs
  const _onClickNotOK = async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem("accessToken");

    const body:any = {
      ID: taskId,
      Star: rateValue,
      Reason: reason,
    };

    try {
      await ServiceUtils.postV2(PROPOSE_UNCONFIRM, body, {
          headers: {
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Không duyệt đề xuất thành công");
            handleClose();
            _loadData();
            setIsLoading(false);
            context.handleClose();
          } else {
            toast.error(res.data.Message || "Không duyệt đề xuất thất bại");
          }
        });
    } catch (error: any) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className=" flex flex-col bg-white lg:w-[30rem] md:w-[30rem] lg:h-[18rem] w-screen rounded-md ">
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Xác nhận không duyệt đề xuất</p>
        <button className="" onClick={handleClose}>
          X
        </button>
      </div>

      {/* content */}
      <div className="danh-gia lg:w-[30rem] h-full md:w-[30rem] w-full justify-center p-3">
        <div className="flex flex-row">
          <p>
            Đánh giá&nbsp;<span className="text-red-500">(*)</span>
          </p>
          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
            className="ml-3 scale-125 items-center"
          >
            <Rating
              name="simple-controlled"
              value={rateValue}
              onChange={(event, newValue) => {
                setRateValue(newValue);
              }}
            />
          </Box>
        </div>
        <div className="flex flex-col w-full h-full">
          <label>
            Lý do&nbsp;<span className="text-red-500">(*)</span>
          </label>
          <textarea
            className="w-full h-[7rem] rounded-md p-2 mt-2 border-2 border-slate-200"
            placeholder="Nhập lý do"
            onChange={(e) => setReason(e.target.value)}
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div
          className={`
          ${
            reason === "" || rateValue === 0 || rateValue === null
              ? "flex"
              : "hidden"
          }
        `}
        >
          <p className="w-full text-red-500 font-semibold text-xs">
            Vui lòng nhập đánh giá và lý do không duyệt!
          </p>
        </div>
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            reason === "" || rateValue === 0 || rateValue === null
              ? "bg-slate-300"
              : "bg-green-500 hover:bg-green-400"
          }
        `}
            disabled={
              reason === "" || rateValue === 0 || rateValue === null
                ? true
                : false
            }
            onClick={_onClickNotOK}
          >
            <CheckIcon fontSize="small" />
            <p>Xác nhận</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-red-500
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnConfirmPropose;
