import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';

import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import { takePermission } from "../../../funcs";

import SubModal from "../_subModal/_index";

import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

import {
  DETAIL_QUYTRINH,
  DETAIL_STEP,
  DETAIL_RESULT,
  UPDATE_QUYTRINH,
  CREATE_QUYTRINH_STEP,
  UPDATE_QUYTRINH_BUOC,
  DELETE_QUYTRINH_STEP,
  CREATE_RESULT,
  DELETE_RESULT,
  UPDATE_RESULT
} from "../../../apis/_index";
import { ONLOAD_QUYTRINH_LIST } from "../../../redux/reducers/workingReducer/_index";
import { READ_ALL_LIST_QUYTRINH } from "../../../apis/_index";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const DetailWorkflow = (props: any) => {

  const taskId = props.taskId;
  const dispatch = useDispatch();
  const menu = takePermission(19);

  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const shouldLog = useRef(true);

  const [quytrinhName, setQuytrinhName] = useState<any>("");
  const [stepList, setStepList] = useState<any[] | null>(null);
  const [resultList, setResultList] = useState<any[] | null>(null);

  const [newPositionStep, setNewPositionStep] = useState<number | null>(null);
  const [newHourStep, setNewHourStep] = useState<number | null>(null);
  const [newNameStep, setNewNameStep] = useState<string | null>(null);

  const [newResult, setNewResult] = useState<string | null>(null);

  // funcs
  const _onClickDeleteQuytrinh = () => {
    handleOpenSub();
    setFuncsSub("deleteQuytrinh");
  }

  const _onChangeNewResult = (e: any) => {
    e.preventDefault();
    setNewResult(e.target.value);
  }

  const _onChangeNameStep = (e: any) => {
    e.preventDefault();
    setNewNameStep(e.target.value);
  }

  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setNewPositionStep(e.target.value);
  }

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setNewHourStep(e.target.value);
  }

  const _onChangeQuitrinhName = (e: any) => {
    e.preventDefault();
    setQuytrinhName((value: any) => {
      return {
        ...value,
        tenquytrinh: e.target.value
      }
    });
  }

  // change elements of quy trinh

  const _onChangeStepOrderBy = (e: any, id: number) => {
    e.preventDefault();

    const newState: any = stepList?.map((item: any) => {
      if (item.idbuoc === id) {
        return { ...item, orderBy: e.target.value }
      }

      return item;
    })

    setStepList(newState);
  }

  const _onChangeStepHour = (e: any, id: number) => {
    e.preventDefault();

    const newState: any = stepList?.map((item: any) => {
      if (item.idbuoc === id) {
        return { ...item, giohoanthanh: e.target.value }
      }

      return item;
    })

    setStepList(newState);
  }

  const _onChangeStepName = (e: any, id: number) => {
    e.preventDefault();

    const newState: any = stepList?.map((item: any) => {
      if (item.idbuoc === id) {
        return { ...item, tenbuoc: e.target.value }
      }

      return item;
    })

    setStepList(newState);
  }

  const _onChangeResult = (e: any, id: number) => {
    e.preventDefault();

    const newState: any = resultList?.map((item: any) => {
      if (item.idkq === id) {
        return { ...item, tenketqua: e.target.value }
      }

      return item;
    })

    setResultList(newState);
  }

  const _onClickCreateNewStep = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      idquytrinh: parseInt(taskId),
      tenbuoc: newNameStep,
      giohoanthanh: newHourStep,
      orderBy: newPositionStep
    }

    try {
      await ServiceUtils.postV2(
        CREATE_QUYTRINH_STEP,
        body,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            setNewPositionStep(null);
            setNewHourStep(null);
            setNewNameStep(null);

            const takeDataStep = async () => {
              const API_STEP = DETAIL_STEP + taskId.toString();
              await ServiceUtils.getV2(
                API_STEP,
                {
                  headers: {
                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setStepList(res.data.Data);
                })
            }
            takeDataStep();
          }
        })
    } catch (error) {
      toast.error("Thêm bước thất bại.");
    }
  }

  const _onClickDeleteStep = async (id: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const API = DELETE_QUYTRINH_STEP + id.toString();

    try {
      await ServiceUtils.getV2(
        API,
        {
          headers: {
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const API_STEP = DETAIL_STEP + taskId.toString();

            const takeDataStep = async () => {
              await ServiceUtils.getV2(
                API_STEP,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setStepList(res.data.Data);
                })
            }

            takeDataStep();
          }
        })
    } catch (error: any) {
      toast.error("Xoá thất bại");
    }
  }

  const _onClickUpdateStep = async (id: number, stepName: string, timeFinish: number, orderBy: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      idbuoc: id,
      idquytrinh: parseInt(taskId),
      tenbuoc: stepName,
      giohoanthanh: timeFinish,
      orderBy: orderBy
    }

    try {
      await ServiceUtils.postV2(
        UPDATE_QUYTRINH_BUOC,
        body,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const takeDataStep = async () => {
              const API_STEP = DETAIL_STEP + taskId.toString();
              await ServiceUtils.getV2(
                API_STEP,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setStepList(res.data.Data);
                })
            }
            takeDataStep();
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật bước thực hiện thất bại.");
    }
  }

  const _onClickUpdateQuytrinhName = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      id: taskId,
      tenquytrinh: quytrinhName.tenquytrinh
    }

    try {
      await ServiceUtils.postV2(
        UPDATE_QUYTRINH,
        body,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const takeData = async () => {
              await ServiceUtils.getV2(
                READ_ALL_LIST_QUYTRINH,
                {
                  headers: {
                    Accept: "text/plain",
                    Authorization: "Bearer " + accessToken
                  }
                }
              )
                .then((res: any) => {
                  dispatch(ONLOAD_QUYTRINH_LIST(res.data.Data));
                  toast.success("Cập nhật thành công");
                })
            }

            takeData();
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật thất bại");
    }
  }

  const _onClickCreateNewResult = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      idquytrinh: parseInt(taskId),
      tenketqua: newResult
    }

    try {
      await ServiceUtils.postV2(
        CREATE_RESULT,
        body,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            setNewResult("");
            const API_RESULT = DETAIL_RESULT + taskId.toString();

            const takeDataResult = async () => {
              await ServiceUtils.getV2(
                API_RESULT,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setResultList(res.data.Data);
                })
            }

            takeDataResult();
          }
        })
    } catch (error: any) {
      toast.error("Tạo mới thất bại");
    }
  }

  const _onClickDeleteResult = async (id: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const API = DELETE_RESULT + id.toString();

    try {
      await ServiceUtils.getV2(
        API,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const API_RESULT = DETAIL_RESULT + taskId.toString();

            const takeDataResult = async () => {
              await ServiceUtils.getV2(
                API_RESULT,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setResultList(res.data.Data);
                })
            }

            takeDataResult();
          }
        })
    } catch (error: any) {
      toast.error("Xoá kết quả thất bại.");
    }
  }



  const _onClickUpdateResult = async (id: number, tenketqua: string) => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      idkq: id,
      idquytrinh: parseInt(taskId),
      tenketqua: tenketqua
    }

    try {
      await ServiceUtils.postV2(
        UPDATE_RESULT,
        body,
        {
          headers: {

            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status) {
            const API_RESULT = DETAIL_RESULT + taskId.toString();

            const takeDataResult = async () => {
              await ServiceUtils.getV2(
                API_RESULT,
                {
                  headers: {

                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  setResultList(res.data.Data);
                })
            }

            takeDataResult();
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật thất bại.")
    }
  }


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const API_QUITRINH = DETAIL_QUYTRINH + taskId.toString();
    const API_STEP = DETAIL_STEP + taskId.toString();
    const API_RESULT = DETAIL_RESULT + taskId.toString();

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeDataQuiTrinh = async () => {
          await ServiceUtils.getV2(
            API_QUITRINH,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              setQuytrinhName(res.data.Data[0])
            })
        }

        const takeDataStep = async () => {
          await ServiceUtils.getV2(
            API_STEP,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              setStepList(res.data.Data);
            })
        }

        const takeDataResult = async () => {
          await ServiceUtils.getV2(
            API_RESULT,
            {
              headers: {

                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              setResultList(res.data.Data);
            })
        }

        takeDataQuiTrinh();
        takeDataStep();
        takeDataResult();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại.");
      }
    }
  })


  // take props
  const handleClose = props.handleClose;

  return (
    <div className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
      rounded-md
    ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Chi tiết quy trình</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-3 text-sm" style={{
        padding: '5px 10px'
      }}>
        <div className="flex flex-col space-y-4 w-full h-full">
          {/* <div className="flex flex-col w-full h-max">
            <label>Tên quy trình</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên quy trình"
              value={quytrinhName.tenquytrinh}
              onChange={(e: any) => _onChangeQuitrinhName(e)}
            />
          </div> */}

          <div className="flex w-full h-max">
            <div className="flex flex-col w-4/5 h-max">
              <label>Tên quy trình</label>
              <div className="flex w-full h-max space-x-10">
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên quy trình"
                  value={quytrinhName.tenquytrinh}
                  onChange={(e: any) => _onChangeQuitrinhName(e)}
                  disabled={menu.PerEdit === true ? false : true}
                />
              </div>
            </div>
            <div className="pl-3 flex flex-col w-1/5 h-max">
              <label className="text-white">Cập nhật</label>
              <button className="w-full h-[1.9rem] bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={_onClickUpdateQuytrinhName}
                disabled={menu.PerEdit === true ? false : true}
              >
                Cập nhật
              </button>
            </div>
          </div>

          <div className="flex w-full h-max" style={{
            marginTop: 5
          }}>
            <div className="flex flex-col w-4/5 h-max">
              <label>Nội dung bước thực hiện</label>
              <div className="flex w-full h-max space-x-10">
                <input
                  className="w-1/6 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Thứ tự"
                  type="number"
                  value={newPositionStep === null ? "" : newPositionStep}
                  onChange={(e: any) => _onChangePositionStep(e)}
                  disabled={menu.PerEdit === true ? false : true}
                />
                <input
                  className="w-1/6 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Số giờ thực hiện"
                  type="number"
                  value={newHourStep === null ? "" : newHourStep}
                  onChange={(e: any) => _onChangeHourStep(e)}
                  disabled={menu.PerEdit === true ? false : true}
                />
                <input
                  className="w-3/6 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập nội dung bước"
                  value={newNameStep === null ? "" : newNameStep}
                  onChange={(e: any) => _onChangeNameStep(e)}
                  disabled={menu.PerEdit === true ? false : true}
                />
              </div>
            </div>
            <div className="pl-3 flex flex-col w-1/5 h-max">
              <label className="text-white">Cập nhật</label>
              <button className="w-full h-[1.9rem] bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={_onClickCreateNewStep}
                disabled={menu.PerEdit === true ? false : true}
              >
                Thêm
              </button>
            </div>
          </div>

          {/* {stepList === null ? "" :
            <div className="flex flex-col w-full h-max">
              {stepList.map((item: any, index: any) => (
                <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-6 items-center">

                    <div className="w-max h-max flex space-x-1 items-center">
                      <p>Bước</p>
                      <input
                        className="w-[3rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black"
                        value={stepList[index].orderBy}
                        type="number"
                        onChange={(e: any) => { _onChangeStepOrderBy(e, item.idbuoc) }}
                      />
                    </div>

                    <div className="w-max h-max flex space-x-1 items-center">
                      <p>Giờ</p>
                      <input
                        className="w-[3rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black"
                        value={item.giohoanthanh}
                        type="number"
                        onChange={(e: any) => { _onChangeStepHour(e, item.idbuoc) }}
                      />
                    </div>

                    <div className="w-max h-max flex space-x-1 items-center">
                      <p>Nội dung</p>
                      <input
                        className="w-[14rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black"
                        value={item.tenbuoc}
                        onChange={(e: any) => { _onChangeStepName(e, item.idbuoc) }}
                      />
                    </div>
                  </div>
                  <button className="text-primary w-1/6 flex justify-end"
                    onClick={() => _onClickUpdateStep(item.idbuoc, item.tenbuoc, item.giohoanthanh, item.orderBy)}
                  >
                    <CreateTwoToneIcon fontSize="small" />
                  </button>

                  <button className="text-red-500 w-1/6 flex justify-end"
                    onClick={() => _onClickDeleteStep(item.idbuoc)}
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              ))}
            </div>
          } */}

          {stepList === null || stepList.length === 0 ? "" :
            <div className="tableModal styled-scrollbars w-full h-[60rem] shadow-md core-table-management-workflow" style={{
              height: '45% !important'
            }}>
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">Bước</th>
                    <th className="border-l-[1px] border-slate-300">Nội dung</th>
                    <th className="border-l-[1px] border-slate-300">Số giờ thực hiện</th>
                    <th className="border-l-[1px] border-slate-300">Cập nhật</th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300">Xoá</th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto height-auto">
                  {stepList.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[2rem] bg-white">
                      <td className="text-center border border-slate-300">
                        <input
                          className="w-[3rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black text-center"
                          value={stepList[index].orderBy}
                          type="number"
                          onChange={(e: any) => { _onChangeStepOrderBy(e, item.idbuoc) }}
                          disabled={menu.PerEdit === true ? false : true}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <input
                          className="w-full h-max rounded-md pl-2 pt-1 pb-1 pr-2 border-2 border-slate-200 text-black"
                          value={item.tenbuoc}
                          onChange={(e: any) => { _onChangeStepName(e, item.idbuoc) }}
                          disabled={menu.PerEdit === true ? false : true}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <input
                          className="w-[3rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black text-center"
                          value={item.giohoanthanh}
                          type="number"
                          onChange={(e: any) => { _onChangeStepHour(e, item.idbuoc) }}
                          disabled={menu.PerEdit === true ? false : true}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="flex justify-center items-center w-full h-full">
                          <button className="text-primary w-max h-max flex text-center hover:text-sky-300"
                            onClick={() => _onClickUpdateStep(item.idbuoc, item.tenbuoc, item.giohoanthanh, item.orderBy)}
                            disabled={menu.PerEdit === true ? false : true}
                          >
                            <SaveTwoToneIcon fontSize="small" />
                          </button>
                        </div>

                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="flex justify-center items-center w-full h-full">
                          <button className="text-red-500 w-max h-max flex text-center hover:text-red -300"
                            onClick={() => _onClickDeleteStep(item.idbuoc)}
                            disabled={menu.PerEdit === true ? false : true}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          <div className="flex w-full h-max" style={{
            marginTop: 5
          }}>
            <div className="flex flex-col w-4/5 h-max">
              <label>Kết quả thực hiện</label>
              <div className="flex w-full h-max space-x-20">
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập nội dung kết quả"
                  value={newResult === null ? "" : newResult}
                  onChange={(e: any) => _onChangeNewResult(e)}
                  disabled={menu.PerEdit === true ? false : true}
                />

              </div>
            </div>
            <div className="pl-3 flex flex-col w-1/5 h-max">
              <label className="text-white">Cập nhật</label>
              <button className="w-full h-[1.9rem] p-2 bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={_onClickCreateNewResult}
                disabled={menu.PerEdit === true ? false : true}
              >
                Thêm
              </button>
            </div>
          </div>

          {/* {resultList === null ? "" :
            <div className="flex flex-col w-full h-max">
              {resultList.map((item: any, index: any) => (
                <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-6 items-center">

                    <div className="w-max h-max flex space-x-4 items-center">
                      <p>Kết quả {index + 1}</p>
                      <input
                        className="w-[20rem] h-max rounded-md pl-2 pr-2 border-2 border-slate-200 text-black"
                        value={item.tenketqua}
                        onChange={(e: any) => { _onChangeResult(e, item.idkq) }}
                      />
                    </div>
                  </div>
                  <button className="text-primary w-1/6 flex justify-end"
                    onClick={() => _onClickUpdateResult(item.idkq, item.tenketqua)}
                  >
                    <CreateTwoToneIcon fontSize="small" />
                  </button>

                  <button className="text-red-500 w-1/6 flex justify-end"
                    onClick={() => _onClickDeleteResult(item.idkq)}
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              ))}
            </div>
          } */}

          {resultList === null || resultList.length === 0 ? "" :
            <div className="tableModal styled-scrollbars w-full h-[60rem] shadow-md core-table-management-workflow" style={{
              height: '45% !important'
            }}>
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">Nội dung</th>
                    <th className="border-l-[1px] border-slate-300">Cập nhật</th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300">Xoá</th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {resultList.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[2rem] bg-white">
                      <td className="text-center border border-slate-300">
                        <p>{index + 1}</p>
                      </td>
                      <td className="text-center border border-slate-300">
                        <input
                          className="w-full h-max rounded-md pl-2 pt-1 pb-1 pr-2 border-2 border-slate-200 text-black"
                          value={item.tenketqua}
                          disabled={menu.PerEdit === true ? false : true}
                          onChange={(e: any) => { _onChangeResult(e, item.idkq) }}
                        />
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="flex justify-center items-center w-full h-full">
                          <button className="text-primary w-max h-max flex text-center hover:text-sky-300"
                            onClick={() => _onClickUpdateResult(item.idkq, item.tenketqua)}
                            disabled={menu.PerEdit === true ? false : true}
                          >
                            <SaveTwoToneIcon fontSize="small" />
                          </button>
                        </div>

                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="flex justify-center items-center w-full h-full">
                          <button className="text-red-500 w-max h-max flex text-center hover:text-red-300"
                            onClick={() => _onClickDeleteResult(item.idkq)}
                            disabled={menu.PerEdit === true ? false : true}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>

      {/* footer */}
      <div className={`flex w-full p-12-16 footer rounded-b-md ${menu.PerDelete === true ? "justify-between" : "justify-end"} items-center text-sm space-x-4`}>
        <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-500 text-white rounded-md
          hover:bg-slate-400 core-background-color-red
        "
          onClick={() => _onClickDeleteQuytrinh()}
        >
          <DeleteIcon fontSize="small" />
          <p>Xoá</p>
        </button>

        <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  )
}

export default DetailWorkflow;
