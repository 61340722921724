import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { takePermission } from "../../../../funcs";

import AddIcon from "@mui/icons-material/Add";

import Select from "react-select";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";
import { convertNameParameter } from "../../../../helperText/index";

import {
    TAKE_PARAMETER_LISTKEY,
    READ_PARAMETER,
    GET_DIAGRAM,
    GET_LIST_USER_BY_ORGANIZATION,
} from "../../../../apis/_index";
import {
    ONCHANGE_PARAMETER_LISTKEY,
    ONLOAD_PARAMETER_LIST,
    ONCHANGE_PARAMETERCHOOSE,
} from "../../../../redux/reducers/settingReducer/_index";

import axios from "axios";

import AccountTree from "@mui/icons-material/AccountTree";
import { MODAL_KEYS } from "../../../../constants/constants";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { DIAGRAM_TYPES } from "../../../../constants/dataConstants";

import RemoveIcon from "@mui/icons-material/Remove";
import ServiceUtils from "../../../../utils/services";

import '../../../../styles/organizations/index.css';

const FONT_SIZE_SCALES: any = {
    '0.25': '28px',
    '0.5': '24px',
    '0.75': '20px',
    '1': '16px',
    '1.25': '14px',
    '1.5': '12px',
    '1.75': '10px'
};

const ListOfOrganizationUI = () => {
    // // menuList
    // const menuList = useSelector((state: any) => state.appReducer.subMenu.filter((e: any) => e.FormId === 12));
    const menu = takePermission(12);

    const context = useContext(ModalController);
    const data = useSelector((state: any) => state.settingReducer);
    const [parameterChoose, setParameterChoose] = useState<any>(1);
    const dispatch = useDispatch();
    const [isOpenDiagramOrganization, setIsOpenDiagramOrganization] = useState<any>(false);
    const [diagrams, setDiagram] = useState<any>();
    const [renderDiagrams, setRenderDiagram] = useState<any>([]);
    const [renderDiagramLines, setRenderDiagramLine] = useState<any>([]);
    const [users, setUser] = useState<any>([]);
    const [scale, setScale] = useState<any>(1);

    let mouseX = 0;
    let mouseY = 0;

    const shouldLog = useRef(true);
    const shouldLog2 = useRef(true);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        let API = READ_PARAMETER + `?key=LI_PHONGBAN`;

        const takeParameterList = async () => {
            try {
                await ServiceUtils.getV2(API, {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    })
                    .then((res: any) => {
                        if (res.status === 200) {
                            dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
                        } else {
                            return;
                        }
                    });
            } catch (error: any) {
                toast.error("Tải dữ liệu thất bại.");
            }
        };
        takeParameterList();
    }, []);

    const getListUserByOrganization = (organizationId: any) => {
        return new Promise((resolve) => {

            const api = GET_LIST_USER_BY_ORGANIZATION.replaceAll('{organizationId}', organizationId);

            try {
                ServiceUtils.getV2(api, {
                        headers: {
                        },
                    })
                    .then((res: any) => {
                        if ((res.data || {}).StatusCode == 200) {
                            resolve(((res.data || {}).Data || {}).users || []);
                        } else {
                            resolve([]);
                        }
                    });
            } catch (error: any) {
                toast.error("Tải dữ liệu thất bại!");
            }
        });
    }

    const getListOrganization = () => {
        const accessToken = localStorage.getItem("accessToken");

        const API = READ_PARAMETER + `?key=LI_PHONGBAN`;

        const takeParameterList = async () => {
            try {
                await ServiceUtils.getV2(API, {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    })
                    .then((res: any) => {
                        if (res.status === 200) {
                            dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
                        } else {
                            return;
                        }
                    });
            } catch (error: any) {
                toast.error("Tải dữ liệu thất bại!");
            }
        };

        takeParameterList();
    }

    const getDiagram = () => {

        let API = GET_DIAGRAM;

        setDiagram([]);
        setUser([]);
        setRenderDiagram([]);
        setRenderDiagramLine([]);

        try {
            ServiceUtils.getV2(API, {
                    headers: {
                    },
                })
                .then((res: any) => {
                    if ((res.data || {}).StatusCode == 200) {
                        const departments = ((res.data || {}).Data || {}).departments || [];
                        const users = ((res.data || {}).Data || {}).users || [];

                        setDiagram(departments);

                        setUser(users);

                        console.log(users);

                        const renderDiagramResult: any = renderDiagram(departments, users);

                        const observer = new MutationObserver(function (mutations) {
                            const diagramBox = document.getElementById('diagramBox');

                            if (document.contains(diagramBox)) {
                                renderDiagramLine(renderDiagramResult.trees);

                                if (diagramBox) {
                                    const diagram = document.getElementById('diagram');

                                    if (diagram) {
                                        const scrollWidth = diagramBox?.scrollWidth ?? 0;

                                        const diagramElementFirst = document.querySelector('.diagram-element:nth-child(1)');

                                        let widthHalfDiagramElementFirst = 0;

                                        if (diagramElementFirst) {
                                            const widthDiagramElementFirst = diagramElementFirst.clientWidth ?? 0;

                                            widthHalfDiagramElementFirst = widthDiagramElementFirst / 2;
                                        }

                                        diagram.scrollLeft = (scrollWidth / 2) - ((scrollWidth / 2) / 2) - widthHalfDiagramElementFirst;
                                    }
                                }

                                observer.disconnect();
                            }
                        });

                        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });

                        setRenderDiagram(renderDiagramResult.elements);
                    } else {

                    }
                });
        } catch (error: any) {
            toast.error("Tải dữ liệu thất bại!");
        }
    }

    // funcs
    const _onChangeParameterKey = (e: any) => {
        e.preventDefault();
        setParameterChoose(e.target.value);
        dispatch(ONCHANGE_PARAMETERCHOOSE(e.target.value));
    };

    const _onClickCreateListOfGeneral = () => {
        context.setDataModal({
            callBack: getListOrganization,
        });
        context.setFuncs(MODAL_KEYS.createSettingOrganization);
        context.handleOpen();
    };

    const _onOpenDiagramOrganization = () => {
        if (!isOpenDiagramOrganization) {
            getDiagram();
        }

        setIsOpenDiagramOrganization(!isOpenDiagramOrganization);
    }

    // Preprocessing
    // state
    const tempParameterList = data.parameterListKey?.map((item: any) => {
        return {
            ...item,
            value: item.ParameterKey,
            label: convertNameParameter(item.ParameterKey),
        };
    });

    // funcs
    const _onChangeTempParameter = (data: any) => {
        if (data === null) {
            setParameterChoose(1);
            dispatch(ONCHANGE_PARAMETERCHOOSE(1));
        } else {
            setParameterChoose(data.ParameterKey);
            dispatch(ONCHANGE_PARAMETERCHOOSE(data.ParameterKey));
        }
    };

    const calculatorWidthDiagram = () => {
        let width = '100%';

        const navbarElement = document.getElementById('navbar');
        const optionListScrollElement = document.getElementById('optionListScroll');

        const widthNavbarElement = navbarElement?.clientWidth ?? 0;
        const widthOptionListScrollElement = optionListScrollElement?.clientWidth ?? 0;

        const totalWidth = widthNavbarElement + widthOptionListScrollElement + 70;

        width = `calc(100vw - ${totalWidth}px)`;

        return width;
    }

    const calculatorMinWidthDiagramBox = () => {
        let width = '150%';

        return width;
    }

    const calculatorMinHeightDiagramBox = () => {
        let height = '150%';

        return height;
    }

    const renderDiagramLine = (diagrams: any) => {
        const elements: any = [];
        let items = [];

        for (let i = 0; i < diagrams.length; i++) {
            items = diagrams[i].items || [];

            for (let m = 0; m < items.length; m++) {
                const parent = document.getElementById(`diagram-element-${items[m].ParameterID}`);

                if (parent) {
                    const containerParent = parent.offsetParent;

                    let offsetTopParent = 0;

                    if (containerParent) {
                        const containerParent2 = document.getElementById(containerParent.id);

                        offsetTopParent = containerParent2?.offsetTop ?? 0;
                    } else {
                        offsetTopParent = 0;
                    }

                    offsetTopParent += parent.offsetTop ?? 0;

                    const offsetLeftParent = parent.offsetLeft ?? 0;
                    const widthParent = parent.offsetWidth ?? 0;
                    const heightParent = parent.offsetHeight ?? 0;

                    const leftParent = offsetLeftParent + (widthParent / 2);
                    const topParent = offsetTopParent + heightParent;

                    const childrens = document.querySelectorAll(`[data-refId="${items[m].ParameterID}"]`);

                    for (let t = 0; t < childrens.length; t++) {
                        const children = document.getElementById(childrens[t].id);

                        if (children) {
                            const containerChildren = children.offsetParent;

                            let offsetTopChildren = 0;

                            if (containerChildren) {
                                const containerChildren2 = document.getElementById(containerChildren.id);

                                offsetTopChildren = containerChildren2?.offsetTop ?? 0;
                            } else {
                                offsetTopChildren = 0;
                            }

                            offsetTopChildren += children.offsetTop;

                            const offsetLeftChildren = children.offsetLeft ?? 0;
                            const widthChildren = children.offsetWidth ?? 0;

                            const leftChildren = offsetLeftChildren + (widthChildren / 2);
                            const topChildren = offsetTopChildren - 5;

                            elements.push(<svg className="diagram-line diagram-element-animation" style={{
                                display: 'block !important',
                                width: '100%',
                                height: '100%',
                                position: 'absolute'
                            }}>
                                <line x1={leftParent} y1={topParent} x2={leftChildren} y2={topChildren} style={{
                                    stroke: 'rgb(255, 0, 0)',
                                    strokeWidth: '1px'
                                }} /></svg>);
                        }
                    }
                }
            }
        }

        setRenderDiagramLine(elements);
    }

    const onEditDiagram = (item: any) => () => {
        context.setDataModal({
            callBack: getDiagram,
            item
        });
        context.setTaskId(item.ParameterID);
        context.setFuncs(MODAL_KEYS.detailSettingOrganization);
        context.handleOpen();
    }

    const onAddDiagram = (item: any) => () => {
        context.setDataModal({
            callBack: getDiagram,
            item
        });
        context.setFuncs(MODAL_KEYS.createSettingOrganization);
        context.handleOpen();
    }

    const getUserDiagramUpperLevelManager = (item: any, users: any) => {
        // {item.positionName}: {item.username}

        const userDiagrams = users.filter((p: any) => p.PhongBanID == item.ParameterID && p.DiagramType == DIAGRAM_TYPES.upperLevelManaer);

        if (userDiagrams.length <= 0) {
            return null;
        }

        const userDiagram = userDiagrams[0];

        const positionName = userDiagram.ChucVu || '';

        if (!positionName) {
            return null;
        }

        const usernames = userDiagrams.filter((p: any) => p.FullName).map((p: any) => p.FullName);

        if (usernames.length <= 0) {
            return {
                positionName,
                usernames: ['Trống']
            };
        }

        return {
            positionName,
            usernames
        };
    }

    const getUserDiagramMainManager = (item: any, users: any) => {
        // {item.positionName}: {item.username}

        const userDiagrams = users.filter((p: any) => p.PhongBanID == item.ParameterID && p.DiagramType == DIAGRAM_TYPES.mainManager);

        if (userDiagrams.length <= 0) {
            return null;
        }

        const userDiagram = userDiagrams[0];

        const positionName = userDiagram.ChucVu || '';

        if (!positionName) {
            return null;
        }

        const usernames = userDiagrams.filter((p: any) => p.FullName).map((p: any) => p.FullName);

        if (usernames.length <= 0) {
            return {
                positionName,
                usernames: ['Trống']
            };
        }

        return {
            positionName,
            usernames
        };
    }

    const getUserDiagramSubManager = (item: any, users: any) => {
        // {item.positionName}: {item.username}

        const userDiagrams = users.filter((p: any) => p.PhongBanID == item.ParameterID && p.DiagramType == DIAGRAM_TYPES.subManager);

        if (userDiagrams.length <= 0) {
            return null;
        }

        const userDiagram = userDiagrams[0];

        const positionName = userDiagram.ChucVu || '';

        if (!positionName) {
            return null;
        }

        const usernames = userDiagrams.filter((p: any) => p.FullName).map((p: any) => p.FullName);

        if (usernames.length <= 0) {
            return {
                positionName,
                usernames: ['Trống']
            };
        }

        return {
            positionName,
            usernames
        };
    }

    const onViewStaff = (item: any) => () => {
        getListUserByOrganization(item.ParameterID).then((res: any) => {
            const users = res || [];

            if (users.length > 0) {
                context.setFuncs(MODAL_KEYS.showStaffOrganization);
                context.setDataModal({
                    users,
                    item
                });
                context.handleOpen();
            } else {
                toast.info('Phòng ban này hiện không có nhân viên!');
            }
        });
    }

    const renderChildrenDiagramElement = (item: any, level: any, users: any, childrens: any) => {
        const userDiagramUpperLevelManager = getUserDiagramUpperLevelManager(item, users);
        const userDiagramMainManager = getUserDiagramMainManager(item, users);
        const userDiagramSubManager = getUserDiagramSubManager(item, users);

        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className={`diagram-element diagram-element-animation-delay`} id={`diagram-element-${item.ParameterID}`} data-refId={item.RefID} data-level={level} style={{
                width: '18rem',
                height: '10rem',
                borderRadius: '5px',
                backgroundColor: '#38BDF8',
                border: '1px solid #38BDF8',
                position: 'relative',
                margin: '0px 20px',
                marginBottom: '75px'
            }}>
                <div style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingBottom: '15px',
                }}>
                    <p className="diagram-element-organization-name" id={`diagram-element-organization-name-${item.ParameterID}`} style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#ffffff',
                        textAlign: 'center',
                        userSelect: 'none'
                    }}>{item.ParameterValue}</p>
                    <div style={{
                        height: '6.5rem',
                        overflowY: 'auto',
                        paddingRight: '5px'
                    }}>
                        {userDiagramUpperLevelManager && <div style={{
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '100px'
                            }}>
                                <p style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#000000',
                                    marginRight: '5px',
                                    userSelect: 'none'
                                }}>1. Quản lý bởi:</p>
                            </div>
                            <div>
                                {userDiagramUpperLevelManager.usernames.map((username: any, index: any) => {
                                    return <p key={`upper-level-user-diagram-${item.ParameterID}-${index}`} style={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#000000',
                                        userSelect: 'none'
                                    }}>{username}</p>
                                })}
                            </div>
                        </div>}
                        {userDiagramMainManager && <div style={{
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '100px'
                            }}>
                                <p style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#000000',
                                    marginRight: '5px',
                                    userSelect: 'none'
                                }}>2. {userDiagramMainManager.positionName}:</p>
                            </div>
                            <div>
                                {userDiagramMainManager.usernames.map((username: any, index: any) => {
                                    return <p key={`main-user-diagram-${item.ParameterID}-${index}`} style={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#ffffff',
                                        userSelect: 'none'
                                    }}>{username}</p>
                                })}
                            </div>
                        </div>}
                        {userDiagramSubManager && <div style={{
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '100px'
                            }}>
                                <p style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#000000',
                                    marginRight: '5px',
                                    userSelect: 'none'
                                }}>3. {userDiagramSubManager.positionName}:</p>
                            </div>
                            <div>
                                {userDiagramSubManager.usernames.map((username: any, index: any) => {
                                    return <p key={`sub-user-diagram-${item.ParameterID}-${index}`} style={{
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#000000',
                                        userSelect: 'none'
                                    }}>{username}</p>
                                })}
                            </div>
                        </div>}
                        <div style={{
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '100px'
                            }}>
                                <p style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#000000',
                                    marginRight: '5px',
                                    userSelect: 'none'
                                }}>4. Nhân viên:</p>
                            </div>
                            <div>
                                <p onClick={onViewStaff(item)} key={`staff-user-diagram-${item.ParameterID}`} style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#ffffff',
                                    cursor: 'pointer'
                                }}>Xem danh sách</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: -18,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <button onClick={onAddDiagram(item)} style={{
                        backgroundColor: '#4ADE80',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px'
                    }}>
                        <AddIcon fontSize="small" style={{
                            color: '#ffffff'
                        }} />
                    </button>
                </div>
                <button onClick={onEditDiagram(item)} style={{
                    position: 'absolute',
                    right: 2,
                    bottom: 2
                }}>
                    <EditIcon fontSize="small" style={{
                        color: '#ffffff'
                    }} />
                </button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                {childrens}
            </div>
        </div>;
    }

    const renderContainerDiagramElement = (item: any) => {
        return <div id={`diagram-container-${item.level}`} style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative'
        }}>
            {item.childrens || []}
        </div>;
    }

    const renderDiagram = (diagrams: any, users: any) => {
        let elements: any = [];
        const trees: any = [];

        const root = diagrams.find((p: any) => !p.RefID);

        if (!root) {
            return [];
        }

        const level = 1;

        elements.push({
            level: 1,
            childrens: [renderChildrenDiagramElement(root, level, users, [])]
        });

        trees.push({
            level,
            items: [root]
        });

        elements.push({
            level: 2,
            childrens: [renderDiagramItem(diagrams, elements, root, level, users, trees)]
        });

        return {
            elements,
            trees
        };
    }

    const renderDiagramItem = (diagrams: any, elements: any, parent: any, level: any, users: any, trees: any): any => {
        // if (!parent) {
        //     return;
        // }

        // level++;

        // elements.push({
        //     level,
        //     childrens: [],
        //     items: []
        // });

        // const element: any = elements.find((p: any) => p.level == level);

        // const childrensByParent = diagrams.filter((p: any) => p.RefID == parent.ParameterID);

        // const level2 = level;

        // for (let i = 0; i < childrensByParent.length; i++) {
        //     element.childrens.push(renderChildrenDiagramElement(childrensByParent[i], level, users));
        //     element.items.push(childrensByParent[i]);

        //     renderDiagramItem(diagrams, elements, childrensByParent[i], level2, users);
        // }

        if (!parent) {
            return;
        }

        const childrensByParent = diagrams.filter((p: any) => p.RefID == parent.ParameterID);

        let level2 = level;

        const childrens = [];

        if (childrensByParent.length > 0) {
            level++;

            level2 = level;

            trees.push({
                level,
                items: []
            });
        }

        const tree: any = trees.find((p: any) => p.level == level);

        for (let i = 0; i < childrensByParent.length; i++) {
            childrens.push(renderChildrenDiagramElement(childrensByParent[i], level, users, renderDiagramItem(diagrams, elements, childrensByParent[i], level2, users, trees)));

            tree.items.push(childrensByParent[i]);
        }

        return childrens;
    }

    const onMouseMoveDiagram = (e: any) => {
        const mouseXMove = e.nativeEvent.offsetX;
        const mouseYMove = e.nativeEvent.offsetY;

        const offsetXMove = mouseXMove - mouseX;
        const offsetYMove = mouseYMove - mouseY;

        const offsetXMoveAbs = Math.abs(offsetXMove);
        const offsetYMoveAbs = Math.abs(offsetYMove);

        const diagramBox = document.getElementById('diagram');

        if (diagramBox) {
            if (mouseX > 0 || mouseY > 0) {
                if (offsetXMoveAbs > offsetYMoveAbs) {
                    if (offsetXMove > 0) {
                        diagramBox.scrollLeft -= 15;
                    } else if (offsetXMove < 0) {
                        diagramBox.scrollLeft += 15;
                    }
                } else if (offsetXMoveAbs < offsetYMoveAbs) {
                    if (offsetYMove > 0) {
                        diagramBox.scrollTop -= 15;
                    } else if (offsetYMove < 0) {
                        diagramBox.scrollTop += 15;
                    }
                }
            }

            // if (mouseX > 0 || mouseY > 0) {
            //     if (offsetXMove > 0) {
            //         diagramBox.scrollLeft -= 15;
            //     } else if (offsetXMove < 0) {
            //         diagramBox.scrollLeft += 15;
            //     }
            // }

            // if (mouseY > 0) {
            //     if (offsetYMove > 0) {
            //         diagramBox.scrollTop -= 15;
            //     } else if (offsetYMove < 0) {
            //         diagramBox.scrollTop += 15;
            //     }
            // }
        }
    }

    const onMouseDownDiagram = (e: any) => {
        mouseX = e.nativeEvent.offsetX;
        mouseY = e.nativeEvent.offsetY;
    }

    const onMouseUpDiagram = (e: any) => {
        mouseX = 0;
        mouseY = 0;
    }

    const updateFontSizeTitle = (fontSize: any) => {
        const titles = document.querySelectorAll('.diagram-element-organization-name');

        for (let i = 0; i < titles.length; i++) {
            const title = document.getElementById(titles[i].id);

            if (title) {
                title.style.fontSize = fontSize;
            }
        }
    }

    const onScaleUp = () => {
        if (scale >= 1.75) {
            return;
        }

        const scaleUp = scale + 0.25;

        const fontSize = FONT_SIZE_SCALES[scaleUp.toString()];

        setScale(scaleUp);

        updateFontSizeTitle(fontSize);
    }

    const onScaleDown = () => {
        if (scale <= 0.25) {
            return;
        }

        const scaleDown = scale - 0.25;

        const fontSize = FONT_SIZE_SCALES[scaleDown.toString()];

        setScale(scaleDown);

        updateFontSizeTitle(fontSize);
    }

    return (
        <div className="h-full w-full flex flex-col">
            <Title />

            <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
                <div className="w-full h-[2rem] flex justify-end items-center">
                    {/* <button onClick={renderDiagramLine}>caca</button> */}
                    {isOpenDiagramOrganization && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '10px'
                    }}>
                        <label style={{
                            marginRight: '5px'
                        }}>Phóng to/thu nhỏ</label>
                        <button
                            className={`
                p-2 bg-primary rounded-md text-white
                hover:bg-green-400 items-center flex`}
                            onClick={onScaleDown}
                        >
                            <RemoveIcon fontSize="small" />
                        </button>
                        <p style={{
                            margin: '0px 5px',
                            width: '30px',
                            textAlign: 'center'
                        }}>{scale}</p>
                        <button
                            className={`
                p-2 bg-primary rounded-md text-white
                hover:bg-green-400 items-center flex`}
                            onClick={onScaleUp}
                        >
                            <AddIcon fontSize="small" />
                        </button>
                    </div>}
                    <button
                        className={`
                p-2 bg-primary rounded-md text-white
                hover:bg-green-400 items-center flex`}
                        onClick={_onOpenDiagramOrganization}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        <AccountTree fontSize="small" />
                        <p className="hidden lg:flex" style={{
                            marginLeft: '5px'
                        }}>{isOpenDiagramOrganization ? 'Danh sách phòng ban' : 'Sơ đồ tổ chức'}</p>
                    </button>
                    <button
                        className={`
                p-2 bg-green-500 rounded-md text-white
                hover:bg-green-400 items-center
                ${menu.PerInsert === true ? "flex" : "hidden"}
              `}
                        onClick={_onClickCreateListOfGeneral}
                    >
                        <AddIcon fontSize="small" />
                        <p className="hidden lg:flex">Thêm mới</p>
                    </button>
                </div>
                {isOpenDiagramOrganization ? <>
                    <div id="diagram" style={{
                        height: '100%',
                        overflow: 'auto',
                        width: calculatorWidthDiagram(),
                        borderRadius: '5px',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                    }}>
                        <div id="diagramBox" onMouseEnter={onMouseUpDiagram} onMouseUpCapture={onMouseUpDiagram} onMouseDownCapture={onMouseDownDiagram} onMouseMoveCapture={onMouseMoveDiagram} style={{
                            // minWidth: calculatorMinWidthDiagramBox(),
                            // minHeight: calculatorMinHeightDiagramBox(),
                            width: 'fit-content',
                            height: 'fit-content',
                            borderRadius: '5px',
                            padding: '5px',
                            position: 'relative',
                            transition: 'all 300ms ease-out',
                            transform: `scale(${scale})`
                        }}>
                            {renderDiagramLines}
                            {renderDiagrams.map((p: any) => {
                                return renderContainerDiagramElement(p);
                            })}
                        </div>
                    </div>
                </> : <Table />}
            </div>
        </div>
    );
};

export default ListOfOrganizationUI;
