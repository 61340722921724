import React, { useState, useEffect, useRef } from "react";

import Title from "../../../../components/title";

import SaveIcon from '@mui/icons-material/Save';

import { takePermission } from "../../../../funcs";

import { CONFIG_EMAIL, CONFIG_EMAILINFO } from "../../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../../utils/services";

const EmailAutomaticUI = () => {

  // state
  const [emailName, setEmailName] = useState<string>("");
  const [emailPassword, setEmailPassword] = useState<string>("");
  const [emailContent, setEmailContent] = useState<string>("");
  const [emailMark, setEmailMark] = useState<string>("");
  const [isActiveEmail, setIsActiveEmail] = useState<boolean>(false);

  const [passwordType, setPasswordType] = useState<string>("password");

  const _onClickShowPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
    } else if (passwordType === "text") {
      setPasswordType("password")
    }
  }

  const menu = takePermission(16);

  const shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      const accessToken = localStorage.getItem("accessToken");
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(
            CONFIG_EMAILINFO,
            {
              headers: {
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setEmailName(res.data.Data[0].EmailAddress);
                setEmailPassword(res.data.Data[0].EmailPassword);
                setEmailContent(res.data.Data[0].EmailContent);
                setEmailMark(res.data.Data[0].EmailMark);
                setIsActiveEmail(res.data.Data[0].IsActiveEmail)
              } else {
                toast.error("Tải dữ liệu thất bại.");
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      }

      takeData();
    }
  });

  const _onChangeEmailName = (e: any) => {
    e.preventDefault();
    setEmailName(e.target.value);
  }

  const _onChangeEmailPassword = (e: any) => {
    e.preventDefault();
    setEmailPassword(e.target.value);
  }

  const _onChangeEmailContent = (e: any) => {
    e.preventDefault();
    setEmailContent(e.target.value);
  }

  const _onChangeEmailMark = (e: any) => {
    e.preventDefault();
    setEmailMark(e.target.value);
  }

  const _onChangeIsActiveEmail = () => {
    setIsActiveEmail((value: any) => !value);
  }

  // funcs
  const _onClickUpdate = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      EmailAddress: emailName,
      EmailPassword: emailPassword,
      EmailMark: emailMark,
      EmailContent: emailContent,
      IsActiveEmail: isActiveEmail
    }

    try {
      await ServiceUtils.postV2(
        CONFIG_EMAIL,
        body,
        {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("Cập nhật thành công.");
          } else {
            toast.error("Cập nhật thất bại.");
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật thất bại.");
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] text-sm p-5 space-y-3">
        <div className="w-full h-[2rem] flex justify-end items-end">
          <button className={`
              p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 items-center space-x-1
              ${menu.PerEdit === true ? "flex" : "hidden"}
            `}
            onClick={_onClickUpdate}
          >
            <SaveIcon fontSize="small" />
            <p>Lưu lại</p>
          </button>
        </div>

        <div className="w-full h-[calc(100%-2rem)] flex">
          <div className="flex flex-col lg:w-1/2 w-full h-full lg:p-10 p-5 space-y-4">
            <div className="flex w-full h-max justify-between items-center">
              <p>Kích hoạt tính năng email trên hệ thống</p>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={isActiveEmail}
                onChange={_onChangeIsActiveEmail}
                readOnly={menu.PerEdit === true ? false : true}
              />
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Email</label>
              <input
                className={`w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${menu.PerEdit === true ? "" : "bg-slate-200"}`}
                placeholder="Nhập email"
                readOnly={menu.PerEdit === true ? false : true}
                value={emailName}
                onChange={(e: any) => { _onChangeEmailName(e) }}
              />
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Mật khẩu email</label>
              <input
                className={`${menu.PerEdit === true ? "" : "bg-slate-200"} w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200`}
                placeholder="Mật khẩu email"
                value={emailPassword}
                readOnly={menu.PerEdit === true ? false : true}
                onChange={(e: any) => { _onChangeEmailPassword(e) }}
                type="password"
              />
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Tên hiển thị</label>
              <input
                className={` ${menu.PerEdit === true ? "" : "bg-slate-200"} w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200`}
                placeholder="Nhập tên công việc"
                value={emailMark}
                readOnly={menu.PerEdit === true ? false : true}
                onChange={(e: any) => { _onChangeEmailMark(e) }}
              />
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Nội dung</label>
              <textarea
                className={`${menu.PerEdit === true ? "" : "bg-slate-200"} w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200`}
                placeholder="Nhập nội dung"
                value={emailContent}
                readOnly={menu.PerEdit === true ? false : true}
                onChange={(e: any) => { _onChangeEmailContent(e) }}
              />
              {/* <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value="3000"
              /> */}
            </div>
          </div>

          {/* <div className="flex flex-col w-1/2 h-full p-10 space-y-4">
            <div className="flex flex-col w-full h-max">
              <label>Tiêu đề gắn thêm</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="[GiaoViec4.0] - Thông báo:"
              />
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Chữ gắn thêm</label>
              <textarea
                className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Đây là nội dung gắn thêm vào email"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default EmailAutomaticUI;
