import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import SendIcon from "@mui/icons-material/Send";
import Popover from "@mui/material/Popover";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { CHECK_RESULT } from "../../../apis/_index";
import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
  NGUOI_NHAN_FILE_STEP_UPLOAD,
  LICH_SU_GIA_HAN,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_GIAO_VIEC_DETAIL } from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { truncateParagraph } from "../../../funcs/truncateParagraph";
import Button from "@mui/material/Button";
import { ModalController } from "../../../ui/homeUI/_index";
import { _onClickFuncs } from "../../../funcs/onClickShowBtn";
import { IndeterminateCheckBoxSharp } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import RecoveryTaskComponent from "../../recoveryTasks/recoveryTaskComponent";
import { MODAL_KEYS } from "../../../constants/constants";
import ServiceUtils from "../../../utils/services";

const ReadTotalTask_nguoigiao = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);

  const appData = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const [isTransfer, setIsTransfer] = useState(false);
  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any[]>([]);
  const [taskResults, setTaskResults] = useState<any>(null);
  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [isSendingChat, setIsSendingChat] = useState<boolean>(false);

  const [text, setText] = useState<string>("");
  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [fileListAPI, setFileListAPI] = useState<any>(null);
  const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);
  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);
  const shouldLog4 = useRef(true);

  const [filesList, setFilesList] = useState<any>([]);
  const [filesList2, setFilesList2] = useState<any>([]);
  const [selectedTypeOf, setSelectedTypeOf] = useState<number>(0);
  const [isViewHistoty, setIsViewHistoty] = useState<any>(null);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);

  const [listConfirmHistory, setListConfirmHistory] = useState<any[]>([]);

  const uploadFileChat = useRef<any>(null);
  const [content, setContent] = useState<any>(null);

  const [taskHistory, setTaskHistory] = useState<any[]>([]);
  const [takeHistoryDetail, setTakeHistoryDetail] = useState<any[]>();

  const context = useContext(ModalController);
  const handleShowContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleCloseShowContent = (e: any) => {
    setAnchorEl(null);
  };
  const openContent = Boolean(anchorEl);

  const urlLink = window.location.pathname;
  // funcs
  const _onChangeMultipleChoicePartner = (
    text: string,
    item: any = null,
    isViewHistoty: any = null
  ) => {
    handleOpenSub();
    if (
      text === "completedTaskContent" ||
      text === "multipleUploadFileContent"
    ) {
      setTaskContentHistoryId(item);
    }
    if (isViewHistoty === 1) {
      setIsViewHistoty(1);
    } else {
      setIsViewHistoty(0);
    }
    setFuncsSub(text);
  };

  const onRecoveryTask = (isTransfer: any) => {
    setIsTransfer(isTransfer);

    handleOpenSub();

    setFuncsSub(MODAL_KEYS.recoveryTask);
  };

  const onOpenRecoveryAndTransferTask = (isTransfer: any) => {
    setIsTransfer(isTransfer);

    handleOpenSub();

    setFuncsSub(MODAL_KEYS.editTaskRecoveryAndTransfer);
  };

  const _onClickSubModal = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _handleCheckedHistory = (taskReasons: any, checked: any) => {
    // if (statusCheck === false) {
    //   return "Không duyệt";
    // } else if (statusCheck === true) {
    //   return "Đã duyệt";
    // } else {
    //   return "Chưa duyệt";
    // }
    if (checked === false) {
      return "Bị trả về";
    } else if (checked === true) {
      return "Đã duyệt";
    } else {
      return "Chờ duyệt";
    }
  };

  const _handleCheckedColor = (taskReasons: any, checked: any) => {
    // if (taskReasons === "Không duyệt") {
    //   return "#ff0000";
    // } else if (taskReasons === "Chưa duyệt" || taskReasons === null) {
    //   return "#FFAA00";
    // } else {
    //   return "#00ff00";
    // }

    if (checked === false) {
      return "#ff0000";
    } else if (checked === true) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _onCheckResult = (id: any, taskId: any, checked: boolean) => {
    setIsLoading(true);

    let resultChecking: boolean;

    if (checked === true) {
      resultChecking = false;
    } else {
      resultChecking = true;
    }
    const checkResult = async () => {
      // const accessToken = localStorage.getItem("accessToken");

      const body: any = {
        ID: id,
        TaskID: taskId,
        Checked: resultChecking,
      };

      try {
        await ServiceUtils.postV2(CHECK_RESULT, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.status === 200) {
            const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

            const takeData = async () => {
              try {
                await ServiceUtils.getV2(API, {
                  headers: {
                    Accept: "text/plain",
                  },
                }).then((res: any) => {
                  if (res.status === 200) {
                    setTask(res.data.Data.task);
                    setTaskContents(res.data.Data.taskContents);
                    setTaskResults(res.data.Data.taskResults);
                    setTaskInfoText(res.data.Data.taskInfoText);
                    setTaskHistory(res.data.Data.taskHistory);

                    const fileListResponse =
                      res.data.Data.task.ListFiles.split(";");
                    const fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(",");

                    let tempFileArray: any[] = [];

                    fileListNameResponse.forEach((item: any, index: any) => {
                      let tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };

                      tempFileArray.push(tempObject);
                    });

                    setFileListAPI(tempFileArray);

                    const fileListResponse2 =
                      res.data.Data.task.ResultFiles.split(";");
                    const fileListNameResponse2 =
                      res.data.Data.task.ResultFilesName.split(",");

                    let tempFileArray2: any[] = [];

                    fileListNameResponse2.forEach((item: any, index: any) => {
                      let tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };

                      tempFileArray2.push(tempObject);
                    });

                    setFileListAPI2(tempFileArray2);
                    setIsLoading(false);
                  }
                });
              } catch (error: any) {
                setIsLoading(false);
              }
            };

            takeData();
          }
        });
      } catch (error: any) { }
    };

    checkResult();
  };

  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onChangeFileImage2 = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList2] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList2(tempArray);
  };

  const _onClickSentChat = () => {
    if (text === "" && filesList.length <= 0) {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");

      const formData = new FormData();
      let fileNameString: string = "";

      formData.append("TaskID", taskId);
      formData.append("Contents", text);
      formData.append("typeofNote", selectedTypeOf.toString());

      if (filesList) {
        for (let i = 0; i < filesList.length; i++) {
          formData.append(`File_ListFile`, filesList[i]);
          if (i === filesList.length - 1) {
            fileNameString += `${filesList[i].name}`;
          } else {
            fileNameString += `${filesList[i].name};`;
          }
        }
        formData.append("ListFileName", fileNameString);
      }

      const sentCode = async () => {
        try {
          setIsSendingChat(true);
          await ServiceUtils.postFormDataV2(SENT_MESSAGE, formData, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res: any) => {
              const data = res.data.Data[0];
              if (data.RCode === 200) {
                getDetail(0);

                setSelectedTypeOf(0);
                setText("");
                setFilesList([]);

                toast.success("Gửi trao đổi thành công");

                setIsSendingChat(false);
              } else {
                toast.error(data.RMessage || "Gửi trao đổi thất bại");
                setIsSendingChat(false);
              }
            })
            .catch((err: any) => {
              toast.error("Gửi trao đổi thất bại");
              setIsSendingChat(false);
            });
        } catch (error: any) {
          setIsSendingChat(false);
        }
      };

      sentCode();
    }
  };

  const getDetail = (isStart: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const API = NGUOI_GIAO_VIEC_DETAIL(taskId);
    if (isStart === 1) {
      if (uuTienList === null || doMatList === null) {
      } else {
        if (shouldLog.current) {
          shouldLog.current = false;

          const takeData = async () => {
            try {
              await ServiceUtils.getV2(API, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  setTask(res.data.Data.task);
                  setTaskContents(res.data.Data.taskContents);
                  setTaskContentsHistory(res.data.Data.taskContentHistory);
                  setTaskResults(res.data.Data.taskResults);
                  //setTaskInfoText(res.data.Data.taskInfoText)
                  setTaskHistory(res.data.Data.taskHistory);

                  let fileListResponse: any[] = [];
                  let fileListNameResponse: any[];
                  if (
                    res.data.Data.task.ListFiles &&
                    res.data.Data.task.ListFilesName
                  ) {
                    fileListResponse = res.data.Data.task.ListFiles.split(";");
                    fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(";");

                    let tempFileArray: any[] = [];
                    if (
                      fileListResponse.length > 0 &&
                      fileListNameResponse.length > 0
                    ) {
                      fileListNameResponse.forEach((item: any, index: any) => {
                        let tempObject: any;
                        if (item) {
                          tempObject = {
                            listFile: fileListResponse[index],
                            listFileName: item,
                          };
                        }
                        tempFileArray.push(tempObject);
                      });
                      setFileListAPI(tempFileArray);
                    }
                  }

                  let fileListResponse2: any[] = [];
                  let fileListNameResponse2: any[] = [];
                  if (
                    res.data.Data.task.ResultFiles &&
                    res.data.Data.task.ResultFilesName
                  ) {
                    fileListResponse2 =
                      res.data.Data.task.ResultFiles.split(";");
                    fileListNameResponse2 =
                      res.data.Data.task.ResultFilesName.split(";");
                    let tempFileArray2: any[] = [];
                    if (
                      fileListNameResponse2.length > 0 &&
                      fileListResponse2.length > 0
                    ) {
                      fileListNameResponse2.map((item: any, index: any) => {
                        let tempObject: any;
                        if (
                          item !== "" &&
                          item !== null &&
                          typeof item !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponse2[index],
                            listFileName: item,
                          };
                        }
                        if (tempObject) {
                          tempFileArray2.push(tempObject);
                        }
                      });
                      setFileListAPI2(tempFileArray2);
                    }
                  }

                  let _taskInfoText: any[] = res.data.Data.taskInfoText;
                  // debugger

                  let fileListResponseChat: any[] = [];
                  let fileListResponseNameChat: any[] = [];
                  if (_taskInfoText.length > 0) {
                    let tempFileArrFileChat: any[] = [];
                    _taskInfoText.map((item: any) => {
                      if (item?.ListFile && item?.ListFileName) {
                        fileListResponseChat = item?.ListFile.split(";");
                        fileListResponseNameChat =
                          item?.ListFileName.split(";");

                        let arr: any[] = [];
                        if (
                          fileListResponseNameChat.length > 0 &&
                          fileListResponseChat.length > 0
                        ) {
                          fileListResponseNameChat.map(
                            (itemC: any, index: any) => {
                              let tempObject: any;

                              if (
                                itemC !== "" &&
                                itemC !== null &&
                                typeof itemC !== "undefined"
                              ) {
                                tempObject = {
                                  listFile: fileListResponseChat[index],
                                  listFileName: itemC,
                                };
                              }
                              if (tempObject) {
                                arr.push(tempObject);
                              }
                            }
                          );

                          if (arr) {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: arr,
                            });
                          } else {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: [],
                            });
                          }
                        }
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    });
                    setTaskInfoText(tempFileArrFileChat);
                  }
                }
              });
            } catch (error: any) {
              // ERROR: here
            }
          };

          // const API = LICH_SU_GIA_HAN(taskId);
          // const fetchData = async () => {
          //   try {
          //     await axios
          //       .get(API, {
          //         headers: {
          //           Authorization: "Bearer " + accessToken,
          //           Accept: "text/plain",
          //         },
          //       })
          //       .then((res: any) => {
          //         if (((res || {}).data || {}).StatusCode === 200) {
          //           setListConfirmHistory(((res || {}).data || {}).Data || []);
          //         } else {
          //           toast.error(res.data.Message || "Lỗi không xác định");
          //         }
          //       });
          //   } catch (error: any) {
          //     toast.error(error || "Tải dữ liệu thất bại");
          //   }
          // };

          // fetchData();

          takeData();
        }
      }
    } else {
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setTask(res.data.Data.task);
              setTaskContents(res.data.Data.taskContents);
              setTaskContentsHistory(res.data.Data.taskContentHistory);
              setTaskResults(res.data.Data.taskResults);
              //setTaskInfoText(res.data.Data.taskInfoText)
              setTaskHistory(res.data.Data.taskHistory);

              let fileListResponse: any[] = [];
              let fileListNameResponse: any[];
              if (
                res.data.Data.task.ListFiles &&
                res.data.Data.task.ListFilesName
              ) {
                fileListResponse = res.data.Data.task.ListFiles.split(";");
                fileListNameResponse =
                  res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];
                if (
                  fileListResponse.length > 0 &&
                  fileListNameResponse.length > 0
                ) {
                  fileListNameResponse.forEach((item: any, index: any) => {
                    let tempObject: any;
                    if (item) {
                      tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };
                    }
                    tempFileArray.push(tempObject);
                  });
                  setFileListAPI(tempFileArray);
                }
              }

              let fileListResponse2: any[] = [];
              let fileListNameResponse2: any[] = [];
              if (
                res.data.Data.task.ResultFiles &&
                res.data.Data.task.ResultFilesName
              ) {
                fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                fileListNameResponse2 =
                  res.data.Data.task.ResultFilesName.split(";");
                let tempFileArray2: any[] = [];
                if (
                  fileListNameResponse2.length > 0 &&
                  fileListResponse2.length > 0
                ) {
                  fileListNameResponse2.map((item: any, index: any) => {
                    let tempObject: any;
                    if (
                      item !== "" &&
                      item !== null &&
                      typeof item !== "undefined"
                    ) {
                      tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };
                    }
                    if (tempObject) {
                      tempFileArray2.push(tempObject);
                    }
                  });
                  setFileListAPI2(tempFileArray2);
                }
              }

              let _taskInfoText: any[] = res.data.Data.taskInfoText;
              // debugger

              let fileListResponseChat: any[] = [];
              let fileListResponseNameChat: any[] = [];
              if (_taskInfoText.length > 0) {
                let tempFileArrFileChat: any[] = [];
                _taskInfoText.map((item: any) => {
                  if (item?.ListFile && item?.ListFileName) {
                    fileListResponseChat = item?.ListFile.split(";");
                    fileListResponseNameChat = item?.ListFileName.split(";");

                    let arr: any[] = [];
                    if (
                      fileListResponseNameChat.length > 0 &&
                      fileListResponseChat.length > 0
                    ) {
                      fileListResponseNameChat.map((itemC: any, index: any) => {
                        let tempObject: any;

                        if (
                          itemC !== "" &&
                          itemC !== null &&
                          typeof itemC !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponseChat[index],
                            listFileName: itemC,
                          };
                        }
                        if (tempObject) {
                          arr.push(tempObject);
                        }
                      });

                      if (arr) {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: arr,
                        });
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    }
                  } else {
                    tempFileArrFileChat.push({ ...item, listFileInChat: [] });
                  }
                });
                setTaskInfoText(tempFileArrFileChat);
              }
            }
          });
        } catch (error: any) {
          // ERROR: here
        }
      };

      takeData();
    }
  };

  useEffect(() => {
    getDetail(1);
  });

  // useEffect(() => {
  //   if (shouldLog4.current) {
  //     shouldLog4.current = false
  //     const accessToken = localStorage.getItem("accessToken")
  //     const API = LICH_SU_GIA_HAN(taskId);

  //     const fetchData = async () => {
  //       try {
  //         await axios
  //           .get(API, {
  //             headers: {
  //               Authorization: "Bearer " + accessToken,
  //               Accept: "text/plain",
  //             },
  //           })
  //           .then((res: any) => {
  //             if (((res || {}).data || {}).StatusCode === 200) {
  //               setListConfirmHistory(((res || {}).data || {}).Data || []);
  //             } else {
  //               toast.error(res.data.Message || "Lỗi không xác định");
  //             }
  //           });
  //       } catch (error: any) {
  //         toast.error(error || "Tải dữ liệu thất bại");
  //       }
  //     };

  //     fetchData();
  //   }
  // })

  useEffect(() => {
    if (uuTienList === null || doMatList === null) {
      if (shouldLog2.current) {
        // const accessToken = localStorage.getItem("accessToken");
        shouldLog2.current = false;

        const takeUuTien = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setUuTienList(res.data.Data);
            });
          } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
          }
        };

        const takeDoMat = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setDoMatList(res.data.Data);
            });
          } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
          }
        };

        takeUuTien();
        takeDoMat();
      }
    }
  });

  useEffect(() => {
    if (appData.userList === null && shouldLog3.current) {
      shouldLog3.current = false;

      const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
          });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };
      takeData();
    }
  });

  useEffect(() => {
    _handleTaskContentHistory();
  }, [taskContents, taskContentsHistory]);

  const _handleTaskContentHistory = () => {
    if (taskContents.length > 0 && taskContentsHistory.length > 0) {
      let _taskContent: any[] = [];
      let foundObject: any[] = [];
      let _foundObject: any;

      _taskContent = taskContents.map((item1: any) => {
        foundObject = taskContentsHistory.filter(
          (item2: any) => item1.ID === item2.Task_ContentID
        );
        if (foundObject) {
          _foundObject = foundObject.find(
            (x: any, index: any) =>
              x.Checked === null || index == foundObject.length - 1
          );

          return { ...item1, taskContentsHistory: _foundObject };
        } else {
          return { ...item1 };
        }
      });
      set_TaskContents(_taskContent);
    } else {
      set_TaskContents(taskContents);
    }
  };

  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };

  const _handleChecked = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "Người thực hiện chính không duyệt";
    } else if (statusCheck === true && ownerChecked === null) {
      return "Người giao việc chưa duyệt";
    } else if (statusCheck === null && ownerChecked === null) {
      return "Người thực hiện chính chưa duyệt";
    } else if (statusCheck === true && ownerChecked === false) {
      return "Người giao việc không duyệt";
    } else if (statusCheck === true && ownerChecked === true) {
      return "Người giao việc đã duyệt";
    }
  };

  const _handleColor = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "#a855f7";
    } else if (statusCheck === true && ownerChecked === null) {
      return "#FFAA00";
    } else if (statusCheck === null && ownerChecked === null) {
      return "#78716c";
    } else if (statusCheck === true && ownerChecked === false) {
      return "#ff0000";
    } else if (statusCheck === true && ownerChecked === true) {
      return "#00ff00";
    }
  };

  const _handleCheckCompled = (data: any) => {
    if (data === 1) {
      getDetail(0);
    }
  };

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  const [idHistory, setIdHistory] = useState<string>("");

  // funcs
  const _onClickDetailHistory = (id: any, index: number) => {
    setFuncsSub("watchTaskHistory");
    setIdHistory(id);

    setTakeHistoryDetail(taskHistory.find((item) => item?.ID === id));

    handleOpenSub();
  };

  const _onOpenPopupShareTask = (taskID: any) => {
    setFuncsSub("shareTask");
    handleOpenSub();
  };

  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [taskInfoText]);

  return (
    <div
      className="
      flex flex-col bg-white
      
      lg:w-[80rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        timeExtend={task === null ? "" : task.DeadLine}
        hoursExtend={task === null ? "" : task?.HoursUsed}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        isTransfer={isTransfer}
        taskHistory={taskHistory}
        takeHistoryDetail={takeHistoryDetail}
        isViewHistoty={isViewHistoty}
        taskContentsHistory={taskContentsHistory}
        taskContentHistoryId={taskContentHistoryId}
        taskId={taskId}
        idHistory={idHistory}
        _taskContents={_taskContents}
        _handleCheckCompled={_handleCheckCompled}
        taskInfoText={taskInfoText}
        task={task === null ? " " : task}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {task === null ||
        taskContents === null ||
        taskResults === null ||
        taskInfoText === null ||
        appData.userList === null ||
        isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <div className="lg:flex hidden w-full h-[calc(100%-6rem)] overflow-hidden p-4 text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 w-3/5 h-full pr-5 overflow-auto border-r-[1px] border-primary ">
              <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã công việc - {task === null ? "" : task.TaskCode}
              </p>

              {/* elements */}
              <div className="flex flex-col w-full h-max">
                <label>Tên công việc</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={task === null ? "" : task.TaskName}
                  disabled
                />
              </div>
              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${task === null ? "" : "font-bold"
                      }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${task === null ? "" : task.PriorityLevelColor
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${task === null ? "" : "font-bold"
                      }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${task === null ? "" : task.SecurityLevelColor
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người đánh giá</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskReviewerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Kết quả</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Chưa đánh giá"
                    value={task === null ? "" : task.TaskScorecardName}
                    disabled
                  />
                </div>
              </div>

              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md pt-2 px-2 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}

              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {_taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Trạng thái</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {_taskContents.map((item: any, index: any) => (
                        <>
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {item?.SortOrder}
                            </td>
                            <td className="border border-slate-300">
                              <>
                                {item?.taskContentsHistory &&
                                  item?.taskContentsHistory.IsCompleted ===
                                  true ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: _handleColor(
                                          item?.taskContentsHistory.Checked,
                                          item?.taskContentsHistory.OwnerChecked
                                        ),
                                        color: "white",
                                        padding: 5,
                                        textAlign: "center",
                                        borderRadius: 13,
                                        width: "fit-content",
                                      }}
                                    >
                                      {_handleChecked(
                                        item?.taskContentsHistory.Checked,
                                        item?.taskContentsHistory.OwnerChecked
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#00B1FF",
                                      color: "white",
                                      padding: 5,
                                      textAlign: "center",
                                      borderRadius: 13,
                                      width: "fit-content",
                                    }}
                                  >
                                    Chưa có kết quả
                                  </div>
                                )}
                              </>
                            </td>
                            <td className="border border-slate-300">
                              <p>{item?.StepName}</p>
                              <p>
                                <i>
                                  Người thực hiện: <b>{item?.UserDoName}</b>
                                </i>
                              </p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <p>
                                {moment(item?.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <>
                                <div className="dropdown">
                                  <button className="dropbtn">
                                    <MoreHorizIcon fontSize="small" />
                                  </button>

                                  <div className="dropdown-content text-xs font-semibold">
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onChangeMultipleChoicePartner(
                                          "multipleUploadFileContent",
                                          item?.ID,
                                          1
                                        )
                                      }
                                    >
                                      <div className="flex w-full h-full space-x-1">
                                        <HistoryIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        <div>Xem lịch sử</div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max">
                  {fileListAPI === null ? <></> : <label>File đính kèm</label>}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}

                    {fileListAPI === null ? (
                      <></>
                    ) : (
                      fileListAPI.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="flex w-full h-max text-primary items-center justify-between"
                        >
                          <a
                            href={item?.listFile}
                            target="_blank"
                            rel="noreferrer"
                            className="flex w-5/6 h-max space-x-1"
                          >
                            <InsertDriveFileTwoToneIcon fontSize="small" />
                            <p>{item?.listFileName}</p>
                          </a>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max">
                {/* elements */}
                <div className="flex flex-col w-max h-max space-y-3">
                  {taskResults.length !== 0 ? (
                    <>
                      <label>Kết quả cần đạt</label>
                      {/* element */}
                      {taskResults.map((item: any, index: any) => (
                        <div className="h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}

                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={item?.Checked}
                                onClick={() =>
                                  _onCheckResult(
                                    item?.ID,
                                    item?.TaskID,
                                    item?.Checked
                                  )
                                }
                                disabled={item?.Checked ? true : false}
                              />
                              <p>{item?.ResultText}</p>
                            </div>

                            {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item?.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item?.ID,
                                  item?.TaskID,
                                  item?.Checked
                                )
                              }
                              disabled={item?.Checked ? true : false}
                            />
                          </div> */}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {fileListAPI2.length !== 0 ? (
                    <>
                      <label>File kết quả</label>

                      {/* fields of file */}
                      <div className="flex flex-col w-full h-max">
                        {/* element */}
                        <div className="flex w-full h-max text-primary items-center justify-between">
                          <div className="flex flex-col w-full h-max">
                            {fileListAPI2.map((item: any, index: any) => (
                              <div key={index} className="flex w-full h-max">
                                <a
                                  href={item?.listFile}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex w-5/6 h-max space-x-1"
                                >
                                  <InsertDriveFileTwoToneIcon fontSize="small" />
                                  <p>{item?.listFileName}</p>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* <div className="flex flex-col w-full h-max">
                <label>Nội dung hoàn thành</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập nội dung hoàn thành"
                  disabled
                />
              </div> */}
              <div className="flex flex-col w-full h-max drop-shadow-lg ">
                <p className="">Lịch sử xử lý công việc</p>
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 ">Lần</th>
                      <th className="border border-slate-300">Trạng thái</th>
                      <th className="border border-slate-300">Thời gian</th>
                      <th className="border border-slate-300">
                        Nội dung báo cáo
                      </th>
                      <th className="border border-slate-300">Nhận xét</th>
                      {/* <th className="border border-slate-300">Người duyệt</th> */}
                      <th className="border border-slate-300">Ngày duyệt</th>
                      <th className="border border-slate-300"></th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {taskHistory === null ? (
                      <></>
                    ) : (
                      taskHistory.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white">
                          <td className="text-center border border-slate-300">
                            {index + 1}
                          </td>
                          <td className="border border-slate-300">
                            <div
                              style={{
                                backgroundColor: _handleCheckedColor(
                                  item?.Reason,
                                  item?.Checked
                                ),
                                color: "white",
                                padding: 5,
                                textAlign: "center",
                                borderRadius: 13,
                                width: "fit-content",
                              }}
                            >
                              {_handleCheckedHistory(
                                item?.Reason,
                                item?.Checked
                              )}
                            </div>
                          </td>
                          <td className="border border-slate-300">
                            <div className="w-full h-max flex flex-col justify-center items-center">
                              <p>
                                {moment(item?.DateAdd).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item?.DateAdd).format("HH:mm:ss")}</p>
                            </div>
                          </td>

                          <td className="text-center border border-slate-300">
                            <>
                              <p>
                                {truncateParagraph(
                                  item?.FinishedContent || " ",
                                  4
                                )}
                              </p>
                              {item?.FinishedContent?.split(" ").length > 4 ? (
                                <Button
                                  onClick={(e) => (
                                    handleShowContent(e),
                                    setContent(item?.FinishedContent)
                                  )}
                                  aria-describedby="simple-popover"
                                  title="Xem chi tiết nội dung"
                                >
                                  <MoreHorizIcon />
                                </Button>
                              ) : null}
                            </>
                          </td>
                          <td className="text-center border border-slate-300">
                            <>
                              <p>{truncateParagraph(item?.Reason || " ", 4)}</p>

                              {item?.Reason?.split(" ").length > 4 ? (
                                <Button
                                  onClick={(e) => (
                                    handleShowContent(e),
                                    setContent(item?.Reason)
                                  )}
                                  aria-describedby="simple-popover"
                                  title="Xem chi tiết lý do"
                                >
                                  <MoreHorizIcon />
                                </Button>
                              ) : null}
                            </>
                          </td>
                          {/* <td className="text-center border border-slate-300">
                            <p>{item?.UserCheckedName}</p>
                          </td> */}
                          <td className="text-center border border-slate-300">
                            {item?.CheckedDate ? (
                              <>
                                <p>
                                  {moment(item?.CheckedDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p>
                                  {moment(item?.CheckedDate).format("HH:mm:ss")}
                                </p>
                              </>
                            ) : null}
                          </td>
                          {
                            <td className="text-center border border-slate-300">
                              <>
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickDetailHistory(item?.ID, index)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex items-center space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div className="hidden lg:block whitespace-nowrap">
                                      <span className="block">Chi tiết</span>
                                      <span className="block">đánh giá</span>
                                    </div>
                                    <div className="block lg:hidden">
                                      <span className="block">
                                        Chi tiết đánh giá
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </>
                            </td>
                          }
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <Popover
                  id="simple-popover"
                  open={openContent}
                  anchorEl={anchorEl}
                  onClose={handleCloseShowContent}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                >
                  {/* <Typography sx={{ p: 2 }}> {content}</Typography> */}
                  <div className="break-word w-max max-w-xl h-max p-2">
                    {content}
                  </div>
                </Popover>
              </div>
            </div>

            <div className="styled-scrollbars w-2/5 h-full pl-5 flex flex-col space-y-3 overflow-hidden">
              <div className="styled-scrollbars relative flex flex-col w-full h-full">
                <div className="flex absolute top-0 w-full h-[1.5rem] bg-white font-bold mb-2">
                  <p>THÔNG TIN TRAO ĐỔI</p>
                </div>

                <div
                  className="styled-scrollbars absolute top-[1.5rem] bottom-[6.5rem] w-full h-[calc(100%-8rem)] overflow-auto flex flex-col space-y-2"
                  ref={chatContainerRef}
                >
                  {taskInfoText === null ? (
                    <></>
                  ) : (
                    taskInfoText.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          className={
                            item?.UserAdd === appData.userData.Id
                              ? "flex flex-col w-full h-max justify-end items-end"
                              : "flex flex-col w-full h-max justify-start items-start"
                          }
                        >
                          <p className="text-xs text-slate-500">
                            <b>{item?.UserAddName ? item?.UserAddName : ""}</b>
                          </p>
                          <div
                            className={
                              item?.UserAdd === appData.userData.Id
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-primary pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-white"
                                : item?.typeofNote === 0
                                  ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                                  : item?.typeofNote === 1
                                    ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                                    : "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                            }
                          >
                            <div className="w-full h-full flex flex-col">
                              <p>{item?.Contents}</p>
                              {item?.listFileInChat.length > 0 ? (
                                <>
                                  {item?.listFileInChat.map(
                                    (itemFChat: any, indexFChat: any) => (
                                      <a
                                        key={indexFChat}
                                        className={
                                          item?.UserAdd === appData.userData.Id
                                            ? `text-white text-xs hover:underline`
                                            : `text-primary text-xs hover:underline`
                                        }
                                        href={itemFChat.listFile}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <FindInPageIcon fontSize="small" />
                                        {itemFChat.listFileName}
                                      </a>
                                    )
                                  )}
                                </>
                              ) : null}
                            </div>
                            <p className="text-left text-[12px] text-white-500">
                              {moment(item?.DateAdd).format("HH:mm:ss")} -{" "}
                              {moment(item?.DateAdd).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </div>

                <div className="flex flex-col absolute bottom-0 w-full h-[6.5rem] bg-slate-100 mt-2 rounded-sm">
                  <div className="flex items-center w-full h-[2rem]">
                    {/* elements */}
                    {/* <button className="flex justify-center items-center w-[2rem] h-full text-slate-500 hover:text-slate-400">
                      <input
                        className={`w-max h-max`}
                        multiple type='file'
                      />
                      <UploadFileTwoToneIcon fontSize="small" />
                    </button> */}
                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                        onClick={_onClickChooseFileChat}
                      >
                        <AttachFileIcon fontSize="small" />
                        <p>Chọn tệp tin</p>
                      </button>
                    </div>
                    <input
                      ref={uploadFileChat}
                      className={`w-max h-max`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage(e)}
                      hidden
                    />
                    <p
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      {filesList.length > 0
                        ? filesList.length + " tệp tin được chọn"
                        : ""}
                    </p>

                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className={`
                        flex justify-center p-2 items-center w-max h-full text-slate-500 hover:text-slate-400
                        ${selectedTypeOf === 0 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(0)}
                      >
                        <p>Bình thường</p>
                      </button>
                      <button
                        className={`
                        flex justify-center p-2 items-center w-max h-full text-green-500 hover:text-green-400
                        ${selectedTypeOf === 1 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(1)}
                      >
                        <p>Ưu tiên</p>
                      </button>
                      <button
                        className={`
                        flex justify-center p-2 items-center w-max h-full text-red-500 hover:text-red-400
                        ${selectedTypeOf === 2 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(2)}
                      >
                        <p>Khẩn cấp</p>
                      </button>
                    </div>
                  </div>

                  <div className="flex w-full h-[4.5rem]">
                    <div className="w-4/5 flex h-full">
                      <textarea
                        className="w-full h-full rounded-md p-2 border-2 border-slate-200"
                        placeholder="Nhập nội dung"
                        value={text}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="w-1/5 h-full flex justify-center items-center">
                      <button
                        className="bg-primary hover:bg-green-500 w-max h-max p-2 rounded-md font-semibold"
                        onClick={_onClickSentChat}
                      >
                        <div className="flex text-white">
                          {isSendingChat === false ? (
                            <>
                              <span style={{ paddingRight: 3 }}>
                                <p>Gửi</p>
                              </span>
                              <SendIcon fontSize="small" />
                            </>
                          ) : (
                            <div className="w-full h-full flex justify-center items-center">
                              <LoaderSpinner w={20} h={20} />
                            </div>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  phone */}
          <div className="lg:hidden flex w-full h-[calc(100%-6rem)] p-0 overflow-hidden text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 p-4 w-full h-full overflow-auto border-r-[1px] border-primary ">
              {/*  TaskName - TaskCode */}
              <div>
                <p className="flex w-full font-semibold">
                  {task === null ? "" : task.TaskName} -{" "}
                  {task === null ? "" : task.TaskCode}
                </p>
              </div>
              {/*  Uu tien */}
              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${task === null ? "" : "font-bold"
                      }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${task === null ? "" : task.PriorityLevelColor
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${task === null ? "" : "font-bold"
                      }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${task === null ? "" : task.SecurityLevelColor
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item?.Id}>
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>
              {/*  Do mat */}
              <div className="flex w-full h-max gap-3">
                <div className="w-full h-max flex flex-col">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
                
              </div>

              <div className="flex w-full h-max gap-3">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max gap-3">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người đánh giá</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskReviewerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Kết quả</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Chưa đánh giá"
                    value={task === null ? "" : task.TaskScorecardName}
                    disabled
                  />
                </div>
              </div>

              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/*  Nguoi phoi hop */}
              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md px-1 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}
              {/*Description*/}
              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>
              {/*Noted*/}
              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {/*Step Table*/}
              {_taskContents.length !== 0 ? (
                <div className="flex flex-col w-full h-max">
                  <p>Bước thực hiện</p>
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">Bước</th>
                        <th className="border border-slate-300">Trạng thái</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">Thời hạn</th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {_taskContents.map((item: any, index: any) => (
                        <>
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {item?.SortOrder}
                            </td>
                            <td className="border border-slate-300">
                              <>
                                {item?.taskContentsHistory &&
                                  item?.taskContentsHistory.IsCompleted ===
                                  true ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: _handleColor(
                                          item?.taskContentsHistory.Checked,
                                          item?.taskContentsHistory.OwnerChecked
                                        ),
                                        color: "white",
                                        padding: 5,
                                        textAlign: "center",
                                        borderRadius: 13,
                                        width: "fit-content",
                                      }}
                                    >
                                      {_handleChecked(
                                        item?.taskContentsHistory.Checked,
                                        item?.taskContentsHistory.OwnerChecked
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#00B1FF",
                                      color: "white",
                                      padding: 5,
                                      textAlign: "center",
                                      borderRadius: 13,
                                      width: "fit-content",
                                    }}
                                  >
                                    Chưa có kết quả
                                  </div>
                                )}
                              </>
                            </td>
                            <td className="border border-slate-300">
                              <p>{item?.StepName}</p>
                              <p>
                                <i>
                                  Người thực hiện: <b>{item?.UserDoName}</b>
                                </i>
                              </p>
                            </td>
                            <td className="text-center border border-slate-300">
                              <p>
                                {moment(item?.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item?.DeadLine).format("HH:mm:ss")}</p>

                              <>
                                <div className="dropdown my-2">
                                  {/* button options */}
                                  <button className="dropbtn">
                                    <MoreHorizIcon fontSize="small" />
                                  </button>

                                  {/* options fields */}
                                  <div className="dropdown-content text-xs font-semibold">
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onChangeMultipleChoicePartner(
                                          "multipleUploadFileContent",
                                          item?.ID,
                                          1
                                        )
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <HistoryIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Xem lịch sử</div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {/* Result */}
              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max">
                  {fileListAPI === null ? <></> : <label>File đính kèm</label>}
                  <div className="flex flex-col w-full h-max">
                    {/* element */}

                    {fileListAPI === null ? (
                      <></>
                    ) : (
                      fileListAPI.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="flex w-full h-max text-primary items-center justify-between"
                        >
                          <a
                            href={item?.listFile}
                            target="_blank"
                            rel="noreferrer"
                            className="flex w-5/6 h-max space-x-1"
                          >
                            <InsertDriveFileTwoToneIcon fontSize="small" />
                            <p className="text-xs">{item?.listFileName}</p>
                          </a>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 h-max space-y-3">
                  {taskResults.length !== 0 ? (
                    <>
                      <label>Kết quả cần đạt</label>
                      {taskResults.map((item: any, index: any) => (
                        <div className="w-max h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}

                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={item?.Checked}
                                disabled
                              />
                              <p>{item?.ResultText}</p>
                            </div>
                            {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item?.Checked}
                              disabled
                            />
                          </div> */}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {fileListAPI2.length !== 0 ? (
                    <>
                      <label>File kết quả</label>

                      {/* fields of file */}
                      <div className="flex flex-col w-full h-max">
                        {/* element */}
                        <div className="flex w-full h-max text-primary items-center justify-between">
                          <div className="flex flex-col w-full h-max">
                            {fileListAPI2.length === 0 ? (
                              <></>
                            ) : (
                              fileListAPI2.map((item: any, index: any) => (
                                <div key={index} className="flex w-full h-max">
                                  <a
                                    href={item?.listFile}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex w-5/6 h-max space-x-1"
                                  >
                                    <InsertDriveFileTwoToneIcon fontSize="small" />
                                    <p>{item?.listFileName}</p>
                                  </a>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="flex flex-col w-1/2 h-max"></div>
              </div>

              <div className="flex flex-col w-full h-max">
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 drop-shadow-lg">
                        Lịch sử xử lý công việc
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {taskHistory === null ? (
                      <></>
                    ) : (
                      taskHistory.map((item: any, index: any) => (
                        <tr key={index} className="w-max h-[3rem] bg-white ">
                          <td className="border border-slate-300">
                            <div className="flex w-full justify-between">
                              <p className="font-bold">
                                Báo cáo lần {index + 1}:
                              </p>
                              <div
                                className={`drop-shadow-lg font-semibold`}
                                style={{
                                  backgroundColor: _handleCheckedColor(
                                    item?.Reason,
                                    item?.Checked
                                  ),
                                  color: "white",
                                  padding: 5,
                                  textAlign: "center",
                                  borderRadius: 13,
                                  width: "fit-content",
                                }}
                              >
                                {_handleCheckedHistory(
                                  item?.Reason,
                                  item?.Checked
                                )}
                              </div>
                            </div>
                            <p>
                              Thời gian:&nbsp;
                              <span className="italic">
                                {moment(item?.DateAdd).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )}
                              </span>
                            </p>

                            <p>Nội dung duyệt:&nbsp;{item?.FinishedContent}</p>

                            {item?.Checked === false ? (
                              <p>
                                Lý do bị trả về:&nbsp;
                                <span className="font-bold text-red-500">
                                  {item?.Reason}
                                </span>
                              </p>
                            ) : (
                              item?.Checked === true && (
                                <p>
                                  Nhận xét:&nbsp;
                                  <span className="font-bold text-green-500">
                                    {item?.Reason}
                                  </span>
                                </p>
                              )
                            )}

                            {item?.CheckedDate ? (
                              <>
                                <p>
                                  Ngày duyệt:&nbsp;
                                  <span className="italic">
                                    {moment(item?.CheckedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )}
                                  </span>
                                </p>
                              </>
                            ) : null}
                            <>
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() =>
                                  _onClickDetailHistory(item?.ID, index)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex items-center space-x-1">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div className="hidden lg:block whitespace-nowrap">
                                    <span className="block">Chi tiết</span>
                                    <span className="block">đánh giá</span>
                                  </div>
                                  <div className="block lg:hidden">
                                    <span className="block">
                                      Chi tiết đánh giá
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center lg:text-sm text-xs">
        {task === null ? (
          <div className="flex h-max w-max space-x-4"></div>
        ) : (
          <div className="flex justify-end flex-wrap h-max w-full gap-3">
            {/* Delay button */}

            {urlLink === "/working/receiver/task-processing" ? (
              <button
                className="
            flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
            hover:bg-green-400
          "
                onClick={() => _onClickSubModal("finishTask")}
              >
                <DoneIcon fontSize="small" />
                <p>Hoàn tất</p>
              </button>
            ) : task?.TaskStatus === 5 ? (
              <></>
            ) : (
              <>
                <RecoveryTaskComponent
                  task={task}
                  onOpenRecoveryTask={onRecoveryTask}
                  onOpenRecoveryAndTransferTask={onOpenRecoveryAndTransferTask}
                />
                <button
                  className="
                  flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
                  hover:bg-green-400
                "
                  onClick={() => _onClickSubModal("editDeadLine")}
                >
                  <CalendarMonthIcon fontSize="small" />
                  <p>Gia hạn</p>
                </button>

                <button
                  className="
                  flex items-center space-x-1 w-max h-max p-2 bg-amber-500 text-white rounded-md
                  hover:bg-amber-400
                "
                  onClick={() => _onClickSubModal("notiTask")}
                >
                  <NotificationsActiveIcon fontSize="small" />
                  <p>Nhắc việc</p>
                </button>
              </>
            )}

            <button
              className="lg:hidden flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md"
              onClick={() =>
                _onChangeMultipleChoicePartner("chatPopup", null, null)
              }
            >
              <SendIcon fontSize="small" />
              <p>Trao đổi</p>
            </button>

            {task.TaskStatus === 5 && (
              <button
                className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-slate-200
        "
                onClick={() => _onOpenPopupShareTask(task.ID)}
              >
                <ShareIcon fontSize="small" />
                <p>Chia sẻ công việc</p>
              </button>
            )}

            <button
              className="
          hidden lg:flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadTotalTask_nguoigiao;
