import React from "react"

import { EditorInterface } from "./editor.interface"

import EditorRouter from "./routers/editor.router"
import EditorComponent from "./components/editor.component"

/**
 * Description of Component
 * 
 * 1. Everything component will have 2 attrs: { state, type }
 * - state for building new component 
 * - type for calling the component will be re-used on many pages. 
 * 
 * 2. Just 1 in to have value. Not right on both at same times.
 * --> Good case: 
 * ----- <Component state={{<object>}} type={undefined} /> 
 * ----- <Component state={undefined} type={{<object>}} />
 * 
 * --> Bad case:
 * ----- <Component state={{<object>}} type={{<object>}} />
 * 
 * **/

const EditorFC: React.FC<EditorInterface> = (props) => {
	const type = props.type?.value

	return (
		<>
			{
				type === undefined ? <EditorComponent state={props.state} />
					: <EditorRouter type={props.type} state={props.state} />
			}
		</>
	)
}

export default EditorFC