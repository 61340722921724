import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONCHANGE_NGUOI_PHOI_HOP } from "../../../redux/reducers/workingReducer/_index";

import { DETAIL_STEP, DETAIL_RESULT } from "../../../apis/_index";

import Select from "react-select"
import { onTouchForm } from "../../../funcs/onTochForm";
import CloseIcon from '@mui/icons-material/Close';
import UserRepository from "../../../repositories/users/userRepository";
import { toast } from "react-toastify";
import { unsignedToAccented } from "../../../helpers/handleHelper";
import OrganizationUtils from "../../../utils/organizations/organizationUtils";
import OrganizationRepository from "../../../repositories/organizations/organizationRepository";
import LevelComponent from "../../organizations/levels/levelComponent";
const MultipleChoicePartner = (props: any) => {

  const handleClose = props.handleClose;

  let selectedNguoiPhoiHopList = props.selectedNguoiPhoiHopList;
  const selectedNguoiLamViecList = props.selectedNguoiLamViecList;

  const _onCheckNguoiPhoiHop = props._onCheckNguoiPhoiHop;
  const selectedNguoiGopYList = props.selectedNguoiGopYList

  const _onCheckNguoiGopY = props._onCheckNguoiGopY

  const workingData = useSelector((state: any) => state.workingReducer)
  const _onChangeWorkerViaDepart = props._onChangeWorkerViaDepart
  const resetFilter = props.resetFilter || function () { }

  // redux
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const [_listrender, set_listrender] = useState<any | null>(null);
  const [supporterOriginals, setSupporterOriginal] = useState<any>([]);
  const [organizationId, setOrganizationId] = useState<any>(null);
  const [keyword, setKeyword] = useState<any>(null);
  const [organizations, setOrganization] = useState<any>([]);

  const _onClickSearchSubmit = (e: any) => {
    set_listrender(
      props.selectedNguoiPhoiHopList?.filter((x: any) =>
        x.FullName.toUpperCase().includes(e.target.value.toUpperCase())
      )
    );
  };

  const getListSupporter = () => {
    UserRepository.getListSupporter().then((res: any) => {
      if (res.status) {
        let supporters = (res.data || []).map((p: any) => {
          return {
            ...p,
            TenPhongBan: (p.TenPhongBan || '').replaceAll('|', ', ')
          }
        });

        supporters = supporters.filter((p: any) => p.Id != (selectedNguoiLamViecList[0] || {}).Id);

        set_listrender(supporters);
        setSupporterOriginal(supporters);
      } else {
        toast.error('Lấy dữ liệu người hỗ trợ thất bại!');
      }
    });
  }

  const filterSupporter = (organizationId: any, keyword: any) => {
    let supporters = [...supporterOriginals];

    if (organizationId) {
      supporters = supporters.filter((p: any) => {
        const isCheck = (p.IdPhongBan || '').split(',').find((m: any) => (m || '').trim() == organizationId);

        return isCheck ? true : false;
      });
    }

    if (keyword) {
      const keyword2 = unsignedToAccented(keyword || '').toLowerCase();

      supporters = supporters.filter((p: any) => {
        const fullName = unsignedToAccented(p.FullName || '').toLowerCase();

        const isCheck = fullName.includes(keyword2) || fullName.startsWith(keyword2) || fullName.endsWith(keyword2) || fullName.lastIndexOf(keyword2) >= 0 || fullName.indexOf(keyword2) >= 0;

        return isCheck ? true : false;
      });
    }

    return supporters;
  }

  const onChangeOrganization = (e: any) => {
    const value = (e || {}).ParameterId;

    setOrganizationId(value);

    const supporters = filterSupporter(value, keyword);

    set_listrender(supporters);
  }

  const onChangeKeyword = (e: any) => {
    const value = e.target.value;

    setKeyword(value);

    const supporters = filterSupporter(organizationId, value);

    set_listrender(supporters);
  }

  // console.log("Nguoi phoi hop: ", selectedNguoiPhoiHopList)
  // console.log("Nguoi lam viec: ", selectedNguoiLamViecList)

  // funcs
  // const _onChangeWorkerViaDepart2 = (data: any) => {
  //   if (data === null) {
  //     set_listrender(
  //       selectedNguoiPhoiHopList?.filter((item: any) => item.Id !== selectedNguoiLamViecList[0].Id)
  //     );  
  //   } else {
  //     set_listrender(
  //       selectedNguoiPhoiHopList?.filter((item: any) => item.IdPhongBan === data.ParameterId)
  //     );  
  //   }
  // }

  const getListOrganization = () => {
    OrganizationRepository.getListByCurrentUser().then((res: any) => {
      const organizations = res.data || [];

      const organizationTrees = OrganizationUtils.getListTree(organizations, {
        id: 'PhongBanId',
        parentId: 'RefID'
      });

      const organizationTreeValueLabels = OrganizationUtils.convertToListValueLabel(organizationTrees, {
        value: 'PhongBanId',
        label: 'PhongBan'
      });

      setOrganization(organizationTreeValueLabels);
    });
  }

  useEffect(() => {
    // set_listrender([...selectedNguoiPhoiHopList]);

    getListSupporter();
  }, [selectedNguoiPhoiHopList]);

  useEffect(() => {
    // set_listrender([...selectedNguoiPhoiHopList]);

    getListSupporter();

    getListOrganization();
  }, []);

  // select
  // const tempPhongBan = workingData.listPhongBan?.map((item: any) => { return { ...item, label: item.ParameterValue, value: item.ParameterValue } });

  const tempPhongBan = organizations;

  console.log(_listrender);
  console.log(selectedNguoiLamViecList);

  return (
    <div
      className="
        lg:w-[40rem] w-screen lg:h-[40rem] h-[calc(100vh-5rem)] bg-white rounded-md
        flex flex-col
      "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chọn người phối hợp</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={() => {
            resetFilter()
            handleClose()
          }}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      <div
        className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
      >
        <div className="flex w-full h-[5rem] space-x-3">
          <div className="flex flex-col w-1/3 h-max">
            <label>Phòng ban</label>
            <Select
              className="z-[5]"
              options={[{
                value: null,
                label: 'Chọn phòng ban'
              }, ...tempPhongBan]}
              // onChange={(data: any) => _onChangeWorkerViaDepart(data)}
              onChange={(data: any) => onChangeOrganization(data)}
              isClearable
              placeholder="--- Chọn phòng ban ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: (props: any, a: any) => {
                  return <div {...props.innerProps} className="core-select-item"><LevelComponent item={props.data} />{props.label}</div>;
                }
              }}
            />
          </div>
          <div className="flex flex-col w-2/3 h-max">
            <label>Tên nhân sự</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Nhập tên nhân sự cần tìm"
              // onChange={(e: any) => _onClickSearchSubmit(e)}
              onChange={(e: any) => onChangeKeyword(e)}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          {/* <div className="flex flex-col w-1/5 pl-3">
            <label className="text-white">Tìm kiếm</label>
            <button
              className="
                bg-slate-300 text-black w-full h-max rounded-md
                hover:bg-slate-200 pt-1.5 pb-1.5
              " onClick={_onClickSearchSubmit}
            >
              Tìm kiếm
            </button>
          </div> */}
        </div>

        <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300"></th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  STT
                </th>
                <th className="border-l-[1px] border-slate-300">Tên nhân sự</th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Phòng ban
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Tổng số giờ đã sử dụng trong tháng
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Số giờ còn lại
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {_listrender === null ? (
                <tr></tr>
              ) : (
                _listrender?.map((item: any, index: any) => (
                  <>
                    {item.Id === "999" ? (
                      ""
                    ) : (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td
                          className={`
                      
                          hidden lg:table-cell border border-slate-300 text-center
                        `}
                        >
                          <div className="w-max h-max flex flex-col space-y-3">
                            {/* Support for Execute */}


                            <div className="w-[8rem] h-max flex space-x-1 justify-start items-center">
                              <input
                                key={index}
                                type="checkbox"
                                className="w-5 h-5"
                                value={item.Id}
                                checked={
                                  selectedNguoiLamViecList?.find(
                                    (item2: any) => item2.Id === item.Id
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                onChange={() => _onCheckNguoiPhoiHop(item.Id, _listrender)}
                              />
                              <p className="text-xs">Hỗ trợ thực hiện</p>
                            </div>

                            {/* Support for Comment */}
                            <div className="w-[8rem] h-max flex space-x-1 justify-start items-center">
                              <input
                                key={index}
                                type="checkbox"
                                className="w-5 h-5"
                                value={item.Id}
                                checked={
                                  selectedNguoiGopYList?.find(
                                    (item2: any) => item2.Id === item.Id
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                onChange={() => _onCheckNguoiGopY(item.Id, _listrender)}
                              />
                              <p className="text-xs">Hỗ trợ góp ý</p>
                            </div>

                            {/* <input type="radio" value={item.Id} name="Hỗ trợ thực hiện" checked={
                              selectedNguoiGopYList?.find(
                                (item2: any) => item2.Id === item.Id
                              ) === undefined
                                ? false
                                : true
                            }
                              onChange={() => _onCheckNguoiPhoiHop(item.Id)}
                            />
                            <input type="radio" value={item.Id} name="Hỗ trợ góp ý" checked={
                              selectedNguoiGopYList?.find(
                                (item2: any) => item2.Id === item.Id
                              ) === undefined
                                ? false
                                : true
                            }
                              onChange={() => _onCheckNguoiGopY(item.Id)} /> */}
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300 text-center">
                          {index + 1}
                        </td>
                        <td className={`border border-slate-300`}>
                          <p
                            className="hidden lg:flex font-bold"
                            style={{ color: `${item.ColorText}` }}
                          >
                            {item.FullName}
                          </p>

                          <div className="lg:hidden">
                            <p
                              className="font-bold"
                              style={{ color: `${item.ColorText}` }}
                            >
                              <span className="lg:hidden">
                                {index + 1}
                                .&nbsp;
                              </span>
                              {item.FullName}
                            </p>

                            <p>Phòng ban: {item.TenPhongBan}</p>
                            <p>
                              Tổng số giờ đã sử dụng trong tháng: <b style={{ color: `${item.ColorText}` }}>{item?.Hours_in_month_used}/{item?.Hours_in_month}</b>
                            </p>
                            <p>
                              Số giờ còn lại: <b style={{ color: `${item.ColorText}` }}>{item?.Hours_in_month_remaining}</b>
                            </p>
                            <div className="flex items-center space-x-1 mt-2">
                              <input
                                key={index}
                                type="checkbox"
                                className="w-5 h-5"
                                value={item.Id}
                                checked={
                                  selectedNguoiLamViecList?.find(
                                    (item2: any) => item2.Id === item.Id
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                onChange={() => _onCheckNguoiPhoiHop(item.Id, _listrender)}
                              />
                              <label>Hỗ trợ thực hiện</label>
                            </div>
                            <div className="flex items-center space-x-1">
                              <input
                                key={index}
                                type="checkbox"
                                className="w-5 h-5"
                                value={item.Id}
                                checked={
                                  selectedNguoiGopYList?.find(
                                    (item2: any) => item2.Id === item.Id
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                // onChange={() => _onCheckNguoiPhoiHop(item.Id)}
                                onChange={() => _onCheckNguoiGopY(item.Id, _listrender)}
                              />
                              <label>Hỗ trợ góp ý</label>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`hidden lg:table-cell border border-slate-300 text-center`}
                        >
                          {item.TenPhongBan}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300  text-center font-bold" style={{ color: `${item.ColorText}` }}>
                          {item?.Hours_in_month_used}/{item?.Hours_in_month}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300  text-center font-bold" style={{ color: `${item.ColorText}` }}>
                          {item?.Hours_in_month_remaining}
                        </td>
                      </tr>
                    )}
                  </>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
          flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
        "
      >
        <button
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-400"
          onClick={() => {
            resetFilter()
            handleClose()
          }}
        >
          Xác nhận
        </button>
        <button
          className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
          onClick={() => {
            resetFilter()
            handleClose()
          }}
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default MultipleChoicePartner;
