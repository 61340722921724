import React, { useState, useContext, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CONFIRM_EXTEND } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import { ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN } from "../../../redux/reducers/workingReducer/_index";
import { NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE } from "../../../apis/_index";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import { set } from "date-fns";
import moment from "moment";
import ServiceUtils from "../../../utils/services";

const ConfirmExtend = (props: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const hourExtend = props.hourExtend
  const taskId = props.taskId;
  const timeExtend = props.timeExtend;
  const [isChangeTimeExtend, setIsChangeTimeExtend] = useState<boolean>(false);
  const extendId = props.extendId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const [newTimeExtend, setNewTimeExtend] = useState<string>(timeExtend);
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch()

  const [hour, setHour] = useState<any>(hourExtend)

  const urlLink = window.location.pathname
  const shouldLog = useRef<any>(true)

  const _onClickSubmit = async () => {
    const body: any = {
      ID: extendId,
      TaskID: taskId,
      Result: result,
      TimeExtend: newTimeExtend == timeExtend ? null : newTimeExtend,
      IsResult: isResult === 0 ? false : true,
      Hours: parseInt(hour)
    };

    setIsLoading(true);

    try {
      await ServiceUtils.postV2(CONFIRM_EXTEND, body, {
        headers: {
          Accept: "text/plain",
        },
      })
        .then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            toast.success(res.data.Data.RMessage || "Đã duyệt");

            setIsLoading(false);
            handleClose();
            context.handleClose();
            // re-load
            readList(
              NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
              data.pageCurrent,
              data.choosePage,
              data.searchFilter.taskName,
              data.searchFilter.selectedPrior,
              data.searchFilter.selectedReceiver,
              data.searchFilter.fromDate,
              data.searchFilter.toDate,
              data.searchFilter.deadLine,
              shouldLog,
              urlLink,
              (res: any) => {
                dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
              }
            );

            // _loadData();
            // window.location.reload()
          } else {
            toast.error(res.data.Data.RMessage || "Lỗi xác nhận duyệt");
          }
        });
    } catch (error: any) {
      toast.error(error || "Lỗi xác nhận duyệt");
    }
  };

  const _onChangeHour = (e: any) => {
    e.preventDefault()
    setHour(e.target.value)
  }

  useEffect(() => {
    console.log(props.timeExtend);

  }, [])

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>{isResult ? 'Xác nhận duyệt gia hạn' : 'Xác nhận không duyệt gia hạn'}</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        {isResult === 1 ? (
          <div className="space-y-2">
            <label className="text-slate-400 italic">
              * Người duyệt được quyền thay đổi thời hạn
            </label>
            <div className="flex items-center">
              <label className="w-1/4">Chọn thời hạn mới</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="datetime-local"
                value={newTimeExtend}
                onChange={(e: any) => {
                  e.preventDefault();
                  setNewTimeExtend(e.target.value);
                  setIsChangeTimeExtend(
                    timeExtend === e.target.value ? false : true
                  );
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="w-1/4">Thời gian hoàn thành</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                type="number"
                placeholder="Nhập số giờ hoàn thành"
                value={hour}
                onChange={(e: any) => {
                  _onChangeHour(e);
                  setIsChangeTimeExtend(
                    timeExtend === e.target.value ? false : true
                  );
                }}
              />
            </div>
            {isChangeTimeExtend && (
              <div className="space-y-1">
                <label>
                  Lý do thay đổi&nbsp;
                  <span className="text-red-500">(*)</span>
                </label>
                <textarea
                  className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập lý do thay đổi ngày"
                  onChange={(e: any) => {
                    e.preventDefault();
                    setResult(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        ) : <>
          {isResult === 2 ?
            (<label>
              Bạn đồng ý xác nhận duyệt thời hạn <b>{moment(timeExtend).format('hh:mm:ss DD/MM/yyyy')}</b> {hourExtend && (<span> và thời gian dự kiến hoàn thành là <b>{hourExtend} giờ</b></span>)}?
            </label>
            )
            : (
              <div className="flex flex-col w-full space-y-1">
                <label>
                  Lý do không duyệt&nbsp;
                  <span className="text-red-500">(*)</span>
                </label>
                <textarea
                  className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập lý do không duyệt"
                  onChange={(e: any) => {
                    e.preventDefault();
                    setResult(e.target.value);
                  }}
                />
              </div>
            )

          }
        </>}
      </div>
      {/* footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${((isResult === false || isResult == false) && (result === "" || !result)) ||
                  (isChangeTimeExtend === true && result === "")
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
                }`}
              disabled={
                ((isResult === false || isResult == false) && (result === "" || !result)) ||
                (isChangeTimeExtend === true && result === "")
              }
              onClick={_onClickSubmit}
            >
              <p>Xác nhận</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmExtend;
