const linkIncludes = [
  "/working/new-task",
  "/working/task-change-deadline",
  "/working/task-need-give",
  "/working/task-finish",
  "/working/receiver/task-new",
  "/working/receiver/task-waiting",
];

const firstDayOfYear = (link: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
      .toISOString()
      .slice(0, 16);
  }
};

const currentDate = (link: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(
      new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
    )
      .toISOString()
      .slice(0, 16);
  }
};

const lastDayOfYear = (link?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)
      .toISOString()
      .slice(0, 16);
  }
};

const HandleDate = {
  firstDayOfYear,
  currentDate,
  lastDayOfYear,
};

export default HandleDate;
