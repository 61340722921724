import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

import { ModalController } from "../../../ui/homeUI/_index";
import { DELETE_CATEGORY_SUGGEST_API, GET_LIST_CATEGORY_SUGGEST_API, PRIOR_DELETE, PRIOR_LIST } from "../../../apis/_index";
import { ONLOAD_CATEGORY_SUGGEST, ONLOAD_PRIORLIST } from "../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const DeleteCategorySuggestModal = (props: any) => {
    const dispatch = useDispatch();
    const context = useContext(ModalController);

    // take props
    const id = props.id;
    const handleClose = props.handleClose;

    const _close = () => {
        handleClose();

        context.setTaskId('');

        context.handleClose();
    }

    // funcs
    const _onClickDelete = async () => {
        const accessToken = localStorage.getItem("accessToken");

        const API = DELETE_CATEGORY_SUGGEST_API.replace('{id}', id.toString());

        try {
            await ServiceUtils.getV2(API, {
                    headers: {
                        Accept: "text/plain",
                    },
                })
                .then((res: any) => {
                    if (res.status === 200) {
                        const takeData = async () => {
                            try {
                                await ServiceUtils.getV2(GET_LIST_CATEGORY_SUGGEST_API, {
                                        headers: {
                                            Accept: "text/plain",
                                        },
                                    })
                                    .then((res: any) => {
                                        if (res.status === 200) {
                                            dispatch(ONLOAD_CATEGORY_SUGGEST(res.data.Data));

                                            _close();

                                            toast.success("Xoá thành công.");
                                        } else {
                                            toast.error("Tải dữ liệu thất bại.");
                                        }
                                    });
                            } catch (error: any) {
                                toast.error("Tải dữ liệu thất bại.");
                            }
                        };

                        takeData();
                    }
                });
        } catch (error: any) {
            toast.error("Xoá thất bại.");
        }
    };

    return (
        <div
            className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
        >
            {/* icon */}
            <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
                <DeleteIcon sx={{ fontSize: 100 }} />
            </div>

            {/* content */}
            <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
                <p>
                    Bạn có đồng ý <span className="font-semibold">XOÁ</span> danh mục đề xuất này
                    không?
                </p>
                <div className="w-full h-max flex justify-center items-center space-x-12">
                    <button
                        className="w-[5rem] h-max p-2 bg-slate-500 rounded-md hover:bg-slate-400"
                        onClick={_onClickDelete}
                    >
                        Có
                    </button>
                    <button
                        className="w-[5rem] h-max p-2 bg-red-500 rounded-md hover:bg-red-300"
                        onClick={_close}
                    >
                        Không
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteCategorySuggestModal;