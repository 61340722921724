import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import { SHARE_TASK, LOAD_PHONG_BAN } from "../../../apis/_index";
import SaveIcon from '@mui/icons-material/Save';
import Select from "react-select";
import axios from "axios";
import ServiceUtils from "../../../utils/services";

const ShareTask = (props: any) => {

  // take props
  const handleClose = props.handleClose;
  const taskID = props.taskId;

  // state
  const [isAllUser, setIsAllUser] = useState<boolean>(false);
  const [listMultiUser, setListMultiUser] = useState<any[]>([]);
  const [userListForSelect, setUserListForSelect] = useState<any[] | undefined>(undefined);
  const [phongBanSelectedForSelect, setPhongBanSelectedForSelect] = useState<any>(-1);
  const [userListForSelected, setUserListForSelected] = useState<any[] | undefined>(undefined);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [phongBanListForSelect, setPhongBanListForSelect] = useState<any[] | undefined>(undefined);

  // reducer
  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  const takeDataPhongBan = async (accessToken: any) => {
    try {
      await ServiceUtils.getV2(LOAD_PHONG_BAN, {
          headers: {
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res?.data?.StatusCode === 200) {

            setPhongBanList(res?.data?.Data?.PhongBan);
            setPhongBanListForSelect(_onConvertToSelect(res?.data?.Data?.PhongBan, 'TenPhongBan', 'IdPhongBan', 'ColorText'))
            setUserListForSelect(_onConvertToSelect(res?.data?.Data?.NguoiNhan.filter((x: any) => x.Id !== userData?.Data[0]?.Id), 'FullName', 'Id'))
          } else {

          }
        });
    } catch (error: any) { }
  }
  useEffect(() => {

    const accessToken = localStorage.getItem("accessToken");
    takeDataPhongBan(accessToken)
  }, [])

  const _onConvertToSelect = (arr: any[] | null = [], name: string, value: string, color: string | null = null) => {
    let newArr: any[] | null = arr === null ? [] : [...arr];
    newArr = newArr.map((x: any) => ({
      ...x,
      label: x[name],
      value: x[value],
      color: (color !== null) ? x[color] : "#000"
    }))
    return newArr;
  }

  const getUnique = (arr: any[], index: any) => {

    if (arr.length == 0) {
      return []
    }
    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e]).map((e: any) => arr[e]);

    return unique;
  }

  const _onChangePhongBanSelect = (e: any, type: number) => {
    e.preventDefault();


    const nguoiNhan = userListForSelect?.filter(
      (item: any) => (item?.IdPhongBan || '').toString().split(',').find((p: any) => p == parseInt(e.target.value)) ? true : false
    );

    setUserListForSelected(nguoiNhan);

    setPhongBanSelectedForSelect(
      phongBanListForSelect?.find(
        (item: any) => (item?.IdPhongBan || '').toString().split(',').find((p: any) => p == parseInt(e.target.value)) ? true : false
      )
    );

  };

  const _onChangeListMultiUser = (type: number, e: any) => {
    const _listMultiUser = [...listMultiUser];
    if (type === 1) {
      userListForSelect?.map((item: any) => {
        if (item && e.value && (item?.IdPhongBan || '').toString().split(',').find((p: any) => p == e.value)) {
          if (item?.Id !== userData?.Data[0]?.Id) {
            _listMultiUser.push(item);
          }
        }
      });

      setListMultiUser(getUnique(_listMultiUser, 'value'));
    } else if (type === 2 || type === 3) {
      if (e?.Id !== userData?.Data[0]?.Id) {
        _listMultiUser.push(e);
      }
      setListMultiUser(getUnique(_listMultiUser, 'value'));
    } else if (type === 4) {
      setListMultiUser(e)
    } else if (type === 5) {
      // setListMultiUser(e)
      setIsAllUser((item: any) => !item);

    }
  }

  useEffect(() => {
    let _listMultiUser: any[] = [];
    _listMultiUser = [...listMultiUser];
    if (isAllUser) {
      userListForSelect?.map((item: any) => {
        if (item?.Id !== userData?.Data[0]?.Id) {
          _listMultiUser.push(item);
        }
      });

    } else {
      _listMultiUser = [];
    }
    setListMultiUser(getUnique(_listMultiUser, 'value'));
  }, [isAllUser]);

  const _onSubmitShareTask = () => {
    const accessToken = localStorage.getItem("accessToken");
    let sharedUsers = "";

    listMultiUser.forEach((item: any, index: any) => {
      if (index === listMultiUser.length - 1) {
        sharedUsers += `${item.Id.toString()}`;
      } else {
        sharedUsers += `${item.Id.toString()},`;
      }

    });

    const shareTask = async () => {
      const API = SHARE_TASK + taskID + '&sharedUsers=' + sharedUsers
      try {
        await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          })
          .then((res: any) => {

            if (res?.data?.StatusCode === 200) {
              toast.success(res?.data?.Message || "Chia sẻ thành công!")
              handleClose();
            } else {
              toast.error(res?.data?.Message || "Chia sẻ thất bại!")
              handleClose();
            }
          });
      } catch (error: any) {
        toast.error(error || "Chia sẻ thất bại!")
        handleClose();
      }
    }
    shareTask();
  }

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  ">
      {/* Header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chia sẻ công việc</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* Content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        <div className="flex space-x-2 items-top">
          <div className="w-full space-y-2">
            <div className="flex w-max h-max space-x-3">
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={isAllUser}
                onChange={(e: any) => _onChangeListMultiUser(5, e)}

              />
              <label className="text-slate-500 font-bold pl-2 pr-2">
                Chọn toàn bộ
              </label>
            </div>
            <label>Chọn nhanh cả phòng</label>
            <Select
              className="w-full z-50"
              options={phongBanListForSelect}
              value={null}
              onChange={(e: any) => _onChangeListMultiUser(1, e)}
              isClearable
              placeholder="Chọn nhanh cả phòng"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            <label>Chọn từng người</label>
            <Select
              className="w-full z-49"
              options={userListForSelect}
              value={null}
              onChange={(e: any) => _onChangeListMultiUser(2, e)}
              placeholder="Chọn từng người"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {/* <div className="w-full space-y-1"> */}
            <label>Chọn theo phòng ban</label>
            {/* <div className="flex w-full space-x-3"> */}
            {/* <div className="w-full space-y-1"> */}
            <select
              className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
              value={
                phongBanSelectedForSelect === -1
                  ? -1
                  : phongBanSelectedForSelect?.IdPhongBan
              }
              onChange={(e: any) => _onChangePhongBanSelect(e, 2)}
            >
              <option value={-1} disabled>
                --- Chọn phòng ban ---
              </option>
              {phongBanList === null ? (
                <></>
              ) : (
                phongBanList.map(
                  (item: any, index: any) => (
                    <option
                      key={index}
                      value={item?.IdPhongBan}
                    >
                      {item?.TenPhongBan}
                    </option>
                  )
                )
              )}
            </select>
            {/* </div> */}

            <Select
              className="w-full z-48"
              options={userListForSelected}
              // value={single}
              onChange={(e: any) => _onChangeListMultiUser(3, e)}
              placeholder="Chọn nhân viên"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}

            />

            {/* </div> */}
            {/* </div> */}
          </div>

          <div className="w-full">
            <Select
              className="w-full"
              // options={votingList}
              isMulti
              value={listMultiUser}
              onChange={(e: any) => _onChangeListMultiUser(4, e)}
              isClearable
              placeholder="Danh sách người nhận"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage: () => null,
              }}
              styles={{
                control: (baseStyles, state) => (
                  {
                    ...baseStyles,
                    whiteSpace: 'normal'

                  }),
              }}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={() => {
              _onSubmitShareTask();
            }}
          >
            <SaveIcon fontSize="small" />
            <p>Chia sẻ</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShareTask