import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "./navbar"
import OptionList from "./optionList"
import {
    ONCHANGE_MENULIST, ONSET_NAVBAR_BUTTON_AT_FIRST, ONSET_NAVBAR_OPTIONS_AT_FIRST
} from "../../../redux/reducers/systemReducer/index";

const NavigationComponent = (props: any) => {
    const menuList = localStorage.getItem("menuList");
    const systemData = useSelector((state: any) => state.systemReducer);
    const shouldLog = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (menuList !== null && shouldLog.current) {
            shouldLog.current = false;

            if (systemData.mainMenu.length === 0 && systemData.subMenu.length === 0) {
                dispatch(ONCHANGE_MENULIST());
            } else {
                return;
            }
        } else {
            return;
        }
    }, [systemData.mainMenu, systemData.subMenu.length, menuList]);

    useEffect(() => {
        let navbarButton = sessionStorage.getItem("isShowButtonNavbar");
        let navbarOptions = sessionStorage.getItem("whatMainOptionsShowing");

        // navbar button show
        if (navbarButton === null) {
            sessionStorage.setItem("isShowButtonNavbar", "false");
            dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("true"));
        } else {
            dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST(navbarButton));
        }

        // options on navbar
        if (navbarOptions === null) {
            sessionStorage.setItem("whatMainOptionsShowing", "1");
            dispatch(ONSET_NAVBAR_OPTIONS_AT_FIRST("1"));
        } else {
            dispatch(ONSET_NAVBAR_OPTIONS_AT_FIRST(navbarOptions));
        }
    }, []);

    return (
        <div style={{
            display: 'flex'
        }}>
            <Navbar />
            <div style={{
                height: 'calc(100% -1 px)',
                overflowY: 'auto',

            }}>
                <OptionList />
            </div>
        </div>
    )
}

export default NavigationComponent;