import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";
import Table from "../../../components/table";
import SourceTwoToneIcon from "@mui/icons-material/SourceTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import Select from "react-select";

import { takePermission } from "../../../funcs";

import {
  GROUP_READ_ALL_DROPDOWN,
  LI_TASK_LIST_PERMISSION,
  LI_TASK_LIST_LOADFUNCBYGROUP,
  LI_TASK_LIST_UPDATE,
} from "../../../apis/_index";
import { ONLOAD_GROUPLIST_DROPDOWN } from "../../../redux/reducers/userReducer/_index";
import { ONLOAD_LIST_PERMISSION } from "../../../redux/reducers/workingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";

const WorkCategoryListUI = () => {
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);

  const [selectedPage, setSelectedPage] = useState<any>(0);

  const menu = takePermission(9);

  const shouldLog = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {

    if (selectedPage === 0) {
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(LI_TASK_LIST_PERMISSION, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_LIST_PERMISSION(res.data.Data));
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeData();
    } else {
      const API = LI_TASK_LIST_LOADFUNCBYGROUP + selectedPage.toString();

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_LIST_PERMISSION(res.data.Data));
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };

      takeData();
    }
  }, [selectedPage, dispatch]);

  // load group
  useEffect(() => {

    if (userData.groupList === null && shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(GROUP_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_GROUPLIST_DROPDOWN(res.data.Data));
              } else {
                toast.error("Tải dữ liệu nhóm quyền thất bại.");
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu nhóm quyền thất bại.");
        }
      };

      takeData();
    } else {
      return;
    }
  });

  // funcs
  const _onChangePermission = async (perActive: boolean, id: any) => {
    let active: boolean = !perActive;

    const accessToken = localStorage.getItem("accessToken");
    const API = LI_TASK_LIST_UPDATE(selectedPage, id, active);

    try {
      await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.status === 200) {
            const API = LI_TASK_LIST_LOADFUNCBYGROUP + selectedPage.toString();

            const takeData = async () => {
              try {
                await ServiceUtils
                  .getV2(API, {
                    headers: {
                      Accept: "text/plain",
                    },
                  })
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_LIST_PERMISSION(res.data.Data));
                    }
                  });
              } catch (error: any) {
                toast.error("Tải dữ liệu thất bại.");
              }
            };

            takeData();
          }
        });
    } catch (error: any) {
      toast.error("Cập nhật thất bại");
    }
  };

  const tempArray = userData.groupListDropdown?.map((item: any) => {
    return { ...item, label: item.GroupName, value: item.GroupName };
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] text-sm p-3 lg:px-5 lg:py-3">
        <div className="flex w-full h-max justify-between space-x-5">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label className="text-xs mb-1">Nhóm quyền</label>

            <Select
              className="z-[5]"
              options={tempArray}
              onChange={(data: any) => {
                if (data === null) {
                  setSelectedPage(0);
                } else {
                  setSelectedPage(data.ID);
                }
              }}
              isClearable
              placeholder="--- Chọn nhóm quyền ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />

            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={selectedPage}
              onChange={(e: any) => {
                e.preventDefault();
                setSelectedPage(e.target.value);
              }}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn nhóm quyền ---</option>
              {userData.groupListDropdown === null ? <tr></tr>
                : userData.groupListDropdown.map((item: any, index: any) => (
                  <option key={index} value={item.ID}>{item.GroupName}</option>
                ))
              }
            </select> */}
          </div>

          <div className="w-2/3 h-max flex flex-col">
            {/* <label className="text-xs">Tìm kiếm</label>
            <input
              className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Tìm kiếm chức năng"
            /> */}
          </div>
        </div>

        <div className="flex flex-col w-full h-[calc(100%-3rem)] text-sm">
          <div className="flex flex-col w-full h-max pt-3">
            <>
              {selectedPage === 0 ? (
                <p className="mt-1 text-slate-500 font-semibold text-lg">
                  VUI LÒNG CHỌN NHÓM QUYỀN
                </p>
              ) : workingData.listPermission === null ? (
                ""
              ) : (
                workingData.listPermission.map((item: any, index: any) => (
                  <div key={index} className="flex flex-col w-full h-max">
                    {/* parent 0 */}
                    <div className="flex w-full h-max space-x-1 items-center">
                      <input
                        className="accent-green-500 w-5 h-5"
                        type="checkbox"
                        checked={item.PerActive}
                        onChange={() =>
                          _onChangePermission(item.PerActive, item.Id)
                        }
                        disabled={menu.PerEdit === true ? false : true}
                      />
                      <p className="pl-1 font-semibold text-black">
                        {item.Tendauviec}
                      </p>
                    </div>

                    <div className="flex flex-col w-full h-max pl-9">
                      {item.subItem.length === 0
                        ? ""
                        : item.subItem.map((subItem: any, subIndex: any) => (
                            <div
                              key={subIndex}
                              className="flex w-full h-max space-x-1"
                            >
                              <input
                                className="accent-green-500 w-5 h-5"
                                type="checkbox"
                                checked={subItem.PerActive}
                                onChange={() =>
                                  _onChangePermission(
                                    subItem.PerActive,
                                    subItem.Id
                                  )
                                }
                                disabled={menu.PerEdit === true ? false : true}
                              />
                              <p className="text-black">{subItem.Tendauviec}</p>
                            </div>
                          ))}
                    </div>
                  </div>
                ))
              )}
            </>

            {/* <div className="flex flex-col w-full h-max">
              <div className="flex w-full h-max space-x-1 items-center">
                <p className="font-medium">&gt;</p>
                <input
                  className="accent-green-500"
                  type="checkbox"
                  defaultChecked
                />
                <p className="pl-1 font-bold text-green-500">Văn phòng - Thống kê</p>
              </div>

              <div className="flex flex-col w-full h-max pl-6">
                <div className="flex w-full h-max space-x-1">
                  <p className="font-medium">&gt;</p>
                  <input
                    className="accent-green-500"
                    type="checkbox"
                    defaultChecked
                  />
                  <p className="text-green-500">Quản lý con dấu</p>
                </div>
                <div className="flex w-full h-max space-x-1">
                  <p className="font-medium">&gt;</p>
                  <input
                    className="accent-green-500"
                    type="checkbox"
                    defaultChecked
                  />
                  <p className="text-green-500">Đề xuất thay đổi tài khoản cá nhân cán bộ</p>
                </div>
                <div className="flex w-full h-max space-x-1">
                  <p className="font-medium">&gt;</p>
                  <input
                    className="accent-green-500"
                    type="checkbox"
                    defaultChecked
                  />
                  <p className="text-green-500">Triển khai phần mềm ứng dụng</p>
                </div>
                <div className="flex w-full h-max space-x-1">
                  <p className="font-medium">&gt;</p>
                  <input
                    className="accent-green-500"
                    type="checkbox"
                    defaultChecked
                  />
                  <p className="text-green-500">Phát động phong trào thi đua</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCategoryListUI;
