import React, { useEffect, useRef, useState } from "react";

import Title from "../../../../components/title";

import SaveIcon from "@mui/icons-material/Save";

import { takePermission } from "../../../../funcs";

import {
  CONFIG_OVERLOADSETTING,
  CONFIG_OVERLOADINFO,
} from "../../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";
import ServiceUtils from "../../../../utils/services";

const OverloadSettingUI = () => {
  // state
  const [overloadInfo, setOverloadInfo] = useState<any>("");
  const [overloadSetting, setOverloadSetting] = useState<any>("");
  const shouldLog = useRef(true);
  const menu = takePermission(17);

  const [overloadType, setOverloadType] = useState<any>("");
  const [overloadFree, setOverloadFree] = useState<any>("");
  const [overloadBusy, setOverloadBusy] = useState<any>("");
  const [overloadFull, setOverloadFull] = useState<any>("");
  const [isActive, setIsActive] = useState<boolean>(false);

  // funcs
  const _onChangeOverloadType = () => {
    switch (overloadType) {
      case 0:
        setOverloadType(1);
        break;
      case 1:
        setOverloadType(0);
        break;
    }
  };

  const _onChangeOverloadFree = (e: any) => {
    e.preventDefault();
    setOverloadFree(parseInt(e.target.value));
  };

  const _onChangeOverloadBusy = (e: any) => {
    e.preventDefault();
    setOverloadBusy(parseInt(e.target.value));
  };

  const _onChangeOverloadFull = (e: any) => {
    e.preventDefault();
    setOverloadFull(parseInt(e.target.value));
  };

  const _onChangeIsActive = () => {
    setIsActive((value: any) => !value);
  };

  const _onClickSubmit = async () => {
    const body:any = {
      OverloadType: overloadType,
      OverloadFree: overloadFree,
      OverloadBusy: overloadBusy,
      OverloadFull: overloadFull,
      IsActiveOverload: isActive,
    };

    try {
      await ServiceUtils.getV2(CONFIG_OVERLOADSETTING, body, {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        })
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("Cập nhật thành công.");
          } else {
            toast.error("Cập nhật thất bại");
          }
        });
    } catch (error: any) {
      toast.error("Cập nhật thất bại.");
    }
  };

  useEffect(() => {

    try {
      if (shouldLog.current) {
        shouldLog.current = false;

        const takeData = async () => {
          await ServiceUtils.getV2(CONFIG_OVERLOADINFO, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              setOverloadType(res.data.Data[0].OverloadType);
              setOverloadFree(res.data.Data[0].OverloadFree);
              setOverloadBusy(res.data.Data[0].OverloadBusy);
              setOverloadFull(res.data.Data[0].OverloadFull);
              setIsActive(res.data.Data[0].IsActiveOverload);
            });
        };

        takeData();
      }
    } catch (error: any) {
      toast.error("Tải dữ liệu thất bại.");
    }
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 text-sm space-y-3">
        <div className="w-full h-[2rem] flex justify-end items-end">
          <button
            className={`
              p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 items-center space-x-1
              ${menu.PerEdit === true ? "flex" : "hidden"}
            `}
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Lưu lại</p>
          </button>
        </div>

        <div className="w-full h-[calc(100%-2rem)] space-y-4 flex flex-col">
          <p>
            Tính năng cho phép người giao việc theo dõi mức độ quá tải thông qua
            màu sắc
          </p>

          <div className="flex w-full h-max items-center space-x-3">
            <p className="flex">
              Kích hoạt tính năng, đổi màu sắc nhân viên khi giao việc
            </p>
            <input
              type="checkbox"
              className="w-5 h-5"
              readOnly={menu.PerEdit === true ? false : true}
              checked={isActive}
              onChange={_onChangeIsActive}
            />
          </div>

          <fieldset
            disabled={menu.PerEdit === true ? false : true}
            onChange={_onChangeOverloadType}
            className="flex flex-col w-full h-max"
          >
            {/* <div className="flex items-center space-x-3">
              <input type="radio" id="1" name="fav_language" value={0} checked={overloadType === 0 ? true : false} placeholder="" />
              <p>Quá tải = Số đầu việc đang xử lý</p>
            </div> */}
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id="1"
                name="fav_language"
                value={1}
                checked={overloadType === 1 ? true : false}
                placeholder=""
              />
              {/* <p>Quá tải = Số ngày việc đã được giao trong tháng</p> */}
              <p>Công thức quá tải dựa trên tổng số giờ sử dụng để làm việc trong tháng!</p>
            </div>
          </fieldset>

          <p className="font-bold">Ghi chú:</p>
          <div className="flex w-full h-max space-x-3">
            <div className="flex flex-col w-max h-max space-y-3">
              <div className="flex justify-between gap-3 items-center">
                <div className="flex justify-between gap-3 items-center sm:w-[133px]">
                  <p className="h-[1.5rem] flex items-center w-[65px] sm:w-auto">
                    Có thể nhận việc
                  </p>
                  <div className="w-[1rem] h-[1rem] bg-green-500 rounded-full"></div>
                </div>
                <div className="flex justify-between gap-3 items-center">
                  <p className="h-[1rem] flex items-center w-[65px] sm:w-max">
                    Thời gian xử lý việc
                  </p>
                  <p>(%) &lt;=</p>
                  <input
                    className="h-[1rem] w-[4rem] rounded-md pl-2 pr-2 border-2 border-green-500"
                    value={overloadFree}
                    disabled={menu.PerEdit === true ? false : true}
                    onChange={(e: any) => {
                      _onChangeOverloadFree(e);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex justify-between gap-3 items-center sm:w-[133px]">
                  <p className="h-[1.5rem] flex items-center w-[65px] sm:w-auto">
                    Nhiều việc
                  </p>
                  <div className="w-[1rem] h-[1rem] bg-yellow-500 rounded-full"></div>
                </div>
                <div className="flex justify-between gap-3 items-center">
                  <p className="h-[1rem] flex items-center w-[65px] sm:w-max">
                    Thời gian xử lý việc
                  </p>
                  <p>(%) &lt;=</p>
                  <input
                    className="h-[1rem] w-[4rem] rounded-md pl-2 pr-2 border-2 border-yellow-500"
                    value={overloadBusy}
                    disabled={menu.PerEdit === true ? false : true}
                    onChange={(e: any) => {
                      _onChangeOverloadBusy(e);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3 items-center">
                <div className="flex justify-between gap-3 items-center sm:w-[133px]">
                  <p className="h-[1.5rem] flex items-center w-[65px] sm:w-auto">
                    Quá tải
                  </p>
                  <div className="w-[1rem] h-[1rem] bg-red-500 rounded-full"></div>
                </div>
                <div className="flex justify-between gap-3 items-center">
                  <p className="h-[1rem] flex items-center w-[65px] sm:w-max">
                    Thời gian xử lý việc
                  </p>
                  <p>(%) &lt;=</p>
                  <input
                    className="h-[1rem] w-[4rem] rounded-md pl-2 pr-2 border-2 border-red-500"
                    value={overloadFull}
                    disabled={menu.PerEdit === true ? false : true}
                    onChange={(e: any) => {
                      _onChangeOverloadFull(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverloadSettingUI;
