import React, { Component, ReactNode } from "react";


type Props = { value?: any, placeholder?: any, onChange?: any };

class TextareaControl extends Component<Props>  {
    private ref: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount(): void {
        const value = this.props.value ?? "";

        if (value) {
            this.ref.current?.classList.remove('placeholder');
            this.ref.current?.classList.add('focus');
        } else {
            this.ref.current?.classList.remove('focus');
            this.ref.current?.classList.add('placeholder');
        }
        
        if (this.ref.current != null) {
            this.ref.current.textContent = value;
        }
    }

    onFocus = (e: any) => {
        this.ref.current?.classList.remove('placeholder');
        this.ref.current?.classList.add('focus');
    }

    onBlur = (e: any) => {
        const value = this.ref.current?.value ?? "";

        if (!value) {
            this.ref.current?.classList.remove('focus');
            this.ref.current?.classList.add('placeholder');
        }
    }

    onInput = (e: any) => {
        const value = e.target.textContent;

        const onChange = this.props.onChange;

        if (onChange) {
            onChange({
                preventDefault: () => { },
                target: {
                    value
                }
            });

            // const range = document.createRange();

            // range.selectNodeContents(e.target);
            // range.collapse(false);

            // const select = window.getSelection();

            // if (select) {
            //     select.removeAllRanges();
            //     select.addRange(range);
            // }
        }
    }

    render() {
        const placeholder = this.props.placeholder ?? "";

        return <div onInput={this.onInput} ref={this.ref} onFocus={this.onFocus} onBlur={this.onBlur} placeholder={placeholder} contentEditable={true} className="core-textarea"></div>;
    }
}

export default TextareaControl;