import axios from "axios";
import axiosRetry from 'axios-retry';

import { getAccessToken, logout, refreshToken } from "./users/userUtils";
import { DOMAIN_USED, LOAD_ALL_NOTI } from "../apis/_index";
import { REQUESTS, SERVER_STATUSES, STATUS_CODE_RESPONSES, VARIABLES } from "../constants/constants";
import URLHepers from "../helpers/urlHelper";
import { tr } from "date-fns/locale";
import HandleData from "./handleData";

const cmsAPI = axios.create({
    // baseURL: DOMAIN_USED,
    timeout: REQUESTS.timeOut,
});

cmsAPI.interceptors.request.use(function (config) {
    const token = getAccessToken();


    config.headers.Authorization = "Bearer " + token;

    return config;
});


let INSTANCE_REQUESTS: any = {
    cmsAPI
}

axiosRetry(axios, { retries: REQUESTS.countRetry });

const setDefaultTokenRequest = (token: any, apiRequest: any) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        instanceRequest.defaults.headers.common["Authorization"] = token;
    }
};

const getBearerToken = () => {
    const authorization: any = {};

    const token = getAccessToken();

    if (token) {
        authorization.Authorization = "Bearer " + token;
    }

    return authorization;
};

const waitingRefreshToken = async (): Promise<any> => {
    return new Promise((resolve: any) => {
        let interval = setInterval(() => {

            if (!VARIABLES.isRefeshToken) {
                clearInterval(interval);

                resolve(true);
            }
        }, 100);
    });
}

const handleRefreshToken = async (accessTokenOld: any, res: any, func: any) => {
    return new Promise(async (resolve: any) => {
        await waitingRefreshToken();

        const accessTokenNew = getAccessToken();

        if (accessTokenOld != accessTokenNew && accessTokenNew && accessTokenOld) {
            const result = await func();

            return resolve(result);
        } else if (res.status == SERVER_STATUSES.unAuthorized || (res.response || {}).status == SERVER_STATUSES.unAuthorized) {

            VARIABLES.isRefeshToken = true;

            const resultRefreshToken = await refreshToken();

            if (!resultRefreshToken.status) {
                logout();

                VARIABLES.isRefeshToken = false;

                return resolve(res);
            } else {
                VARIABLES.isRefeshToken = false;

                const result = await func();

                return resolve(result);
            }
        }

        resolve(res);
    });
}

const get = (url: any, apiRequest: any, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        timeOut = timeOut || REQUESTS.timeOut;

        return new Promise((resolve: any) => {
            instanceRequest
                .get(url, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        ...getBearerToken(),
                        ...headers,
                    },
                    ...configs,
                    timeout: timeOut
                })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const getV2 = (url: any, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null, countRetryRefreshToken = REQUESTS.countRetryRefreshToken): any => {
    timeOut = timeOut || REQUESTS.timeOut;

    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        const accessTokenOld = getAccessToken();

        const bearerToken = getBearerToken();

        const queries = URLHepers.getListQuery(url);

        for (let i = 0; i < queries.length; i++) {
            if (queries[i].value == '-1') {
                queries[i].value = '';
            }
        }

        const urlOriginalAndParam = URLHepers.getUrlOriginalAndParam(url);

        const newUrl = URLHepers.mapUrlWithQuery(urlOriginalAndParam, queries);

        cmsAPI
            .get(newUrl, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...bearerToken,
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
            .then(async (res: any) => {
                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await getV2(url, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {
                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await getV2(url, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const post = (url: any, apiRequest: any, params = null, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        timeOut = timeOut || REQUESTS.timeOut;

        return new Promise((resolve: any) => {
            instanceRequest
                .post(url, params, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        ...getBearerToken(),
                        ...headers,
                    },
                    ...configs,
                    timeout: timeOut
                })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const postV2 = (url: any, params = null, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null, countRetryRefreshToken = REQUESTS.countRetryRefreshToken) => {
    timeOut = timeOut || REQUESTS.timeOut;

    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        params = HandleData.convertData(params);

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();

        cmsAPI
            .post(url, params, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    ...bearerToken,
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
            .then(async (res: any) => {
                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await postV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {
                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await postV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const put = (url: any, apiRequest: any, params = null, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        timeOut = timeOut || REQUESTS.timeOut;

        return new Promise((resolve: any) => {
            instanceRequest
                .put(url, params, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        ...getBearerToken(),
                        ...headers,
                    },
                    ...configs,
                    timeout: timeOut
                })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const putV2 = (url: any, params = null, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null, countRetryRefreshToken = REQUESTS.countRetryRefreshToken) => {
    timeOut = timeOut || REQUESTS.timeOut;

    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        params = HandleData.convertData(params);

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();

        cmsAPI
            .put(url, params, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    ...bearerToken,
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
            .then(async (res: any) => {

                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await putV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {

                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await putV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const patchV2 = (url: any, params = null, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null, countRetryRefreshToken = REQUESTS.countRetryRefreshToken) => {
    timeOut = timeOut || REQUESTS.timeOut;

    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        params = HandleData.convertData(params);

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();

        cmsAPI
            .patch(url, params, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    ...bearerToken,
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
            .then(async (res: any) => {

                return resolve(handleRefreshToken(accessTokenOld, res, () => {
                    countRetryRefreshToken--;

                    return patchV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);
                }));
            })
            .catch((err: any) => {

                return resolve(handleRefreshToken(accessTokenOld, err, () => {
                    countRetryRefreshToken--;

                    return patchV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);
                }));
            });
    });
};

const _delete = (url: any, apiRequest: any, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        return new Promise((resolve: any) => {
            instanceRequest
                .delete(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        ...getBearerToken(),
                        ...headers,
                    },
                    ...configs,
                    timeout: timeOut
                })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const _deleteV2 = (url: any, headers = {}, configs = {}, timeOut = REQUESTS.timeOut, dispatch = null, countRetryRefreshToken = REQUESTS.countRetryRefreshToken) => {
    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();

        cmsAPI
            .delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    ...bearerToken,
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
            .then(async (res: any) => {
                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await _deleteV2(url, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {
                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await _deleteV2(url, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const postUploadFile = (
    url: any,
    apiRequest: any,
    params: any,
    headers = {},
    configs = {},
    timeOut = REQUESTS.timeOut,
    dispatch = null
) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        timeOut = timeOut || REQUESTS.timeOut;

        const formData: any = new FormData();

        const keys = Object.keys(params);

        for (let i = 0; i < keys.length; i++) {
            if (Array.isArray(params[keys[i]])) {
                const array = params[keys[i]];

                const isFile = array.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                if (!isFile) {
                    for (let j = 0; j < array.length; j++) {
                        const keyArrays = Object.keys(array[j]);

                        for (let k = 0; k < keyArrays.length; k++) {
                            if (Array.isArray(array[j][keyArrays[k]])) {
                                let array2 = array[j][keyArrays[k]];

                                const isFile = array2.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                                if (!isFile) {
                                    for (let o = 0; o < array2.length; o++) {
                                        const keyArrays = Object.keys(array2[o]);

                                        for (let m = 0; m < keyArrays.length; m++) {
                                            formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array2[o][keyArrays[m]]);
                                        }
                                    }
                                } else {
                                    for (let o = 0; o < array2.length; o++) {
                                        formData.append(`${keys[i]}[${j}].${keyArrays[k]}[${o}]`, array2[o]);
                                    }
                                }
                            } else if (typeof array[j][keyArrays[k]] === 'object') {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, JSON.stringify(array[j][keyArrays[k]]));
                            } else {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array[j][keyArrays[k]]);
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < array.length; j++) {
                        formData.append(`${keys[i]}`, array[j]);
                    }
                }
            } else if (typeof params[keys[i]] === 'object') {
                formData.append(keys[i], JSON.stringify(params[keys[i]]));
            } else {
                formData.append(keys[i], params[keys[i]]);
            }
        }


        return new Promise((resolve: any) => {
            instanceRequest({
                method: "POST",
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data;' + ' boundary=' + formData._boundary,
                    ...getBearerToken(),
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const postUploadFileV2 = (
    url: any,
    params: any,
    headers = {},
    configs = {},
    timeOut = REQUESTS.timeOut,
    dispatch = null,
    countRetryRefreshToken = REQUESTS.countRetryRefreshToken
) => {
    timeOut = timeOut || REQUESTS.timeOut;

    let formData: any = new FormData();

    if (!(params instanceof FormData)) {
        params = HandleData.convertData(params);

        const keys = Object.keys(params);

        for (let i = 0; i < keys.length; i++) {
            if (Array.isArray(params[keys[i]])) {
                const array = params[keys[i]];

                const isFile = array.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                if (!isFile) {
                    for (let j = 0; j < array.length; j++) {
                        const keyArrays = Object.keys(array[j]);

                        for (let k = 0; k < keyArrays.length; k++) {
                            if (Array.isArray(array[j][keyArrays[k]])) {
                                let array2 = array[j][keyArrays[k]];

                                const isFile = array2.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                                if (!isFile) {
                                    for (let o = 0; o < array2.length; o++) {
                                        const keyArrays = Object.keys(array2[o]);

                                        for (let m = 0; m < keyArrays.length; m++) {
                                            formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array2[o][keyArrays[m]]);
                                        }
                                    }
                                } else {
                                    for (let o = 0; o < array2.length; o++) {
                                        formData.append(`${keys[i]}[${j}].${keyArrays[k]}[${o}]`, array2[o]);
                                    }
                                }
                            } else if (typeof array[j][keyArrays[k]] === 'object') {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, JSON.stringify(array[j][keyArrays[k]]));
                            } else {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array[j][keyArrays[k]]);
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < array.length; j++) {
                        formData.append(`${keys[i]}`, array[j]);
                    }
                }
            } else if (typeof params[keys[i]] === 'object') {
                formData.append(keys[i], JSON.stringify(params[keys[i]]));
            } else {
                formData.append(keys[i], params[keys[i]]);
            }
        }
    } else if (params instanceof FormData) {
        formData = params;
    }

    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();


        cmsAPI({
            method: "POST",
            url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;' + ' boundary=' + formData._boundary,
                ...bearerToken,
                ...headers,
            },
            ...configs,
            timeout: timeOut
        })
            .then(async (res: any) => {
                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await postUploadFileV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {


                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await postUploadFileV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const putUploadFile = (
    url: any,
    params: any,
    apiRequest: any,
    headers = {},
    configs = {},
    timeOut = REQUESTS.timeOut,
    dispatch = null
) => {
    const instanceRequest = INSTANCE_REQUESTS[apiRequest];

    if (instanceRequest) {
        timeOut = timeOut || REQUESTS.timeOut;

        const formData: any = new FormData();

        const keys = Object.keys(params);

        for (let i = 0; i < keys.length; i++) {
            if (Array.isArray(params[keys[i]])) {
                const array = params[keys[i]];

                const isFile = array.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                if (!isFile) {
                    for (let j = 0; j < array.length; j++) {
                        const keyArrays = Object.keys(array[j]);

                        for (let k = 0; k < keyArrays.length; k++) {
                            if (Array.isArray(array[j][keyArrays[k]])) {
                                let array2 = array[j][keyArrays[k]];

                                const isFile = array2.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                                if (!isFile) {
                                    for (let o = 0; o < array2.length; o++) {
                                        const keyArrays = Object.keys(array2[o]);

                                        for (let m = 0; m < keyArrays.length; m++) {
                                            formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array2[o][keyArrays[m]]);
                                        }
                                    }
                                } else {
                                    for (let o = 0; o < array2.length; o++) {
                                        formData.append(`${keys[i]}[${j}].${keyArrays[k]}[${o}]`, array2[o]);
                                    }
                                }
                            } else if (typeof array[j][keyArrays[k]] === 'object') {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, JSON.stringify(array[j][keyArrays[k]]));
                            } else {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array[j][keyArrays[k]]);
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < array.length; j++) {
                        formData.append(`${keys[i]}`, array[j]);
                    }
                }
            } else if (typeof params[keys[i]] === 'object') {
                formData.append(keys[i], JSON.stringify(params[keys[i]]));
            } else {
                formData.append(keys[i], params[keys[i]]);
            }
        }

        return new Promise((resolve: any) => {
            instanceRequest({
                method: "PUT",
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data;' + ' boundary=' + formData._boundary,
                    ...getBearerToken(),
                    ...headers,
                },
                ...configs,
                timeout: timeOut
            })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err: any) => {
                    resolve(err);
                });
        });
    } else {
        return new Promise((resolve: any) => resolve(STATUS_CODE_RESPONSES.notFoundInstanceRequest));
    }
};

const putUploadFileV2 = (
    url: any,
    params: any,
    headers = {},
    configs = {},
    timeOut = REQUESTS.timeOut,
    dispatch = null,
    countRetryRefreshToken = REQUESTS.countRetryRefreshToken
) => {
    timeOut = timeOut || REQUESTS.timeOut;

    let formData: any = new FormData();

    if (!(params instanceof FormData)) {
        params = HandleData.convertData(params);

        const keys = Object.keys(params);

        for (let i = 0; i < keys.length; i++) {
            if (Array.isArray(params[keys[i]])) {
                const array = params[keys[i]];

                const isFile = array.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                if (!isFile) {
                    for (let j = 0; j < array.length; j++) {
                        const keyArrays = Object.keys(array[j]);

                        for (let k = 0; k < keyArrays.length; k++) {
                            if (Array.isArray(array[j][keyArrays[k]])) {
                                let array2 = array[j][keyArrays[k]];

                                const isFile = array2.filter((p: any) => p.type && (p.fileName || p.name)).length > 0;

                                if (!isFile) {
                                    for (let o = 0; o < array2.length; o++) {
                                        const keyArrays = Object.keys(array2[o]);

                                        for (let m = 0; m < keyArrays.length; m++) {
                                            formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array2[o][keyArrays[m]]);
                                        }
                                    }
                                } else {
                                    for (let o = 0; o < array2.length; o++) {
                                        formData.append(`${keys[i]}[${j}].${keyArrays[k]}[${o}]`, array2[o]);
                                    }
                                }
                            } else if (typeof array[j][keyArrays[k]] === 'object') {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, JSON.stringify(array[j][keyArrays[k]]));
                            } else {
                                formData.append(`${keys[i]}[${j}].${keyArrays[k]}`, array[j][keyArrays[k]]);
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < array.length; j++) {
                        formData.append(`${keys[i]}`, array[j]);
                    }
                }
            } else if (typeof params[keys[i]] === 'object') {
                formData.append(keys[i], JSON.stringify(params[keys[i]]));
            } else {
                formData.append(keys[i], params[keys[i]]);
            }
        }
    } else if (params instanceof FormData) {
        formData = params;
    }


    return new Promise(async (resolve: any) => {
        if (countRetryRefreshToken < 0) {
            return resolve(STATUS_CODE_RESPONSES.serverError);
        }

        const accessTokenOld = getAccessToken();

        const bearerToken = await getBearerToken();


        cmsAPI({
            method: "PUT",
            url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;' + ' boundary=' + formData._boundary,
                ...bearerToken,
                ...headers,
            },
            ...configs,
            timeout: timeOut
        })
            .then(async (res: any) => {
                return resolve(handleRefreshToken(accessTokenOld, res, async () => {
                    countRetryRefreshToken--;

                    const result = await putUploadFileV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            })
            .catch((err: any) => {
                return resolve(handleRefreshToken(accessTokenOld, err, async () => {
                    countRetryRefreshToken--;

                    const result = await putUploadFileV2(url, params, headers, configs, timeOut, dispatch, countRetryRefreshToken);

                    return result;
                }));
            });
    });
};

const ServiceUtils = {
    get,
    post,
    put,
    _delete,
    setDefaultTokenRequest,
    postFormData: postUploadFile,
    putFormData: putUploadFile,
    getV2,
    postV2,
    putV2,
    _deleteV2,
    putFormDataV2: putUploadFileV2,
    postFormDataV2: postUploadFileV2,
    patchV2
}

export default ServiceUtils;