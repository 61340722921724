import { useContext, useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams } from "react-router-dom";
import { ModalController } from "../../../../ui/homeUI/_index";
import {
  patchDnD,
  fetchData,
  downloadFile,
  fetchFolderShare,
  fetchDriveTrash,
  restoreDrive,
  searchData,
} from "../../utils/helper";
import { FOLDER_IDS } from "../../../../constants/dataConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  ONCOPY_ITEM,
  ONCUT_ITEM,
  ONLOAD_FILELIST,
  ONLOAD_FOLDERLIST,
} from "../../../../redux/reducers/driveReducer/_index";
import LoaderSpinner from "../../../loader-spinner/_index";
import { ContextMenu, FileItem, FolderItem } from "..";
import DeviceUtils from "../../../../utils/devices/deviceUtils";
import { is } from "immer/dist/internal";

const ContentUI = (props: any) => {
  const { id } = useParams();
  const isDriveTrash = id == FOLDER_IDS.trash;
  const isDriveShare = id == FOLDER_IDS.share;
  const isMobile = DeviceUtils.isMobile();

  const [page, setPage] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(0);

  const [isLoading, setIsLoading] = useState(true);

  const [itemActive, setItemActive] = useState<any>(null);

  const dispatch = useDispatch();

  const [sortByName, setSortByName] = useState(true);

  const [openDropdown, setOpenDropdown] = useState(false);

  const dropdownRef = useRef<any>(null);
  const activeRef = useRef<any>(null);

  const [selectChange, setSelectChange] = useState("Sửa đổi lần cuối");

  const context = useContext(ModalController);

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [contextMenuItem, setContextMenuItem] = useState<any>(null);

  const [draggedItem, setDraggedItem] = useState<any | null>(null);
  const [activeDropTarget, setActiveDropTarget] = useState<any | null>(null);

  const { copiedItem, cutItem, fileList, folderList } = useSelector(
    (state: any) => state.driveReducer
  );

  const _onClickRename = (item: any) => {
    context.setFuncs("renameDrive");
    context.setFolderState(item);
    context.handleOpen();
  };

  const _onClickDeleteOnTrash = (item: any) => {
    context.setFuncs("deleteOnTrash");
    context.setFolderState(item);
    context.handleOpen();
  };

  const _onClickMoveToTrash = (item: any) => {
    context.setFuncs("moveToTrash");
    context.setFolderState(item);
    context.handleOpen();
  };

  const _onClickDownload = (item: any) => {
    const filePath = item.FilePath || "";
    const splitExtensions = filePath.split(".");
    const extension = splitExtensions[splitExtensions.length - 1] || "";
    const fileName = item.FileName + "." + extension;

    item.FileId
      ? downloadFile(item.FileId, fileName)
      : window.alert("Comming soon!");
  };

  const _onClickShare = (item: any) => {
    context.setFuncs("shareDrive");
    context.setFolderState(item);
    context.handleOpen();
  };

  const _onClickRestore = (item: any) => {
    restoreDrive(item).then(() => {
      fetchDriveTrash().then((response: any) => {
        dispatch(ONLOAD_FILELIST(response.FileClouds));
        dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
      });
    });
  };

  const _onClickCopy = (item: any) => {
    dispatch(ONCUT_ITEM(null));
    dispatch(ONCOPY_ITEM(item));
  };

  const _onClickCut = (item: any) => {
    dispatch(ONCOPY_ITEM(null));
    dispatch(ONCUT_ITEM(item));
  };

  const _onClickPaste = (iD: any) => {
    if (copiedItem) {
      window.alert("Comming soon!");
    } else if (cutItem && cutItem.FolderId != iD) {
      patchDnD(cutItem, iD).then(() => {
        fetchData(id).then((response: any) => {
          dispatch(ONLOAD_FILELIST(response.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
          removeItem();
        });
      });
    }
  };

  const removeItem = () => {
    dispatch(ONCOPY_ITEM(null));
    dispatch(ONCUT_ITEM(null));
  };

  const _handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }

    if (activeRef.current && !activeRef.current.contains(event.target)) {
      setItemActive(null);
    }
  };

  const _handleContextMenu = (e: any) => {
    e.preventDefault();
    const contextMenu = contextMenuRef.current;

    if (contextMenu) {
      if (!isMobile) {
        let x = e.clientX;
        let y = e.clientY;
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        const cmWidth = contextMenu.offsetWidth;
        const cmHeight = contextMenu.offsetHeight;

        x = x > winWidth - cmWidth ? winWidth - cmWidth - 5 : x;
        y = y > winHeight - cmHeight ? winHeight - cmHeight - 5 : y;

        contextMenu.style.left = `${x}px`;
        contextMenu.style.top = `${y}px`;
      } else {
        contextMenu.style.left = `0px`;
        contextMenu.style.top = `0px`;
      }
      contextMenu.style.visibility = "visible";
    }
  };

  const _handleCloseContextMenu = (e: any) => {
    const contextMenu = contextMenuRef.current;

    if (!e.target || !(e.target instanceof HTMLElement)) {
      return;
    }

    if (e.target.closest(".openContextMenu")) {
      return;
    }

    if (contextMenu && !contextMenuRef.current.contains(e.target)) {
      contextMenu.style.left = `0px`;
      contextMenu.style.top = `0px`;
      contextMenu.style.visibility = "hidden";
    }
  };

  const _handleDragStart = (e: React.DragEvent<HTMLElement>, item: any) => {
    setDraggedItem(item);
  };

  const _handleDragOver = (e: React.DragEvent<HTMLElement>, target: any) => {
    e.preventDefault();
    setActiveDropTarget(target);
  };

  const _handleDragLeave = () => {
    setActiveDropTarget(null);
  };

  const _handleDragEnd = () => {
    setDraggedItem(null);
  };

  const _handleDrop = (
    e: React.DragEvent<HTMLElement>,
    target: any,
    type: boolean
  ) => {
    e.preventDefault();

    if (type && draggedItem.FolderId !== target.FolderId) {
      patchDnD(draggedItem, target.FolderId).then(() => {
        fetchData(id).then((response: any) => {
          dispatch(ONLOAD_FILELIST(response.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
        });
      });
    }

    setDraggedItem(null);
    setActiveDropTarget(null);
  };

  useEffect(() => {
    document.addEventListener("mousedown", _handleCloseContextMenu);
    document.addEventListener("wheel", _handleCloseContextMenu);
    document.addEventListener("mousedown", _handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", _handleCloseContextMenu);
      document.removeEventListener("wheel", _handleCloseContextMenu);
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleCXV = (event: any) => {
      if (event.ctrlKey && event.key == "v") {
        if (copiedItem) {
          window.alert("Comming soon!");
        } else if (cutItem && cutItem.FolderId != id) {
          patchDnD(cutItem, id).then(() => {
            fetchData(id).then((response: any) => {
              dispatch(ONLOAD_FILELIST(response.FileClouds));
              dispatch(ONLOAD_FOLDERLIST(response.FolderClouds));
            });
          });
        }
        removeItem();
      } else if (event.ctrlKey && event.key === "c") {
        dispatch(ONCOPY_ITEM(itemActive));
        dispatch(ONCUT_ITEM(null));
      } else if (event.ctrlKey && event.key === "x") {
        dispatch(ONCOPY_ITEM(null));
        dispatch(ONCUT_ITEM(itemActive));
      }
    };

    document.addEventListener("keydown", handleCXV);

    return () => {
      document.removeEventListener("keydown", handleCXV);
    };
  }, [copiedItem, cutItem, dispatch, id, itemActive]);

  const systemData = useSelector((state: any) => state.systemReducer);
  const spreadrums = systemData.spreadrums || [];
  const isSearch = () => {
    if (spreadrums.length == 1 && spreadrums[0].id == "search") return true;
    return false;
  };

  const { searchStr } = useSelector((state: any) => state.driveReducer);

  useEffect(() => {
    if (id == FOLDER_IDS.share) {
      fetchFolderShare(page, pageSize)
        .then((res: any) => {
          dispatch(ONLOAD_FILELIST(res.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(res.FolderClouds));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (id == FOLDER_IDS.trash) {
      fetchDriveTrash()
        .then((res: any) => {
          dispatch(ONLOAD_FILELIST(res.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(res.FolderClouds));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (id == FOLDER_IDS.search) {
      searchData(searchStr)
        .then((res: any) => {
          dispatch(ONLOAD_FILELIST(res?.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(res?.FolderClouds));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchData(id)
        .then((res: any) => {
          dispatch(ONLOAD_FILELIST(res?.FileClouds));
          dispatch(ONLOAD_FOLDERLIST(res?.FolderClouds));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, searchStr]);

  // console.log(folderList);
  // console.log(fileList);

  const [holdTimeout, setHoldTimeout] = useState<any>(null);
  const tableRef = useRef<any>(null);

  const handleMouseDown = (e: any) => {
    if (
      !e.target.closest(".folder-item-class") &&
      !e.target.closest(".file-item-class") &&
      tableRef.current.contains(e.target)
    ) {
      const timeout: any = setTimeout(() => {
        _handleContextMenu(e);
        setContextMenuItem(null);
      }, 2000);

      setHoldTimeout(timeout);
    }
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimeout);
  };

  if (isLoading) {
    return (
      <div className="w-full h-full justify-center items-center flex flex-col">
        <LoaderSpinner w={32} h={32} />
        <p className="text-primary">Đang tải dữ liệu</p>
      </div>
    );
  }

  return (
    <>
      <div
        className="flex-grow"
        onContextMenu={(e) => {
          _handleContextMenu(e);
          setContextMenuItem(null);
        }}
        onMouseDown={(e) => {
          handleMouseDown(e);
        }}
        onMouseUp={handleMouseUp}
        ref={tableRef}
      >
        <ContextMenu
          contextMenuRef={contextMenuRef}
          contextMenuItem={contextMenuItem}
          _onClickCopy={_onClickCopy}
          _onClickCut={_onClickCut}
          _onClickPaste={_onClickPaste}
          _onClickShare={_onClickShare}
          _onClickDownload={_onClickDownload}
          _onClickRename={_onClickRename}
          _onClickMoveToTrash={_onClickMoveToTrash}
          _onClickRestore={_onClickRestore}
          _onClickDeleteOnTrash={_onClickDeleteOnTrash}
        />

        {props.changeType ? (
          <div className="flex flex-col px-0 w-full overflow-x">
            <table className="w-full border-collapse bg-white text-left text-sm cursor-default">
              <thead className="font-normal bg-white w-full">
                <tr className="">
                  <th className="table-cell bg-white">
                    <div className="flex">
                      <p>Tên</p>

                      <div
                        className="text-sm ml-2 hover:bg-gray-200 rounded-full cursor-pointer"
                        onClick={() => setSortByName(!sortByName)}
                      >
                        {sortByName ? (
                          <ArrowDownwardIcon className="p-0.5" />
                        ) : (
                          <ArrowUpwardIcon className="p-0.5" />
                        )}
                      </div>
                    </div>
                  </th>
                  {(isDriveTrash || isDriveShare) && (
                    <th className="hidden lg:table-cell bg-white">
                      <div className="flex">
                        <p>Chủ sỡ hữu</p>
                      </div>
                    </th>
                  )}

                  <th
                    className="hidden lg:table-cell bg-white relative z-5"
                    ref={dropdownRef}
                  >
                    <div
                      className="flex cursor-pointer"
                      onClick={() => {
                        setOpenDropdown(!openDropdown);
                      }}
                    >
                      <p>{selectChange}</p>
                      <ArrowDropDownIcon />
                    </div>
                    {openDropdown && (
                      <div className="absolute left-0 z-5 w-56 rounded-md bg-white shadow-xl shadow-[#C0C0C0] font-normal">
                        <div className="mt-4 mx-4">
                          <p className="text-xs font-medium">Sắp xếp theo</p>
                        </div>
                        <ul className="list-none p-0 m-0 my-2">
                          <li
                            className="text-gray-700 px-4 py-2 flex items-center hover:cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                              setSelectChange("Sửa đổi lần cuối");
                              setOpenDropdown(false);
                            }}
                          >
                            <CheckIcon
                              className={`mr-4 text-blue-500 ${
                                selectChange !== "Sửa đổi lần cuối" &&
                                "opacity-0"
                              }`}
                            />
                            <p>Sửa đổi lần cuối</p>
                          </li>
                          <li
                            className="text-gray-700 px-4 py-2 flex items-center hover:cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                              setSelectChange("Do tôi sửa gần đây nhất");
                              setOpenDropdown(false);
                            }}
                          >
                            <CheckIcon
                              className={`mr-4 text-blue-500 ${
                                selectChange !== "Do tôi sửa gần đây nhất" &&
                                "opacity-0"
                              }`}
                            />
                            <p>Do tôi sửa gần đây nhất</p>
                          </li>
                          <li
                            className="text-gray-700 px-4 py-2 flex items-center hover:cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                              setSelectChange("Được tôi mở lần cuối");
                              setOpenDropdown(false);
                            }}
                          >
                            <CheckIcon
                              className={`mr-4 text-blue-500 ${
                                selectChange !== "Được tôi mở lần cuối" &&
                                "opacity-0"
                              }`}
                            />
                            <p>Được tôi mở lần cuối</p>
                          </li>
                        </ul>
                      </div>
                    )}
                  </th>
                  <th className="hidden lg:table-cell bg-white">
                    <div className="flex">
                      <p>Kích cỡ tệp</p>
                    </div>
                  </th>
                  <th className="table-cell text-right bg-white">
                    <div className=" flex justify-end">
                      {/* <button className="openContextMenu hover:bg-gray-300 p-1 rounded-full cursor-pointer">
                        <MoreVertIcon />
                      </button> */}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {folderList &&
                  folderList.map((item: any, index: any) => (
                    <FolderItem
                      key={index}
                      activeRef={activeRef}
                      ChangeType={props.changeType}
                      item={item}
                      itemActive={itemActive}
                      activeDropTarget={activeDropTarget}
                      setItemActive={setItemActive}
                      setContextMenuItem={setContextMenuItem}
                      _handleContextMenu={_handleContextMenu}
                      _onClickRename={_onClickRename}
                      _onClickShare={_onClickShare}
                      _handleDragEnd={_handleDragEnd}
                      _handleDragLeave={_handleDragLeave}
                      _handleDragOver={_handleDragOver}
                      _handleDragStart={_handleDragStart}
                      _handleDrop={_handleDrop}
                    />
                  ))}
                {fileList &&
                  fileList.map((item: any, index: any) => (
                    <FileItem
                      key={index}
                      activeRef={activeRef}
                      ChangeType={props.changeType}
                      item={item}
                      itemActive={itemActive}
                      setItemActive={setItemActive}
                      setContextMenuItem={setContextMenuItem}
                      _handleContextMenu={_handleContextMenu}
                      _onClickRename={_onClickRename}
                      _onClickShare={_onClickShare}
                      _onClickDownload={_onClickDownload}
                      _handleDragEnd={_handleDragEnd}
                      _handleDragStart={_handleDragStart}
                      _handleDrop={_handleDrop}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="px-3"
            onContextMenu={(e) => {
              _handleContextMenu(e);
              setContextMenuItem(null);
            }}
          >
            {(folderList || []).length !== 0 && (
              <>
                <h3 className="mb-2 font-normal">Thư mục</h3>
                <div className="grid items-center  gap-4 mx-auto grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                  {folderList.map((item: any, index: any) => (
                    <FolderItem
                      key={index}
                      activeRef={activeRef}
                      ChangeType={props.changeType}
                      item={item}
                      itemActive={itemActive}
                      activeDropTarget={activeDropTarget}
                      setItemActive={setItemActive}
                      setContextMenuItem={setContextMenuItem}
                      _handleContextMenu={_handleContextMenu}
                      _onClickRename={_onClickRename}
                      _onClickShare={_onClickShare}
                      _handleDragEnd={_handleDragEnd}
                      _handleDragLeave={_handleDragLeave}
                      _handleDragOver={_handleDragOver}
                      _handleDragStart={_handleDragStart}
                      _handleDrop={_handleDrop}
                    />
                  ))}
                </div>
              </>
            )}
            {(fileList || []).length !== 0 && (
              <>
                <h3 className="mt-5 mb-2 font-normal">Tệp</h3>
                <div className="grid items-center  gap-4 mx-auto grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4  ">
                  {fileList.map((item: any, index: any) => (
                    <FileItem
                      key={index}
                      activeRef={activeRef}
                      ChangeType={props.changeType}
                      item={item}
                      itemActive={itemActive}
                      setItemActive={setItemActive}
                      setContextMenuItem={setContextMenuItem}
                      _handleContextMenu={_handleContextMenu}
                      _onClickRename={_onClickRename}
                      _onClickShare={_onClickShare}
                      _onClickDownload={_onClickDownload}
                      _handleDragEnd={_handleDragEnd}
                      _handleDragStart={_handleDragStart}
                      _handleDrop={_handleDrop}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ContentUI;
