import React, { useState, useRef, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from 'react-color';

import { TAKE_PARAMETER_LISTKEY_DROPDOWN } from "../../../apis/_index";

import axios from "axios";

import LoaderSpinner from "../../loader-spinner/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import { ONE_BANGDIEM } from "../../../apis/_index";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import ServiceUtils from "../../../utils/services";

const DetailTableScoringCapacity = (props: any) => {

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const shouldLog = useRef(true);
  const shouldLog1 = useRef(true)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null)
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1)
  const [detailDanhGia, setDetailDanhGia] = useState<any>(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")

    if (shouldLog.current) {
      shouldLog.current = false

      const takePhongBan = async () => {
        try {
          setIsLoading(true)
          const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN"

          await ServiceUtils.getV2(
            API,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setPhongBanList(res.data.Data)
                setIsLoading(false)
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu phòng ban thất bại")
          setIsLoading(false)
        }
      }

      takePhongBan()
    }
  })

  useEffect(() => {
    const API = ONE_BANGDIEM + taskId
    const accessToken = localStorage.getItem("accessToken")

    if (shouldLog1.current) {
      shouldLog1.current = false

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(
            API,
            {
              headers: {
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setDetailDanhGia(res.data.Data)
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      takeData()
    }
  })

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[70rem] w-screen h-[50rem]
    rounded-md
  ">
      {/* header */}
      <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
        <p>Thông tin bảng thiết lập đánh giá</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {
        isLoading === true || detailDanhGia === null ?
          <div className="relative justify-center items-center flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang xử lý biểu mẫu</p>
          </div>
          :
          <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            {/* lines */}


            <div className="flex w-full h-max space-x-6">

              {/* <div className="flex flex-col w-1/3 pr-3 h-max">
            <label>Mã thiết lập &nbsp;<span className="text-red-500">(*)</span></label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Tên thiết lập"
              value={taskId} />
          </div> */}

              <div className="flex flex-col w-3/4 pr-3 h-max">
                <label>Phòng ban áp dụng&nbsp;<span className="text-red-500">(*)</span></label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
                  value={selectedPhongBan}
                >
                  <option className="text-gray-300" value={-1} disabled>--- Chọn phòng ban ---</option>
                  {
                    phongBanList === null ? <></>
                      : phongBanList.map((item: any, index: any) => (
                        <option value={item.ParameterId} key={index}>{item.ParameterValue}</option>
                      ))
                  }
                </select>
              </div>

              <div className="flex flex-col w-1/4 pr-3 h-max items-start">
                <label>Áp dụng</label>
                <input
                  className="w-5 h-5"
                  type="checkbox"
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-3/4 pr-3 h-max">
                <label>Tên thiết lập đánh giá&nbsp;<span className="text-red-500">(*)</span></label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Tên thiết lập"
                  value={detailDanhGia.DanhGia[0].mathietlap}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              <div className="flex flex-col w-1/4 pl-3 h-max">
                <label>Ngày áp dụng</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  type="date"
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full h-max">
                <label>Mô tả&nbsp;<span className="text-red-500">(*)</span></label>
                <textarea
                  className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              {/* elements */}
              <div className="flex flex-col w-1/2 h-max pr-3 space-y-3">
                {/* elements */}
                <div className="flex flex-col w-full h-max">
                  <label className="font-bold">Tiêu chí</label>

                  <div className="flex w-full h-max">
                    <div className="w-2/3 h-max pr-2">
                      <select
                        className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
                        defaultValue={0}
                      >
                        <option className="text-gray-300" value={0} disabled>--- Chọn tiêu chí ---</option>
                        <option>Tiêu chí 1</option>
                        <option>Tiêu chí 2</option>
                        <option>Tiêu chí 3</option>
                      </select>
                    </div>

                    <div className="flex items-center space-x-1">
                      <p>Đạt</p>
                      <input className="w-5 h-5"
                        type="checkbox" />
                    </div>

                    <div className="w-1/3 pl-2">
                      <button className="
                    w-[1.9rem] h-[1.9rem] rounded-full
                    text-xl font-semibold border-green-500 border-2
                    flex justify-center items-center text-green-500 
                    hover:bg-green-500 hover:text-white 
                  ">
                        <p className="pt-1 pb-2">+</p>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full h-max">
                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border-l-[1px] border-slate-300">STT</th>
                        <th className="border-l-[1px] border-slate-300">Tên tiêu chí</th>
                        <th className="border-l-[1px] border-slate-300">Biến số</th>
                        <th className="border-l-[1px] border-slate-300">Giá trị</th>
                        <th className="border-l-[1px] border-r-[1px] border-slate-300 w-[1rem]"></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">#</td>
                        <td className="border border-slate-300">Không có việc trễ hạn quá 3 ngày</td>
                        <td className="text-center border border-slate-300"></td>
                        <td className="text-center border border-slate-300"></td>
                        <td className="text-center border border-slate-300">
                          <button className="text-red-500">
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </td>
                      </tr>
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">#</td>
                        <td className="border border-slate-300">Không đi làm trễ quá 5 lần</td>
                        <td className="text-center border border-slate-300"></td>
                        <td className="text-center border border-slate-300"></td>
                        <td className="text-center border border-slate-300">
                          <button className="text-red-500">
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex flex-col w-1/2 h-max pl-3 space-y-3">
                {/* elements */}
                <div className="flex flex-col w-full h-max">
                  <label className="font-bold">Bảng điểm</label>

                  <div className="flex w-full h-max space-x-2">
                    <div className="flex items-center space-x-1">
                      <p>Từ</p>
                      <input
                        className="w-[2.5rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={1}
                        onTouchStart={(e: any) => onTouchForm(e)}
                      />
                    </div>

                    <div className="flex items-center space-x-1">
                      <p>Đến</p>
                      <input
                        className="w-[2.5rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={5}
                        onTouchStart={(e: any) => onTouchForm(e)}
                      />
                    </div>

                    <div className="flex items-center space-x-1">
                      <p>Đạt</p>
                      <input className="w-5 h-5"
                        type="checkbox" />
                    </div>

                    <div className="flex items-center space-x-1">
                      <p>Kết quả</p>

                      <input
                        className="w-[10.5rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      />
                    </div>

                    <div className="flex items-center space-x-1">
                      <button className="
                    w-[1.9rem] h-[1.9rem] rounded-full
                    text-xl font-semibold border-green-500 border-2
                    flex justify-center items-center text-green-500
                    hover:bg-green-500 hover:text-white
                  ">
                        <p className="pt-1 pb-2">+</p>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full h-max">
                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border-l-[1px] border-slate-300">STT</th>
                        <th className="border-l-[1px] border-slate-300">Bảng điểm</th>
                        <th className="border-l-[1px] border-slate-300">Kết quả</th>
                        <th className="border-l-[1px] border-r-[1px] border-slate-300 w-[1rem]"></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">#</td>
                        <td className="border border-slate-300">Đạt 1 đến 5 tiêu chí</td>
                        <td className="border border-slate-300">Không đạt</td>
                        <td className="text-center border border-slate-300">
                          <button className="text-red-500">
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </td>
                      </tr>
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">#</td>
                        <td className="border border-slate-300">Đạt 6 đến 9 tiêu chí</td>
                        <td className="border border-slate-300">Đạt</td>
                        <td className="text-center border border-slate-300">
                          <button className="text-red-500">
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </td>
                      </tr>
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">#</td>
                        <td className="border border-slate-300">Đạt 10 tiêu chí</td>
                        <td className="border border-slate-300">Xuất sắc</td>
                        <td className="text-center border border-slate-300">
                          <button className="text-red-500">
                            <DeleteTwoToneIcon fontSize="small" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      }


      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">
        <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
        "

        >
          <DeleteIcon fontSize="small" />
          <p>Xoá</p>
        </button>

        <div className="flex w-max h-max space-x-3">
          <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-400
        "

          >
            <SaveIcon fontSize="small" />
            <p>Lưu</p>
          </button>

          <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DetailTableScoringCapacity;
