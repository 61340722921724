/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { EditorInterface } from '../editor.interface';
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import "../editor.css"

const EditorComponent: React.FC<EditorInterface> = (props) => {

	// Take props
	const width = props.state?.width || "100%"
	const height = props.state?.height || 600

	const value = props.state?.value || ""
	const onChange = props.state?.onChange || function() { }
	const setValue = props.state?.setValue || function() { }
	const placeHolder = props.state?.placeHolder || "Mô tả"
	const isShowSaveButton = props.state?.isShowSaveButton === undefined ? true : props.state?.isShowSaveButton
	
	const bodyParams = props.state?.bodyParams || {}
	const onchange = props.state?.bodyParams?.onChange || function() {}

	console.log(bodyParams.TaskID)

	const editorRef = useRef<any>(null)

	// Non - funcs
	const saveContent = () => {
		if (editorRef.current) {
			setValue(editorRef.current.getContent());
		}
	};

	return (
		<>
			<div className='editor-image'
				style={{
					height: height
				}}
			>
				<p>{bodyParams?.TaskID}</p>
				<Editor
					apiKey='0rzlxi2ly7dyfurhu9kcyk5gwq6t2z2iypfq0o16xnyzn6e0'
					onInit={(_evt, editor) => editorRef.current = editor}
					initialValue={value}
					init={{
						placeholder: placeHolder,
						language: 'vi',
						width: width,
						height: height,
						menubar: false,
						entity_encoding : "raw",
						plugins: [
							'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
							'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
							'insertdatetime', 'media', 'table', 'code', 'wordcount'
						],
						toolbar_mode: 'wrap',
						toolbar: 'undo redo | fontfamily | fontsize | table | language | ' +
							'bold italic forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat',
						content_style: 'body { font-family:Times New Roman,Arial,sans-serif;font-size: 12pt }',
						
						setup: (editor) => {
							editor.on("keyup", (e) => {
								onchange(editor.getContent())
							})
						}
					}}
				/>
			</div>

			<div className={`w-full h-max ${isShowSaveButton === true ? "flex" : "hidden"} pt-1 justify-end`}>
				{/* Save button */}
			</div>
		</>
	);
}

export default EditorComponent