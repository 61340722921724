import React, { useEffect, useRef, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import { TAKE_USER_INFO, TAKE_MENU } from "../../../apis/_index";
import { ONLOAD_USERDATA } from "../../../redux/reducers/_appReducer/_index";
import { ONCHANGE_MENULIST } from "../../../redux/reducers/systemReducer/index";

import { takePermission } from "../../../funcs";

// ui importing
import Navbar from "./navbar";
import OptionList from "./optionList";
import HiddenButton from "./hiddenButton";
import MainContent from "./mainContent";

import { Start } from "../../../utils/signalR";
import NavigationComponent from "./navigation";
import ServiceUtils from "../../../utils/services";

const ContentUI = (props: any) => {

  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();
  const menu = takePermission(10);

  const [userdata_, setUserdata_] = useState<any>()

  const shouldLog = useRef(true);

  const urlLink = window.location.pathname;
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");

  // take user info
  // shouldlog - make useEffect called once time
  useEffect(() => {
    if (accessToken === null  && shouldLog.current) {
      shouldLog.current = false;
      navigate("/", { replace: true });
      toast.info("Phiên làm việc đã kết thúc. Vui lòng đăng nhập lại để vào hệ thống");
    } else {
      //console.log("vo");

      const userData = localStorage.getItem("userData");
      const menuList = localStorage.getItem("menuList")

      // take user info
      if ((userData === null || typeof(userData) === undefined) && shouldLog.current) {
        shouldLog.current = false;

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(
              TAKE_USER_INFO,
              {
                headers: {
                }
              }
            )
              .then((res: any) => {
                localStorage.setItem("userData", JSON.stringify(res.data));
                setUserdata_(JSON.stringify(res.data));
                dispatch(ONLOAD_USERDATA(res.data.Data[0]))
                //Start({ users: JSON.stringify(res.data), token: accessToken });
              })
          } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
          }
        }
        takeData();
      } else {
        const userData2: any = localStorage.getItem("userData");

        if (data.userData === null && userData2) {
          dispatch(ONLOAD_USERDATA(JSON.parse(userData2).Data[0]))
        }
        return;
      }

      // take menu
      if (menuList === null) {
        const takeMenu = async () => {
          try {
            await ServiceUtils.getV2(
              TAKE_MENU,
              {
                headers: {
                }
              }
            )
              .then((res: any) => {
                localStorage.setItem("menuList", JSON.stringify(res.data.Data));
                dispatch(ONCHANGE_MENULIST());

              })
          } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
          }
        }
        takeMenu();
      } else {
        return;
      }
      
    }
  }); 

  // h-[calc(100vh-5.14rem)]

  return (
    <div className="flex w-full" style={{
      flex: 1,
      overflow: 'auto'
    }}>
      {/* navbar */}
      {/* <Navbar /> */}

      {/* options list */}
      {/* <OptionList /> */}

      {/* <NavigationComponent /> */}
      {/* hidden button */}
      <HiddenButton />

      {/* main content */}
      <MainContent />
    </div>
  )
}

export default ContentUI;
