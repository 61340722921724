import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONCHANGE_NGUOI_PHOI_HOP } from "../../../redux/reducers/workingReducer/_index";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DETAIL_STEP, DETAIL_RESULT } from "../../../apis/_index";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  UploadFileTaskContent,
  CheckCompletedTaskContent,
  EditFileTaskContent,
} from "../../../action/taskOptionsAction";
import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelIcon from "@mui/icons-material/Cancel";
import SubModal from "./_index";

import { onTouchForm } from "../../../funcs/onTochForm";

const MultipleUploadFileContent = (props: any) => {
  const handleClose = props.handleClose;
  const taskContentsHistory = props.taskContentsHistory;

  const taskContentHistoryId = props.taskContentHistoryId;

  const task = props.task;

  const taskId = props.taskId;
  const isViewHistoty = props.isViewHistoty;
  const _handleCheckCompled = props._handleCheckCompled;

  const _taskContents: any[] = props._taskContents;

  // redux
  const data = useSelector((state: any) => state.workingReducer);
  const appData = useSelector((state: any) => state.appReducer);

  const dispatch = useDispatch();
  const [listFileHistory, setListFileHistory] = useState<any[]>([]);
  const [isConfirm, setIsConfirm] = useState<any>();
  const [listFileHistoryChoose, setListFileHistoryChoose] = useState<any[]>([]);
  const [taskContentsHistoryFilter, settaskContentsHistoryFilter] = useState<
    any[]
  >([]);
  const [isloading, setIsLoading] = useState(false);
  const [isShowButtonUpload, setIsShowButtonUpload] = useState<boolean>(false);
  const [finishedContent, setFinishedContent] = useState<string | "">("");
  const [finishedContentChange, setFinishedContentChange] = useState<
    string | ""
  >("");
  const [_taskContentHistoryId, set_taskContentHistoryId] = useState<
    string | null
  >(null);
  const uploadFileResult = useRef<any>(null);
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [isUploadfile, setIsUploadFile] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<any>();

  useEffect(() => {
    if (isViewHistoty !== 1) {
      if (_taskContents) {
        const __taskContents = _taskContents.find(
          (x: any) => x.ID == taskContentHistoryId
        );

        if (__taskContents.taskContentsHistory) {
          if (__taskContents.taskContentsHistory.IsCompleted === true) {
            if (
              __taskContents.taskContentsHistory.Checked === true &&
              __taskContents.taskContentsHistory.OwnerChecked !== false
            ) {
              setIsShowButtonUpload(false);
            } else if (__taskContents.taskContentsHistory.Checked === null) {
              setIsShowButtonUpload(false);
            } else {
              setIsShowButtonUpload(true);
              setIsUploadFile(true);
            }
          } else {
            let tempFileArray: any[] = [];
            let fileListNameResponse: any[];
            let fileListResponse: any[] = [];

            if (
              __taskContents.taskContentsHistory.ListFileResult &&
              __taskContents.taskContentsHistory.ListNameFileResult
            ) {
              fileListResponse =
                __taskContents.taskContentsHistory.ListFileResult.split(";");
              fileListNameResponse =
                __taskContents.taskContentsHistory.ListNameFileResult.split(
                  ";"
                );
              if (
                fileListResponse.length > 0 &&
                fileListNameResponse.length > 0
              ) {
                fileListNameResponse.map((item: any, index: any) => {
                  let tempObject: any;
                  if (
                    item !== "" &&
                    item !== null &&
                    typeof item !== "undefined"
                  ) {
                    tempObject = {
                      listFile: fileListResponse[index],
                      listFileName: item,
                    };
                  }
                  if (tempObject) {
                    tempFileArray.push(tempObject);
                  }
                  setListFileHistory(tempFileArray);
                });
              }
            }

            setFinishedContent(
              __taskContents.taskContentsHistory.FinishedContent
            );
            setIsShowButtonUpload(true);
            setIsUploadFile(false);
          }
        } else {
          setIsUploadFile(true);
          setIsShowButtonUpload(true);
        }
      }
    } else {
      if (taskContentHistoryId && taskContentsHistory.length > 0) {
        let tempFileArray: any[] = [];
        taskContentsHistory.map((x: any) => {
          if (
            x.Task_ContentID == taskContentHistoryId &&
            x.IsCompleted !== null
          ) {
            tempFileArray.push(x);
          }
        });
        settaskContentsHistoryFilter(tempFileArray);
      }
    }
  }, [_taskContents]);

  const _onClickChooseFileResult = () => {
    uploadFileResult.current.click();
  };

  const _onChangeFile = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...listFileHistoryChoose] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setListFileHistoryChoose(tempArray);
  };

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...listFileHistoryChoose];
    tempArray.splice(index, 1);
    setListFileHistoryChoose(tempArray);
  };

  const _onDeleteFileReady = (linkFile: string) => {
    const tempArray: any[] = [...listFileHistory];

    var index = tempArray.indexOf(linkFile);

    if (index !== -1) {
      tempArray.splice(index, 1);
    }

    setListFileHistory(tempArray);
  };

  const _onClickUploadFileResult = () => {
    setIsLoading(true);
    let stringResultFiles: string = "";
    let formData = new FormData();
    let fileNameString: string = "";

    if (listFileHistory) {
      listFileHistory.map((item: any) => {
        if (item === listFileHistory.length - 1) {
          stringResultFiles += item.listFile;
          if (item.listFileName) {
            fileNameString += item.listFileName;
          }
        } else {
          stringResultFiles += item.listFile + ";";
          if (item.listFileName) {
            fileNameString += item.listFileName + ";";
          }
        }
      });
    }

    formData.append("ID", taskContentHistoryId ? taskContentHistoryId : "");
    formData.append(
      "ListFileResult",
      stringResultFiles ? stringResultFiles : ""
    );
    formData.append("TaskID", taskId ? taskId : "");
    if (listFileHistoryChoose) {
      for (let i = 0; i < listFileHistoryChoose.length; i++) {
        formData.append(`File_ListFileResult`, listFileHistoryChoose[i]);

        if (i === listFileHistoryChoose.length - 1) {
          fileNameString += `${listFileHistoryChoose[i].name}`;
        } else {
          fileNameString += `${listFileHistoryChoose[i].name};`;
        }
      }
      formData.append("ListNameFileResult", fileNameString);
    }
    formData.append(
      "FinishedContent",
      finishedContentChange ? finishedContentChange : ""
    );

    UploadFileTaskContent(formData).then((res: any) => {
      try {
        const data = (res || {}).data || {};

        if (data.RCode == 200) {
          if (_handleCheckCompled) {
            _handleCheckCompled(1);
          }
          setIsLoading(false);
          toast.success("Tải tệp tin thành công");
        } else {
          setIsLoading(false);
          toast.error(data.RMessage || "Tải tệp tin thất bại");
        }
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error || "Tải tệp tin thất bại");
      }
    });

    setListFileHistoryChoose([]);
  };

  const _onClickEditFileResult = () => {
    setIsLoading(true);
    let stringResultFiles: string = "";
    let formData = new FormData();
    let fileNameString: string = "";

    if (listFileHistory) {
      listFileHistory.map((item: any) => {
        if (item === listFileHistory.length - 1) {
          stringResultFiles += item.listFile;
          if (item.listFileName) {
            fileNameString += item.listFileName;
          }
        } else {
          stringResultFiles += item.listFile + ";";
          if (item.listFileName) {
            fileNameString += item.listFileName + ";";
          }
        }
      });
    }

    formData.append("ID", taskContentHistoryId ? taskContentHistoryId : "");
    formData.append(
      "ListFileResult",
      stringResultFiles ? stringResultFiles : ""
    );
    formData.append("TaskID", taskId ? taskId : "");
    if (listFileHistoryChoose) {
      for (let i = 0; i < listFileHistoryChoose.length; i++) {
        formData.append(`File_ListFileResult`, listFileHistoryChoose[i]);

        if (i === listFileHistoryChoose.length - 1) {
          fileNameString += `${listFileHistoryChoose[i].name}`;
        } else {
          fileNameString += `${listFileHistoryChoose[i].name};`;
        }
      }
      formData.append("ListNameFileResult", fileNameString);
      formData.append(
        "FinishedContent",
        finishedContentChange ? finishedContentChange : ""
      );
    }

    EditFileTaskContent(formData).then((res: any) => {
      try {
        const data = (res || {}).data || {};

        if (data.RCode == 200) {
          if (_handleCheckCompled) {
            _handleCheckCompled(1);
          }
          setIsLoading(false);
          toast.success("Tải tệp tin thành công");
        } else {
          setIsLoading(false);
          toast.error(data.RMessage || "Tải tệp tin thất bại");
        }
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error || "Tải tệp tin thất bại");
      }
    });
    setListFileHistoryChoose([]);
  };

  const _conClickComplateTaskContent = () => {
    if (taskContentHistoryId) {
      setIsLoading(true);
      CheckCompletedTaskContent(taskContentHistoryId).then((res: any) => {
        try {
          const data = (res || {}).data || [];

          if (data[0].RCode == 200) {
            if (_handleCheckCompled) {
              _handleCheckCompled(1);
            }
            setIsLoading(false);
            if (handleClose) {
              handleClose();
            }
            toast.success("Xác nhận hoàn thành bước thành công");
          } else {
            setIsLoading(false);
            if (handleClose) {
              handleClose();
            }
            toast.error(
              data[0].RMessage || "Xác nhận hoàn thành bước thất bại"
            );
          }
        } catch (error: any) {
          setIsLoading(false);
          toast.error(error || "Xác nhận hoàn thành bước thất bại");
        }
      });
      setListFileHistoryChoose([]);
    }
  };

  const _handleChecked = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "Người thực hiện chính không duyệt";
    } else if (statusCheck === true && ownerChecked === null) {
      return "Người giao việc chưa duyệt";
    } else if (statusCheck === null && ownerChecked === null) {
      return "Người thực hiện chính chưa duyệt";
    } else if (statusCheck === true && ownerChecked === false) {
      return "Người giao việc không duyệt";
    } else if (statusCheck === true && ownerChecked === true) {
      return "Người giao việc đã duyệt";
    }
  };

  const _handleColor = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "#a855f7";
    } else if (statusCheck === true && ownerChecked === null) {
      return "#FFAA00";
    } else if (statusCheck === null && ownerChecked === null) {
      return "#78716c";
    } else if (statusCheck === true && ownerChecked === false) {
      return "#ff0000";
    } else if (statusCheck === true && ownerChecked === true) {
      return "#00ff00";
    }
  };

  const _handleListFile = (listFile: any, listFileName: any) => {
    let tempFileArray: any[] = [];
    if (listFile && listFileName) {
      let fileListNameResponse: any[];
      let fileListResponse: any[] = [];

      fileListResponse = listFile.split(";");
      fileListNameResponse = listFileName.split(";");

      fileListNameResponse.map((item: any, index: any) => {
        let tempObject: any;
        if (item !== "" && item !== null && typeof item !== "undefined") {
          tempObject = {
            listFile: fileListResponse[index],
            listFileName: item,
          };
        }
        if (tempObject) {
          tempFileArray.push(tempObject);
        }
      });
    }

    return (
      <>
        {tempFileArray.length > 0 &&
          tempFileArray.map((item: any) => {
            return (
              <a
                href={item.listFile}
                target="_blank"
                rel="noreferrer"
                className="flex w-5/6 h-max space-x-1 text-primary"
              >
                {item.listFileName}
              </a>
            );
          })}
      </>
    );
  };

  const onChangeText = (e: any) => {
    e.persist();
    const value = e.target.value;
    setFinishedContentChange(value);
  };

  const onOpenSub = (
    text: string,
    _taskContentHistoryId: any,
    isConfirm: any,
    isOwner: any
  ) => {
    handleOpenSub();
    setFuncsSub(text);
    set_taskContentHistoryId(_taskContentHistoryId);
    setIsConfirm(isConfirm);
    setIsOwner(isOwner);
  };
  // console.log(appData.userData.Id)
  // console.log(task.TaskOwner)
  return (
    <>
      {isloading ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <SubModal
            open={openSub}
            handleClose={handleCloseSub}
            taskContentID={taskContentHistoryId}
            funcs={funcsSub}
            taskContentHistoryId={_taskContentHistoryId}
            isConfirm={isConfirm}
            _handleCheckCompled={_handleCheckCompled}
            isOwner={isOwner}
          />
          {isViewHistoty === 1 ? (
            <div>
              <div
                className="
        lg:w-[70rem] w-screen h-[37rem] bg-white rounded-md
        flex flex-col
      "
              >
                {/* header */}
                <div
                  className="
        flex items-center pl-4 pr-4
        text-white font-semibold
        bg-sky-500 w-full h-[3rem]
        rounded-t-md text-lg justify-between
      "
                >
                  <p>Lịch sử xử lý bước</p>
                  <button
                    className="hover:bg-slate-300 p-0.5 rounded-md"
                    onClick={handleClose}
                  >
                    X
                  </button>
                </div>

                {/* content */}
                <div
                  className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
                >
                  <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
                    <table>
                      <thead className="text-white font-semibold text-center w-full h-[2rem]">
                        <>
                          <tr style={{ borderBottom: "1px solid white" }}>
                            <th
                              className="border-l-[1px] border-slate-300"
                              colSpan={4}
                            ></th>
                            <th
                              className="border-l-[1px] border-slate-300"
                              colSpan={3}
                            >
                              Người thực hiện chính
                            </th>
                            <th
                              className="border-l-[1px] border-slate-300"
                              colSpan={3}
                            >
                              Người giao việc
                            </th>
                            {/* {(appData.userData.Id === task.TaskOwner &&
                              item.Checked === true &&
                              item.OwnerChecked === null) ||
                            (appData.userData.Id === task.Recipient &&
                              item.OwnerChecked === null) ? (
                              <th className="border-l-[1px] border-slate-300"></th>
                            ) : null} */}
                            {taskContentsHistoryFilter.map(
                              (item: any, index: any) => (
                                <>
                                  {item.OwnerChecked === null ||
                                  (item.OwnerChecked === null &&
                                    appData.userData.Id === task.TaskOwner) ? (
                                    <th className="border-l-[1px] border-slate-300"></th>
                                  ) : null}
                                </>
                              )
                            )}
                          </tr>
                          <tr>
                            <th className="border-l-[1px] border-slate-300">
                              Trạng thái
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Ngày hoàn thành
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Danh sách file
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Nội dung
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Người duyệt
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Ngày duyệt
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Lý do
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Người duyệt
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Ngày duyệt
                            </th>
                            <th className="border-l-[1px] border-slate-300">
                              Lý do
                            </th>
                            {/* {(appData.userData.Id === task.TaskOwner &&
                              item.Checked === true &&
                              item.OwnerChecked === null) ||
                            (appData.userData.Id === task.Recipient &&
                              item.OwnerChecked === null &&
                              appData.userData.Id === task.TaskOwner) ||
                            appData.userData.Id === task.Recipient ? (
                              <th className="border-l-[1px] border-slate-300"></th>
                            ) : (
                              <p>hi</p>
                            )} */}
                            {taskContentsHistoryFilter.map(
                              (item: any, index: any) => (
                                <>
                                  {item.OwnerChecked === null ||
                                  (item.OwnerChecked === null &&
                                    appData.userData.Id === task.TaskOwner) ? (
                                    <th className="border-l-[1px] border-slate-300"></th>
                                  ) : null}
                                </>
                              )
                            )}
                          </tr>
                        </>
                        {/* {taskContentsHistoryFilter.length === 0 ? (
                          <tr></tr>
                        ) : (
                          taskContentsHistoryFilter.map(
                            (item: any, index: any) => (
                              <>
                                <tr style={{ borderBottom: "1px solid white" }}>
                                  <th
                                    className="border-l-[1px] border-slate-300"
                                    colSpan={4}
                                  ></th>
                                  <th
                                    className="border-l-[1px] border-slate-300"
                                    colSpan={3}
                                  >
                                    Người thực hiện chính
                                  </th>
                                  <th
                                    className="border-l-[1px] border-slate-300"
                                    colSpan={3}
                                  >
                                    Người giao việc
                                  </th>
                                  {(appData.userData.Id === task.TaskOwner &&
                                    item.Checked === true &&
                                    item.OwnerChecked === null) ||
                                  (appData.userData.Id === task.Recipient &&
                                    item.OwnerChecked === null) ? (
                                    <th className="border-l-[1px] border-slate-300"></th>
                                  ) : null}
                                </tr>
                                <tr>
                                  <th className="border-l-[1px] border-slate-300">
                                    Trạng thái
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Ngày hoàn thành
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Danh sách file
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Nội dung
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Người duyệt
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Ngày duyệt
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Lý do không duyệt
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Người duyệt
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Ngày duyệt
                                  </th>
                                  <th className="border-l-[1px] border-slate-300">
                                    Lý do không duyệt
                                  </th>
                                  {(appData.userData.Id === task.TaskOwner &&
                                    item.Checked === true &&
                                    item.OwnerChecked === null) ||
                                  (appData.userData.Id === task.Recipient &&
                                    item.OwnerChecked === null &&
                                    appData.userData.Id === task.TaskOwner) ||
                                  appData.userData.Id === task.Recipient ? (
                                    <th className="border-l-[1px] border-slate-300"></th>
                                  ) : (
                                    <p>hi</p>
                                  )}
                                </tr>
                              </>
                            )
                          )
                        )} */}
                      </thead>
                      <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                        {taskContentsHistoryFilter.length === 0 ? (
                          <tr></tr>
                        ) : (
                          taskContentsHistoryFilter.map(
                            (item: any, index: any) => (
                              <>
                                <tr
                                  key={index}
                                  className="w-max h-[3rem] bg-white"
                                >
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: _handleColor(
                                          item.Checked,
                                          item.OwnerChecked
                                        ),
                                        color: "white",
                                        padding: 5,
                                        textAlign: "center",
                                        borderRadius: 13,
                                        width: "fit-content",
                                      }}
                                    >
                                      {_handleChecked(
                                        item.Checked,
                                        item.OwnerChecked
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    <p>
                                      {moment(item.DateAdd).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                    <p>
                                      {moment(item.DateAdd).format("HH:mm:ss")}
                                    </p>
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    <>
                                      {_handleListFile(
                                        item.ListFileResult,
                                        item.ListNameFileResult
                                      )}
                                    </>
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.FinishedContent}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.UserCheckedName}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.DateChecked ? (
                                      <>
                                        <p>
                                          {moment(item.DateChecked).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                        <p>
                                          {moment(item.DateChecked).format(
                                            "HH:mm:ss"
                                          )}
                                        </p>
                                      </>
                                    ) : null}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.Reason}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.OwnerName}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.OwnerCheckedDate ? (
                                      <>
                                        <p>
                                          {moment(item.OwnerCheckedDate).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                        <p>
                                          {moment(item.OwnerCheckedDate).format(
                                            "HH:mm:ss"
                                          )}
                                        </p>
                                      </>
                                    ) : null}
                                  </td>
                                  <td
                                    className={`border border-slate-300 font-bold`}
                                  >
                                    {item.OwnerReason}
                                  </td>

                                  {(appData.userData.Id === task.TaskOwner &&
                                    item.Checked === true &&
                                    item.OwnerChecked === null) ||
                                  (appData.userData.Id === task.Recipient &&
                                    item.OwnerChecked === null) ? (
                                    <td
                                      className={`border border-slate-300 font-bold`}
                                    >
                                      {item.Checked === null ||
                                      (item.Checked === true &&
                                        appData.userData.Id ===
                                          task.TaskOwner) ? (
                                        <div className="dropdown">
                                          {/* button options */}
                                          <button className="dropbtn">
                                            <MoreHorizIcon fontSize="small" />
                                          </button>

                                          {/* options fields */}

                                          <div className="dropdown-content text-xs font-semibold">
                                            <button
                                              className="flex w-full h-full hover:text-sky-500"
                                              onClick={() => {
                                                if (
                                                  appData.userData.Id ===
                                                    task.TaskOwner &&
                                                  item.Checked === true
                                                ) {
                                                  onOpenSub(
                                                    "confirmTaskContents",
                                                    item.ID,
                                                    1,
                                                    1
                                                  );
                                                } else {
                                                  onOpenSub(
                                                    "confirmTaskContents",
                                                    item.ID,
                                                    1,
                                                    0
                                                  );
                                                }
                                              }}
                                            >
                                              {/* detail - contain */}
                                              <div className="flex w-full h-full space-x-1">
                                                {/* icon */}
                                                <CheckIcon
                                                  className="text-sky-600"
                                                  sx={{ fontSize: 18 }}
                                                  fontSize="small"
                                                />

                                                {/* title */}
                                                <div>Duyệt</div>
                                              </div>
                                            </button>

                                            <button
                                              className="flex w-full h-full hover:text-sky-500"
                                              onClick={() => {
                                                if (
                                                  appData.userData.Id ===
                                                    task.TaskOwner &&
                                                  item.Checked === true
                                                ) {
                                                  onOpenSub(
                                                    "confirmTaskContents",
                                                    item.ID,
                                                    0,
                                                    1
                                                  );
                                                } else {
                                                  onOpenSub(
                                                    "confirmTaskContents",
                                                    item.ID,
                                                    0,
                                                    0
                                                  );
                                                }
                                              }}
                                            >
                                              {/* detail - contain */}
                                              <div className="flex w-full h-full space-x-1">
                                                {/* icon */}
                                                <CancelIcon
                                                  className="text-sky-600"
                                                  sx={{ fontSize: 18 }}
                                                  fontSize="small"
                                                />

                                                {/* title */}
                                                <div>Không duyệt</div>
                                              </div>
                                            </button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </td>
                                  ) : null}
                                </tr>
                              </>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* footer */}
                <div
                  className="
          flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
        
        "
                >
                  {/* <button className="bg-green-500 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-green-400"
              onClick={handleClose}
            >
              Xác nhận
            </button> */}
                  <button
                    className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
                    onClick={handleClose}
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="
        w-[40rem] h-[37rem] bg-white rounded-md
        flex flex-col
      "
            >
              {/* header */}
              <div
                className="
                flex w-full bg-sky-500 rounded-t-md
                p-12-16 items-center font-semibold text-white
                text-lg justify-between
        
      "
              >
                <p>Cập nhật kết quả từng bước</p>
                <button
                  className="hover:bg-slate-300 p-0.5 rounded-md"
                  onClick={handleClose}
                >
                  X
                </button>
              </div>

              {/* content */}
              <div
                className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
              >
                <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border-l-[1px] border-slate-300">
                          File
                        </th>
                        <th className="border-l-[1px] border-slate-300">
                          <button
                            className="
                  flex items-center justify-center w-max h-max p-2 text-white-400 text-sm
                  rounded-full border-2 border-white-400 hover:text-white hover:bg-green-400
                "
                            onClick={_onClickChooseFileResult}
                          >
                            Thêm file
                          </button>
                          <input
                            ref={uploadFileResult}
                            className={`w-max h-max hidden`}
                            multiple
                            type="file"
                            onChange={(e: any) => _onChangeFile(e)}
                            onTouchStart={(e: any) => onTouchForm(e)}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {listFileHistory.length === 0 ? (
                        <tr></tr>
                      ) : (
                        listFileHistory.map((item: any, index: any) => (
                          <>
                            <tr key={index} className="w-max h-[3rem] bg-white">
                              <td
                                className={`border border-slate-300 font-bold`}
                              >
                                <a
                                  href={item.listFile}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex w-5/6 h-max space-x-1 text-primary"
                                >
                                  {item.listFileName}
                                </a>
                              </td>
                              <td
                                className={`border border-slate-300 font-bold`}
                              >
                                <button
                                  className="text-red-500 w-1/6 flex p-2"
                                  onClick={() => _onDeleteFileReady(item)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </td>
                            </tr>
                          </>
                        ))
                      )}
                      {listFileHistoryChoose.length === 0 ? (
                        <tr></tr>
                      ) : (
                        listFileHistoryChoose.map((item: any, index: any) => (
                          <>
                            <tr key={index} className="w-max h-[3rem] bg-white">
                              <td
                                className={`border border-slate-300 font-bold`}
                              >
                                <p className="flex w-5/6 h-max space-x-1 text-primary">
                                  {item.name}
                                </p>
                              </td>
                              <td
                                className={`border border-slate-300 font-bold`}
                              >
                                <div>
                                  <button
                                    className="text-red-500 p-2 w-1/6 flex justify-end"
                                    onClick={() => _onDeleteElements(index)}
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div style={{ marginTop: 5, display: "flex" }}>
                    <div className="flex flex-col w-full h-max">
                      <label>Nội dung hoàn thành</label>
                      <textarea
                        className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập nội dung hoàn thành"
                        onChange={(e: any) => onChangeText(e)}
                        value={
                          isShowButtonUpload
                            ? finishedContentChange
                              ? finishedContentChange
                              : finishedContent
                              ? finishedContent
                              : ""
                            : ""
                        }
                      />
                    </div>
                  </div>
                  {isShowButtonUpload === true ? (
                    <div style={{ marginTop: 5, display: "flex" }}>
                      <button
                        className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                        onClick={
                          isUploadfile === true
                            ? _onClickUploadFileResult
                            : _onClickEditFileResult
                        }
                      >
                        {/* detail - contain */}
                        <div className="flex w-full h-full space-x-1">
                          {/* icon */}
                          <FileUploadIcon
                            className="text-white-600"
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />
                          {/* title */}
                          <div>Cập nhật</div>
                        </div>
                      </button>
                      {finishedContent !== "" || listFileHistory.length > 0 ? (
                        <button
                          className="
            flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
            hover:bg-green-400
          "
                          style={{ marginLeft: 5 }}
                          onClick={() => _conClickComplateTaskContent()}
                        >
                          <DoneIcon fontSize="small" />
                          <p>Hoàn tất</p>
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* footer */}
              <div
                className="
          flex items-center justify-end w-full p-12-16 footer rounded-b-md space-x-4 text-sm
        "
              >
                {/* <button className="bg-green-500 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-green-400"
              onClick={handleClose}
            >
              Xác nhận
            </button> */}
                <button
                  className="bg-slate-300 text-white p-2 rounded-md hover:bg-slate-200"
                  onClick={handleClose}
                >
                  Đóng
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MultipleUploadFileContent;
