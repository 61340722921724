import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { takePermission } from "../../../../funcs";

import { ModalController } from "../../../homeUI/_index";
import {
  GET_LIST_CATEGORY_SUGGEST_API,
  PRIOR_LIST,
} from "../../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import {
  ONLOAD_CATEGORY_SUGGEST,
  ONLOAD_PRIORLIST,
} from "../../../../redux/reducers/settingReducer/_index";
import { unsignedToAccented } from "../../../../helpers/handleHelper";
import { MODAL_KEYS } from "../../../../constants/constants";
import ServiceUtils from "../../../../utils/services";

const CategorySuggest = () => {
  const settingData = useSelector((state: any) => state.settingReducer);

  const [categorySuggests, setCategorySuggest] = useState(
    (settingData || {}).categorySuggests || []
  );

  const context = useContext(ModalController);
  const menu = takePermission(13);

  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const _onClickCreateCategorySuggest = () => {
    context.setFuncs(MODAL_KEYS.createOrUpdateCategorySuggest);
    context.handleOpen();
  };

  useEffect(() => {

    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(GET_LIST_CATEGORY_SUGGEST_API, {
              headers: {
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_CATEGORY_SUGGEST(res.data.Data));
              } else {
                toast.error("Tải dữ liệu thất bại.");
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };

      takeData();
    }
  });

  useEffect(() => {
    if (categorySuggests.length <= 0) {
      setCategorySuggest(settingData.categorySuggests || []);
    }
  }, []);

  const onKeyUpSearch = (e: any) => {
    const value = e.target.value;

    if (!value) {
      dispatch(ONLOAD_CATEGORY_SUGGEST(categorySuggests));
    } else {
      const valueFilter = unsignedToAccented(value.trim().toLowerCase());

      const categorySuggestFilters = categorySuggests.filter((p: any) => {
        const name = unsignedToAccented((p.Name || "").trim().toLowerCase());

        return (
          name.includes(valueFilter) ||
          name.startsWith(valueFilter) ||
          name.endsWith(valueFilter) ||
          name.indexOf(valueFilter) > -1 ||
          name.lastIndexOf(valueFilter) > -1
        );
      });

      dispatch(ONLOAD_CATEGORY_SUGGEST(categorySuggestFilters));
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] lg:px-5 p-3 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          {/* <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm danh mục đề xuất."
            onKeyUp={onKeyUpSearch}
          /> */}
          <div></div>

          <button
            className={`
            p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateCategorySuggest}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default CategorySuggest;
