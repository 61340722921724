import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";

import { CONFIRM_EXTEND, NGUOI_GIAO_UPDATE_DEADLINE, ON_LEAVE_CREATE } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AddIcon from '@mui/icons-material/Add';

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import { set } from "date-fns";

import Select from "react-select"
import ServiceUtils from "../../../utils/services";

const CreateNewLeaving = (props: any) => {

  // Props
  const workingData = useSelector((state: any) => state.workingReducer);

  const accessToken = localStorage.getItem("accessToken");
  const taskId = props.taskId;
  const timeExtend = props.timeExtend;
  const [isChangeTimeExtend, setIsChangeTimeExtend] = useState<boolean>(false);
  const extendId = props.extendId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const [newTimeExtend, setNewTimeExtend] = useState<string>(timeExtend);
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  console.log(context)

  // States
  const [dayOff, setDayOff] = useState<any>("")
  const [timeOff, setTimeOff] = useState<any>("")
  const [reason, setReason] = useState<any>("")
  const [personOff, setPersonOff] = useState<any | null>(null)
  const [typeOff, setTypeOff] = useState<boolean | null>(null)

  // Funcs
  const _onClickCreateNewLeaving = () => {
    const accessToken = localStorage.getItem("accessToken")

    const body: any = {
      DateOff: dayOff,
      HoursOff: timeOff,
      Reason: reason,
      IsAwol: typeOff,
      UseId: personOff
    }

    const fetchData = async () => {
      setIsLoading(true)

      try {
        await ServiceUtils.postV2(
          ON_LEAVE_CREATE,
          body,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              toast.success("Tạo nghỉ phép thành công")
              context.dataModal?.tempFuncs()
              setIsLoading(false)
              handleClose()
            } else {
              toast.error(res.data.Message)
              setIsLoading(false)
              handleClose()
            }
          })
      } catch (error) {
        toast.error("Tạo mới thất bại")
        setIsLoading(false)
      }
    }

    fetchData()
  }

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Tạo mới nghỉ phép</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-[25rem] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="space-y-4">
          <div className="flex items-center">
            <label className="w-1/4">Ngày nghỉ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="date"
              value={dayOff}
              onChange={(e: any) => {
                e.preventDefault()
                setDayOff(e.target.value)
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="w-1/4">Giờ nghỉ</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="number"
              placeholder="Nhập số giờ nghỉ"
              value={timeOff}
              onChange={(e: any) => {
                e.preventDefault()
                setTimeOff(e.target.value)
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="w-1/4">Lý do xin nghỉ</label>
            <textarea
              className="w-full h-[5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={reason}
              onChange={(e: any) => {
                e.preventDefault()
                setReason(e.target.value)
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="w-1/4">Người nghỉ</label>
            <Select
              className="z-30 w-full"
              options={tempReceiverList}
              isClearable
              placeholder="--- Chọn người nghỉ ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(data: any) => {
                if (data === null) {
                  setPersonOff(null)
                } else {
                  setPersonOff(data?.Id)
                }
              }}
            />
          </div>

          <div className="flex items-center">
            <label className="w-1/4">Loại</label>
            <Select
              className="z-20 w-full"
              options={[
                {
                  id: 1,
                  value: "Không phép",
                  label: "Không phép",
                  type: false
                },
                {
                  id: 2,
                  value: "Có phép",
                  label: "Có phép",
                  type: true
                },
              ]}
              isClearable
              placeholder="--- Chọn Loại ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(data: any) => {
                if (data === null) {
                  setTypeOff(null)
                } else {
                  setTypeOff(data?.type)
                }
              }}
            />
          </div>

        </div>
      </div>
      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${(isResult === false && result === "")
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
                }`}
              disabled={
                (isResult === false && result === "")
              }

              onClick={_onClickCreateNewLeaving}
            >
              <AddIcon fontSize="small" />
              <p>Tạo mới</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewLeaving;
