import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import individualImage from "../../assets/images/OperationManagmentLevel2.png";
import receiverImage from "../../assets/images/TaskManagmentLevel2.png";

import {
  ONCHANGE_FROM_HOME_TO_PROCESSING,
  ONCHAGE_FILTER_RESULT_WITH_NUMBER_RECEIVER,
  ONCHAGE_FILTER_RESULT_WITH_NUMBER,
  ONCHAGE_FILTER_SELECTED_NGUOI_NHAN,
  ONCHANGE_RESET_FILTER,
} from "../../redux/reducers/workingReducer/_index";
import CloseIcon from "@mui/icons-material/Close";

import Select from "react-select";

import axios from "axios";

// funcs
import Badge from "@mui/material/Badge";

// icon components
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import BalanceRoundedIcon from "@mui/icons-material/BalanceRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

import AssessmentIcon from "@mui/icons-material/Assessment";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

// icon
import WorkIcon from "@mui/icons-material/Work";
import ContentPasteOffRoundedIcon from "@mui/icons-material/ContentPasteOffRounded";

import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";

// working
// -- sender
import HourglassFullRoundedIcon from "@mui/icons-material/HourglassFullRounded";

import ThumbsUpDownRoundedIcon from "@mui/icons-material/ThumbsUpDownRounded";
import AlarmOnRoundedIcon from "@mui/icons-material/AlarmOnRounded";
import LoaderSpinner from "../../components/loader-spinner/_index";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";

// -- receiver
import { AccessTimeFilledOutlined, DoneRounded } from "@mui/icons-material";
import {
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  YEAR_LIST,
  LOAD_ALL_NOTI,
  USER_READ_ALL,
  LOAD_ALL_NOTI_2,
  GET_LIST_STAFF_MANAGE_BY_CURRENT_USER,
} from "../../apis/_index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import moment from "moment";

// -- chart
import TopicRoundedIcon from "@mui/icons-material/TopicRounded";
//CÁC VIỆC CHỜ DUYỆT VÀ ĐÁNH GIÁ KẾT QUẢ
import RuleFolderRoundedIcon from "@mui/icons-material/RuleFolderRounded";
// biểu quyết
import SnippetFolderRoundedIcon from "@mui/icons-material/SnippetFolderRounded";
// đề xuất
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";
// kết qua
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { toast } from "react-toastify";
import DeviceUtils from "../../utils/devices/deviceUtils";
import ServiceUtils from "../../utils/services";
import { ONCHANGE_RESET_FILTER_PROPOSE2 } from "../../redux/reducers/proposeReducer/_index";

const MediumManagerUI = () => {
  const navigate = useNavigate();

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  // const [phongBanList, setPhongBanList] = useState<any[] | undefined>(undefined)
  const [userFollowPhongBanList, setUserFollowPhongBanList] = useState<
    any[] | undefined
  >(undefined);
  const [notiList, setNotiList] = useState<any | null>(null);
  const [notiList_NguoiNhan, setNotiList_NguoiNhan] = useState<any | null>(
    null
  );

  const [selectedPhongBan, setSelectedPhongBan] = useState<any | undefined>(
    null
  );
  const [selectedUserFollowPhongBanList, setSelectedUserFollowPhongBanList] =
    useState<any[] | undefined>();
  const [subShowing, setSubShowing] = useState<string>("");

  // -- for year
  const [monthSearch, setMonthSearch] = useState<any | "">(
    new Date().getMonth() + 1
  );
  const [yearSearch, setYearSearch] = useState<any | "">(
    new Date().getFullYear()
  );
  const [yearList, setYearList] = useState<any[]>([]);

  // note edit change redux to state
  const [highManagerStorage, setHighManagerStorage] = useState<string>("");
  const [mediumManagerStorage, setMediumManagerStorage] = useState<string>("");
  const [staffs, setStaff] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any | undefined>(null);

  const convertMonthToList = (month: any) => {
    if (month) {
      let a: any;
      tempMonthList.map((x: any) => {
        if (x.id === month) {
          a = x;
        }
      });

      return a;
    }
  };

  const tempMonthList = [
    {
      id: 1,
      value: 1,
      label: 1,
    },
    {
      id: 2,
      value: 2,
      label: 2,
    },
    {
      id: 3,
      value: 3,
      label: 3,
    },
    {
      id: 4,
      value: 4,
      label: 4,
    },
    {
      id: 5,
      value: 5,
      label: 5,
    },
    {
      id: 6,
      value: 6,
      label: 6,
    },
    {
      id: 7,
      value: 7,
      label: 7,
    },
    {
      id: 8,
      value: 8,
      label: 8,
    },
    {
      id: 9,
      value: 9,
      label: 9,
    },
    {
      id: 10,
      value: 10,
      label: 10,
    },
    {
      id: 11,
      value: 11,
      label: 11,
    },
    {
      id: 12,
      value: 12,
      label: 12,
    },
  ];

  const loadDataCount_NguoiGiao = (accessToken: any, api: any) => {
    const takeNoti = async () => {
      try {
        await ServiceUtils.getV2(api, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setNotiList(res.data.Data);
          } else {
            toast.error("Tải dữ liệu thất bại");
          }
        });
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại");
      }
    };

    takeNoti();
  };

  const loadDataCount_NguoiNhan = (accessToken: any, api: any) => {
    const takeNoti_Nguoinhan = async () => {
      try {
        await ServiceUtils.getV2(api, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res?.data?.StatusCode === 200) {
            setNotiList_NguoiNhan(res?.data?.Data);
          } else {
            toast.error("Tải dữ liệu noti thất bại");
          }
        });
      } catch (error: any) {
        toast.error("Tải dữ liệu noti thất bại");
      }
    };
    takeNoti_Nguoinhan();
  };

  const _onChangeSelect = (e: any, type: any) => {
    let _year = yearSearch;
    let _month = monthSearch;

    if (type === "year" && e?.id) {
      _year = e?.id;
      setYearSearch(e?.id);
      setSelectedYear(e);
    } else if (type === "month") {
      _month = e?.id;
      setMonthSearch(e?.id);
    }

    const accessToken = localStorage.getItem("accessToken");
    const API_Noti = LOAD_ALL_NOTI + "?month=" + _month + "&year=" + _year;
    const API_Noti_NGUOI_NHAN =
      LOAD_ALL_NOTI_2 + "?month=" + _month + "&year=" + _year;
    loadDataCount_NguoiGiao(accessToken, API_Noti);
    loadDataCount_NguoiNhan(accessToken, API_Noti_NGUOI_NHAN);
  };

  const convertYearToSelect = (year: any[] = []) => {
    let lstYear: any[] = [];
    if (year && year?.length > 0) {
      year.map((x: any) => {
        lstYear.push({
          id: x.Year,
          value: x.Year,
          label: x.Year,
        });
      });
      return lstYear;
    } else {
      return [];
    }
  };

  const _onClickToChangeNavigate = (title: string) => {
    let year = yearSearch;
    let month = monthSearch;
    let titleFinal = title;

    try {
      Promise.all([
        dispatch(
          ONCHANGE_FROM_HOME_TO_PROCESSING({
            month: month,
            year: year,
            title: titleFinal,
          })
        ),
        dispatch(ONCHANGE_RESET_FILTER({ url: titleFinal })),
        navigate(titleFinal),
      ]);
    } catch (error) { }
  };
  // -- end

  // onMount

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const API_Noti =
      LOAD_ALL_NOTI + "?month=" + monthSearch + "&year=" + yearSearch;
    const API_Noti_NGUOI_NHAN =
      LOAD_ALL_NOTI_2 + "?month=" + monthSearch + "&year=" + yearSearch;

    // const takeData = async () => {
    //   try {
    //     await ServiceUtils.getV2(
    //       API,
    //       {
    //         headers: {
    //           Accept: "text/plain"
    //         }
    //       }
    //     )
    //       .then(res => {
    //         if (res.data.StatusCode === 200) {
    //           const tempArray = res.data.Data.map((item: any) => { return { ...item, value: item.ParameterValue, label: item.ParameterValue } })
    //           setPhongBanList(tempArray)
    //         } else {
    //           toast.error(res.data.Message)
    //         }
    //       })
    //   } catch (error: any) {
    //     toast.error("Tải dữ liệu phòng ban thất bại")
    //   }
    // }

    // const takeNoti = async () => {
    //   try {
    //     await ServiceUtils.getV2(
    //       LOAD_ALL_NOTI,
    //       {
    //         headers: {
    //           Accept: "text/plain"
    //         }
    //       }
    //     )
    //       .then(res => {
    //         if (res.data.StatusCode === 200) {
    //           setNotiList(res.data.Data)
    //         } else {
    //           toast.error("Tải dữ liệu noti thất bại")
    //         }
    //       })
    //   } catch (error: any) {
    //     toast.error("Tải dữ liệu noti thất bại")
    //   }
    // }

    const takeNoti_Nguoinhan = async () => { };

    const takeYear = async () => {
      try {
        await ServiceUtils.getV2(YEAR_LIST, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res?.data?.StatusCode === 200) {
            setYearList(convertYearToSelect(res?.data?.Data?.data || []));

            const years = res?.data?.Data?.data || [];

            if (years.length > 0) {
            
              const accessToken = localStorage.getItem("accessToken");

              const API_Noti =
                LOAD_ALL_NOTI + "?month=" + monthSearch + "&year=" + yearSearch;

              const API_Noti_NGUOI_NHAN =
                LOAD_ALL_NOTI_2 + "?month=" + monthSearch + "&year=" + yearSearch;

              loadDataCount_NguoiGiao(accessToken, API_Noti);

              loadDataCount_NguoiNhan(accessToken, API_Noti_NGUOI_NHAN);
            }
          } else {
            toast.error(res?.data?.Message || "Tải dữ liệu năm thất bại");
          }
        });
      } catch (error: any) {
        toast.error("Tải dữ liệu năm thất bại");
      }
    };

    takeYear();
    // // takeData()
    // takeNoti()
    // loadDataCount_NguoiNhan(accessToken, API_Noti_NGUOI_NHAN)
    // loadDataCount_NguoiGiao(accessToken, API_Noti)
  }, []);

  // onLoadWithSelectedPhongBan
  useEffect(() => {
    if (selectedPhongBan === null) {
      setUserFollowPhongBanList(undefined);
    } else {
      // const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(USER_READ_ALL, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setUserFollowPhongBanList(
                res.data.Data.filter((item: any) =>
                  (item.PhongBan || "")
                    .split(",")
                    .find((p: any) => p == selectedPhongBan) != null
                    ? true
                    : false
                )
              );
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error("Tải dữ liệu phòng ban thất bại");
        }
      };

      takeData();
    }

    _getStaff();
  }, [selectedPhongBan]);

  // funcs
  const _onChangePhongBan = (data: any) => {
    if (data === null) {
      setSelectedPhongBan(null);
    } else {
      setSelectedPhongBan(data.ParameterId);
    }
  };

  const _onClickNavigate = (url: string) => {
    dispatch(ONCHANGE_RESET_FILTER({ url: url }));
    navigate(url);
  };

  const _onClickSubShowing = (title: string) => {
    setHighManagerStorage(title);
    //dispatch(ONCHANG_HIGHMANGER_STORAGE(title))
  };

  const _onClickSubShowing_NhanViec = (title: string) => {
    // dispatch(ONCHANG_MEDIUMMANAGER_STORAGE(title))
    setMediumManagerStorage(title);
  };

  // Funcs
  const _onClickNavigateTaskResult = async (id: number) => {
    try {
      dispatch(
        ONCHAGE_FILTER_RESULT_WITH_NUMBER({
          id: id,
          year: yearSearch,
          month: monthSearch,
        })
      );
    } catch (error) { }
  };

  const _onClickNavigateTaskResultReceiver = async (id: number) => {
    try {
      dispatch(
        ONCHAGE_FILTER_RESULT_WITH_NUMBER_RECEIVER({
          id: id,
          year: yearSearch,
          month: monthSearch,
        })
      );
    } catch (error) { }
  };

  const _getStaff = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");

      ServiceUtils.getV2(GET_LIST_STAFF_MANAGE_BY_CURRENT_USER, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if ((res.data || {}).StatusCode === 200) {
          const staffs = ((res.data || {}).Data || {}).NguoiNhan || [];

          setStaff(staffs);
        } else {
          toast.error("Tải dữ liệu nhân viên thất bại !");
        }
      });
    } catch (error: any) {
      toast.error("Tải dữ liệu nhân viên thất bại !");
    }
  };

  const isMobile = DeviceUtils.isMobile();

  let classResponsiveGrid = "grid-cols-3";

  if (isMobile) {
    classResponsiveGrid = "grid-cols-2";
  }

  staffs.reduce((otherLator: any, currentLator: any) => {
    let existingObject = otherLator.find(
      (object: any) => object.Id === currentLator.Id
    );

    if (existingObject) {
      existingObject = existingObject;
    } else {
      otherLator.push({ ...currentLator });
    }

    return otherLator;
  }, []);

  return (
    <div className="w-full h-full flex lg:flex-row flex-col lg:pl-3 lg:pr-5 pl-0 pr-0 mt-5 lg:space-y-0 space-y-20">
      {/* leftside */}
      <div className="lg:w-1/2 w-full h-max flex flex-col space-y-5 lg:pr-6 pr-0 border-slate-200">
        {/* <div className="w-full bg-amber-500 h-max text-cyan-800 flex justify-center items-center rounded-xl text-center" style={{ padding: 5 }}>
          <p className="font-semibold text-2xl">QUẢN LÝ CÔNG VIỆC CÁ NHÂN</p>
        </div> */}
        <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none"
            style={{ width: "var(--px-180)" }}
            src={individualImage}
            alt=""
          />
        </div>

        {/* Options fields */}
        <div className="flex flex-col w-full h-max text-sm space-y-6">
          {/* Elements with sub */}
          <div className="w-full h-max flex flex-col space-y-3">
            {/* Main */}
            <div className="flex items-center lg:flex-row flex-col">
              <div className="w-full h-max flex items-center">
                <p className="w-2/6 font-bold">Chọn tháng</p>
                <Select
                  className="z-[5] w-4/6 lg:mr-5"
                  options={tempMonthList}
                  defaultValue={convertMonthToList(monthSearch)}
                  onChange={(e: any) => _onChangeSelect(e, "month")}
                  isClearable
                  placeholder="--- Chọn tháng  ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="w-full h-max flex items-center">
                <p className="w-2/6 font-bold">Chọn năm</p>
                <Select
                  className="z-[5] w-4/6"
                  options={yearList}
                  value={
                    selectedYear
                      ? selectedYear
                      : yearList.find((x: any) => x?.value == yearSearch)
                  }
                  onChange={(e: any) => _onChangeSelect(e, "year")}
                  isClearable
                  placeholder="--- Chọn năm  ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div
              className="w-full h-[45px] flex border-2 border-slate-300 rounded-md mt-2 hover:cursor-pointer hover:bg-white"
              onClick={() => _onClickToChangeNavigate("/working/task-totalall")}
            >
              <div className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all">
                <div className="w-max h-max">
                  <TopicRoundedIcon />
                </div>
                <p className="lg:text-lg text-xs font-bold">
                  CÁC VIỆC ĐÃ GIAO TRONG THÁNG {monthSearch}/{yearSearch}
                </p>
              </div>
              <div className="w-[45px] h-full flex justify-center items-center border-l-2 border-slate-300">
                <p className="p-1 bg-red-500 text-white font-semibold rounded-full text-xs">
                  {notiList?.countListAllHasDelivery}
                </p>
              </div>
            </div>

            {/* Back button */}
            {highManagerStorage === "" ? (
              <></>
            ) : (
              <div className="pl-2">
                <button
                  className="flex space-x-1 items-center text-primary hover:text-sky-600"
                  onClick={() => _onClickSubShowing("")}
                >
                  <ArrowBackIosIcon sx={{ fontSize: 14 }} />
                  <p>Quay lại</p>
                </button>
              </div>
            )}

            {/* Sub */}
            {highManagerStorage !== "" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Element */}

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-proccessing")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListProccessing}
                      color="error"
                    >
                      <WorkIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc đang làm</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-finish")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListCompleted}
                      color="error"
                    >
                      <HourglassFullRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc chờ duyệt</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickSubShowing("complete")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={
                        (notiList?.countListLateComplete || 0) +
                        (notiList?.countListEarlyComplete || 0) +
                        (notiList?.countListPunctualCompleted || 0)
                      }
                      color="error"
                    >
                      <DoneRounded sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-full h-max max-w-full">
                    <p className="">Báo cáo</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-return")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListReturnWork}
                      color="error"
                    >
                      <KeyboardReturnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-full h-max max-w-full">
                    <p className="">Các việc bị trả về</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-change-deadline")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListChangeDeadLine}
                      color="error"
                    >
                      <CalendarMonthRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-full h-max max-w-full">
                    <p className="">Các việc xin gia hạn</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigate("/working/task-delay")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge badgeContent={notiList?.countListLate} color="error">
                      <AccessTimeFilledOutlined sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc đang bị trễ</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigate("/voting/voting_work")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListAllNeedToJoin}
                      color="error"
                    >
                      <ThumbsUpDownRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc cần biểu quyết</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => {
                    _onClickNavigate("/propose/receiver/list");
                    dispatch(ONCHANGE_RESET_FILTER_PROPOSE2({}));
                  }}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListProposerReceived}
                      color="error"
                    >
                      <BalanceRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Đề xuất chờ duyệt</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickSubShowing("result")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={
                        (notiList?.countNumberNumberGood || 0) +
                        (notiList?.countNumberNumberMedium || 0) +
                        (notiList?.countNumberFail || 0) +
                        (notiList?.countNumberExcellent || 0)
                      }
                      color="error"
                    >
                      <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  {/* <table>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {
                        staffs.map((item: any, index: number) => (
                          <tr key={index} className="w-max h-[3rem] bg-white" style={{ backgroundColor: item?.ColorText + "20" }}>
                            <td className="text-center border border-slate-300">{index + 1}</td>
                            <td className="border border-slate-300 font-semibold" style={{ color: item?.ColorText }}>{item.FullName}</td>
                            <td className="border border-slate-300 font-semibold text-center">{item.Hours_in_month ?? 0}</td>
                            <td className="border border-slate-300 text-center">
                              <div className="w-full h-full flex flex-col justify-center items-center">
                                <p className="font-semibold">{item.Hours_in_month_used ?? 0}</p>
                                <p>({item.Hours_in_month_used_percent}%)</p>
                              </div>
                            </td>
                            <td className="border border-slate-300">
                              <div className="w-full h-full flex flex-col justify-center items-center">
                                <p className="font-semibold">{item.Hours_in_month_remaining ?? 0}</p>
                                <p>({item.Hours_in_month_remaining_percent}%)</p>
                              </div>
                            </td>
                            <td className="text-center border border-slate-300">
                              <button className="text-primary hover:underline"
                                  onClick={() => {
                                    dispatch(ONCHAGE_FILTER_SELECTED_NGUOI_NHAN(item.Id))
                                    _onClickNavigate("/working/task-total")
                                  }}
                                >
                                  Xem
                                </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                 

                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {
                        staffs.map((item: any, index: number) => (
                          <tr key={index} className="w-max h-[3rem] bg-white" style={{ backgroundColor: item?.ColorText + "20" }}>
                            <td className="text-center border border-slate-300">{index + 1}</td>
                            <td className="border border-slate-300 font-semibold" style={{ color: item?.ColorText }}>{item.FullName}</td>
                            <td className="border border-slate-300 font-semibold text-center">{item.Hours_in_month ?? 0}</td>
                            <td className="border border-slate-300 text-center">
                              <div className="w-full h-full flex flex-col justify-center items-center">
                                <p className="font-semibold">{item.Hours_in_month_used ?? 0}</p>
                                <p>({item.Hours_in_month_remaining_percent}%)</p>
                              </div>
                            </td>
                            <td className="border border-slate-300">
                              <div className="w-full h-full flex flex-col justify-center items-center">
                                <p className="font-semibold">{item.Hours_in_month_remaining ?? 0}</p>
                                <p>({item.Hours_in_month_used_percent}%)</p>
                              </div>
                            </td>
                            <td className="text-center border border-slate-300">
                              <button className="text-primary hover:underline"
                                  onClick={() => {
                                    dispatch(ONCHAGE_FILTER_SELECTED_NGUOI_NHAN(item.Id))
                                    _onClickNavigate("/working/task-total")
                                  }}
                                >
                                  Xem
                                </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table> */}

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả công việc đã hoàn thành</p>
                  </div>
                </button>
              </div>
            )}
            <div className="w-full h-[45px] flex border-2 border-slate-300 rounded-md mt-2 hover:cursor-pointer hover:bg-white">
              <div className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all">
                <div className="w-max h-max">
                  <TopicRoundedIcon />
                </div>
                <p className="lg:text-lg text-xs font-bold">
                  DANH SÁCH NHÂN VIÊN
                </p>
              </div>
            </div>
            <div
              className="order-1 tableFixHead styled-scrollbars styled-scrollbars w-full h-max shadow-md"
              style={{
                overflow: isMobile ? "auto" : "none",
                width: isMobile ? "calc(100vw - 5rem)" : "auto",
              }}
            >
              <table className="pb-4">
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">
                      Tên nhân viên
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Tổng số giờ
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Số giờ sử dụng
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Số giờ còn lại
                    </th>
                    <th className="border-l-[1px] border-slate-300"></th>
                  </tr>
                </thead>

                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {staffs?.map((item: any, index: number) => (
                    <tr
                      key={index}
                      className="w-max h-[3rem] bg-white"
                      style={{
                        backgroundColor: item?.ColorText + "20",
                        color: item?.ColorText,
                      }}
                    >
                      <td className="text-center border border-slate-300">
                        {index + 1}
                      </td>
                      <td
                        className="border border-slate-300 font-semibold"
                        style={{ color: item?.ColorText }}
                      >
                        {item.FullName}
                      </td>
                      <td className="border border-slate-300 font-semibold text-center">
                        {item.Hours_in_month ?? 0}
                      </td>
                      <td className="border border-slate-300 text-center">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p className="font-semibold">
                            {item.Hours_in_month_used ?? 0}
                          </p>
                          <p>({item.Hours_in_month_used_percent}%)</p>
                        </div>
                      </td>
                      <td className="border border-slate-300">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p className="font-semibold">
                            {item.Hours_in_month_remaining ?? 0}
                          </p>
                          <p>({item.Hours_in_month_remaining_percent}%)</p>
                        </div>
                      </td>
                      <td className="text-center border border-slate-300">
                        <button
                          className="text-primary hover:underline"
                          onClick={() => {
                            dispatch(
                              ONCHAGE_FILTER_SELECTED_NGUOI_NHAN(item.Id)
                            );
                            _onClickNavigate("/working/task-total");
                          }}
                        >
                          Xem
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Complete */}
            {highManagerStorage !== "complete" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Elements */}
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-finish-early")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListEarlyComplete}
                      color="error"
                    >
                      <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành sớm</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-finish-punctual")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListPunctualCompleted}
                      color="error"
                    >
                      <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành đúng tiến độ</p>
                  </div>
                </button>
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/task-finish-late")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countListLateComplete}
                      color="error"
                    >
                      <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành trễ</p>
                  </div>
                </button>
              </div>
            )}

            {/* Result */}
            {highManagerStorage !== "result" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Elements */}
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResult(1)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countNumberExcellent}
                      color="error"
                    >
                      <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả xuất sắc</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResult(2)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countNumberNumberGood}
                      color="error"
                    >
                      <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả tốt</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResult(3)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countNumberNumberMedium}
                      color="error"
                    >
                      <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả trung bình</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResult(4)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList?.countNumberFail}
                      color="error"
                    >
                      <CloseIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả kém</p>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>

        {/* <div className="flex flex-col w-full h-max text-sm space-y-8">
                    <button className="flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p>1. CÁC VIỆC ĐÃ GIAO</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
              
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">1.1. Các việc đang làm</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
<p className="hover:underline">1.2. Các việc đã làm xong</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">1.3. Các việc bị trả về</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">1.4. Các việc xin gia hạn</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">1.5. Các việc đang bị trễ</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">2. CÁC VIỆC CHỜ DUYỆT VÀ ĐÁNH GIÁ KẾT QUẢ</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">3. CÁC VIỆC CẦN BIỂU QUYẾT</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">4. ĐỀ XUẤT CHỜ DUYỆT</p>
<p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">5. KẾT QUẢ CÔNG VIỆC ĐÃ HOÀN TẤT</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">5.1. Kết quả xuất sắc</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">5.2. Kết quả tốt</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">5.3. Kết quả xấu</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>

                    <button className="ml-10 flex space-x-2">
                      <div className="text-primary flex space-x-2 text-2xl font-medium items-center">
                        <AddBoxIcon />
                        <p className="hover:underline">5.4. Kết quả kém</p>
                        <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                      </div>
                    </button>
                  </div> */}
      </div>

      {/* rightside */}
      <div className="lg:w-1/2 w-full h-max flex flex-col space-y-5 lg:pl-6 pl-0 lg:border-l-2 border-l-0 border-slate-200">
        {/* <div className="w-full bg-amber-500 h-max text-cyan-800 flex justify-center items-center rounded-xl text-center" style={{ padding: 5 }}>
          <p className="font-semibold text-2xl">QUẢN LÝ NHẬN VIỆC</p>
        </div> */}
        <div className="w-full h-max flex justify-center items-center">
          <img
            className="pointer-events-none"
            style={{ width: "var(--px-180)" }}
            src={receiverImage}
            alt=""
          />
        </div>

        <div className="flex flex-col w-full h-max text-sm space-y-6">
          {/* Elements with sub */}
          <div className="w-full h-max flex flex-col space-y-3">
            {/* Main */}
            <div className="w-full h-[45px] flex border-2 border-slate-300 rounded-md">
              <div
                className="w-[calc(100%-45px)] h-full flex items-center px-3 text-amber-500 rounded-md space-x-3 transition-all hover:cursor-pointer hover:bg-white"
                onClick={() =>
                  _onClickToChangeNavigate("/working/receiver/all-task")
                }
              >
                <div className="w-max h-max">
                  <TopicRoundedIcon />
                </div>
                <p className="lg:text-lg text-xs font-bold">
                  CÁC VIỆC ĐÃ NHẬN TRONG THÁNG {monthSearch}/{yearSearch}
                </p>
              </div>
              <div className="w-[45px] h-full flex justify-center items-center border-l-2 border-slate-300">
                <p className="p-1 bg-red-500 text-white font-semibold rounded-full text-xs">
                  {notiList_NguoiNhan?.countListAll}
                </p>
              </div>
            </div>

            {/* Back button */}
            {mediumManagerStorage === "" ? (
              <></>
            ) : (
              <div className="pl-2">
                <button
                  className="flex space-x-1 items-center text-primary hover:text-sky-600"
                  onClick={() => _onClickSubShowing_NhanViec("")}
                >
                  <ArrowBackIosIcon sx={{ fontSize: 14 }} />
                  <p>Quay lại</p>
                </button>
              </div>
            )}

            {/* Sub */}
            {mediumManagerStorage !== "" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Element */}
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/receiver/task-new")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListNew}
                      color="error"
                    >
                      <AddAlarmIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc mới được giao</p>
                  </div>
                </button>
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                  onClick={() =>
                    _onClickToChangeNavigate(
                      "/working/receiver/task-processing"
                    )
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListWorking}
                      color="error"
                    >
                      <WorkIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc đang làm</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/receiver/task-delay")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListLated}
                      color="error"
                    >
                      <AccessTimeFilledOutlined sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các việc đang bị trễ</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                  onClick={() =>
                    _onClickToChangeNavigate("/working/receiver/task-waiting")
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListCompleted}
                      color="error"
                    >
                      <HourglassFullRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Các nhiệm vụ đã hoàn thành chờ đánh giá</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                  onClick={() =>
                    _onClickToChangeNavigate(
                      "/working/receiver/task-returnwork"
                    )
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countReturnWork}
                      color="error"
                    >
                      <KeyboardReturnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-full h-max max-w-full">
                    <p className="">Các việc bị trả về</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2"
                  onClick={() => _onClickSubShowing_NhanViec("complete")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={
                        (notiList_NguoiNhan?.countListPunctualCompleted || 0) +
                        (notiList_NguoiNhan?.countListLateComplete || 0) +
                        (notiList_NguoiNhan?.countListEarlyComplete || 0)
                      }
                      color="error"
                    >
                      <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-full h-max max-w-full">
                    <p className="">Các việc đã duyệt và đánh giá kết quả</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickSubShowing_NhanViec("result")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={
                        (notiList_NguoiNhan?.countNumberNumberGood || 0) +
                        (notiList_NguoiNhan?.countNumberNumberMedium || 0) +
                        (notiList_NguoiNhan?.countNumberFail || 0) +
                        (notiList_NguoiNhan?.countNumberExcellent || 0)
                      }
                      color="error"
                    >
                      <ContentPasteRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả công việc đã hoàn thành</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickSubShowing_NhanViec("reporting")}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge>
                      <AutoStoriesIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Báo cáo / Kế hoạch hành động</p>
                  </div>
                </button>
              </div>
            )}

            {/* Complete */}
            {mediumManagerStorage !== "complete" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Elements */}
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate(
                      "/working/receiver/task-finish_early"
                    )
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListEarlyComplete}
                      color="error"
                    >
                      <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành sớm</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate(
                      "/working/receiver/task-finish_puntual"
                    )
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={
                        notiList_NguoiNhan?.countListPunctualCompleted
                      }
                      color="error"
                    >
                      <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành đúng tiến độ</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() =>
                    _onClickToChangeNavigate(
                      "/working/receiver/task-finish_late"
                    )
                  }
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countListLateComplete}
                      color="error"
                    >
                      <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Hoàn thành trễ</p>
                  </div>
                </button>
              </div>
            )}

            {/* Report */}
            {mediumManagerStorage !== "reporting" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Elements */}
                <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge color="error">
                      <AssessmentIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Báo cáo chủ động</p>
                  </div>
                </button>

                <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge color="error">
                      <ContentPasteGoIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Báo cáo bị động</p>
                  </div>
                </button>

                <button className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all">
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge color="error">
                      <StickyNote2Icon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kế hoạch hành động</p>
                  </div>
                </button>
              </div>
            )}

            {/* Result */}
            {mediumManagerStorage !== "result" ? (
              <></>
            ) : (
              <div className={`w-full h-max grid ${classResponsiveGrid} gap-6`}>
                {/* Elements */}
                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResultReceiver(1)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countNumberExcellent}
                      color="error"
                    >
                      <TaskRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả xuất sắc</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResultReceiver(2)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countNumberNumberGood}
                      color="error"
                    >
                      <EventAvailableRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả tốt</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResultReceiver(3)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countNumberNumberMedium}
                      color="error"
                    >
                      <AlarmOnRoundedIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả trung bình</p>
                  </div>
                </button>

                <button
                  className="w-full text-primary font-semibold hover:text-sky-600 h-max flex flex-col rounded-full justify-center items-center space-y-2 transition-all"
                  onClick={() => _onClickNavigateTaskResultReceiver(4)}
                >
                  <div className="w-[70px] h-[70px] shadow-sm flex justify-center items-center bg-slate-300 rounded-full">
                    <Badge
                      badgeContent={notiList_NguoiNhan?.countNumberFail}
                      color="error"
                    >
                      <CloseIcon sx={{ fontSize: "45px" }} />
                    </Badge>
                  </div>

                  <div className="w-max h-max max-w-full">
                    <p className="">Kết quả kém</p>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MediumManagerUI);
