import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_TASKLIST_WITH_NORMAL,
} from "../../../redux/reducers/workingReducer/_index";
import {
  CONFIG_IS_FORCE,
  NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
} from "../../../apis/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import LoaderSpinner from "../../loader-spinner/_index";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";
import { Checkcolor } from "../../../funcs/checkColor";
import { convertDayTime } from "../../../funcs/convertDayTime";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";
const TotalTaskWithBossJSX = () => {
  _onClickOther();
  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);
  const [bienDoTreHan, setbienDoTreHan] = useState<any>(null);
  const [completedEarly, setCompletedEarly] = useState<any>(null);

  const link = window.location.pathname;

  const shouldLog = useRef(true);

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
      },
      workingData.searchFilter.status,
      workingData.searchFilter.selectedBoss,
      workingData.searchFilter.result
    );
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  useEffect(() => {
    _fetchData(workingData.pageCurrent, workingData.choosePage);
  }, [workingData.choosePage, workingData.pageCurrent]);

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("readTotalTask_nguoigiao");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickCompleteTaskWithId = (id: any) => {
    context.setFuncs("taskNeedConfirm");
    context.setTaskId(id);
    context.handleOpen();
  };

  useEffect(() => {
    fetchBienDoTreHan();
  }, []);

  const _onClickRemindTaskWithId = (id: any) => {
    context.setFuncs("notiTask_Model");
    context.setTaskId(id);
    context.handleOpen();
  };
  // Ví dụ sử dụng JavaScript
  // const responseData = { "Data": [{"BienDoTreHan": 10 }]};

  // Trích xuất giá trị BienDoTreHan từ dữ liệu API
  // const bienDoTreHan = responseData.Data[0].BienDoTreHan;
  async function fetchBienDoTreHan() {
    const accessToken = localStorage.getItem("accessToken"); // => Laco rieng biet
    try {
      await ServiceUtils.getV2(CONFIG_IS_FORCE, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setbienDoTreHan(res?.data?.Data[0]?.BienDoTreHan);
          setCompletedEarly(res?.data?.Data[0]?.CompletedEarly);
        } else {
          toast.error("Tải dữ liệu thất bại");
        }
      });
    } catch (error) {
      toast.error("Tải dữ liệu thất bại");
    }
  }
  const _renderStatus = (item: any) => {
    const currentTime = new Date().getTime();
    const deadlineTime = new Date(item?.DeadLine).getTime();
    const newDeadline = new Date(item?.NewDeadline).getTime();
    const assignmentDate = new Date(item?.AssignmentDate).getTime();
    const finishedDate = new Date(item?.FinishedDate).getTime();
    const isInProgres =
      assignmentDate <= currentTime && currentTime <= deadlineTime;
    const isWithinExtraTime =
      deadlineTime <= currentTime && currentTime <= newDeadline;
    const isWithinExtraTime_ =
      deadlineTime <= finishedDate && finishedDate <= newDeadline;

    if (item?.TaskStatus === 4) {
      if (!item?.IsCompleted_History) {
        if (isInProgres) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      } else {
        if (assignmentDate <= finishedDate && finishedDate <= deadlineTime) {
          return (
            <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong tiến độ
            </p>
          );
        } else if (isWithinExtraTime_) {
          return (
            <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trong biên độ trễ hạn
            </p>
          );
        } else {
          return (
            <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
              Trễ hạn
            </p>
          );
        }
      }
    } else if (item?.TaskStatus === 5) {
      const timeEarlyCompleted = new Date(
        deadlineTime - ((deadlineTime - assignmentDate) / 100) * completedEarly
      ).getTime();
      if (timeEarlyCompleted >= finishedDate && finishedDate <= deadlineTime) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Sớm hạn
          </p>
        );
      } else if (
        timeEarlyCompleted < finishedDate &&
        finishedDate <= deadlineTime
      ) {
        return (
          <p className="bg-pink-700 text-white w-max p-1 rounded-md text-xs font-semibold">
            Đúng hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    } else if (item?.TaskStatus === 2 || item?.TaskStatus === 3) {
      if (isInProgres) {
        return (
          <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong tiến độ
          </p>
        );
      } else if (isWithinExtraTime) {
        return (
          <p className="bg-red-400 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trong biên độ trễ hạn
          </p>
        );
      } else {
        return (
          <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
            Trễ hạn
          </p>
        );
      }
    }
  };
  return (
    <>
      {workingData.taskWithNormal === null ? (
        // <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10 core-table-height">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.taskWithNormal?.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.taskWithNormal.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người đánh giá</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Kết quả</p>
                    </div>
                  </th>

                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.taskWithNormal === null ? (
                  <tr></tr>
                ) : (
                  workingData.taskWithNormal?.data?.map(
                    (item: any, index: any) => (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {index +
                            1 +
                            workingData.pageCurrent * workingData.choosePage}
                        </td>
                        <td className="border border-slate-300 space-y-0.5">
                          <p className="lg:hidden font-bold">
                            <span className="lg:hidden">
                              {index +
                                1 +
                                workingData.pageCurrent *
                                  workingData.choosePage}
                              .&nbsp;
                            </span>
                            {item.TaskName}
                          </p>
                          <p className="hidden lg:flex">{item.TaskName}</p>
                          <p className="lg:hidden">
                            <span className="text-xs"> Người giao: </span>
                            <span className="font-semibold">
                              {item.TaskOwnerName}
                            </span>
                          </p>
                          <p className="lg:hidden">
                            <span className="text-xs">Người đánh giá: </span>
                            <span className="font-semibold">
                              {item.TaskReviewerName}
                            </span>
                          </p>
                          <p className="lg:hidden">
                            <span className="text-xs">Người nhận: </span>
                            <span className="font-semibold">
                              {item.RecipientName}
                            </span>
                          </p>
                          {item.SupporterName !== null && (
                            <p className="lg:hidden">
                              <span className="text-xs">
                                Người hỗ trợ:&nbsp;
                              </span>
                              <span className="text-slate-600">
                                {item.SupporterName}
                              </span>
                            </p>
                          )}

                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">Thời hạn: </span>
                              <span className="italic">
                                {moment(item.AssignmentDate).format("HH:mm")}
                                &nbsp;
                                {moment(item.AssignmentDate).format("DD/MM")}
                              </span>
                              &nbsp;-&nbsp;
                              <span className="italic">
                                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                {moment(item.DeadLine).format("DD/MM")}
                              </span>
                              <p
                                style={{
                                  color: Checkcolor(
                                    item.PriorityLevelID,
                                    appData
                                  ),
                                }}
                                className={`font-bold mr-2 ${Checkcolor(
                                  item.PriorityLevelID,
                                  appData
                                )}`}
                              >
                                {item.PriorityLevelName}
                              </p>
                            </p>
                          </div>

                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">
                                Kết quả:{" "}
                                <strong>{item.TaskScorecardName}</strong>
                              </span>
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="work-status flex gap-1 flex-wrap">
                              {item.TaskStatus === 2 ? (
                                <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Chưa nhận
                                </p>
                              ) : null}
                              {item.TaskStatus === 3 ? (
                                <p className="bg-sky-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Chưa xử lý
                                </p>
                              ) : null}
                              {item?.TaskStatus === 4 ? (
                                <>
                                  {!item?.IsCompleted_History ? (
                                    <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                      Đang xử lý
                                    </p>
                                  ) : (
                                    <p className="bg-orange-300 text-white w-max p-1 rounded-md text-xs font-semibold">
                                      Đã báo cáo chờ duyệt
                                    </p>
                                  )}
                                </>
                              ) : null}
                              {item.TaskStatus === 5 ? (
                                <>
                                  <p className="bg-teal-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                    Hoàn tất
                                  </p>
                                </>
                              ) : null}
                              {<>{_renderStatus(item)}</>}
                              {item.NumberReturnWork > 0 && (
                                <p className="text-red-500 text-xs lg:text-sm font-semibold">
                                  Bị trả {item.NumberReturnWork} lần
                                </p>
                              )}
                            </div>

                            <div className="lg:hidden ml-3 ">
                              <div className="dropdown2">
                                {/* button options */}
                                <button
                                  className="dropbtn2"
                                  onClick={() =>
                                    _onClickFuncs(index.toString())
                                  }
                                >
                                  ...
                                </button>

                                {/* options fields */}
                                {item.TaskStatus === 5 ? (
                                  <div
                                    id={index.toString()}
                                    className="dropdown-content2 text-xs font-semibold"
                                  >
                                    {/* detail */}
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onClickCompleteTaskWithId(item.ID)
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <DescriptionTwoToneIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Chi tiết</div>
                                      </div>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    id={index.toString()}
                                    className="dropdown-content2 text-xs font-semibold"
                                  >
                                    {/* detail */}
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onClickEditTaskWithId(item.ID)
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <DescriptionTwoToneIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Chi tiết</div>
                                      </div>
                                    </button>

                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onClickRemindTaskWithId(item.ID)
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <NotificationsActiveIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Nhắc việc</div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.TaskOwnerName}
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {item.TaskReviewerName}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.RecipientName}
                          {item.SupporterName && (
                            <p className="text-xs">
                              <span className="text-slate-600">Hỗ trợ:</span>{" "}
                              {item.SupporterName}
                            </p>
                          )}
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p>{item.TaskScorecardName}</p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="dropdown">
                            {/* button options */}
                            <button className="dropbtn">
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            {/* options fields */}
                            {item.TaskStatus === 5 ? (
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickCompleteTaskWithId(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickEditTaskWithId(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>

                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickRemindTaskWithId(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <NotificationsActiveIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Nhắc việc</div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TotalTaskWithBossJSX;
