import axios from "axios";
import { CREATE_TASK_LIST_NGUOI_GIAO, CREATE_TASK_RECOVERY_API, GET_LIST_ORGANIZATION_BY_CURRENT_USER, GET_LIST_ORGANIZATION_DROPDOWN, READ_PARAMETER, RECOVERY_TASK_API } from "../../apis/_index";
import { STORAGE_KEYS } from "../../constants/constants";
import { getAccessToken } from "../../utils/users/userUtils";
import ServiceUtils from "../../utils/services";

class OrganizationRepository {
    static async getListByCurrentUser() {
        return new Promise(async resolve => {
            // const accessToken = getAccessToken();

            await ServiceUtils.getV2(GET_LIST_ORGANIZATION_BY_CURRENT_USER, {
                headers: {
                    Accept: "text/plain",
                    'Content-Type': "application/json",
                },
            })
                .then((res: any) => {
                    if (((res || {}).data || {}).statusCode === 200) {
                        resolve({
                            status: true,
                            data: ((res || {}).data || {}).data || [],
                            message: ((res.data || {}).data || {}).message
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).data || {}).message
                        });
                    }
                });
        });
    }

    static async getListDropdown() {
        return new Promise(async resolve => {
            // const accessToken = getAccessToken();

            await ServiceUtils.getV2(GET_LIST_ORGANIZATION_DROPDOWN, {
                headers: {
                    Accept: "text/plain",
                    'Content-Type': "application/json",
                },
            })
                .then((res: any) => {
                    console.log(res);

                    if (((res || {}).data || {}).StatusCode === 200) {
                        resolve({
                            status: true,
                            data: ((res || {}).data || {}).Data || [],
                            message: ((res.data || {}).data || {}).Message
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).data || {}).Message
                        });
                    }
                });
        });
    }

    static getList() {
        return new Promise(async (resolve: any) => {
            const api = `${READ_PARAMETER}?key=LI_PHONGBAN`;

            ServiceUtils.getV2(api, {

            })
                .then((res: any) => {
                    if (res.status == 200) {
                        resolve({
                            data: (res.data || {}).Data || [],
                            status: true,
                            message: ((res.data || {}).data || {}).Message
                        });
                    } else {
                        resolve({
                            status: false,
                            message: ((res.data || {}).data || {}).Message
                        });
                    }
                });
        });
    }
}

export default OrganizationRepository;