import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import DoneIcon from '@mui/icons-material/Done';

import { ModalController } from '../../../ui/homeUI/_index';
import LoaderSpinner from "../../loader-spinner/_index";
import { GET_LIST_ORGANIZATION_DROPDOWN, GET_LIST_POSITION_BY_ORGANIZATION_DROPDOWN, GET_LIST_POSITION_DROPDOWN, READ_PARAMETER } from "../../../apis/_index";
import { ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { CREATE_PAREMETER } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import { convertNameParameter } from "../../../helperText/index";
import { ORGANIZATION_PARAMETER_TYPES, PARAMETER_KEYS, SETTING_ORGANIZATION_TYPES } from "../../../constants/dataConstants";

import Select, { StylesConfig } from "react-select";
import ServiceUtils from '../../../utils/services';

import '../../../styles/createListOfOrganizations/index.css';

const CreateListOfOrganization = (props: any) => {
    const taskId = props.taskId;
    const handleClose = props.handleClose;

    const context = useContext(ModalController);
    const data = useSelector((state: any) => state.settingReducer);
    const dispatch = useDispatch();

    const [parameterName, setParameterName] = useState<string>("");
    const [parameterGroup, setParameterGroup] = useState<any>(0);
    const [isActive, setIsActive] = useState<boolean>(true);

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [type, setType] = useState<any>(-1);
    const [organizations, setOrganization] = useState<any>([]);
    const [positions, setPosition] = useState<any>([]);
    const [positionUpperLevels, setPositionUpperLevel] = useState<any>([]);
    const [organizationParent, setOrganizationParent] = useState<any>(-1);
    const [upperLevelManager, setUpperLevelManager] = useState<any>(-1);
    const [mainManager, setMainManager] = useState<any>(-1);
    const [subManager, setSubManager] = useState<any>([]);

    useEffect(() => {
        if (
            parameterName === ""
            || parameterGroup === 0
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [parameterName, parameterGroup]);

    useEffect(() => {
        getListOrganization();

        getListPosition();

        const item = (context.dataModal || {}).item;

        if (item) {
            setOrganizationParent(item.ParameterID);

            getListPositionByOrganization(item.ParameterID);
        }
    }, []);

    const getListOrganization = () => {
        const accessToken = localStorage.getItem("accessToken");

        try {
            ServiceUtils.getV2(GET_LIST_ORGANIZATION_DROPDOWN, {
                    headers: {
                        Accept: "text/plain",
                    },
                })
                .then((res: any) => {
                    if ((res.data || {}).StatusCode === 200) {
                        const organizations = ((res.data || {}).Data || {}).organizations || [];

                        setOrganization(organizations);
                    } else {
                        toast.error("Lấy dữ liệu phòng ban thất bại!");
                    }
                });
        } catch (error: any) {
            toast.error("Lấy dữ liệu phòng ban thất bại!");
        }
    }

    const getListPosition = () => {
        // const accessToken = localStorage.getItem("accessToken");

        try {
            ServiceUtils.getV2(GET_LIST_POSITION_DROPDOWN, {
                    headers: {
                        Accept: "text/plain",
                    },
                })
                .then((res: any) => {
                    if ((res.data || {}).StatusCode === 200) {
                        const positions = (((res.data || {}).Data || {}).positions || []).map((p: any) => {
                            return {
                                label: p.ParameterValue,
                                value: p.ParameterId
                            };
                        });

                        setPosition(positions);
                    } else {
                        toast.error("Lấy dữ liệu chức vụ thất bại!");
                    }
                });
        } catch (error: any) {
            toast.error("Lấy dữ liệu chức vụ thất bại!");
        }
    }

    const getListPositionByOrganization = (organizationId: any) => {
        // const accessToken = localStorage.getItem("accessToken");

        try {
            ServiceUtils.getV2(GET_LIST_POSITION_BY_ORGANIZATION_DROPDOWN.replace('{organizationId}', organizationId), {
                    headers: {
                        Accept: "text/plain",
                    },
                })
                .then((res: any) => {
                    console.log(res);

                    if ((res.data || {}).StatusCode === 200) {
                        const positionUpperLevels = ((res.data || {}).Data || {}).positions || [];

                        setPositionUpperLevel(positionUpperLevels);
                    } else {
                        toast.error("Lấy dữ liệu chức vụ thất bại!");
                    }
                });
        } catch (error: any) {
            toast.error("Lấy dữ liệu chức vụ thất bại!");
        }
    }

    const onChangeOrganizationParent = (e: any) => {
        const value = e.target.value;

        setOrganizationParent(value);

        getListPositionByOrganization(value);
    }

    const onChangeUpperLevelManager = (e: any) => {
        const value = e.target.value;

        setUpperLevelManager(value);
    }

    const onChangeSubManager = (data: any) => {
        // const value = e.target.value;

        // setSubManager(value);

        setSubManager(data);
    }

    const onChangeMainManager = (e: any) => {
        const value = e.target.value;

        setMainManager(value);
    }

    const _onChangeParameterName = (e: any) => {
        e.preventDefault();
        setParameterName(e.target.value);
    }

    const _onChangeParameterGroup = (e: any) => {
        e.preventDefault();
        setParameterGroup(e.target.value);
    }

    const _onChangeIsActive = () => {
        setIsActive((data: any) => !data);
    }

    const _onChangeType = (e: any) => {
        const value = e.target.value;

        setType(value);
    }

    // funcs
    const _onSubmit = () => {
        if (
            !parameterName || (ORGANIZATION_PARAMETER_TYPES.department != type && ORGANIZATION_PARAMETER_TYPES.organization != type && ORGANIZATION_PARAMETER_TYPES.partAndTeam != type)
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
            setIsSuccess(false);
            setIsLoading(true);

            const body:any = {
                ParameterKey: PARAMETER_KEYS.organization,
                ParameterValue: parameterName,
                IsActive: isActive,
                RefID: organizationParent,
                ParameterType: type,
                UpperLevelManager: upperLevelManager,
                MainManager: mainManager,
                SubManager: subManager.map((p: any) => p.value)
            }

            const accessToken = localStorage.getItem("accessToken");

            try {
                ServiceUtils.postV2(
                    CREATE_PAREMETER,
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then((res: any) => {
                        console.log(res);

                        if (res.status === 200) {
                            setIsLoading(false);
                            setIsSuccess(true);

                            const callBack = (context.dataModal || {}).callBack;

                            if (callBack) {
                                callBack();
                            }

                            setParameterName('');
                            setType(-1);
                            setOrganizationParent(-1);
                            setUpperLevelManager(-1);
                            setMainManager(-1);
                            setSubManager([]);

                            context.handleClose();
                        } else {
                            setIsLoading(false);

                            toast.error("Thêm danh mục phòng ban thất bại!");
                        }
                    })
            } catch (error: any) {
                setIsLoading(false);

                toast.error("Thêm danh mục phòng ban thất bại!");
            }
        }
    }

    return (
        <div style={{
            maxHeight: '100vh'
        }} className="
    flex flex-col bg-white
    lg:w-[30rem] w-screen
    rounded-md
  ">
            {/* header */}
            <div className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      ">
                <p>Tạo danh mục phòng ban</p>
                <button
                    className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </button>
            </div>

            {/* content */}
            <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
                {/* lines */}
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full pr-0 h-max">
                        <label>Tên danh mục phòng ban&nbsp;<span className="text-red-500">(*)</span></label>
                        <input
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={parameterName}
                            onChange={(e: any) => { _onChangeParameterName(e) }}
                            onTouchStart={(e: any) => onTouchForm(e)}
                        />
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Loại tổ chức&nbsp;<span className="text-red-500">(*)</span></label>
                        <select
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={type}

                            onChange={(e: any) => _onChangeType(e)}
                        >
                            <option className="text-gray-300" value={-1} disabled>--- Chọn loại tổ chức ---</option>
                            {SETTING_ORGANIZATION_TYPES.map((item: any, index: any) => (
                                <option key={index} value={item.value}>{item.label}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Thuộc tổ chức&nbsp;<span className="text-red-500"></span></label>
                        <select
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={organizationParent}

                            onChange={(e: any) => onChangeOrganizationParent(e)}
                        >
                            <option className="text-gray-300" value={-1}>--- Thuộc tổ chức ---</option>
                            {organizations.map((item: any, index: any) => (
                                <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Người quản lý từ cấp trên&nbsp;<span className="text-red-500"></span></label>
                        <select
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={upperLevelManager}

                            onChange={(e: any) => onChangeUpperLevelManager(e)}
                        >
                            <option className="text-gray-300" value={0}>--- Người quản lý từ cấp trên ---</option>
                            {positionUpperLevels.map((item: any, index: any) => (
                                <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Người quản lý chính&nbsp;<span className="text-red-500"></span></label>
                        <select
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={mainManager}

                            onChange={(e: any) => onChangeMainManager(e)}
                        >
                            <option className="text-gray-300" value={0}>--- Người quản lý chính ---</option>
                            {positions.map((item: any, index: any) => (
                                <option key={index} value={item.value}>{item.label}</option>
                            ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Người quản lý phụ&nbsp;<span className="text-red-500"></span></label>
                        {/* <select
                            multiple={true}
                            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                            value={subManager}

                            onChange={(e: any) => onChangeSubManager(e)}
                        >
                            <option className="text-gray-300" value={0}>--- Người quản lý phụ ---</option>
                            {positions.map((item: any, index: any) => (
                                <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                            ))
                            }
                        </select> */}
                        <Select
                            // ref={selectDauViec}
                            className="z-[5]"
                            options={positions}
                            value={subManager}
                            onChange={(data: any) => onChangeSubManager(data)}
                            isClearable
                            isMulti
                            placeholder="--- Người quản lý phụ ---"
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                        // defaultValue={tempDauViecList?.find(
                        //   (item) => item?.Id === selectedDauViec?.Id
                        // )}
                        />
                    </div>
                </div>
                <div className="flex w-full h-max">
                    <div className="flex flex-col w-full h-max">
                        <label>Hiển thị&nbsp;<span className="text-red-500"></span></label>
                        <input
                            type="checkbox"
                            className="w-5 h-5"
                            checked={isActive}
                            onChange={_onChangeIsActive}
                            onTouchStart={(e: any) => onTouchForm(e)}
                        />
                    </div>
                </div>
            </div>

            {/* footer */}
            <div className="flex w-full p-12-16 footer rounded-b-md justify-between items-center text-sm space-x-4">
                <div className="flex w-max h-max items-center">
                    <div className={`w-max h-max items-center ${isLoading === true ? "flex" : "hidden"}`}>
                        <LoaderSpinner h={32} w={32} />
                    </div>

                    <div className={`${isSuccess === true ? "flex" : "hidden"} w-max h-max items-center text-green-500`}>
                        <DoneIcon fontSize="small" />
                        <p className="font-bold">Đã thêm</p>
                    </div>
                </div>

                <div className="flex w-max h-max space-x-3">
                    <button className={`
          flex items-center space-x-1 w-max h-max p-2
          text-white rounded-md
          ${isValid === true || isLoading === true ? "bg-slate-400" : "bg-primary hover:bg-sky-400"}
        `}
                        onClick={_onSubmit}
                    >
                        <SaveIcon fontSize="small" />
                        <p>Tạo danh mục</p>
                    </button>

                    <button className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200 core-create-list-of-organization-button-close
        "
                        onClick={handleClose}
                    >
                        <ExitToAppTwoToneIcon fontSize="small" />
                        <p>Đóng</p>
                    </button>
                </div>
            </div>
            <div className={`absolute right-4 bottom-4 ${isValid === false ? "hidden" : "flex"} items-center space-x-4 w-max lg:pr-2 mt-5 pr-0 h-max`} style={{
                marginBottom: '30px'
            }}>
                <p className="text-red-500">Vui lòng điền đầy đủ thông tin</p>
            </div>
        </div>
    )
}

export default CreateListOfOrganization;
